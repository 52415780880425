import {Price} from "../pages/Billing/Checkout";
import {Dispatch} from "redux";
import baseApi from "../apis/baseApi";
import {CheckoutActionTypes} from "./types";
import {BillingPlan} from "../data/plans";

export interface CheckoutState {
    selectedPlan: BillingPlan | null
    selectedPaymentMethod: string | null
    prices: Price[]
    paymentMethods: PaymentMethod[]
    step: number
    loading: boolean
}

export type CheckoutAction = {
    type: CheckoutActionTypes,
    payload: any
}

export const fetchPrices = () => {
    return async (dispatch: Dispatch) => {
        baseApi.get<Price[]>("/cbb/products/").then((resp) => {
            dispatch<CheckoutAction>({
                type: CheckoutActionTypes.FETCHED_PRICES,
                payload: resp.data
            })
        }).catch((err) => {
            alert("Error")
        })
    }
}

export const fetchPaymentMethods = () => {
    return async (dispatch: Dispatch) => {
        baseApi.get<PaymentMethodRootObject>("/cbb/pay-methods/").then((resp) => {
            dispatch<CheckoutAction>({
                type: CheckoutActionTypes.FETCHED_PM,
                payload: resp.data.data
            })
        }).catch((err) => {
            alert("Error")
        })
    }
}

export const checkoutNextStep = () => {
    return {
        type: CheckoutActionTypes.NEXT_STEP,
        payload: null
    } as CheckoutAction
}

export const selectPlan = (plan: BillingPlan) => {
    return {
        type: CheckoutActionTypes.SELECT_PLAN,
        payload: plan
    }
}

export interface PaymentMethod {
    id: string;
    object: string;
    billing_details: BillingDetails;
    card: Card;
    created: number;
    customer?: any;
    livemode: boolean;
    type: string;
}

interface PaymentMethodRootObject {
    object: string;
    url: string;
    has_more: boolean;
    data: PaymentMethod[];
}

interface Address {
    city?: any;
    country?: any;
    line1?: any;
    line2?: any;
    postal_code?: any;
    state?: any;
}

interface BillingDetails {
    address: Address;
    email?: any;
    name?: any;
    phone?: any;
}

interface Checks {
    address_line1_check?: any;
    address_postal_code_check?: any;
    cvc_check?: any;
}

interface Networks {
    available: string[];
    preferred?: any;
}

interface ThreeDSecureUsage {
    supported: boolean;
}

interface Card {
    brand: string;
    checks: Checks;
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    generated_from?: any;
    last4: string;
    networks: Networks;
    three_d_secure_usage: ThreeDSecureUsage;
    wallet?: any;
}


