import React from 'react';
import {Row} from "react-bootstrap";
import MembershipCard from "./ui/MembershipCard";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {StudioPlansState} from "../../../actions/studioPlans";
import {PlanSaleItem} from "../index";
import {SaleItem} from "../../Reports/Sales";
import {addMonths, startOfMonth} from "date-fns"

interface Props {
    cart: PlanSaleItem[],
    setCart: (items: PlanSaleItem[]) => void
    cartItems: SaleItem[], // SALES V2
    setCartItems: (items: SaleItem[]) => void // SALES V2
}

const Memberships = ({cart, setCart, cartItems, setCartItems}: Props) => {
    const studioPlansState = useSelector<StoreState, StudioPlansState>(state => state.studioPlans)
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)

    return (
        <div>
            <div className="d-flex align-items-center mb-5">
                <h4 className="text-dark">Suscripciones</h4>
            </div>
            <Row className="h-100">
                {studioPlansState.plans.filter((p) => p.is_subscription).map(p => {
                    return <MembershipCard key={p.id} plan={p} currency={currency} onClick={() => {
                        if (!cart.map(i => i.plan).includes(p)) {
                            let activate_on = new Date()
                            if (p.bills_on_1st_of_month && activate_on.getDate() !== 1) {
                                activate_on = startOfMonth(addMonths(activate_on, 1))
                            }
                            setCart([{
                                plan: p,
                                quantity: 1,
                                price: parseFloat(p.price),
                                activate_on: activate_on
                            }])
                        }
                    }}/>
                })}
            </Row>
        </div>
    );
};

export default Memberships;
