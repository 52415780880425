import {OptionTypeBase, StylesConfig} from "react-select";

export const filterSelectStyle: StylesConfig<OptionTypeBase, false> = {
    control: base => ({
        ...base,
        minHeight: '30px',
        maxHeight: '30px',
        minWidth: '110px',
        maxWidth: '130px',
        fontSize: '12px'
    }),
    clearIndicator: base => ({
        ...base,
        padding: '0'
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: '0 2px 0 0'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 0 0 2px',
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        fontSize: '6px'
    }),
}