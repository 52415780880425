import {StudioMemberExtraFieldTypes} from "../actions";
import {StudioMemberExtraFieldsActions, StudioMemberExtraFieldsState} from "../actions/memberExtraFields";

const initialState = {
    fetching: true,
    extra_fields: []
} as StudioMemberExtraFieldsState

export const studioMemberProfileExtraFieldsReducer = (state = initialState, action: StudioMemberExtraFieldsActions) => {
    switch (action.type) {
        case StudioMemberExtraFieldTypes.FETCHED:
            return {fetching: false, extra_fields: action.payload}
        default:
            return state
    }
}