import React, {useEffect, useState} from 'react';
import baseApi from "../../../apis/baseApi";
import ToolboxHeader from "../ToolboxHeader";
import MiniFormField from "../../../components/form/MiniFormField";
import {Formik, Form as FormikForm, Field} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faClock,
    faDollarSign, faMoneyBillWave,
    faSave,
    faShoePrints,
    faUserTimes
} from '@fortawesome/free-solid-svg-icons';
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {Alert} from "react-bootstrap";
import {FormikSelect} from "../../../components/form/FormikSelect";
import Checkbox from "../../../components/form/Checkbox";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {formatISO, parse} from "date-fns";

export interface Penalizations {
    id: number,
    no_assistance_count_to_penalize_credits: number,
    "no_assistance_days_range_credits": number,
    "cash_amount": number,
    "no_assistance_count_to_penalize_membership": number,
    "no_assistance_days_range_membership": number,
}

export interface Preferences {
    id: number;
    hours_to_cancellation: number;
    penalizations: Penalizations;
    public: string | null
    payment_processor: string | null;
    paypal: boolean;
    shoe_size_country: string;
    uses_shoe_field: boolean;
    currency: string;
    automatic_waitlist: boolean;
    privacy_policy: string;
    terms_and_conditions: string;
    allow_member_in_shop_payment: boolean;
    requires_registration_fee: boolean;
    morning_cancellation_hour_limit: string | null
    morning_hours_to_cancellation: number | null
}

const Settings = () => {
    const [loading, setLoading] = useState(true)
    const [preferences, setPreferences] = useState<Preferences>()
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("");

    useEffect(() => {
        baseApi.get<Preferences>("/prefs/").then((resp) => {
            setPreferences(resp.data)
            setLoading(false)
        })
    }, [])

    const renderAlert = () => {
        if (success === null) return <React.Fragment/>
        return <Alert className="mb-5" variant={success ? "success" : "danger"}>{alertMsg}</Alert>
    }

    if (loading) return <div>Cargando...</div>
    return (
        <div style={{overflowY: "auto"}} className="p-4">
            <ToolboxHeader title="Preferencias"/>
            {renderAlert()}
            <Formik initialValues={{
                hours_to_cancellation: preferences?.hours_to_cancellation,
                shoe_size_country: preferences?.shoe_size_country,
                currency: preferences?.currency,
                uses_shoe_field: preferences?.uses_shoe_field,
                automatic_waitlist: preferences?.automatic_waitlist,
                terms_and_conditions: preferences?.terms_and_conditions,
                allow_member_in_shop_payment: preferences?.allow_member_in_shop_payment,
                requires_registration_fee: preferences?.requires_registration_fee,
                privacy_policy: preferences?.privacy_policy,
                morning_cancellation_hour_limit: !preferences?.morning_cancellation_hour_limit ? null : parse(preferences?.morning_cancellation_hour_limit!, "HH:mm:ss", new Date()),
                morning_hours_to_cancellation: preferences?.morning_hours_to_cancellation,
            }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        let data = values
                        if (values.morning_cancellation_hour_limit) {
                            const time = formatISO(values.morning_cancellation_hour_limit,{representation: "time"} )
                            console.log(time)
                            // @ts-ignore
                            data = {...values, morning_cancellation_hour_limit: time }
                        } else {
                            data.morning_hours_to_cancellation = null
                        }
                        baseApi.patch("/prefs/", data).then((resp) => {
                            setAlertMsg("Preferencias actualizadas correctamente")
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch((e) => {
                            setAlertMsg(getApiErrorMessage(e))
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }}>
                {({isSubmitting, setFieldValue, values}) => (
                    <FormikForm>
                        <div className="">
                            <h5><i className="text-dark mr-2"><FontAwesomeIcon icon={faDollarSign}/>
                            </i>Divisa</h5>
                            <div className="col-12 text-muted mt-n2 font-size-sm">*Esta es la divisa que se usara para
                                realizar cobros de paquetes y membresías
                            </div>
                            <div className="ml-5" style={{width: "300px"}}>
                                <Field isDisabled={true}  name="currency" component={FormikSelect}
                                       options={[
                                           {value: "ars", label: "Argentina - ARS"},
                                           {value: "cad", label: "Canada - CAD"},
                                           {value: "cop", label: "Colombia - COP"},
                                           {value: "eur", label: "Euro - €"},
                                           {value: "hnl", label: "Honduras - HNL"},
                                           {value: "mxn", label: "México - MXN"},
                                           {value: "pen", label: "Perú - SOL"},
                                           {value: "usd", label: "United States - USD"},
                                       ]}/>
                            </div>

                        </div>
                        <h5 className="mt-5">
                            <i className="text-dark mr-2">
                                <FontAwesomeIcon icon={faUserTimes}/>
                            </i>
                            Cancelaciones</h5>
                        <div className="form-group row ml-5 align-items-center">
                            <label className="col-auto col-form-label font-size-lg">
                                Los usuarios cuentan con
                            </label>
                            <MiniFormField name="hours_to_cancellation" type="number"/>

                            <label className="col-auto col-form-label font-size-lg ml-n4">
                                horas antes de su reservación para cancelarla
                            </label>
                            <div className="col-12 text-muted mt-n2 font-size-sm">Crédito sera devuelto</div>
                        </div>
                        <div className="text-muted">Opcional: </div>
                        <div className="form-group row ml-5 align-items-center">

                            <label className="col-auto col-form-label font-size-lg">
                                En horarios matutinos antes de las
                            </label>
                            <div className="customDatePickerWidth" style={{width: "80px"}}>
                                <ReactDatePicker
                                    isClearable
                                    showTimeSelect
                                    showTimeSelectOnly
                                    minTime={new Date(2023, 1, 1, 0)}
                                    maxTime={new Date(2023, 1, 1, 12)}
                                    locale={es}
                                    selected={values.morning_cancellation_hour_limit === null ? null : values.morning_cancellation_hour_limit}
                                    onChange={date => {
                                        setFieldValue("morning_cancellation_hour_limit", date)
                                        if (!date) {
                                            console.log(date)
                                            setFieldValue("morning_hours_to_cancellation", undefined)
                                        }
                                    }}
                                    dateFormat="h:mm aa"
                                    timeIntervals={15}
                                    timeCaption={"Hora"}
                                    customInput={<input className="form-control form-control-sm"/>}
                                />
                            </div>
                            <div className="px-2">
                                cuentan con
                            </div>
                            <MiniFormField name="morning_hours_to_cancellation" type="number"/>

                            <label className="col-auto col-form-label font-size-lg ml-n4">
                                horas antes de su reservación para cancelarla
                            </label>

                            <div className="col-12 text-muted mt-n2 font-size-sm">Crédito sera devuelto</div>
                        </div>
                        <div className="mb-5">
                            <h5>
                                <i className="text-dark mr-2">
                                    <FontAwesomeIcon icon={faClock}/>
                                </i>
                                Waitlist: Reservación automática</h5>
                            <div className="ml-5">
                                <Field name="automatic_waitlist" component={Checkbox}
                                       label="Al momento de liberarse un espacio, automáticamente se crea una reservación al primer usuario en la lista de espera."/>
                            </div>
                        </div>
                        <h5>
                            <i className="text-dark mr-2">
                                <FontAwesomeIcon icon={faShoePrints} rotation={270}/>
                            </i>
                            Zapatos</h5>
                        <div className="ml-5">
                            <Field name="uses_shoe_field" component={Checkbox}
                                   label="Mostrar campo talla de zapatos en registro de usuario"/>
                        </div>
                        <div className="ml-5 d-inline-flex align-content-center">
                            <div className="align-self-center mr-3">- País</div>
                            <div className="" style={{width: "300px"}}>
                                <Field name="shoe_size_country" component={FormikSelect}
                                       options={[{value: "MX", label: "México"}, {
                                           value: "US",
                                           label: "Estados Unidos"
                                       }]}/>
                            </div>
                        </div>
                        {/*TEMP HIDE INSCRIPCIONES CHECKBOX*/}
                        {/*<h5>*/}
                        {/*    <i className="text-dark mr-2 mt-2">*/}
                        {/*        <FontAwesomeIcon icon={faMoneyCheck}/>*/}
                        {/*    </i>*/}
                        {/*    Inscripción</h5>*/}
                        {/*<div className="ml-5">*/}
                        {/*    <Field name="requires_registration_fee" component={Checkbox}*/}
                        {/*           label="Los usuarios requieren comprar una inscripción antes de poder comprar paquetes y membresías."/>*/}
                        {/*</div>*/}


                        <h5 className="mt-5">
                            <i className="text-dark mr-2">
                                <FontAwesomeIcon icon={faMoneyBillWave}/>
                            </i>
                            Pagos al llegar
                        </h5>
                        <div className="ml-5">
                            <Field name="allow_member_in_shop_payment" component={Checkbox}
                                   label='Permitir a los clientes adquirir un paquete desde la app con método de pago “Pagar en sucursal". (deuda)' />
                            <div className="font-size-sm text-black-50">El cliente puede utilizar el paquete sin completar su pago. La venta aparecerá <span className="font-weight-bold text-warning">pendiente de pago</span>.</div>
                            <div className="font-size-sm text-black-50">Es responsabilidad del studio realizar el cobro del paquete en sucursal.</div>
                            <div className="font-size-sm text-black-50">Tip: Puedes ajustar el número de reservaciones que el cliente puede realizar sin completar su pago desde la sección Paquetes y Membresías.</div>
                        </div>
                        {/*<div className="w-50 mt-5">*/}
                        {/*    <p className="p-0 m-0">Política de privacidad</p>*/}
                        {/*    <Field name="privacy_policy" className="form-control form-control-sm"/>*/}
                        {/*    <FieldError name="terms_and_conditions"/>*/}
                        {/*</div>*/}
                        {/*<div className="w-50 mt-5">*/}
                        {/*    <p className="p-0 m-0">Términos y Condiciones</p>*/}
                        {/*    <Field name="terms_and_conditions" className="form-control form-control-sm"/>*/}
                        {/*    <FieldError name="terms_and_conditions"/>*/}
                        {/*</div>*/}

                        {/*<h5>*/}
                        {/*    <i className="text-dark mr-2">*/}
                        {/*        <FontAwesomeIcon icon={faUserClock}/>*/}
                        {/*    </i>*/}
                        {/*    Lista de Espera*/}
                        {/*</h5>*/}
                        {/*<div className="form-group row ml-5">*/}
                        {/*    <label className="col-auto col-form-label font-size-lg">Permitir lista de espera en*/}
                        {/*        horarios</label>*/}
                        {/*    <div className="col-auto ml-n4">*/}
                        {/*      <span className="switch">*/}
                        {/*        <label>*/}
                        {/*          <input type="checkbox" name=""/>*/}
                        {/*          <span/>*/}
                        {/*        </label>*/}
                        {/*      </span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <hr/>
                        <button disabled={isSubmitting} className="btn btn-primary mt-5">
                            <i><FontAwesomeIcon icon={faSave}/></i>
                            Guardar cambios
                        </button>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default Settings;
