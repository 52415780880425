import React, {useEffect, useState} from 'react';
import baseApi from "../../../apis/baseApi";
import {Button, Table} from 'react-bootstrap';
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import PlanMembersList from "./PlanMembersList";

interface PlanSaleStats {
    id: string;
    name: string;
    today: number;
    last_month: number;
    current_month: number;
    year: number;
}

const PlanSalesTable = () => {
    const [planStats, setPlanStats] = useState<PlanSaleStats[]>([])
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [selectedPlanId, setSelectedPlanId] = useState<string>();
    const [selectedPlanName, setSelectedPlanName] = useState<string>("");


    useEffect(() => {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        const fetchPage = (page: number) => {
            setLoading(true)
            baseApi.get(`/reports/plans/?tz=${tz}`).then(resp => {
                setPlanStats(resp.data)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            },)
        }
        fetchPage(page)
    }, [page]);

    const handlePageChange = (page: number) => {
        setLoading(true)
        setPage(page)
    }


    return (
        <div>
            <h1 className="pt-10">💳 Ventas por plan</h1>
            <div className='mb-12 text-dark-50'>Conoce cuales son tus planes más vendidos.</div>

            <div className="rounded shadow-sm px-2 pt-2" style={{background: "white"}}>
                {selectedPlanId ? <PlanMembersList planId={selectedPlanId} planName={selectedPlanName} onGoBack={() => {
                    setSelectedPlanId(undefined)
                }}/> : <Table hover>
                    <TableHeader headers={["PLAN", "HOY", "MES", "MES ANTERIOR", "AÑO", "ACTIVOS"]}/>
                    <tbody>
                    {loading ? <LoadingTr/> : planStats.map(s => {
                        return <tr className="" key={s.name}>
                            <td className="w-200px">{s.name}</td>
                            <td className="text-center w-150px">{s.today}</td>
                            <td className="text-center w-150px">{s.current_month}</td>
                            <td className="text-center w-150px">{s.last_month}</td>
                            <td className="text-center w-150px">{s.year}</td>
                            <td className="text-center w-150px">
                                <Button
                                    className="btn btn-icon btn-light btn-xs btn-hover-primary mr-1"
                                    onClick={() => {
                                        setSelectedPlanName(s.name)
                                        setSelectedPlanId(s.id)
                                    }}>
                                    <FontAwesomeIcon icon={faList}/>
                                </Button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </Table>}
            </div>
        </div>
    );
};

export default PlanSalesTable;
