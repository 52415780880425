import React, {useState} from 'react';
import Select from "react-select";
import {SelectOption} from "../../../components/form/FormSelectAlt";
import ReservationsCountReport from "./ReservationsCountReport";
import NewMembersReport from "./NewMembersReport";

const OtherReports = () => {
    const [reportType, setReportType] = useState<SelectOption>();

    const renderReport = () => {
        switch (reportType?.value) {
            case "reservations-count":
                return <ReservationsCountReport/>
            case "new-members":
                return <NewMembersReport/>
            default:
                return <React.Fragment/>
        }
    }

    return (
        <div className="pt-10">
            <div className="w-300px pt-2">
                <Select
                    value={reportType}
                    options={[
                        {value: "new-members", label: "Clientes nuevos"},
                        {value: "reservations-count", label: "Horarios mas concurridos"},
                    ]}
                    placeholder="Seleccionar reporte"
                    onChange={(o) => {
                        setReportType(o as SelectOption)
                    }}/>
            </div>
            <h1 className="pt-3">{reportType?.label}</h1>

            {renderReport()}
        </div>
    );
};

export default OtherReports;
