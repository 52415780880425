import React, {useEffect, useRef, useState} from 'react';
import {Badge, Button, Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import {Sale} from "../../Reports/Sales";
import baseApi from "../../../apis/baseApi";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {formatDateTime} from "../../../utils/dateFormatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import TableFooter from "../../../components/table/TableFooter";
import {Reservation} from "../../Reservations";
import {CartMember} from "../Cart";
import CancelPenaltyFeeModal from "./CancelPenaltyFeeModal";
import FeePaymentModal from "./FeePaymentModal";
import {format, parseISO} from "date-fns";

export enum PenalizationFeeType {
    NO_SHOW_FEE = "No Show Fee",
    ACCUMULATED_NO_SHOW_FEE = "No Show"
}

const MemberFees = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [loading, setLoading] = useState(true);
    const [fees, setFees] = useState<PenalizationFee[]>([]);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedFee, setSelectedFee] = useState<PenalizationFee>();
    const [statusFilter, setStatusFilter] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const count = useRef(0);

    const handlePageChange = (page: number) => {
        setLoading(true)
        baseApi.get(`/fees/?branch=${branch.id}&page=${page}&status=${statusFilter}`).then(resp => {
            setFees(resp.data.results)
            count.current = resp.data.count
            setLoading(false)
        }).catch(err => alert("Error"))
    }

    useEffect(() => {
        handlePageChange(1)
    }, [statusFilter, branch]);

    useEffect(() => {
        let timeout = setTimeout(() => {
            baseApi.get(`/fees/?branch=${branch.id}&page=1&status=${statusFilter}&search=${searchTerm}`).then((resp) => {
                setFees(resp.data.results)
                count.current = resp.data.count
            })
        }, 600)
        return () => {
            clearTimeout(timeout)
        };
    }, [searchTerm, statusFilter, branch]);

    return (
        <div>
            <FeePaymentModal show={showPaymentModal} onHide={() => {
                setShowPaymentModal(false)
            }
            } fee={selectedFee!} onSuccess={() => {
                setFees(fees.filter(f => f.id !== selectedFee!.id))
            }}/>
            <CancelPenaltyFeeModal show={showCancelModal} onHide={() => {
                setShowCancelModal(false)
            }} fee={selectedFee!} onSuccess={
                () => {
                    setFees(fees.filter(f => f.id !== selectedFee!.id))
                }
            }/>
            <div className="d-flex d-flex-row align-items-start mt-3">
                <h4 className="text-dark">Pago de Multas</h4>
            </div>
            <div className="d-flex d-flex-row align-items-start mb-5">
                <Button variant={statusFilter === 0 ? "secondary" : "outline-secondary"} size="sm" className="mr-3"
                        onClick={() => {
                            setStatusFilter(0)
                        }}>Pendientes</Button>
                <Button variant={statusFilter === 2 ? "secondary" : "outline-secondary"} size="sm" onClick={() => {
                    setStatusFilter(2)
                }}>Canceladas</Button>
            </div>
            {/*SEARCH BAR*/}
            <div className="mr-auto w-md-400px  pb-2">
                <input className=" form-control form-control-sm" value={searchTerm}
                       onChange={(e) => setSearchTerm(e.target.value)} placeholder="Buscar..."/>
            </div>
            <div>

                <Table hover className='table-responsive'>
                    <TableHeader headers={["FECHA", "MONTO", "TIPO", "CLIENTE", "RESERVACIÓN", "PLAN", "ACCIÓN"]}/>
                    <tbody>
                    {loading ? <LoadingTr/> : fees.length === 0 ? <EmptyTr/> : fees.map(f => {
                        const feeType = Object.values(PenalizationFeeType)[f.fee_type]
                        return <tr className="text-left" key={f.id}>
                            <td>
                                <div>{format(parseISO(f.created_on), "dd/MMM/yy H:mm")}</div>
                            </td>
                            <td>$ {f.amount}</td>
                            <td>{feeType}{feeType === PenalizationFeeType.ACCUMULATED_NO_SHOW_FEE ? `(x${f.reservations.length})` : <React.Fragment />}</td>
                            <td className="text-left">
                                <div className="font-weight-bold font-size-sm">{f.reservation.member.full_name} {f.reservation.member.last_name ?? ''}</div>
                                <div className="text-muted font-size-xs">{f.reservation.member.email}</div>
                            </td>
                            <td className="text-left">
                                <div className="font-weight-bold font-size-sm">{f.reservation.timeslot_class}</div>
                                <div
                                    className="text-muted font-size-xs">{formatDateTime(f.reservation.timeslot_start)}</div>
                            </td>
                            <td>{f.plan_name}</td>
                            <td className="text-center">
                                <div hidden={statusFilter === 2}>
                                    <Button onClick={() => {
                                        setSelectedFee(f)
                                        setShowPaymentModal(true)
                                    }} className="px-2 py-1 m-0" variant="outline-success" size="sm">Cobrar</Button>
                                    <Button onClick={() => {
                                        setSelectedFee(f)
                                        setShowCancelModal(true)
                                    }} className="px-2 py-1 ml-2" variant="outline-danger" size="sm">
                                        <FontAwesomeIcon icon={faTrashAlt} size="sm"/>
                                    </Button>
                                </div>
                            </td>
                        </tr>


                    })}
                    </tbody>
                </Table>

                <Table hover className='d-md-none'>
                    <TableHeader headers={["FECHA", "ACCIÓN"]}/>

                    <tbody>
                    {loading ? <LoadingTr/> : fees.length === 0 ? <EmptyTr/> : fees.map(f => {
                        return <tr className="text-left" key={f.id}>


                        </tr>


                    })}
                    </tbody>
                </Table>


                <TableFooter count={count.current} pageCount={fees.length} pageSize={10}
                             onPageChange={handlePageChange}/>
            </div>
        </div>
    );
};

export default MemberFees;

interface PenalizationFeeReservation {
    id: string;
    member: CartMember;
    timeslot_class: string;
    timeslot_start: string;

}

export interface PenalizationFee {
    id: string;
    reservation: PenalizationFeeReservation;
    reservations: string[]
    status: number;
    plan_name: string;
    fee_type: number;
    amount: string;
    paid_on?: string;
    created_on: string;
}

