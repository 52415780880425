import React from 'react';

const WebsiteApp = () => {
    return (
        <div className="h-100">
            <div className="row h-75" style={{minHeight: "92%"}}>
                <div className="col-md-6 h-md-100">
                    <div className="card card-custom h-100 pt-20">
                        <div className="text-center font-size-h1">Obtén tu página web</div>
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-8 webimage align-self-center">
                            </div>
                        </div>
                        <div className="text-center px-10 pb-5">
                            Diseño personalizado<br />
                            Agrega tus propias imágenes, colores, fondo, texto, etc. <br/>
                            Se realizan 3 revisiones para que quede como tu quieres 🤩
                        </div>
                        <div className="text-center mb-20">
                            <a href="https://template.cyclingboost.com/template1/" target="_blank" rel="noreferrer"
                               className="btn btn-danger btn-pill btn-lg font-weight-bold">Ver Template (Ejemplo)</a></div>
                    </div>
                </div>

                <div className="col-md-6 h-md-100">
                    <div className="card card-custom h-100 pt-20">
                        <div className="text-center font-size-h1">App</div>
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-8 appimage align-self-center">
                            </div>
                        </div>
                        <div className="text-center px-15 pb-5">
                            En conjunto con tu pagina web los clientes podrán descargar la app desde donde también podrán reservar y comprar mas cómodamente
                        </div>
                        <div className="text-center mb-20">
                            <a href="https://apps.apple.com/us/app/boost-app-bookings/id1582304603#?platform=iphone"
                               target="_blank" rel="noreferrer"
                               className="btn btn-danger btn-pill btn-lg font-weight-bold mr-2">App Store</a>
                            <a href="https://play.google.com/store/apps/details?id=com.cyclingboost.app" target="_blank"
                               rel="noreferrer" className="btn btn-danger btn-pill btn-lg font-weight-bold">Google
                                Play</a>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card card-custom">


                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebsiteApp;
