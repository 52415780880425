import React from 'react';
import {BillingPlan} from "../../data/plans";
import {useDispatch} from "react-redux";
import {selectPlan} from "../../actions/checkout";

interface Props {
    plan: BillingPlan
}

const PlanCard = ({plan}: Props) => {
    const dispatch = useDispatch()
    const mainCardClassNames = "col-md-4 col-lg-3 card card-custom py-20 px-10 mt-5 mt-md-0 mr-md-5 shadow";
    const normalCardClassNames = "col-md-4 col-lg-3 card card-custom py-20 px-10 mr-md-5 mt-md-12"

    return (
        <div className={plan.isMain ? mainCardClassNames : normalCardClassNames} style={{borderRadius: '25px'}}>
            {/*<div>{plan.name}</div>*/}
            {/*<div className="d-flex align-items-center mt-5 mb-3">*/}
            {/*    <span className="font-size-h1"><s> {currencyFormat.format(plan.price)}  </s></span>*/}
            {/*    <span className="font-size-h1">/mes</span>*/}
            {/*</div>*/}
            {/*<div className="d-flex align-items-center mb-1">*/}
            {/*    <div*/}
            {/*        className="font-size-h2 text-muted">{currencyFormat.format(plan.price - (plan.price * (plan?.discount_percent || 0)))}</div>*/}
            {/*    <div className="text-muted font-size-h5">/mes</div>*/}
            {/*    <span className="label label-light-danger label-pill label-lg label-inline font-weight-bold ml-3">50% OFF</span>*/}
            {/*</div>*/}
            {/* Features */}
            <div className="mt-5">
                {plan.features.map(f => {
                    return <div key={f.text} className="d-flex">
                        <i className="fi-rr-check"/>
                        {/*<i className="fi-rr-shield-check"></i>*/}
                        <div className={`ml-3 font-size-lg ${f.bold ? "font-weight-bolder" : ""}`}>{f.text}</div>
                    </div>
                })}
            </div>
            <div className="text-center">
                <button
                    onClick={() => dispatch(selectPlan(plan))}
                    className="btn btn-light-primary btn-lg mt-5 px-10 btn-block font-weight-bolder font-size-h5">
                    Elegir
                </button>
            </div>
            <div className="text-muted text-center">
                {/*<div>Activa hoy, primer mes solo $999</div>*/}
                {/*<div>Garantía de reembolso 30 dias</div>*/}
            </div>
        </div>
    );
};

export default PlanCard;
