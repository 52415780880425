import {format, parseISO} from "date-fns";
import {es, enUS} from "date-fns/locale";

const locales = [es, enUS]

export const formatDate = (date: string | undefined): string => {
    if (!date) return ""
    // @ts-ignore
    const localeName = window.__localeId__
    const locale = locales.find(loc => loc.code?.startsWith(localeName) || loc.code?.startsWith(localeName.substring(0, 2))) || es
    if (!localeName.startsWith("es")) {
        return format(parseISO(date), "P", {locale: locale})
    }
    return format(parseISO(date), "d/MMM/yy", {locale: locale})
    // return format(parseISO(date), "d/MMM/yy", {locale: es})
}

export const formatDateTime = (date: string | undefined): string => {
    if (!date) return ""
    // @ts-ignore
    const localeName = window.__localeId__
    const locale = locales.find(loc => loc.code?.startsWith(localeName) || loc.code?.startsWith(localeName.substring(0, 2))) || es
    if (!localeName.startsWith("es")) {
        return format(parseISO(date), "PP HH:mm", {locale: locale})
    }
    return format(parseISO(date), "d MMMM yyyy HH:mm", {locale: es})
}

export const formatDateWithWeekday = (date: string | Date | undefined): string => {
    if (!date) return ""
    // @ts-ignore
    const localeName = window.__localeId__
    const locale = locales.find(loc => loc.code?.startsWith(localeName) || loc.code?.startsWith(localeName.substring(0, 2))) || es
    if (typeof date === "string") {
        date = parseISO(date)
    }
    if (!localeName.startsWith("es")) {
        return format(date, "PPPP", {locale: locale})
    }
    return format(date, "EEEE d 'de' MMMM", {locale: locale})
}