import React, {useEffect, useState} from 'react';

import CreateRoomModal from "./CreateRoomModal";
import ToolboxHeader from "../ToolboxHeader";
import {Table} from 'react-bootstrap';
import TableHeader from "../../../components/table/TableHeader";
import TableActionsCell from "../../../components/table/TableActionsCell";
import DeleteModal from "../../../components/modals/DeleteModal";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import {fetchRooms, removeRoom, RoomsState} from "../../../actions/rooms";

export interface Seat {
    id: string;
    label: string;
    row: number;
    col: number;
    is_coach: boolean;
    in_maintenance: boolean;
}

export interface Room {
    id: string;
    seats: Seat[];
    archived: boolean;
    name: string;
    people_limit: number;
    rows: number;
    cols: number;
    branch: string;
    uses_map: boolean;
}

const Rooms = () => {
    const dispatch = useDispatch()
    const roomsState = useSelector<StoreState, RoomsState>(state => state.rooms)
    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showMaintenance, setShowMaintenance] = useState(false)

    const [selectedRoom, setSelectedRoom] = useState<Room>()

    const branches = useSelector<StoreState, Branch[]>(state => state.branches.branches)


    useEffect(() => {
        if (!roomsState.fetched) {
            dispatch(fetchRooms())
        }
    }, [roomsState, dispatch]);

    const getBranchName = (id: string) => {
        return branches.find(b => b.id === id)?.name ?? ""
    }

    return (
        <div className="p-4">
            <CreateRoomModal show={showCreate} onHide={() => {
                setShowCreate(false)
                setSelectedRoom(undefined)
            }} room={selectedRoom} onSave={(room) => {
            }}/>
            <DeleteModal url={`/rooms/${selectedRoom?.id}/`} onSuccess={() => {
                dispatch(removeRoom(selectedRoom!.id))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedRoom(undefined)
            }}>
                <p>¿Estas seguro que quieres eliminar el salon <strong> {selectedRoom?.name} </strong> ?</p>
            </DeleteModal>
            {/*<SeatMaintenanceMap show={showMaintenance} onHide={() => {*/}
            {/*    setShowMaintenance(false)*/}
            {/*    setSelectedRoom(undefined)*/}
            {/*}} room={selectedRoom!}/>*/}
            <ToolboxHeader title="Salones" action={() => setShowCreate(true)}/>
            <div className="mt-5">
                <Table className="text-center">
                    <TableHeader headers={["Nombre", "Capacidad", "Sucursal", "Mapa", "Acciones"]}/>
                    <tbody>
                    {roomsState.rooms.length === 0 ? <EmptyTr/> : roomsState.rooms.map((r) => (
                        <tr key={r.id}>

                            <td>{r.name}</td>
                            <td>{r.people_limit}</td>
                            <td>{getBranchName(r.branch)}</td>
                            <td>{r.uses_map ? "SI" : "NO"}</td>
                            <td><TableActionsCell
                                // onClickDetails={() => {
                                //     setSelectedRoom(r)
                                //     setShowMaintenance(true)
                                // }}
                                onClickDelete={() => {
                                    setSelectedRoom(r)
                                    setShowDelete(true)
                                }}
                                onClickEdit={() => {
                                    setSelectedRoom(r)
                                    setShowCreate(true)
                                }}
                            />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default Rooms;
