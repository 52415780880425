import {Dispatch} from "redux";
import baseApi from "../apis/baseApi";
import {Room} from "../pages/Toolbox/Rooms";
import {RoomsActionTypes} from "./types";

export interface RoomsState {
    fetched: boolean
    loading: boolean
    error: string
    rooms: Room[]
}
export interface RoomsActions {
    type: RoomsActionTypes
    payload: any
}
export const fetchRooms = () => {
    return async (dispatch: Dispatch) => {
        dispatch({type: RoomsActionTypes.FETCHING, payload: null})
        baseApi.get<Room[]>(`/rooms/`).then((response) => {
            dispatch<RoomsActions>({
                type: RoomsActionTypes.FETCHED,
                payload: response.data
            })
        })
    }
}

export const addRoom = (room: Room) => {
    return (dispatch: Dispatch) => {
        dispatch({type: RoomsActionTypes.ADD_ROOM, payload: room})
    }
}

export const updateRoom = (room: Room) => {
    return (dispatch: Dispatch) => {
        dispatch({type: RoomsActionTypes.UPDATE_ROOM, payload: room})
    }
}

export const removeRoom = (roomId: string) => {
    return (dispatch: Dispatch) => {
        dispatch({type: RoomsActionTypes.REMOVE_ROOM, payload: roomId})
    }
}