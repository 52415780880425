import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import EmptyTrSales from "../../../components/table/rows/EmptyTrSales";
import {formatDateTime} from "../../../utils/dateFormatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import TableFooter from "../../../components/table/TableFooter";
import baseApi from "../../../apis/baseApi";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import {endOfDay, formatISO, startOfDay} from "date-fns";
import {Paginated} from "../../../types";
import {Sale, SaleLocations} from "./index";
import {formatCurrency} from "../../../utils/currencyFormatters";
import {useHistory} from "react-router-dom";

interface Props {
    rangeStart: Date
    rangeEnd: Date
}

const AllSales = ({rangeStart, rangeEnd}: Props) => {
    const history = useHistory()
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)
    const [saleReports, setSaleReports] = useState<Sale[]>([])
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0)


    useEffect(() => {
        if (rangeStart && rangeEnd) {
            setLoading(true)
            const start = formatISO(startOfDay(rangeStart))
            const end = formatISO(endOfDay(rangeEnd))
            baseApi.get<Paginated>(`/sales/?branch=${branch.id}&start=${start}&end=${end}`).then((resp) => {
                setSaleReports(resp.data.results)
                setCount(resp.data.count)
                setLoading(false)
            }).catch((err) => {
                alert("ERROR")
            })
        }
    }, [branch, rangeStart, rangeEnd]);


    const handlePageChange = (page: number) => {
        setLoading(true)
        const start = formatISO(startOfDay(rangeStart))
        const end = formatISO(endOfDay(rangeEnd))
        baseApi.get<Paginated>(`/sales/?branch=${branch.id}&page=${page}&start=${start}&end=${end}`).then((resp) => {
            setSaleReports(resp.data.results)
            setCount(resp.data.count)
            setLoading(false)
        }).catch((err) => {
            alert("ERROR")
        })
    }

    return (
        <div>
            {/* K NUMERO DE VENTAS */}
                                    {/* <div className='text-right mt-2'>
                                        <div className='label label-inline label-dark rounded-xl font-weight-bolder font-size-h6'># De Ventas: {count}</div>
                                    </div> */}
            {/* FIN K NUMERO DE VENTAS */}

            <Table hover>

                <TableHeader
                    headers={["FECHA", "MONTO", "MÉTODO", "CLIENTE", "PRODUCTOS"]}/>
                <tbody>
                {loading ? <LoadingTr/> : saleReports.length === 0 ? <EmptyTrSales/> : saleReports.map(s => {
                    return <tr className="text-center" key={s.id}>
                        {/*<td className="text-uppercase font-size-sm">{s.id.slice(-8)}</td>*/}
                        <td>{formatDateTime(s.created_on)}
                            <div className='text-muted font-size-xs'>Por: {s.transaction_by}</div>
                        </td>
                        <td className=' font-size-h6 text-left'>{formatCurrency(parseFloat(s.total), currency)}
                             <span hidden={s.status !== 2} className="label label-inline label-light-danger ml-1 font-weight-bolder rounded-xl">
                                        <FontAwesomeIcon className='mr-1' icon={faTimes}/>
                                        CANCELADO
                                    </span>
                            <div>
                                {s.plan_items.map(i => <span key={i.id} className='label label-inline label-light-dark font-weight-bold rounded-xl'>
                                    {i.plan}
                                    <span hidden={!i.cancelled} className="label label-inline label-light-danger ml-1 font-weight-bolder rounded-xl">
                                        <FontAwesomeIcon className='mr-1' icon={faTimes}/>
                                        CANCELADO
                                    </span>
                                </span>)}
                            </div>
                            <div>
                                {s.items.map(i => <span key={i.id} className='label label-inline label-light-dark font-weight-bold rounded-xl'>
                                    {i.name}
                                </span>)}
                            </div>
                        </td>

                        {/* ORIGINAL PAYMENT METHOD COLUMN */}
                                        {/* {s.payment_method === null
                                            ? <td className="text-warning ">Pendiente de pago (deuda)</td>
                                            : <td className="font-size-lg text-left">{s.payment_method} {s.location === SaleLocations.POS ?
                                                <FontAwesomeIcon className="text-muted" icon={faCashRegister}/> : ""}
                                                <div hidden={s.paid_on?.substring(0, 10) === s.created_on.substring(0, 10)} className=" font-size-sm font-weight-normal"><span className="text-success">*</span>Deuda pagada el: {s.paid_on ? formatDateTime(s.paid_on) : ""}</div>
                                            </td>
                                        } */}
                        {/* FIN ORIGINAL PAYMENT METHOD COLUMN */}


                        {s.payment_method === null
                            ? <td className="text-warning text-left"><span className='label label-inline label-light-warning font-weight-bold mr-1'>Deuda</span>Pendiente de pago</td>
                            : <td className=" font-size-lg text-left"><div>{s.payment_method}</div> {s.location === SaleLocations.POS ?

                                // PAGO PRESENCIAL
                                    <span className='label label-inline label-light-success'>POS
                                        {/* <FontAwesomeIcon className="text-muted" icon={faCashRegister}/> */}
                                    </span>
                                // FIN PAGO PRESENCIAL
                                    : 
                                // PAGO EN LINEA
                                    <span className='label label-inline label-light-primary label-rounded'>Pago en línea</span>}
                                {/* FIN PAGO EN LINEA */}

                                {/* DEUDA */}
                                <div hidden={s.paid_on?.substring(0, 15) === s.created_on.substring(0, 15)} className=" font-size-sm font-weight-normal"><span className="text-success">*</span>Deuda pagada el: {s.paid_on ? formatDateTime(s.paid_on) : ""}
                                </div>
                                {/* FIN DEUDA */}

                                
                            </td>
                        }

                        <td onClick={() => history.push(`/users/${s.member.id}`)} className="text-left text-hover-info clickable">
                            <div className="font-size-sm">{s.member.full_name}</div>
                            <div className="text-muted font-size-xs">{s.member.email}</div>
                        </td>

                        {/* ORIGINAL PLAN COLUMN */}
                                                {/* <td className="">{s.plan_items.map(i => <span className='label label-inline label-light-dark font-weight-bold rounded-xl'>{i.plan}
                                                    <span hidden={!i.cancelled} className="label label-inline label-light-danger ml-1 font-weight-bolder">
                                                        <FontAwesomeIcon className='mr-1' icon={faTimes}/>
                                                        CANCELADO
                                                    </span>,
                                                </span>)}
                                                </td> */}
                        {/* FIN ORIGINAL PLAN COLUMN */}



                        <td className="text-center">{s.products.map(i => i.product).join(", ")}</td>

                        {/* ORIGINAL TRANSACTION BY */}
                                            {/* <td>{s.transaction_by}</td> */}
                        {/* FIN ORIGINAL TRANSACTION BY */}

                    </tr>
                })}
                </tbody>


            </Table>
            <TableFooter count={count} pageCount={saleReports.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default AllSales;
