import React, {useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import Select from "react-select";
import {addDays, format, formatISO} from "date-fns";
import {useSelector} from "react-redux";

import {StoreState} from "../../../../reducers";
import {StudioPlan} from "../../../../actions/studioPlans";
import baseApi from "../../../../apis/baseApi";

type AddPlanModalProps = {
    show: boolean,
    onHide: Function,
    memberId: string
}

const AddMemberPlanModal = ({show, onHide, memberId}: AddPlanModalProps) => {
    const studioPlans = useSelector<StoreState, StudioPlan[]>(state => state.studioPlans.plans)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [selectedPlan, setSelectedPlan] = useState<{ label: string, value: string } | null>()
    const [expireOn, setExpireOn] = useState(new Date())


    // useEffect(() => {
    //     if (selectedPlan) {
    //         const plan: Plan | undefined = plans.find((p) => p.id === selectedPlan.value)
    //         setExpireOn(addDays(new Date(), plan?.days_duration ?? 0))
    //     }
    // }, [selectedPlan, plans])

    const renderAlert = () => {
        if (success === null) return;
        const message = success
            ? "Se ha agregado el plan de membresía con éxito."
            : "Ocurrió un error al intentar agregar el plan de membresía.";
        return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const renderExpiration = () => {
        if (selectedPlan) {
            const plan = studioPlans.find((p) => p.id === selectedPlan.value)
            if (plan) {
                return <p>Expiración: {format(addDays(new Date(), plan.days_duration), "dd-MMM-yyyy")}</p>
            }
        }
        return <React.Fragment/>
    }

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik initialValues={{}} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    if (selectedPlan) {
                        baseApi.post("/plans/member/", {
                            plan: selectedPlan.value,
                            member: memberId,
                            expire_on: formatISO(expireOn)
                        }).then(() => {
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch((err) => {
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }
                }}>
                    {({isSubmitting}) => (
                        <FormikForm>

                            <Modal.Header>
                                Agregar Plan
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <Select options={studioPlans.map((p) => ({label: p.name, value: p.id}))}
                                        value={selectedPlan} onChange={(o) => setSelectedPlan(o)}/>
                                {renderExpiration()}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Crear"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default AddMemberPlanModal;
