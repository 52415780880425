import { combineReducers } from "redux";
import {authReducer} from "./auth";
import {AuthState} from "../actions";
import {Branch, BranchesState} from "../actions";
import {BranchesReducer} from "./branches";
import {currentBranchReducer} from "./currentBranch";
import {StudioClassesState} from "../actions/studioClasses";
import {studioClassesReducer} from "./studioClasses";
import {StaffUser} from "../actions/staffUser";
import {staffUserReducer} from "./staffUser";
import {CheckoutState} from "../actions/checkout";
import {checkoutReducer} from "./checkout";
import {Studio} from "../actions/studio";
import {studioReducer} from "./studioReducer";
import {TimeslotReservationsState} from "../actions/timeslotReservations";
import {timeSlotReservationsReducer} from "./timeSlotReservations";
import {StudioPlansState} from "../actions/studioPlans";
import {studioPlansReducer} from "./studioPlans";
import {RegistrationFeesState} from "../actions/registrationFees";
import {registrationFeesReducer} from "./registrationFees";
import {CoachesState} from "../actions/coaches";
import {coachesReducer} from "./coaches";
import {StudioMemberExtraFieldsState} from "../actions/memberExtraFields";
import {studioMemberProfileExtraFieldsReducer} from "./studioMemberProfileExtraFields";
import {roomsReducer} from "./roomsReducer";
import {RoomsState} from "../actions/rooms";

export type StoreState = {
  auth: AuthState,
  branches: BranchesState
  checkout: CheckoutState
  coaches: CoachesState
  currentBranch: Branch
  registrationFees: RegistrationFeesState
  rooms: RoomsState
  studio: Studio
  studioClasses: StudioClassesState
  studioMemberProfileExtraFields: StudioMemberExtraFieldsState
  studioPlans: StudioPlansState
  staffUser: StaffUser
  timeSlotReservations: TimeslotReservationsState
}

export const reducers = combineReducers<StoreState>({
  auth: authReducer,
  branches: BranchesReducer,
  checkout: checkoutReducer,
  coaches: coachesReducer,
  currentBranch: currentBranchReducer,
  registrationFees: registrationFeesReducer,
  rooms: roomsReducer,
  studio: studioReducer,
  studioClasses: studioClassesReducer,
  studioMemberProfileExtraFields: studioMemberProfileExtraFieldsReducer,
  studioPlans: studioPlansReducer,
  staffUser: staffUserReducer,
  timeSlotReservations: timeSlotReservationsReducer,
});

