import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons";
import {IconDefinition} from "@fortawesome/free-regular-svg-icons";

type ToolboxSidebarItemProps = {
    text: string
    active: boolean
    onClick: () => void
    icon: IconDefinition
    hidden?: boolean
}

const ToolboxSidebarItem = ({text, onClick, active, icon, hidden=false} : ToolboxSidebarItemProps) => {
    return (
        <li hidden={hidden} className="navi-item border-bottom " onClick={onClick}>
            <div className={`navi-link py-7 ${active ? "active" : ""}`}>
                        <span className="symbol symbol-25 mr-3">
                            <span className="symbol-label bg-transparent text-muted">
                                <FontAwesomeIcon icon={icon}/>
                            </span>
                        </span>
                <div className="navi-text">
                    <span className="d-block font-weight-bold font-size-lg">{text}</span>
                </div>
            </div>
        </li>
    );
};

export default ToolboxSidebarItem;
