import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {addMinutes, format, formatISO} from "date-fns";
import {FormikSelect} from "../../components/form/FormikSelect";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {StudioClass} from "../../actions/studioClasses";
import {Room} from "../Toolbox/Rooms";
import baseApi from "../../apis/baseApi";
import {getApiErrorMessage} from "../../utils/apiErrors";
import {Branch} from "../../actions";
import {TimeSlotV2} from "./index";
import yup from "../../utils/yup";
import FieldError from "../../components/form/FieldError";

type Props = {
    show: boolean,
    onHide: Function,
    timeSlots: TimeSlotV2[],
    setTimeSlots: (slots: TimeSlotV2[]) => void
}

const EditMultipleSlotsModal = ({show, onHide, timeSlots, setTimeSlots}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const classes = useSelector<StoreState, StudioClass[]>(state => state.studioClasses.classes)
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const rooms = useSelector<StoreState, Room[]>(state => state.rooms.rooms.filter(r => r.branch === currentBranch.id))

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const validationSchema = yup.object().shape({
        start_filter: yup.date().min(new Date(), "La fecha debe ser mayor a la fecha actual.").required(),
        room_filter: yup.string().nullable(),
        room: yup.string().nullable().test('at-least-one', 'Es necesario seleccionar un salón o una fecha de publicación.', (value, context) => {
            if (!value) return context.parent.publish_date
            return true
        }).test('not-same-as-current', 'No se puede seleccionar el mismo salón', (value, context) => {
            if (value && value === context.parent.room_filter) return false
            return true
        }),
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        branch_filter: currentBranch.id,
                        start_filter: addMinutes(new Date(), 30),
                        start_times_filter: [],
                        publish_date: undefined as Date | undefined,
                        room: undefined,
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        let body = {
                            ...values,
                            start_filter: formatISO(values.start_filter),
                            publish_date: values.publish_date ? formatISO(values.publish_date) : null,
                            start_times_filter: values.start_times_filter.length > 0 ? [format(values.start_times_filter[0], "HH:mm:ss")] : [],
                        }
                        const newRoom = values.room ? rooms.find(r => r.id === values.room) : null
                        baseApi.post("/schedules/update/", body).then(resp => {
                            setAlertMessage("Horarios actualizados.")
                            setSuccess(true)
                            let updatedSlots: string[] = resp.data.slots
                            setTimeSlots(timeSlots.map(t => updatedSlots.includes(t.id)
                                    ? {
                                        ...t,
                                        room: newRoom?.id ?? null,
                                        people_limit: newRoom?.people_limit ?? t.people_limit,
                                        publish_date: values.publish_date ? formatISO(values.publish_date) : t.publish_date
                                    }
                                    : t
                                )
                            )
                        }).catch(err => {
                            setAlertMessage(getApiErrorMessage(err))
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }}>
                    {({values, isSubmitting, setFieldValue}) => (
                        <FormikForm>
                            <Modal.Header>
                                Editar multiples horarios
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <h6 className="text-primary">Filtros</h6>
                                <div className="d-inline-flex justify-content-around">
                                    <div>
                                        Modificar horarios a partir del
                                        <div className="">
                                            <div className="customDatePickerWidth">
                                                <ReactDatePicker
                                                    showTimeSelect
                                                    dateFormat="dd 'de' MMMM 'del' yyyy HH:mm"
                                                    locale={es}
                                                    selected={values.start_filter}
                                                    minDate={new Date()}
                                                    onChange={date => {
                                                        if (date) {
                                                            setFieldValue("start_filter", date)
                                                        }
                                                    }}
                                                    customInput={<input className="form-control"/>}
                                                />
                                            </div>
                                        </div>
                                        <FieldError name="start_filter" />
                                    </div>
                                    {/* FILTRO CLASE */}
                                    <div className="ml-5">
                                        donde la clase impartida sea <span className="text-muted">(opcional)</span>
                                        <Field name="studio_class_filter"
                                               placeholder={""}
                                               isClearable={true}
                                               options={classes.map((c) => ({label: c.name, value: c.id}))}
                                               component={FormikSelect}/>
                                    </div>
                                </div>
                                <div className="d-inline-flex justify-content-around py-5">
                                    {/* FILTRO HORA */}
                                    <div>
                                        <div> y la hora de inicio sea <span className="text-muted">(opcional)</span>
                                        </div>
                                        <div className="">
                                            <ReactDatePicker
                                                showTimeSelect
                                                showTimeSelectOnly
                                                minTime={new Date(2024, 1, 1, 0, 0)}
                                                maxTime={new Date(2024, 1, 1, 23, 59)}
                                                locale={es}
                                                selected={values.start_times_filter ? values.start_times_filter[0] : null}
                                                onChange={date => {
                                                    if (date) {
                                                        setFieldValue(`start_times_filter`, [date])
                                                    } else {
                                                        setFieldValue("start_times_filter", [])
                                                    }
                                                }}
                                                timeFormat="h:mm aa"
                                                dateFormat="h:mm aa"
                                                timeIntervals={5}
                                                timeCaption={"Hora"}
                                                isClearable={true}
                                                customInput={<input
                                                    className="form-control "/>}
                                            />
                                        </div>
                                    </div>
                                    {/*FILTRO SALON*/}
                                    <div className="ml-5" style={{width: "260px"}}>
                                        y el salón de la clase sea <span className="text-muted">(opcional)</span>
                                        <Field name="room_filter"
                                               placeholder=""
                                               isClearable={true}
                                               options={rooms.map((r) => ({value: r.id, label: r.name}))}
                                               component={FormikSelect}/>
                                    </div>

                                </div>
                                <hr/>
                                <div>
                                    <h6 className="text-primary">Modificaciones</h6>
                                    {/* NUEVO SALON */}
                                    <div style={{width: "260px"}}>
                                        Nuevo salón <span className="text-muted">(opcional)</span>
                                        <Field name="room"
                                               isClearable={true}
                                               options={rooms.map((r) => ({value: r.id, label: `${r.name} (${r.people_limit} lugares)`}))}
                                               component={FormikSelect}/>
                                        <FieldError name="room" />
                                    </div>
                                    {/*  FECHA DE PUBLICACION  */}
                                    <div className="pt-2">
                                        <div style={{width: ""}} className=" ">
                                            <div>
                                                <span className="">Publicar horarios el</span> <span
                                                className="text-muted">(opcional)</span>
                                            </div>
                                            <div className="customDatePickerWidth" style={{width: "250px"}}>
                                                <ReactDatePicker
                                                    // disabled={disabled}
                                                    dateFormat="dd 'de' MMMM 'del' yyyy, HH:mm"
                                                    locale={es}
                                                    showTimeSelect={true}
                                                    timeCaption={"Hora"}
                                                    selected={values.publish_date}
                                                    minDate={new Date()}
                                                    isClearable={true}
                                                    timeIntervals={15}
                                                    onChange={date => {
                                                        setFieldValue("publish_date", date)
                                                    }}
                                                    customInput={<input className="form-control"/>}
                                                />
                                            </div>
                                            <FieldError name="publish_date" />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Confirmar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default EditMultipleSlotsModal;
