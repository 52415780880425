import {CheckoutAction, CheckoutState} from "../actions/checkout";
import {CheckoutActionTypes} from "../actions";

const initialState = {
    selectedPlan: null,
    selectedPaymentMethod: null,
    prices: [],
    paymentMethods: [],
    step: 1,
    loading: true
} as CheckoutState

export const checkoutReducer = (state: CheckoutState = initialState, action: CheckoutAction) => {
    switch (action.type) {
        case CheckoutActionTypes.FETCHED_PRICES:
            return {...state, prices: action.payload}
        case CheckoutActionTypes.FETCHED_PM:
            return {...state, paymentMethods: action.payload}
        case CheckoutActionTypes.SELECT_PLAN:
            return {...state, selectedPlan: action.payload}
        case CheckoutActionTypes.NEXT_STEP:
            return {...state, step: state.step + 1}

        default:
            return state
    }
}