import React, {useCallback, useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";
import RGL, {Layout, WidthProvider} from "react-grid-layout";

import "react-grid-layout/css/styles.css"
import FormField from "../../../components/form/FormField";
import baseApi from "../../../apis/baseApi";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {BranchesState} from "../../../actions";
import Separator from "../../../components/misc/Separator";
import KeenToggle from "../../../components/misc/KeenToggle";
import {Room} from "./index";
import FormSelectAlt, {SelectOption} from "../../../components/form/FormSelectAlt";
import {addRoom, updateRoom} from "../../../actions/rooms";

type CreateRoomModalProps = {
    show: boolean,
    onHide: Function,
    room?: Room
    onSave: (room: Room) => void
}

const ReactGridLayout = WidthProvider(RGL);

const CreateRoomModal = ({show, onHide, room, onSave}: CreateRoomModalProps) => {
        const dispatch = useDispatch()
        const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)

        const [success, setSuccess] = useState<boolean | null>(null);
        const [useMap, setUseMap] = useState(false)
        const [cols, setCols] = useState(8)
        const [seatsLayout, setSeatsLayout] = useState<Layout[]>([{i: "1", x: 1, y: 1, w: 1, h: 1}])
        const [selectedBranch, setSelectedBranch] = useState(branchesState.selectOptions[0])
        const [rowHeight, setRowHeight] = useState(50)

        useEffect(() => {
            if (room) {
                if (room.seats.length > 0) {
                    setUseMap(true)
                    setCols(room.cols)
                    const layout = room.seats.map(s => {
                        return {i: s.label, x: s.col, y: s.row, w: 1, h: 1}
                    })
                    setSeatsLayout(layout)
                }
            }
        }, [room]);


        const mapRef = useCallback(node => {
            if (node !== null) {
                const newHeight = (node.getBoundingClientRect().width - (cols * 5)) / cols
                setRowHeight(newHeight)
            }
        }, [cols])


        const renderAlert = () => {
            if (success === null) return;
            const message = success
                ? "Se ha guardado el salon con éxito."
                : "Ocurrió un error al intentar guardar el salon.";
            return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
        };


        const addSeat = () => {
            const i = (seatsLayout.length + 1).toString()
            setSeatsLayout([...seatsLayout, {i: i, x: 0, y: 0, w: 1, h: 1}])
        }

        const removeSeat = () => {
            if (seatsLayout.length > 1) {
                setSeatsLayout(seatsLayout.slice(0, -1))
            }
        }


        const handleClose = () => {
            setSuccess(null);
            setUseMap(false)
            setSeatsLayout([{i: "1", x: 1, y: 1, w: 1, h: 1}])
            onHide();
        };


        const renderMap = () => {
            if (!useMap || room) return <React.Fragment/>
            return <Row>
                <Col lg={7}>
                    <div ref={mapRef} className="border border-secondary">
                        <ReactGridLayout layout={seatsLayout} rowHeight={rowHeight}
                                         cols={cols}
                                         compactType={null}
                                         isResizable={false}
                                         onLayoutChange={(layout => setSeatsLayout(layout))}
                                         margin={[5, 5]}>

                            {seatsLayout.map((i) => (
                                <div
                                    style={{cursor: "move"}}
                                    className="d-flex justify-content-center align-items-center border border-secondary rounded-circle"
                                    key={i.i}>{i.i}</div>))}

                        </ReactGridLayout>
                    </div>
                </Col>
                <Col lg={5}>
                    <div className="form-group row">
                        <label className="col-lg-5 col-form-label text-lg-right">
                            <i className="fas fa-columns mr-2"/>
                            Columnas
                        </label>
                        <div className="col-lg-6">
                            <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                <span className="input-group-btn input-group-prepend">
                                  <button disabled={room !== undefined} onClick={() => {
                                      if (cols > 1) {
                                          setCols(cols - 1)
                                      }
                                  }} className="btn btn-secondary bootstrap-touchspin-down"
                                          type="button">
                                    -
                                  </button>
                                </span>
                                <input id="kt_touchspin_1" type="text" className="form-control text-center"
                                       value={cols} readOnly={true}/>
                                <span className="input-group-btn input-group-append">
                                  <button disabled={room !== undefined} onClick={() => setCols(cols + 1)}
                                          className="btn btn-secondary bootstrap-touchspin-up"
                                          type="button">
                                    +
                                  </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-5 col-form-label text-lg-right">
                            <i className="fas fa-circle-notch mr-2"/>
                            Spots
                        </label>
                        <div className="col-lg-6">
                            <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                <span className="input-group-btn input-group-prepend">
                                  <button disabled={room !== undefined} onClick={removeSeat}
                                          className="btn btn-secondary bootstrap-touchspin-down"
                                          type="button">
                                    -
                                  </button>
                                </span>
                                <input id="kt_touchspin_1" type="text" className="form-control text-center"
                                       value={seatsLayout.length} disabled={room !== undefined} readOnly={true}/>
                                <span className="input-group-btn input-group-append">
                                  <button disabled={room !== undefined} onClick={addSeat}
                                          className="btn btn-secondary bootstrap-touchspin-up"
                                          type="button">
                                    +
                                  </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        }

        return (
            <React.Fragment>
                <Modal show={show} onHide={handleClose} size="lg">
                    <Formik
                        initialValues={{
                            name: room?.name,
                            people_limit: room ? room.people_limit : 1,
                            branch: !room ? branchesState.selectOptions[0] : branchesState.selectOptions.find(b => b.value === room?.branch),
                            uses_map: room?.uses_map,
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            console.log(values)
                            setSuccess(null)
                            if (room) {
                                // UPDATE
                                baseApi.patch<Room>(`/rooms/${room.id}/`, {
                                    ...values,
                                    branch: values.branch!.value
                                }).then((resp) => {
                                    setSuccess(true)
                                    setSubmitting(false)
                                    onSave(resp.data)
                                    dispatch(updateRoom(resp.data))
                                }).catch(() => {
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            } else {
                                // CREATE
                                const rows = Math.max(...seatsLayout.map((l) => l.y)) + 1;
                                const seats = seatsLayout.map((l) => ({
                                    label: l.i,
                                    row: l.y,
                                    col: l.x,
                                }))
                                const data = {
                                    name: values.name,
                                    people_limit: useMap ? seats.length : values.people_limit,
                                    cols: cols,
                                    rows: rows,
                                    branch: selectedBranch.value,
                                    seats: useMap ? seats : [],
                                    uses_map: useMap
                                }

                                baseApi.post<Room>("/rooms/", data).then((resp) => {
                                    setSuccess(true)
                                    setSubmitting(false)
                                    onSave(resp.data)
                                    dispatch(addRoom(resp.data))
                                }).catch(() => {
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }

                        }}>
                        {({isSubmitting, values, setFieldValue}) => (
                            <FormikForm>

                                <Modal.Header>
                                    {!room ? 'Crear' : 'Editar'} Salon
                                </Modal.Header>
                                <Modal.Body>
                                    {renderAlert()}
                                    <FormField label="Nombre del Salon" name="name"/>
                                    <Form.Group hidden={useMap || values.uses_map} className="row">
                                        <Form.Label className="col-lg-2 offset-lg-1 col-form-label">Limite de
                                            personas</Form.Label>
                                        <div className="col-lg-4">
                                            <Field
                                                className="form-control "
                                                label="Limite de personas"
                                                name="people_limit"
                                                type="number" hidden={useMap}
                                                min={1}
                                                max={1000}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setFieldValue("people_limit", e.target.value)
                                                    if (e.target.value) {
                                                        const totalSeats = parseInt(e.target.value)
                                                        if (totalSeats > 0) {
                                                            const seatNumbers = Array(parseInt(e.target.value)).fill(0).map((_, i) => i + 1);
                                                            const layout = seatNumbers.map((i) => {
                                                                const x = ((i - 1) % 6) + 1
                                                                const y = Math.floor(i / 7) + 1
                                                                return {i: i.toString(), x: x, y: y, w: 1, h: 1,}
                                                            })
                                                            setSeatsLayout(layout)
                                                        }
                                                    }
                                                }}/>
                                        </div>
                                    </Form.Group>
                                    <FormSelectAlt
                                        label="Sucursal" placeholder="Seleccionar sucursal"
                                        value={values.branch}
                                        options={branchesState.selectOptions}
                                        onChange={(option: SelectOption) => setFieldValue("branch", option)}
                                    />
                                    <div hidden={room !== undefined}>
                                        <Separator/>
                                        <div className="d-flex align-items-center">
                                            <h5>Mapa</h5>
                                            <KeenToggle checked={useMap} onChange={(e) => setUseMap(e.target.checked)}/>
                                        </div>
                                        <div className="text-muted font-size-sm">Permite que tus clientes reserven su spot
                                            dentro del salon
                                        </div>
                                    </div>
                                    {renderMap()}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-secondary" onClick={handleClose}>
                                        Cerrar
                                    </Button>
                                    <Button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? "..." : "Guardar"}
                                    </Button>
                                </Modal.Footer>
                            </FormikForm>
                        )
                        }
                    </Formik>
                </Modal>
            </React.Fragment>
        )
            ;
    }
;

export default CreateRoomModal;
