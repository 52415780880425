import React, {useEffect, useState} from 'react';
import {Formik, Form as FormikForm, Field} from "formik";
import FormField from "../../../components/form/FormField";
import {Alert, Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import ToolboxHeader from "../ToolboxHeader";
import {Preferences} from "../Settings";
import baseApi from "../../../apis/baseApi";
import {faPencilRuler} from "@fortawesome/free-solid-svg-icons";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {FormikSelect} from "../../../components/form/FormikSelect";
import yup from "../../../utils/yup";
import FieldError from "../../../components/form/FieldError";


const PaymentProcessors = () => {
    const [loading, setLoading] = useState(true)
    const [preferences, setPreferences] = useState<Preferences>()
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("");

    useEffect(() => {
        baseApi.get<Preferences>("/prefs/").then((resp) => {
            setPreferences(resp.data)
            setLoading(false)
        })
    }, [])

    const ValidationSchema = yup.object().shape({
        public_key: yup.string().when('payment_processor', (pp, schema) => pp !== null ? schema.required() : schema.notRequired().nullable()),
        private_key: yup.string().when('payment_processor', (pp, schema) => pp !== null ? schema.required().matches('^[^*]+$', {message: "La llave no puede incluir asteriscos (*)"}) : schema.notRequired().nullable()),
    })

    if (loading) return <div>Cargando...</div>
    return (
        <div className="p-5">
            <ToolboxHeader title="Procesadores de Pago"/>
            <Alert variant={success ? "success" : "danger"} hidden={success === null}>{alertMsg}</Alert>
            <div className="pr-5">
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{
                        public_key: preferences?.public,
                        private_key: preferences?.public !== null ? "*".repeat(40) : "",
                        payment_processor: preferences?.payment_processor,
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        baseApi.patch("/prefs/", values).then(resp => {
                            if (!values.payment_processor) {
                                setAlertMsg("Preferencias actualizadas.")
                                setSuccess(true)
                                setSubmitting(false)
                            }
                        })
                        if (values.payment_processor) {
                            let url = "";
                            if (values.payment_processor === "ST") {
                                url = "/spp/stripe/"
                            } else if (values.payment_processor === "MP") {
                                url = "/spp/mercadopago/"
                            } else if (values.payment_processor === "WM") {
                                url = "/spp/wompi/"
                            }
                            baseApi.post(url, values).then((resp) => {
                                console.log(resp)
                                setAlertMsg("Llaves actualizadas correctamente.")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMsg(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        }
                    }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>
                            <h6>Tarjetas de Crédito / Débito</h6>
                            <Form.Group className="row">
                                <Form.Label className="col-lg-2 offset-lg-1 col-form-label">Procesador</Form.Label>
                                <div className="col-lg-4">
                                    <Field name="payment_processor" component={FormikSelect}
                                           options={[
                                               {value: null, label: "N/A"},
                                               {value: "ST", label: "Stripe"},
                                               {value: "MP", label: "Mercado Pago"},
                                               {value: "WM", label: "Wompi"},
                                           ]}/>
                                </div>
                            </Form.Group>
                            <div hidden={values.payment_processor === null}>
                                <FormField label="Llave Publica" name="public_key"/>
                                <FieldError name="public_key"/>
                                <FormField type="password" label="Llave Secreta" name="private_key"/>
                                <FieldError name="private_key"/>
                            </div>
                            <div className="text-right">
                                <Button disabled={isSubmitting}
                                        type="submit">{isSubmitting ? "..." : "Guardar"} </Button>
                            </div>
                        </FormikForm>
                    )}
                </Formik>
            </div>
            <hr/>
        </div>
    );
};

export default PaymentProcessors;
