import {ActionTypes, AuthState, LoginAction} from "../actions";
import {getApiErrorMessage} from "../utils/apiErrors";

const initialState = {
    tokens: {},
    loggedIn: false,
    authHeader: {},
    error: "",
    loading: true
} as AuthState

export const authReducer = (state: AuthState = initialState, action: LoginAction) => {
    switch (action.type) {
        case ActionTypes.LOGGING_IN:
            return {...state, loggedIn: false, error: "", loading: true}
        case ActionTypes.LOGIN_SUCCESS:
        case ActionTypes.STORAGE_TOKEN_SUCCESS:
            localStorage.setItem("access", action.payload.access)
            localStorage.setItem("refresh", action.payload.refresh)
            return {
                tokens: action.payload,
                loggedIn: true,
                authHeader: {"Authorization": `Bearer ${action.payload.access}`},
                error: "",
                loading: false,
            } as AuthState
        case ActionTypes.LOGIN_FAILED:
            return {...state, loading: false, error: getApiErrorMessage(action.payload)}
        case ActionTypes.STORAGE_TOKEN_FAILED:
            return {...state, error: action.payload.error, loading: false} as AuthState
        case ActionTypes.LOGOUT:
            localStorage.clear()
            window.location.reload()
            return initialState
        case ActionTypes.LOGOUT_WITH_ERROR:
            localStorage.clear()
            return {...initialState, loading: false, error: action.payload}
        default:
            return state
    }
}