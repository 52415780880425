import React, {useEffect, useState} from 'react';
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {endOfDay, format, formatISO, startOfDay, startOfMonth} from "date-fns";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import baseApi from "../../../apis/baseApi";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import {Button, Table} from "react-bootstrap";
import {formatDate, formatDateTime} from "../../../utils/dateFormatters";
import {CSVDownload} from "react-csv";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import ReactDOM from "react-dom";

interface NewMemberReportItem {
    external_id: string
    full_name: string
    last_name: string | null
    user__email: string
    phone: string
    created_on: string
}

const NewMembersReport = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [rangeStart, setRangeStart] = useState<Date>(startOfMonth(new Date()));
    const [rangeEnd, setRangeEnd] = useState<Date | null>(new Date());
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState<NewMemberReportItem[]>([])
    const [downloader, setDownloader] = useState(<div/>);

    useEffect(() => {
        if (rangeStart && rangeEnd) {
            const fetchPage = () => {
                const start = formatISO(startOfDay(rangeStart))
                const end = formatISO(endOfDay(rangeEnd))
                setLoading(true)
                baseApi.get(`/reports/members-new/?branch=${branch.id}&start=${start}&end=${end}}`).then(resp => {
                    setItems(resp.data)
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                },)
            }
            fetchPage()
        }
    }, [branch, rangeStart, rangeEnd]);

    const onSelectDownload = () => {
        setDownloader(<div />)
        const headers = ["ID", "NOMBRE", "APELLIDO", "EMAIL", "TELÉFONO", "CREADO EN"]
        const data = [headers, ...items.map(i => Object.values({...i, created_on: formatDate(i.created_on)}))]
        let filename = `Clientes nuevos ${branch.name} ${format(rangeEnd!, "dd/MM/yyyy")}`
        const element =  <CSVDownload filename={filename} data={data} target="_blank"/>
        setDownloader(element)
    }

    return (
        <div>
            {downloader}
            <div className='font-size-h5'>Selecciona un rango de fechas:</div>
            <div className="d-inline-flex align-items-center">
                <div className=''>
                    <ReactDatePicker
                        selectsRange
                        selected={rangeStart}
                        locale={es}
                        value={`${format(rangeStart, "dd MMMM yyyy", {locale: es})}  al  ${rangeEnd === null ? '' : format(rangeEnd, "dd MMMM yyyy", {locale: es})}`}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        shouldCloseOnSelect={rangeEnd === null}
                        maxDate={new Date()}
                        onChange={(dates) => {
                            if (dates) {
                                const [start, end] = dates as Date[]
                                setRangeStart(start)
                                setRangeEnd(end)

                            }
                        }}
                        customInput={<input className="form-control font-size-h4 text-primary"
                                            style={{cursor: "pointer"}}/>}
                    />
                </div>
                <Button onClick={onSelectDownload} className="ml-2" variant="outline-success">
                    <span className='font-weight-bold font-size-lg '><FontAwesomeIcon icon={faFileExcel}/> {'Descargar Excel'}</span>
                </Button>
            </div>
            <div className="rounded shadow-sm p-5 my-5 col-lg-8 col-xxl-6" style={{background: "white"}}>
                <Table hover bordered>
                    <TableHeader
                        headers={["ID", "NOMBRE", "EMAIL", "TELÉFONO", "CREADO EN"]}/>

                    <tbody>
                    {loading ? <LoadingTr/> : items.map(item => {
                        return <tr key={item.external_id} className="">
                            <td>{item.external_id}</td>
                            <td>{item.full_name} {item.last_name ?? ''}</td>
                            <td>{item.user__email}</td>
                            <td>{item.phone}</td>
                            <td>{formatDateTime(item.created_on)}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default NewMembersReport;
