import React from 'react';
import {billingPlans} from "../../data/plans";
import PlanCard from "./PlanCard";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {checkoutNextStep, CheckoutState} from "../../actions/checkout";
import {Button} from "react-bootstrap";
import ConfirmPayment from "./Checkout/ConfirmPayment";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {formatCurrency} from "../../utils/currencyFormatters";
import {Studio} from "../../actions/studio";
import BillingDetails from "./BillingDetails";

let key =
    process.env.NODE_ENV === "production"
        ? "pk_live_51IgDKPHX6IMUdX6SFTcOSjrKakWc6Kv1aVWTRn9KFVZWIxpZc9367mWoUcUqxBK0TuEgxwnNbH9yMZEzTvWWoOz100SZ7kGKBw"
        : "pk_test_51IgDKPHX6IMUdX6S3kYKwLrUUzSMgAkOiqeBAIv9c3b9ywZJHs4WPItP2qJjy1tBXe3RLhVsoonIr6LhGlFrktwL00gazkV5na";
const stripePromise = loadStripe(key, {locale: "es"});

const Billing = () => {
    const dispatch = useDispatch()
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const checkoutState = useSelector<StoreState, CheckoutState>(state => state.checkout)
    const currency = studio.preferences.currency

    if (studio.active) {
        return <BillingDetails/>
    }

    if (checkoutState.step === 2) {
        return <Elements stripe={stripePromise}>
            <div>
                <ConfirmPayment/>
            </div>
        </Elements>
    }
    return (
        <div>
            <div className="row mb-5">
                {/*<div className="col col-md-9 offset-1 card card-custom bg-light-secondary border border-secondary">*/}
                {/*    <div className="">*/}
                {/*        hola*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="row pl-md-0 pl-xxl-10">
                {billingPlans.map(plan => (<PlanCard key={plan.price} plan={plan}/>))}
                {/* CARD 4 RESUMEN */}
                <div className="col card card-custom ml-8 mt-md-12 py-10" style={{borderRadius: '25px'}}>
                    <div className="">
                        <div>RESUMEN</div>
                        <div className="mb-3 align-self-bottom border-y my-5">
                            <div hidden={checkoutState.selectedPlan !== null}
                                 className="text-muted font-weight-bolder py-4">
                                Selecciona un plan para continuar
                            </div>
                            <strong>
                                {checkoutState.selectedPlan?.name}
                            </strong>
                            {checkoutState.selectedPlan ?
                                <p>{formatCurrency(checkoutState.selectedPlan.price - (checkoutState.selectedPlan.price * (checkoutState.selectedPlan.discount_percent || 0)), currency)}
                                    cada mes</p> : <React.Fragment/>
                            }
                        </div>
                        {/*<div className="text-muted text-center">Pagaras $500 ahora por el primer mes y después*/}
                        {/*    $1699/mes*/}
                        {/*</div>*/}
                        <Button block disabled={!checkoutState.selectedPlan}
                                onClick={() => dispatch(checkoutNextStep())}
                                variant="primary">Siguiente</Button>

                    </div>
                </div>

            </div>

            <div className="py-xxl-15"/>

            {/*<div className="row pl-md-0 pl-xxl-8">*/}
            {/*    <div className="col-xxl-8">*/}
            {/*        <div className="card card-custom px-0">*/}
            {/*            <ul className="nav nav-tabs nav-tabs-line mb-5 justify-content-center px-0 px-md-20">*/}
            {/*                <li className="nav-item">*/}
            {/*                    <a className="nav-link active nav-text font-size-md-h2 font-weight-bold"*/}
            {/*                       data-toggle="tab" href="#kt_tab_pane_1">Preguntas Frecuentes</a>*/}
            {/*                </li>*/}
            {/*                <li className="nav-item">*/}
            {/*                    <a className="nav-link font-size-md-h2 font-weight-bold" data-toggle="tab"*/}
            {/*                       href="#kt_tab_pane_2">Proximamente</a>*/}
            {/*                </li>*/}
            {/*                <li className="nav-item">*/}
            {/*                    <a className="nav-link font-size-md-h2 font-weight-bold" data-toggle="tab"*/}
            {/*                       href="#kt_tab_pane_2">Pagina Web y App</a>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>


    );
};

export default Billing;




