import React from 'react';

type TableHeaderProps = {
    headers: string[]
}

const TableHeader = ({headers} : TableHeaderProps) => {
    return (
        <thead className="text-muted" style={{letterSpacing: ".1rem"}}>
        <tr>
            {headers.map(header => ( <th key={header} className=" text-center" scope="col">{header}</th>))}
        </tr>
        </thead>
    );
};

export default TableHeader;
