import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import {Sale} from "../../Reports/Sales";
import Select from "react-select";
import {paymentMethods} from "../../../data/paymentMethods";
import FieldError from "../../../components/form/FieldError";
import yup from "../../../utils/yup";
import baseApi from "../../../apis/baseApi";

type Props = {
    show: boolean,
    onHide: Function,
    sale: Sale,
    onSuccess: Function,
}

const PendingPaymentModal = ({show, onHide, sale, onSuccess}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        payment_method: yup.string().required()
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{status: 1, payment_method: ""}} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    baseApi.patch(`/sales/pending/${sale.id}/`, values).then(resp => {
                        setAlertMessage("Venta actualizada")
                        setSuccess(true)
                        onSuccess()
                    }).catch(err => {
                        setAlertMessage("Ocurrió un error al intentar actualizar la venta.")
                        setSuccess(false)
                    })
                }}>
                    {({isSubmitting, setFieldValue}) => (
                        <FormikForm>

                            <Modal.Header>
                                Cobrar venta pendiente
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div>
                                    Cliente: {sale.member.external_id} - {sale.member.full_name}
                                </div>
                                <div>
                                    Plan: {sale.plan_items.map(e => e.plan).join(", ")}
                                </div>
                                <div>
                                    Productos: {sale.products.map(e => e.product).join(", ")}
                                </div>
                                <hr/>
                                <div className="font-weight-bolder font-size-h4">
                                    Total: ${sale.total}
                                </div>
                                <hr/>
                                <Select placeholder="Método de pago"
                                        options={paymentMethods.filter(e => e.value !== null)}
                                        onChange={(option) => setFieldValue("payment_method", option?.value)}/>
                                <FieldError name="payment_method"/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button variant="success" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Finalizar venta"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default PendingPaymentModal;
