import {Dispatch} from "redux";
import baseApi from "../apis/baseApi";
import {StudioMemberExtraFieldTypes} from "./types";

export interface StudioMemberExtraField {
    id: number
    label: string;
    field_type: number;
    required: boolean;
}
export interface MemberProfileExtraFieldValue {
    member: string;
    profile_extra_field: number;
    value: string;
}

export type StudioMemberExtraFieldsActions = {
    type: StudioMemberExtraFieldTypes
    payload: any
}

export type StudioMemberExtraFieldsState = {
    fetching: boolean
    extra_fields: StudioMemberExtraField[]
}

export const fetchStudioMemberExtraFields = () => {
    return async (dispatch: Dispatch) => {
        baseApi.get<StudioMemberExtraField[]>('/studios/extra-fields/').then((response) => {
            dispatch<StudioMemberExtraFieldsActions>({
                type: StudioMemberExtraFieldTypes.FETCHED,
                payload: response.data
            })
        })
    }
}