import React, {useState} from 'react';
import Layout39 from "../../layouts/Layout-3-9";

import "../../assets/styles/personalization.css"
import StudioClasses from "./StudioClasses";
import Branches from "./Branches";
import Rooms from "./Rooms";
import Plans from "./Plans";
import Settings from "./Settings";
import Staff from "./Staff";
import PaymentProcessors from "./PaymentProcessors";
import {
    faBuilding,
    faCogs,
    faCreditCard,
    faIdBadge,
    faIdCard, faKaaba, faMountain,
    faSitemap, faTicketAlt
} from "@fortawesome/free-solid-svg-icons";
import ToolboxSidebarItem from "./SidebarItem";
import Coupons from "./Coupons";
import GympassPreferences from "./Gympass";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {StaffUser} from "../../actions/staffUser";


enum ToolboxOption {Branches, Classes, Rsvp, Rooms, Plans, Settings, Staff, PaymentProcessors, Coupons, Gympass}

const Toolbox = () => {
    const staff = useSelector<StoreState, StaffUser>(state => state.staffUser)
    const [selectedOption, setSelectedOption] = useState(ToolboxOption.Branches)

    const renderComponent = (): JSX.Element => {

        switch (selectedOption) {
            case ToolboxOption.Branches:
                const hasPermission= staff.permissions.find(p => p.feature === 'branches')?.view === true
                if (!hasPermission) {
                    return <div>Mi estudio</div>
                }
                return <Branches/>
            case ToolboxOption.Classes:
                return <StudioClasses/>
            case ToolboxOption.Rooms:
                return <Rooms/>
            case ToolboxOption.Plans:
                return <Plans/>
            case ToolboxOption.Coupons:
                return <Coupons/>
            case ToolboxOption.Settings:
                return <Settings/>
            case ToolboxOption.Staff:
                return <Staff/>
            case ToolboxOption.PaymentProcessors:
                return <PaymentProcessors/>
            case ToolboxOption.Gympass:
                return <GympassPreferences/>
            default:
                return <div>Error</div>
        }
    }


    // Empieza aqui///////////////
    // Empieza aqui///////////////
    // Empieza aqui///////////////

    const renderSidebar = () => {
        return <div className="content-sidebar">
            <div className="card-header">
                <h2>Personalización</h2>
            </div>
            <ul className="navi navi-hover navi-active">

                <ToolboxSidebarItem text="Mi Estudio" onClick={() => setSelectedOption(ToolboxOption.Branches)}
                                    active={selectedOption === ToolboxOption.Branches} icon={faKaaba}/>
                <ToolboxSidebarItem text="Clases" onClick={() => setSelectedOption(ToolboxOption.Classes)}
                                    active={selectedOption === ToolboxOption.Classes} icon={faMountain}
                                    hidden={staff.permissions.find(p => p.feature === 'classes')?.view === false}/>
                <ToolboxSidebarItem text="Salones" onClick={() => setSelectedOption(ToolboxOption.Rooms)}
                                    active={selectedOption === ToolboxOption.Rooms} icon={faSitemap}
                                    hidden={staff.permissions.find(p => p.feature === 'rooms')?.view === false}/>
                <ToolboxSidebarItem text="Paquetes y Membresías"
                                    onClick={() => setSelectedOption(ToolboxOption.Plans)}
                                    active={selectedOption === ToolboxOption.Plans} icon={faIdCard}
                                    hidden={staff.permissions.find(p => p.feature === 'memberships')?.view === false}/>
                <ToolboxSidebarItem text="Cupones"
                                    onClick={() => setSelectedOption(ToolboxOption.Coupons)}
                                    active={selectedOption === ToolboxOption.Coupons} icon={faTicketAlt}
                                    hidden={staff.permissions.find(p => p.feature === 'memberships')?.view === false}/>
                <ToolboxSidebarItem text="Preferencias" onClick={() => setSelectedOption(ToolboxOption.Settings)}
                                    active={selectedOption === ToolboxOption.Settings} icon={faCogs}
                                    hidden={staff.permissions.find(p => p.feature === 'settings')?.view === false}/>
                <ToolboxSidebarItem text="Usuarios Staff" onClick={() => setSelectedOption(ToolboxOption.Staff)}
                                    active={selectedOption === ToolboxOption.Staff} icon={faIdBadge}
                                    hidden={staff.permissions.find(p => p.feature === 'staff')?.view === false}/>
                <ToolboxSidebarItem text="Procesadores de pago"
                                    onClick={() => setSelectedOption(ToolboxOption.PaymentProcessors)}
                                    active={selectedOption === ToolboxOption.PaymentProcessors} icon={faCreditCard}
                                    hidden={staff.permissions.find(p => p.feature === 'settings')?.view === false}/>
                <ToolboxSidebarItem text="Gympass"
                                    onClick={() => setSelectedOption(ToolboxOption.Gympass)}
                                    active={selectedOption === ToolboxOption.Gympass} icon={faBuilding}
                                    hidden={staff.permissions.find(p => p.feature === 'settings')?.view === false}/>
            </ul>
        </div>
    }

    if (staff.loading) return <div>Cargando...</div>
    return (
        <div className="h-100">
            <Layout39 title="" left={renderSidebar()} right={renderComponent()}/>
        </div>
    );
};

export default Toolbox;
