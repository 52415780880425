import React, {useEffect, useState} from 'react';
import baseApi from "../../../apis/baseApi";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import { format, formatISO, parseISO, endOfDay, subDays} from "date-fns";
import {Bar} from "react-chartjs-2";
import {Tab, Tabs} from "react-bootstrap";
import {ChartData} from "chart.js";
import LoadingRoller from "../../../components/misc/LoadingRoller";

interface Item {
    id: string;
    timeslot__start: Date
}

const ReservationsCountReport = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [items, setItems] = useState<Item[]>();
    const [selectedWeekday, setSelectedWeekday] = useState(1);

    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState<ChartData<"bar", number[], string>>();

    useEffect(() => {
        const start = formatISO(subDays(endOfDay(new Date()), 30))
        const end = formatISO(endOfDay(new Date()))
        baseApi.get(`/reports/reservations-count/?branch=${branch.id}&start=${start}&end=${end}&tz=${branch.timezone}`).then(resp => {
            const respItems = resp.data.map((i: any) => {
                return {...i, timeslot__start: parseISO(i.timeslot__start)}
            })
            respItems.sort((a: any, b: any) => a.timeslot__start - b.timeslot__start)
            setItems(respItems)
            setLoading(false)
        })
    }, [branch]);

    useEffect(() => {
        if (items) {
            console.log("REMAKE")
            const wi = items?.filter(i => i.timeslot__start.getDay() === selectedWeekday)
            const labels: string[] = Array.from(new Set(wi.map((i: Item) => format(i.timeslot__start, "h:mm aa"))))
            let data: number[] = []
            let averages: number[] = []
            const avgDivider = items.find(e => e.timeslot__start.getDay() === 0) ? 7 : 6
            labels.forEach(e => {
                data.push(wi.filter((i: Item) => format(i.timeslot__start, "h:mm aa") === e).length)
                averages.push(items.filter((i: Item) => format(i.timeslot__start, "h:mm aa") === e).length / avgDivider)
            })

            setChartData({
                labels, datasets: [
                    {label: "Reservaciones", data: data, backgroundColor: "#519DE9", maxBarThickness: 60},
                    {label: "Promedio semanal", data: averages, backgroundColor: "#F6D173", maxBarThickness: 30},
                ]
            })

        }
    }, [selectedWeekday, items]);




    if (loading) return <LoadingRoller/>
    return (
        <div className="max-h-500px w-75">
            <div className="mt-5"/>
            <Tabs defaultActiveKey={1} onSelect={eventKey => setSelectedWeekday(parseInt(eventKey ?? "1"))}>
                <Tab eventKey={1} title="Lunes"/>
                <Tab eventKey={2} title="Martes"/>
                <Tab eventKey={3} title="Miercoles"/>
                <Tab eventKey={4} title="Jueves"/>
                <Tab eventKey={5} title="Viernes"/>
                <Tab eventKey={6} title="Sabado"/>
                <Tab eventKey={0} title="Domingo"/>
            </Tabs>
            <Bar className="bg-white rounded p-3" data={chartData!} options={{
                maintainAspectRatio: false,
                scales: {
                    y: {
                        ticks: {
                            callback: function (value) {
                                if (value as number % 1 === 0) {
                                    return value;
                                }
                            }
                        }
                    }
                }
            }}/>
            <div>
                * Datos tomados de los últimos 30 dias
            </div>
            {/*<table className="table table-responsive">*/}
            {/*    <thead>*/}
            {/*    <tr>*/}
            {/*        <th>Hora</th>*/}
            {/*        <th>Lunes</th>*/}
            {/*        <th>Martes</th>*/}
            {/*        <th>Miercoles</th>*/}
            {/*        <th>Jueves</th>*/}
            {/*        <th>Viernes</th>*/}
            {/*        <th>Sabado</th>*/}
            {/*        <th>Domingo</th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    {yAxis?.map(e => {*/}
            {/*        console.log(e)*/}
            {/*        return <tr key={e} className="text-center">*/}
            {/*            <td>{e}</td>*/}
            {/*            <td>{items?.filter(i => i.timeslot__start.getDay() === 1 && format(i.timeslot__start, "HH:mm") === e).length}</td>*/}
            {/*            <td>{items?.filter(i => i.timeslot__start.getDay() === 2 && format(i.timeslot__start, "HH:mm") === e).length}</td>*/}
            {/*            <td>{items?.filter(i => i.timeslot__start.getDay() === 3 && format(i.timeslot__start, "HH:mm") === e).length}</td>*/}
            {/*            <td>{items?.filter(i => i.timeslot__start.getDay() === 4 && format(i.timeslot__start, "HH:mm") === e).length}</td>*/}
            {/*            <td>{items?.filter(i => i.timeslot__start.getDay() === 5 && format(i.timeslot__start, "HH:mm") === e).length}</td>*/}
            {/*            <td>{items?.filter(i => i.timeslot__start.getDay() === 6 && format(i.timeslot__start, "HH:mm") === e).length}</td>*/}
            {/*            <td>{items?.filter(i => i.timeslot__start.getDay() === 0 && format(i.timeslot__start, "HH:mm") === e).length}</td>*/}
            {/*        </tr>*/}
            {/*    })}*/}
            {/*    </tbody>*/}
            {/*</table>*/}
        </div>
    );
};

export default ReservationsCountReport;
