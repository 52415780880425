import React, {useState} from 'react';
import {Alert, Button, Col, Form} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Formik, Field, Form as FormikForm} from "formik";
import {useHistory} from "react-router-dom";

import {StoreState} from "../../../reducers";
import {CheckoutState} from '../../../actions/checkout';
import baseApi from "../../../apis/baseApi";
import yup from "../../../utils/yup";
import FieldError from "../../../components/form/FieldError";
import {setStudio, Studio} from "../../../actions/studio";
import {formatCurrency} from "../../../utils/currencyFormatters";


const ConfirmPayment = () => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch()
    const history = useHistory()

    const [errorMsg, setErrorMsg] = useState<string>();

    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const currency = studio.preferences.currency
    const checkoutState = useSelector<StoreState, CheckoutState>(state => state.checkout)

    const renderError = () => {
        if (!errorMsg) return <React.Fragment/>
        return <Alert variant="danger">{errorMsg}</Alert>
    }

    const BillingSchema = yup.object().shape({
        billing_details: yup.object().shape({
            name: yup.string().required(),
            address: yup.object().shape({
                line1: yup.string().required(),
                city: yup.string().required(),
                state: yup.string().required(),
                postal_code: yup.string().required(),
            })
        })
    })

    return (
        <div>
            <Formik
                validationSchema={BillingSchema}
                initialValues={{
                    billing_details: {
                        name: "",
                        address: {
                            line1: "",
                            line2: "",
                            city: "",
                            state: "",
                            postal_code: "",
                            country: "MX",
                        }
                    },
                    titleError: ""
                }} onSubmit={(values, {setSubmitting}) => {
                setErrorMsg(undefined)
                if (!stripe || !elements) {
                    setErrorMsg("Error, la pagina no esta lista.")
                    return
                }
                const cardElement = elements.getElement(CardElement);
                // stripe.createPaymentMethod({
                //     type: "card",
                //     // @ts-ignore
                //     card: cardElement,
                //     billing_details: values.billing_details,
                // }).then((result) => {
                //     if (result.error) {
                //         setErrorMsg(result.error.message);
                //         setSubmitting(false)
                //     } else {
                //         if (checkoutState.selectedPlan) {
                //             baseApi.post("/cbb/subscribe/", {
                //                 payment_method_id: result.paymentMethod.id,
                //                 price_id: checkoutState.selectedPlan.priceId
                //             }).then(() => {
                //                 dispatch(setStudio({
                //                     ...studio,
                //                     active: true,
                //                     product_id: checkoutState.selectedPlan?.productId || null
                //                 }))
                //                 history.push("/checkout-success")
                //             }).catch(e => {
                //                 console.log(e)
                //                 setErrorMsg(e.response.data.error.message)
                //                 setSubmitting(false)
                //             })
                //         }
                //     }
                // }).catch(result => {
                //     setSubmitting(false)
                //     setErrorMsg(result.error.message)
                // })
            }}>
                {({isSubmitting}) => (
                    <FormikForm>
                        <h3 className="text-center mt-5 mb-3">Confirmación</h3>
                        <div className="row justify-content-center">
                            <div
                                className="col-12 col-sm-9 col-md-8 col-lg-7 col-xxl-5 card card-custom py-20 px-10 mt-5 mt-md-0 mr-md-5 shadow">
                                {renderError()}
                                <h5 className=" text-muted">Suscríbete a {checkoutState.selectedPlan?.name}</h5>
                                {/*@ts-ignore*/}
                                <p className="mb-0 pb-0 font-size-h2"> {formatCurrency(checkoutState.selectedPlan?.price - (checkoutState.selectedPlan?.price * (checkoutState.selectedPlan?.discount_percent || 0)), currency)} /
                                    mes </p>
                                <hr/>
                                <h5 className="py-2 pb-4">Agrega tu método de pago</h5>
                                <Field className="form-control" name="billing_details.name"
                                       placeholder="Nombre en tarjeta"/>
                                <FieldError name="billing_details.name"/>
                                <Field className="form-control mt-1" name="billing_details.address.line1"
                                       placeholder="Dirección"/>
                                <FieldError name="billing_details.address.line1"/>
                                <Form.Row>
                                    <Col md={4}>
                                        <Field className="form-control mt-1" name="billing_details.address.city"
                                               placeholder="Ciudad"/>
                                        <FieldError name="billing_details.address.city"/>
                                    </Col>
                                    <Col md={4}>
                                        <Field className="form-control mt-1" name="billing_details.address.state"
                                               placeholder="Estado"/>
                                        <FieldError name="billing_details.address.state"/>
                                    </Col>
                                    <Col md={4}>
                                        <Field className="form-control mt-1" name="billing_details.address.postal_code"
                                               placeholder="Código Postal"/>
                                        <FieldError name="billing_details.address.postal_code"/>
                                    </Col>
                                </Form.Row>
                                <div className="p-2 my-2 rounded border">
                                    <CardElement/>
                                </div>
                                <Button type="submit" disabled={!stripe || isSubmitting} variant="success">
                                    Confirmar Suscripción
                                </Button>
                            </div>
                        </div>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default ConfirmPayment;
