import baseApi from "../apis/baseApi";
import {Dispatch} from "redux";
import {BranchActionTypes} from "./types";


export interface Branch {
    id: string
    name: string
    timezone: string
    phone: string
    whatsapp?: string
    parent: string
    address: BranchAddress
    gympass_gym_id: number | null;
    gympass_active: boolean;
    gympass_automated_checkin: boolean;
    public_key: string | null
    totalpass_config?: BranchTotalpassConfig | null;
}

export interface BranchTotalpassConfig {
    branch: string;
    created_on: string;
    id: number;
    is_active: boolean;
    updated_on: string;
}

interface BranchAddress {
    country: string;
    state: string;
    city: string;
    zip_code: string;
    line1: string;
    line2: string;
    lat?: any;
    lng?: any;
}

export type OptionType = {
    value: string;
    label: string;
}

export interface BranchesState {
    loading: boolean
    error: string
    branches: Branch[]
    // selectOptions: {value: string, label: string}[]
    selectOptions: OptionType[]
}

export type BranchesActions = {
    type: BranchActionTypes
    payload: any
}

export const fetchBranches = () => {
    return async (dispatch: Dispatch) => {
        baseApi.get<Branch[]>('/studios/branch/').then((response) => {
            dispatch<BranchesActions>({
                type: BranchActionTypes.BRANCHES_FETCHED_SUCCESS,
                payload: response.data
            })
            let currentBranch = response.data[0]
            const branchId = localStorage.getItem("branchId")
            if (branchId) {
                const branch = response.data.find(b => b.id === branchId)
                if (branch) currentBranch = branch
            }
            dispatch<BranchesActions>({
                type: BranchActionTypes.SELECT_BRANCH,
                payload: currentBranch
            })
        })
    }
}

export const updateBranches = (branches : Branch[]) => {
    return {
        type: BranchActionTypes.UPDATE_BRANCHES,
        payload: branches
    }
}

export const selectBranch = (branch: Branch) => {
    return  {
        type: BranchActionTypes.SELECT_BRANCH,
        payload: branch
    }
}

export const removeBranch = (branchId: string) => {
    return  {
        type: BranchActionTypes.REMOVE_BRANCH,
        payload: branchId
    }
}