import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import {endOfDay, formatISO, startOfDay} from "date-fns";
import baseApi from "../../../apis/baseApi";
import {Paginated} from "../../../types";
import {Table} from 'react-bootstrap';
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import EmptyTrSales from "../../../components/table/rows/EmptyTrSales";

import {formatDateTime} from "../../../utils/dateFormatters";
import TableFooter from "../../../components/table/TableFooter";

interface CashExpense {
    id: number
    amount: string
    concept: string
    created_on: string
    created_by: string
}

interface Props {
    rangeStart: Date
    rangeEnd: Date
}

const CashExpenses = ({rangeStart, rangeEnd}: Props) => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)

    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const [cashExpenses, setCashExpenses] = useState<CashExpense[]>([]);

    useEffect(() => {
        if (rangeStart && rangeEnd) {
            setLoading(true)
            const start = formatISO(startOfDay(rangeStart))
            const end = formatISO(endOfDay(rangeEnd))
            baseApi.get<Paginated>(`/cash-register/movement/?branch=${branch.id}&start=${start}&end=${end}&page=${page}`).then((resp) => {
                if (page === 1) setCount(resp.data.count)
                setCashExpenses(resp.data.results)
                setCount(resp.data.count)
                setLoading(false)
            }).catch((err) => {
                alert("ERROR")
            })
        }
    }, [branch, rangeStart, rangeEnd, page]);

    return (
        <div>
            <Table hover>
                <TableHeader headers={["FECHA", "MONTO", "CONCEPTO", "CREADOR"]}/>
                <tbody>
                {loading ? <LoadingTr/> : cashExpenses.length === 0 ? <EmptyTrSales/> : cashExpenses.map(s => {
                    const isPositive = parseFloat(s.amount) > 0
                    return <tr className="text-center" key={s.id}>
                        <td>{formatDateTime(s.created_on)}</td>
                        <td className={isPositive ? "text-success" : "text-danger"}>{isPositive ? "+" : ""}{s.amount}</td>
                        <td>{s.concept}</td>
                        <td>{s.created_by}</td>
                    </tr>
                })}
                </tbody>
            </Table>
            <TableFooter count={count} pageCount={cashExpenses.length} pageSize={10} onPageChange={(page) => {
                setPage(page)
            }}/>
        </div>
    );
};

export default CashExpenses;
