import React from 'react';

const EmptyTrNotifications = () => {
    return (
        
        <div>
            <div className="w-100 text-muted font-size-h2-xl font-weight-bold">
                <div>
            
                <div className='font-size-h5 font-wei'>Envía avisos, recordatorios o promociones 🌟</div>
                <div className='font-size-h5 font-wei'>📲 Directo al celular de tus clientes</div>
                <div className=''>
                    <div className="col-12 col-md-8 col-lg-7 col-xl-5 col-xxl-4 mx-auto">
                        <div className="notifications-image col-12"></div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default EmptyTrNotifications;
