import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import baseApi from "../../../../apis/baseApi";
import {getApiErrorMessage} from "../../../../utils/apiErrors";
import {Member} from "../../index";
import FormField from "../../../../components/form/FormField";
import FieldError from "../../../../components/form/FieldError";
import yup from "../../../../utils/yup";

type Props = {
    show: boolean,
    onHide: Function
    member: Member
}

const EditMemberPasswordModal = ({show, onHide, member}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const validationSchema = yup.object().shape({
        password: yup.string().required().min(6).max(32)
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik initialValues={{password: ""}}
                        validationSchema={validationSchema}
                        onSubmit={(values, {setSubmitting}) => {
                            setSuccess(null)
                            baseApi.patch(`/members/update/${member.id}/`, values).then(resp => {
                                setSuccess(true)
                                setAlertMessage("Contraseña actualizada correctamente.")
                                setSubmitting(false)
                            }).catch(err => {
                                    alert(getApiErrorMessage(err))
                                    setSubmitting(false)
                                }
                            )
                        }}>
                    {({isSubmitting}) => (
                        <FormikForm>
                            <Modal.Header>
                                Actualizar contraseña
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FormField label="Nueva contraseña" name="password"/>
                                <FieldError name="password"/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default EditMemberPasswordModal;
