import React from 'react';
import {PlanSaleItem} from "../../index";
import ReactDatePicker from "react-datepicker";
import {format, isToday} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import {formatCurrency} from "../../../../utils/currencyFormatters";
import {Button} from "react-bootstrap";

interface Props {
    item: PlanSaleItem
    currency: string
    onActivateDateChange: (date: Date) => void
    onRemove: () => void
}

const CartPlanListTle = ({item, currency, onActivateDateChange, onRemove}: Props) => {
    return (
        <div className="mt-2  mx-1  card border-0 shadow rounded-lg">
            <div className="row justify-content-around align-items-start gutter-0 mt-5 px-3">
                <div className="col-6 pl-3" style={{lineHeight: '4px'}}>
                    {/* SKU */}
                    {/* <p className="text-muted font-size-xs">SKU: {i.plan.sku}</p> */}
                    {/* FIN SKU */}
                    <div
                        className="font-weight-bolder label label-inline label-lg label-light-primary rounded-lg mt-1 ml-md-3">{item.plan.name}</div>
                    {/* <p className="font-weight-bolder">{i.plan.name}</p> */}
                    <p>{item.plan.description}</p>
                </div>
                <div className="col-3 text-center p-0" style={{lineHeight: '4px'}}>
                    <p className="text-muted font-size-xs">{item.plan.is_subscription ? 'Día de cobro' : 'INICIO'}</p>
                    <ReactDatePicker
                        onChange={(date: Date) => {
                            if (date !== null) {
                                onActivateDateChange(date)
                            }
                        }}
                        customInput={
                            <div className="clickable">
                                <p>
                                    {isToday(item.activate_on) ? 'Hoy' : format(item.activate_on, "dd/MMM/yy")}
                                    <span style={{cursor: "pointer"}} className="text-primary ml-1">
                                            <FontAwesomeIcon icon={faEdit}/>
                                        </span>
                                </p>
                            </div>
                        }
                    />
                </div>
                <div className="col-2 text-center p-0" style={{lineHeight: '4px'}}>
                    <p className="text-muted font-size-xs">PRECIO</p>
                    <p>{formatCurrency(item.price, currency)}</p>
                </div>
                <div className="col-1 p-0" style={{lineHeight: '4px'}}>
                    <Button onClick={onRemove} variant="link">
                        <FontAwesomeIcon icon={faTimes} className='text-danger'/>
                    </Button>
                </div>
            </div>
            <div className="separator separator-solid"/>
        </div>
    );
};

export default CartPlanListTle;
