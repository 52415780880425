import baseApi from "../apis/baseApi";
import {Dispatch} from "redux";
import {StaffActionTypes, StudioActionTypes} from "./types";
import {StudioActions} from "./studio";

export interface StaffUser {
    id: string,
    archived: boolean,
    sms_verified: boolean,
    user: string,
    is_staff_of: string[]
    permissions: StaffUserPermissions[]
    show_onboarding: boolean
    view_all_coaches: boolean
    coaches_filter: string[]
    loading: boolean
}

export interface StaffUserPermissions {
    feature: string,
    view: boolean,
    create: boolean,
    edit: boolean,
    delete: boolean
}

export interface StaffUserActions {
    type: StaffActionTypes,
    payload: StaffUser
}

export const getStaffUser = () => {
    return async (dispatch: Dispatch) => {
        baseApi.get("/staff/me/").then((resp) => {
            dispatch<StaffUserActions>({
                type: StaffActionTypes.FETCHED,
                payload: resp.data
            })
            dispatch<StudioActions>({
                type: StudioActionTypes.RETRIEVED_STUDIO,
                payload: resp.data.studio
            })
        })
    }
}