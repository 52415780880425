import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import TableFooter from "../../../components/table/TableFooter";
import {endOfDay, formatISO, startOfDay} from "date-fns";
import baseApi from "../../../apis/baseApi";
import {Branch} from "../../../actions";

interface MemberRankItem {
    full_name: string;
    last_name: string | null;
    external_id: string;
    total_reservations: number;
    total_assistance: number;
}

interface Props {
    rangeStart: Date
    rangeEnd: Date | null
    branch: Branch
    active: boolean
}

const ReservationsRanking = ({rangeStart, rangeEnd, branch, active}: Props) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<MemberRankItem[]>([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (active && rangeStart && rangeEnd) {
            const fetchPage = (page: number) => {
                const start = formatISO(startOfDay(rangeStart))
                const end = formatISO(endOfDay(rangeEnd))
                setLoading(true)
                baseApi.get(`/reports/member-rankings/?branch=${branch.id}&start=${start}&end=${end}&page=${page}`).then(resp => {
                    if (page === 1) setCount(resp.data.count)
                    setItems(resp.data.results)
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                },)
            }
            fetchPage(page)
        }
    }, [branch, page, rangeStart, rangeEnd, active]);

    return (
        <div className="rounded shadow-sm p-5 my-5 col-lg-8 col-xxl-6" style={{background: "white"}}>
            <div>
                <div className='mt-2 text-right mb-5'>
                    <div
                        className='label label-light-success label-l font-size-l p-4 font-weight-bold mr-2 label-inline'>#
                        asistencias
                    </div>
                    <div
                        className='label label-light-danger label-l font-size-l p-4 font-weight-bold mr-2 label-inline'>#
                        faltas
                    </div>
                    <div className='label label-l font-size-l p-4 font-weight-bold mr-2 label-inline text-muted'>#
                        reservaciones total
                    </div>

                </div>
            </div>
            <Table hover bordered>
                <TableHeader
                    headers={["CLIENTE CON MÁS ASISTENCIAS↑", "TOTAL RESERVAS",]}/>

                <tbody>
                {loading ? <LoadingTr/> : items.map(item => {
                    return <tr key={item.external_id} className="" >
                        <td className="text-center w-300px font-size-lg">
                            <div className='text-muted font-size-xs mb-n2'>{item.external_id}</div>
                            <div className='text-capitalize mb-1'>{item.full_name} {item.last_name ?? ''}</div>
                            <div
                                className='label label-light-success label-lg font-size-h6 p-5 font-weight-bolder'>{item.total_assistance}</div>
                            <div
                                className='label label-light-danger label-lg font-size-h6 p-5 font-weight-bolder ml-2'>{item.total_reservations - item.total_assistance}</div>
                        </td>
                        <td className="text-center w-150px align-items-middle">
                            <div
                                className='label text-muted label-lg font-size-h6 p-5 font-weight-bolder mt-10'>{item.total_reservations}</div>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
            <TableFooter count={count} pageCount={items.length} pageSize={10} onPageChange={(page) => {setPage(page)}} />
        </div>
    );
};

export default ReservationsRanking;
