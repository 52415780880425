import React from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Modal, Col, Row} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";

import FormField from "../../../components/form/FormField";
import baseApi from "../../../apis/baseApi";
import {StoreState} from "../../../reducers";
import {Branch, updateBranches} from "../../../actions";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {FormikSelect} from "../../../components/form/FormikSelect";
import {tzOptions} from "../../../data/timezones";

type CreateEditBranchModalProps = {
    show: boolean,
    onHide: Function,
    branch?: Branch
}

const CreateEditBranchModal = ({show, onHide, branch}: CreateEditBranchModalProps) => {
    // TODO: add timezone field
    const dispatch = useDispatch()
    const branches = useSelector<StoreState, Branch[]>(state => state.branches.branches)
    const [alertMsg, setAlertMsg] = useState("")
    const [success, setSuccess] = useState<boolean | null>(null);

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik initialValues={{
                    name: branch?.name,
                    phone: branch?.phone,
                    timezone: branch?.timezone,
                    line1: branch?.address.line1,
                    line2: branch?.address.line2,
                    city: branch?.address.city,
                    zip_code: branch?.address.zip_code,
                    state: branch?.address.state,
                    country: branch?.address.country
                }} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    setAlertMsg("")
                    if (branch) {
                        baseApi.patch<Branch>(`/studios/branch/${branch.id}/`, {
                            ...values,
                            address: values,
                        }).then((resp) => {
                            dispatch(updateBranches(branches.map((b) => b.id === branch.id ? resp.data : b)))
                            setAlertMsg("Se ha actualizado la sucursal correctamente")
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch((err) => {
                            setAlertMsg(getApiErrorMessage(err))
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    } else {
                        baseApi.post<Branch>("/studios/branch/", {
                            ...values,
                            address: values,
                            parent: branches[0].parent
                        }).then((resp) => {
                            dispatch(updateBranches([...branches, resp.data]))
                            setAlertMsg("Se ha creado la sucursal correctamente")
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch((err) => {
                            setAlertMsg(getApiErrorMessage(err))
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }
                }}>
                    {({isSubmitting}) => (
                        <FormikForm>
                            <Modal.Header>
                                {branch ? "Editar" : "Crear"} Sucursal
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FormField label="Nombre" name="name"/>
                                <FormField label="Teléfono" name="phone"/>
                                <hr/>
                                <Row className="form-group align-items-center">
                                    <Col className="offset-lg-1 col-form-label" lg={2}>
                                        <span className="text-danger">*</span>Zona Horaria
                                    </Col>
                                    <Col lg={4}>
                                        <Field name="timezone" component={FormikSelect} options={tzOptions}/>
                                    </Col>
                                    <Col/>
                                </Row>
                                <FormField label="Direccion" name="line1"/>
                                <FormField label="Colonia" name="line2"/>
                                <FormField label="Ciudad" name="city"/>
                                <FormField label="Estado" name="state"/>
                                <FormField label="Código Postal" name="zip_code"/>
                                <FormField label="Pais" name="country"/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : branch ? "Confirmar" : "Crear"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEditBranchModal;
