import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import ReactJoyride, {Step, ACTIONS, EVENTS, STATUS, CallBackProps} from "react-joyride";
import {useSelector} from "react-redux";
import {Image} from "react-bootstrap";

import {StoreState} from "../reducers";
import {StaffUser} from "../actions/staffUser";
import OnboardingTooltip from "./misc/OnboardingTooltip";
import baseApi from "../apis/baseApi";

import inicial from "../assets/img/onboarding/inicial.png"
import clientsImg from "../assets/img/onboarding/clients.png"
import posImg from "../assets/img/onboarding/pos.png"
import rsvpImg from "../assets/img/onboarding/rsvp.png"
import rsvpWaitlistImg from "../assets/img/onboarding/rsvp_wait.jpg"
import schedulesImg from "../assets/img/onboarding/schedules.png"
import statsImg from "../assets/img/onboarding/stats.png"
import finishImg from "../assets/img/onboarding/finish.svg"

const Onboarding = () => {
    const staff = useSelector<StoreState, StaffUser>(state => state.staffUser)
    const history = useHistory()
    const [run, setRun] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);

    useEffect(() => {
        setRun(staff.show_onboarding || false)
    }, [staff]);


    const steps: Step[] = [
        {
            target: ".on-welcome",
            content: <div className="text-center">
                <h2>Bienvenido</h2>
                <div className="py-2"><Image fluid src={inicial}/></div>
                <h4>Tomemos un breve tour de las funciones <br/> clave de Cycling Boost</h4>
            </div>,
            placement: "center",
            disableBeacon: true
        },
        {
            target: ".on-schedules",
            content: <div className="text-center">
                <p style={{letterSpacing: "2px"}} className="text-muted font-weight-bold font-size-lg">CALENDARIO</p>
                <h4>Aquí crearas y organizaras los horarios de tu<br/>estudio</h4>
                <div className="py-2"><Image fluid src={schedulesImg}/></div>
                <h4>Que se publicaran en tu pagina web y App para<br/>ser reservados por tus clientes</h4>
            </div>,
            placement: "center",
            disableBeacon: true
        },
        {
            target: ".on-rsvp",
            content: <div className="text-center">
                <p style={{letterSpacing: "2px"}} className="text-muted font-weight-bold font-size-lg">RESERVACIONES</p>
                <h4>Todas las reservaciones llegaran aquí</h4>
                <div className="py-2"><Image fluid src={rsvpImg}/></div>
                <h4>La plataforma gestiona automáticamente cancelaciones, reagenda y lista de espera por ti</h4>
            </div>,
            placement: "center",
            disableBeacon: true
        },
        {
            target: ".on-waitlist",
            content: <div className="text-center">
                <p style={{letterSpacing: "2px"}} className="text-muted font-weight-bold font-size-lg">CANCELACIONES &
                    WAITLIST</p>
                <h4>Libera los lugares por cancelaciones instantáneamente</h4>
                <div className="py-2"><Image fluid src={rsvpWaitlistImg}/></div>
            </div>,
            placement: "center",
            disableBeacon: true
        },
        {
            target: ".on-clients",
            content: <div className="text-center">
                <p style={{letterSpacing: "2px"}} className="text-muted font-weight-bold font-size-lg">CLIENTES</p>
                <h4>Gestiona tus Clientes sus creditos y membresías</h4>
                <div className="py-2"><Image fluid src={clientsImg}/></div>
            </div>,
            placement: "center",
            disableBeacon: true
        },
        {
            target: ".on-pos",
            content: <div className="text-center">
                <p style={{letterSpacing: "2px"}} className="text-muted font-weight-bold font-size-lg">PAQUETES Y
                    MEMBRESIAS</p>
                <h4>Crea tus paquetes de Creditos y Membresías Vende Online</h4>
                <div className="py-2"><Image fluid src={posImg}/></div>
            </div>,
            placement: "center",
            disableBeacon: true
        },
        {
            target: ".on-reports",
            content: <div className="text-center">
                <p style={{letterSpacing: "2px"}} className="text-muted font-weight-bold font-size-lg">REPORTES Y
                    ESTADÍSTICAS</p>
                <h4>Lleva un control de las finanzas de tu negocio, genera reportes y estadísticas</h4>
                <div className="py-2"><Image fluid src={statsImg}/></div>
            </div>,
            placement: "center",
            disableBeacon: true
        },
        {
            target: ".on-finish",
            content: <div className="text-center">
                <h4>Listo! Explora la plataforma</h4>
                <div className="py-2"><Image fluid src={finishImg}/></div>
                <h4>Escríbenos si tienes cualquier duda<br/>Por el live chat o whatsapp</h4>
            </div>,
            placement: "center",
            disableBeacon: true
        },
    ]

    const joyrideLocale = {
        back: "Atrás",
        close: "Cerrar",
        last: "Finalizar",
        next: "Siguiente",
        skip: "Saltar"
    };

    const handleJoyrideCallback = async (data: CallBackProps) => {
        const {action, index, status, type} = data;
        switch (index) {
            case 1:
                history.push("/schedules")
                break
            case 2:
                history.push("/reservations")
                break
            case 4:
                history.push("/users")
                break
            case 5:
                history.push("/pos")
                break
            case 6:
                history.push("/reports")
                break
            case 7:
                history.push("/")
                break
            default:
                console.log(index)
                break
        }
        if (type === EVENTS.TOUR_END) {
            baseApi.patch("/staff/me/", {show_onboarding: false}).then()
        }
        if (action === ACTIONS.CLOSE) {
            setRun(false)
        }
        // @ts-ignore
        else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            if (type !== EVENTS.TARGET_NOT_FOUND) {
                setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
            }
        } else { // @ts-ignore
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                setRun(false);
            }
        }
    };

    return <ReactJoyride
        run={run}
        continuous={true}
        stepIndex={stepIndex}
        tooltipComponent={OnboardingTooltip}
        locale={joyrideLocale} steps={steps}
        callback={handleJoyrideCallback}
    />

};

export default Onboarding;
