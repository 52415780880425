import React, {useEffect, useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";

import FormField from "../../../components/form/FormField";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import baseApi from "../../../apis/baseApi";
import {FormikSelect} from "../../../components/form/FormikSelect";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {BranchesState} from "../../../actions";
import yup from "../../../utils/yup";
import FieldError from "../../../components/form/FieldError";
import {CartMember} from "../../Pos/Cart";
import ImageField from "../../../components/form/ImageField";
import {formatISO, startOfDay, subDays} from "date-fns";
import {StudioEmail} from "./index";

type CreateCoachModalProps = {
    show: boolean
    onHide: Function
    emails: StudioEmail[]
    setEmails: (emails: StudioEmail[]) => void
}

enum EmailRecipientFilter {NONE, ALL, ACTIVE_MEMBERS, INACTIVE_SINCE, PLAN_MEMBERS, SELECT_MEMBERS}

const CreateEmailModal = ({show, onHide, emails, setEmails}: CreateCoachModalProps) => {
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const currentBranchId = useSelector<StoreState, string>(state => state.currentBranch.id)

    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const [searchTerm, setSearchTerm] = useState("")
    const [fetchingMembers, setFetchingMembers] = useState(false)
    const [members, setMembers] = useState<CartMember[]>([])
    const [img, setImg] = useState<null | File>(null);

    useEffect(() => {
        baseApi.get(
            `/members/search/?branch=${currentBranchId}`,
        ).then((response) => {
            setMembers(response.data.results);
            setFetchingMembers(false);
        }).catch();
    }, [])

    useEffect(() => {
        const searchFn = setTimeout(() => {
            if (searchTerm.length < 2) return
            setFetchingMembers(true)
            baseApi.get(
                `/members/search/?search=${searchTerm}&branch=${currentBranchId}`,
            ).then((response) => {
                let options = [...members, ...response.data.results]
                console.log(options)
                let uniq = options.filter((m, i, arr) => arr.findIndex(m2 => m2.id === m.id) === i)
                setMembers(uniq)
                setFetchingMembers(false);
            }).catch();
        }, 800);
        return () => clearTimeout(searchFn);
    }, [searchTerm, currentBranchId]);

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        title: yup.string().min(3).max(80).required(),
        text: yup.string().max(2500).required(),
        branch: yup.string().required()
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{
                        email_type: "0",
                        filter: EmailRecipientFilter.ALL,
                        branch: currentBranchId,
                        title: "",
                        text: "",
                        recipients: [] as string[],
                        recipient_rules: {member_since: formatISO(new Date(2020, 0))}

                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        const formData = new FormData();
                        if (img !== null) {
                            formData.append("bottom_image", img!, img!.name)
                        }
                        Object.entries(values).forEach(([k, v]) => {
                            let value;
                            if (v !== null) {
                                if (v.constructor === Array) {
                                    v.forEach(x => {
                                        formData.append(k, x)
                                    })
                                } else if (v.constructor === Object) {
                                    const parentKey = k
                                    Object.entries(v).forEach(([nk, nv]) => {
                                        formData.append(`${parentKey}.${nk}`, nv.toString())
                                    })
                                } else {
                                    value = v.toString()
                                    formData.append(k, value)
                                }
                            }
                        })
                        console.log(formData)
                        baseApi.post("/emails/", formData).then(resp => {
                            setEmails([resp.data, ...emails])
                            setAlertMessage("E-Mails enviados")
                            setSuccess(true)
                        }).catch(e => {
                            setAlertMessage(getApiErrorMessage(e))
                            setSuccess(false)
                            setSubmitting(false)
                        })

                    }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>
                            <Modal.Header>
                                <div>
                                    Crear E-Mail<br/>
                                    <p className="text-muted font-size-sm py-0 my-0">Limite de 1,000 E-Mails por día</p>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div className="form-group row">
                                    <label className="col-lg-2 offset-lg-1 col-form-label">
                                        - Tipo E-Mail
                                    </label>
                                    <div className="col-lg-4">
                                        <Field
                                            name="email_type"
                                            placeholder="Tipo E-Mail"
                                            component={FormikSelect}
                                            options={[
                                                {value: "0", label: "Informativo"},
                                                {value: "1", label: "Promocional"},
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-2 offset-lg-1 col-form-label">
                                        - Filtro clientes
                                    </label>
                                    <div className="col-lg-4">
                                        <Field
                                            name="filter"
                                            placeholder="Filtro de clientes"
                                            component={FormikSelect}
                                            onChange={(option: any) => {
                                                const value = option.value as EmailRecipientFilter
                                                setFieldValue("recipient_rules", null)
                                                setFieldValue("recipients", [])
                                                switch (value) {
                                                    case EmailRecipientFilter.ALL:
                                                        setFieldValue("recipient_rules.member_since", formatISO(new Date(2020, 0)))
                                                        break;
                                                    case EmailRecipientFilter.ACTIVE_MEMBERS:
                                                        setFieldValue("recipient_rules.is_active", true)
                                                        break
                                                    case EmailRecipientFilter.INACTIVE_SINCE:
                                                        setFieldValue("recipient_rules.inactive_since", formatISO(startOfDay(subDays(new Date(), 15))))
                                                        break
                                                }
                                            }}
                                            options={[
                                                {value: EmailRecipientFilter.ALL, label: "Todos"},
                                                {value: EmailRecipientFilter.ACTIVE_MEMBERS, label: "Clientes Activos"},
                                                {
                                                    value: EmailRecipientFilter.INACTIVE_SINCE,
                                                    label: "Clientes por recuperar"
                                                },
                                                {
                                                    value: EmailRecipientFilter.SELECT_MEMBERS,
                                                    label: "Seleccionar clientes"
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div hidden={values.filter !== EmailRecipientFilter.SELECT_MEMBERS}
                                     className='form-group row'>
                                    <label className="col-lg-2 offset-lg-1 col-form-label">
                                        - Clientes
                                    </label>
                                    <div className="col-lg-8">
                                        <Field
                                            name="recipients"
                                            placeholder="Seleccionar clientes"
                                            component={FormikSelect}
                                            isMulti={true}
                                            onInputChange={(text: string) => {
                                                setSearchTerm(text);
                                            }}
                                            noOptionsMessage={() => "Sin resultados"}
                                            isLoading={fetchingMembers} loadingMessage={() => "Cargando clientes..."}
                                            options={members.map((m) => ({
                                                value: m.id,
                                                label: `${m.external_id} - ${m.full_name} ${m.last_name ?? ''}`
                                            }))}
                                        />
                                    </div>
                                    <FieldError name="member"/>
                                </div>
                                <div className="form-group row" hidden={branchesState.selectOptions.length === 1}>
                                    <label className="col-lg-2 offset-lg-1 col-form-label">
                                        - Sucursal
                                    </label>
                                    <div className="col-lg-4">
                                        <Field name="branch" options={branchesState.selectOptions}
                                               component={FormikSelect}/>
                                        <FieldError name="branch"/>
                                    </div>
                                </div>
                                <FormField label="- Asunto" name="title"/>
                                <FieldError name="title"/>
                                <FormField component="textarea" label="- Mensaje" name="text"
                                           fieldClassName="col-lg-8"/>
                                <FieldError name="text"/>
                                <div className="form-group row">
                                    <label className="col-lg-2 offset-lg-1 col-form-label">
                                        - Imagén (opcional)
                                    </label>
                                    <div className="col-lg-4">
                                        <ImageField onChange={(file) => setImg(file)} width={220} maxUploadWidth={900}
                                                    maxUploadHeight={1200} quality={90}/>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Enviar E-Mails"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEmailModal;
