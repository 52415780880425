import React, {useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";
import baseApi from "../../../../apis/baseApi";
import {getApiErrorMessage} from "../../../../utils/apiErrors";
import {Member} from "../../index";
import {useSelector} from "react-redux";
import {StoreState} from "../../../../reducers";
import {
    MemberProfileExtraFieldValue,
    StudioMemberExtraFieldsState
} from "../../../../actions/memberExtraFields";
import FormField from "../../../../components/form/FormField";
import Checkbox from "../../../../components/form/Checkbox";

type Props = {
    show: boolean,
    onHide: Function,
    member: Member
    setMember: (member: Member) => void
}

const MemberExtraFieldsModal = ({show, onHide, member, setMember}: Props) => {
    const extraFieldsState = useSelector<StoreState, StudioMemberExtraFieldsState>(state => state.studioMemberProfileExtraFields)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(false);
    const [formInitialValues, setFormInitialValues] = useState({});

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };


    useEffect(() => {
        if (!extraFieldsState.fetching) {
            baseApi.get<MemberProfileExtraFieldValue[]>(`/members/extra-fields/?member=${member.id}`).then((resp) => {
                let initialValues: any = {}
                for (const extraField of extraFieldsState.extra_fields) {
                    initialValues[extraField.id.toString()] = ""
                }
                for (const value of resp.data) {
                    if (value.value === "false") {
                        initialValues[value.profile_extra_field.toString()] = false
                    } else {
                        initialValues[value.profile_extra_field.toString()] = value.value
                    }
                }
                setFormInitialValues(initialValues)
                setLoading(false)
            })

        }
    }, [extraFieldsState, member])

    if (loading) {
        return <div>Cargando</div>
    }
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    enableReinitialize
                    initialValues={formInitialValues}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSuccess(null)
                        let data = []
                        for (const [k, v] of Object.entries(values)) {
                            data.push({profile_extra_field: k, value: v?.toString() || ""})
                        }
                        baseApi.patch(`/members/update/${member.id}/`, {
                            "extra_fields": data
                        }).then((resp) => {
                            setSuccess(true)
                            setAlertMessage("Usuario actualizado.")
                            const extraFields: [MemberProfileExtraFieldValue] = resp.data.extra_fields
                            let initialValues: any = {}
                            extraFields.forEach(e => {
                                if (e.value === "false") {
                                    return initialValues[e.profile_extra_field.toString()] = false;
                                }
                                return initialValues[e.profile_extra_field.toString()] = e.value;
                            })
                            setFormInitialValues(initialValues)
                            resetForm()
                        }).catch(err => {
                            setAlertMessage(getApiErrorMessage(err))
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }}>
                    {({isSubmitting}) => (
                        <FormikForm>
                            <Modal.Header>
                                Campos extra
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                {extraFieldsState.extra_fields.map((ef) => {
                                    if (ef.field_type === 1) {
                                        return <div className="offset-lg-1">
                                            <Field component={Checkbox} type="checkbox" name={ef.id} label={ef.label}/>
                                        </div>
                                    }
                                    return <FormField key={ef.id} label={ef.label} name={ef.id.toString()}/>
                                })}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button variant="outline-primary" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default MemberExtraFieldsModal;

