import React from 'react';
import {SaleItem} from "../../../Reports/Sales";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {formatCurrency} from "../../../../utils/currencyFormatters";

interface Props {
    item: SaleItem
    currency: string
    onRemove: () => void
}

const CartItemListTile = ({item, currency, onRemove} : Props) => {
    return (
        <div>
            <div className="row justify-content-around align-items-start gutter-0">
                <div className="col-4 p-0" style={{lineHeight: '4px'}}>
                    <p className="text-muted font-size-xs"></p>
                    <p className="font-weight-bolder">{item.name}</p>
                    <p></p>
                </div>
                <div className="col-2 text-center p-0" style={{lineHeight: '4px'}}>

                </div>
                <div className="col-2 text-center p-0" style={{lineHeight: '4px'}}>
                    <p className="text-muted font-size-xs">PRECIO</p>
                    <p>{formatCurrency(item.price, currency)}</p>
                </div>
                <div className="col-1 p-0" style={{lineHeight: '4px'}}>
                    <Button onClick={onRemove} variant="link">
                        <FontAwesomeIcon icon={faTimes}/>
                    </Button>
                </div>
            </div>
            <div className="separator separator-solid"/>
        </div>
    );
};

export default CartItemListTile;
