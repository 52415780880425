import React, {useEffect, useState} from 'react';
import baseApi from "../../../apis/baseApi";
import {Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import {formatDateTime} from "../../../utils/dateFormatters";
import TableFooter from "../../../components/table/TableFooter";

interface Inventory {
    id: number;
    product: string;
    qty: number;
    branch: string;
}

interface InventoryTransaction {
    id: number;
    inventory: Inventory;
    type: number;
    qty: number;
    created_on: string;
    sale?: string;
    created_by: string;
}

const InventoryReport = () => {
    const [transactions, setTransactions] = useState<InventoryTransaction[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const fetchPage = (page: number) => {
            setLoading(true)
            baseApi.get(`/products/tr/?page=${page}`).then(resp => {
                if (page === 1) setCount(resp.data.count)
                setTransactions(resp.data.results)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            },)
        }
        fetchPage(page)
    }, [page]);

    return (
        <div>
            <h2 className="pt-10 pb-5">Movimientos de inventario</h2>
            <div className="rounded shadow-sm px-2 pt-2" style={{background: "white"}}>
                <Table hover>
                    <TableHeader headers={["FECHA", "CANTIDAD", "PRODUCTO", "TIPO", "CREADOR"]}/>
                    <tbody>
                    {loading ? <LoadingTr/> : transactions.map(tr => {
                        return <tr className="" key={tr.id}>
                            <td className="w-200px text-center">{formatDateTime(tr.created_on)}</td>
                            <td className="text-center w-150px">{tr.qty > 0 ? "+" : ""}{tr.qty}</td>
                            <td className="text-center w-150px">{tr.inventory.product}</td>
                            <td className="text-center w-150px">{tr.type === 0 ? "VENTA" : tr.type === 1 ? "AJUSTE" : "INICIAL"}</td>
                            <td className="text-center w-150px">{tr.created_by}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
                <TableFooter count={count} pageCount={transactions.length} pageSize={10}
                             onPageChange={(page) => {
                                 setPage(page)
                             }}/>
            </div>
        </div>
    );
};

export default InventoryReport;
