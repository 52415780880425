import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {StudioClassCategory} from "../../../actions/studioClasses";
import TableHeader from "../../../components/table/TableHeader";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import TableActionsCell from "../../../components/table/TableActionsCell";
import {Table} from "react-bootstrap";
import CreateEditClassCategoryModal from "./CreateEditClassCategoryModal";

const StudioClassCategoriesListView = () => {
    const dispatch = useDispatch()
    const classCategories = useSelector<StoreState, StudioClassCategory[]>(state => state.studio.studio_class_categories)

    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [selectedCat, setSelectedCat] = useState<StudioClassCategory>();

    return <div className="p-4">
        <CreateEditClassCategoryModal show={showCreate} onHide={() => {
            setShowCreate(false);
            setSelectedCat(undefined)
        }} studioClassCategory={selectedCat} />
        <div className="text-right d-none">
            <button onClick={() => setShowCreate(true)} className="btn btn-light-primary mr-3 btn-pill">+ Crear </button>
        </div>
        <Table className="text-left mt-5">
            <TableHeader headers={["Nombre", "Acciones"]}/>
            <tbody className="border-top">

            {classCategories.length === 0 ? <EmptyTr /> : classCategories.map((c) => {
                return <tr className="my-4" key={c.id}>
                    <td className="text-center font-size-lg">{c.name}</td>
                    <td className="text-center px-1">
                        <TableActionsCell onClickEdit={() => {
                            setSelectedCat(c)
                            setShowCreate(true)
                        }} />
                    </td>
                </tr>
            })}
            </tbody>
        </Table>
    </div>
};

export default StudioClassCategoriesListView;
