import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, FieldArray, Field} from "formik";
import FormField from "../../components/form/FormField";
import baseApi from "../../apis/baseApi";
import FormInputGroup from "../../components/form/FormInputGroup";
import yup from '../../utils/yup';
import FieldError from "../../components/form/FieldError";
import {Product} from "./Cart/Products";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import MiniFormField from "../../components/form/MiniFormField";
import Checkbox from "../../components/form/Checkbox";

type CreateProductModalProps = {
    show: boolean,
    onHide: Function
    product?: Product,
    products: Product[],
    setProducts: (products: Product[]) => void
}

export interface ProductInventory {
    id?: number
    qty: number
    branch: string
}

const CreateProductModal = ({show, onHide, products, setProducts, product}: CreateProductModalProps) => {
    const branches = useSelector<StoreState, Branch[]>(state => state.branches.branches)
    const [success, setSuccess] = useState<boolean | null>(null);

    const renderAlert = () => {
        if (success === null) return;
        let message = success
            ? "Se ha creado el producto."
            : "Ocurrió un error al intentar crear el producto.";
        if (product) {
            message = success ? "Producto actualizado" : "El producto no pudo ser actualizado"
        }
        return <Alert variant={success ? "success" : "danger"}>{message}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        name: yup.string().required(),
        price: yup.number().required().positive()
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{
                        name: product?.name ?? "",
                        description: product?.description ?? "",
                        sku: product?.sku ?? "",
                        price: product?.price ?? "",
                        uses_inventory: product?.uses_inventory ?? false,
                        inventories: branches.map(b => {
                            let inventory = product?.inventories.find(i => i.branch === b.id)
                            if (!inventory) {
                                inventory = {qty: 0, branch: b.id}
                            }
                            return inventory
                        }),
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        if (product) {
                            baseApi.put<Product>(`/products/${product.id}/`, values).then((resp) => {
                                setProducts(products.map(p => p.id === product.id ? resp.data : p))
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch(err => {
                                    setSuccess(false)
                                    setSubmitting(false)
                                }
                            )
                        } else {
                            baseApi.post<Product>("/products/", values).then((resp) => {
                                setProducts([...products, resp.data])
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch(err => {
                                    setSuccess(false)
                                    setSubmitting(false)
                                }
                            )
                        }
                    }}>
                    {({values, isSubmitting}) => (
                        <FormikForm>

                            <Modal.Header>
                                {product ? "Editar" : "Crear"} Producto
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FieldError name="name"/>
                                <FormField label="Nombre" name="name"/>
                                <FormField component="textarea" label="Descripción" name="description"/>
                                <FieldError name="price"/>
                                <FormInputGroup name="price" label="Precio" groupText="$" prepend={true}/>
                                <FormField label="SKU" name="sku"/>
                                {/*INVENTORY*/}
                                <div className="offset-lg-1">
                                    <Field name="uses_inventory" component={Checkbox} label="Usar inventario"/>
                                    <div hidden={!values.uses_inventory}>
                                        <FieldArray name="inventories" render={arrayHelpers => (<div>
                                            {values.inventories.map((i: ProductInventory, index) => {
                                                return <div className="d-inline-flex">
                                                    <MiniFormField name={`inventories[${index}].qty`}/>
                                                    <div>{branches.find(b => b.id === i.branch)!.name}</div>
                                                </div>
                                            })}
                                        </div>)}/>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateProductModal;
