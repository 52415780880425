import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {BranchesState} from "../../actions";
import baseApi from "../../apis/baseApi";
import {Member} from "./index";
import {getApiErrorMessage} from "../../utils/apiErrors";
import ModalSelect from "../../components/form/ModalSelect";
import {FormikSelect} from "../../components/form/FormikSelect";
import {Studio} from "../../actions/studio";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {format} from "date-fns";
import Select from "react-select";
import {MX_SIZES} from "../../data/shoeSizes";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faKey} from '@fortawesome/free-solid-svg-icons';
import FormField2 from '../../components/form/FormField2';

type AddUserModalProps = {
    show: boolean,
    onHide: Function,
    membersList: Member[],
    setMembersList: (members: Member[]) => void
}

const CreateMemberModal = ({show, onHide, membersList, setMembersList}: AddUserModalProps) => {
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik initialValues={{
                    full_name: "", last_name: "", username: "", phone: "", current_member: {
                        preferred_branch: branchesState.branches[0].id,
                        studio: studio.id,
                        birthday: null as Date | null,
                        shoe_size: null as string | null,
                    }
                }}
                        onSubmit={(values, {setSubmitting}) => {
                            setSuccess(null)
                            let birthday = values.current_member.birthday ? format(values.current_member.birthday as Date, "yyyy-MM-dd") : null
                            let current_member = {...values.current_member, birthday: birthday}
                            // current_member
                            baseApi.post("/members/", {
                                ...values,
                                current_member: current_member,
                                requires_create_password: true,
                                password: studio.name.replaceAll(" ", "").toLowerCase(),
                            }).then((resp) => {
                                setSuccess(true)
                                setAlertMessage("Se ha creado el usuario correctamente.")
                                console.log(resp.data)
                                // setMembersList([...membersList, resp.data])
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMessage(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>

                            <Modal.Header>
                                Crear nuevo cliente
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div>
                                    <div
                                        className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                        Nombre
                                    </div>
                                    <FormField2 label="" name="full_name"/>
                                </div>

                                <div>
                                    <div
                                        className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                        Apellido
                                    </div>
                                    <FormField2 label="" name="last_name"/>
                                </div>

                                {/* CORREO ELECTRONICO */}
                                <div
                                    className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                    Correo Electrónico
                                </div>
                                <FormField2 label="" name="username"/>

                                {/* TELEFONO */}
                                <div
                                    className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                    Teléfono
                                </div>
                                <FormField2 label="" name="phone"/>

                                {/* SUCURSAL */}
                                <div
                                    className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                    Sucursal preferida
                                </div>
                                <div hidden={branchesState.branches.length === 1}>
                                    <ModalSelect label="Sucursal">
                                        <Field name="current_member.preferred_branch"
                                               options={branchesState.selectOptions}
                                               component={FormikSelect}/>
                                    </ModalSelect>
                                </div>


                                {/* CUMPLEANOS */}
                                <div
                                    className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                    Cumpleaños
                                </div>
                                <div style={{width: "550px"}} className="d-flex flex-row pb-3 ">
                                    {/* <div style={{width: "120px"}}>
                                        <span className="">- Cumpleaños</span>
                                    </div> */}
                                    <div className="customDatePickerWidth" style={{width: "220px"}}>
                                        <ReactDatePicker
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd 'de' MMMM 'de' yyyy"
                                            maxDate={new Date()}
                                            locale={es}
                                            selected={values.current_member.birthday}
                                            onChange={date => {
                                                if (date) {
                                                    setFieldValue("current_member.birthday", date)
                                                }
                                            }}
                                            customInput={<input className="form-control"/>}
                                        />
                                    </div>
                                </div>


                                {/* ZAPATOS */}
                                <div
                                    className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                    Talla Zapatos
                                </div>
                                <div hidden={!studio.preferences.uses_shoe_field}>
                                    <ModalSelect label={"Talla de zapatos"}>

                                        <Select placeholder={""}
                                                isClearable={true}
                                                onChange={(option: { value: string, label: string } | null) => {
                                                    setFieldValue("current_member.shoe_size", option?.value)
                                                }}
                                                options={MX_SIZES.map(s => ({
                                                    value: s.toString(),
                                                    label: s.toString()
                                                }))}/>
                                    </ModalSelect>
                                </div>
                                <div className="font-size-h4 text-dark font-weight-bold">
                                    <FontAwesomeIcon className="mr-1" icon={faKey}/>
                                    Contraseña:
                                    <span
                                        className="font-weight-bold text-success ml-2">{studio.name.replaceAll(" ", "").toLowerCase()}</span>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary btn-pill font-size-lg" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button className="ml-1 font-weight-bold btn-pill font-size-lg" type="submit"
                                        disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Crear y guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateMemberModal;
