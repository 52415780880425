import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown, DropdownButton, Row, Table } from "react-bootstrap";
import { StoreState } from "../../reducers";
import { Branch } from "../../actions";
import { useSelector } from "react-redux";
import { endOfDay, endOfMonth, format, formatISO, startOfDay, startOfMonth, subMonths } from "date-fns";
import baseApi from "../../apis/baseApi";
import TableHeader from "../../components/table/TableHeader";
import LoadingTr from "../../components/table/rows/LoadingTr";
import EmptyTrSales from "../../components/table/rows/EmptyTrSales";
import { formatDateTime } from "../../utils/dateFormatters";
import { formatCurrency } from "../../utils/currencyFormatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBuilding,
    faCashRegister,
    faCreditCard,
    faDollarSign,
    faEdit,
    faExchangeAlt,
    faFileExcel,
    faHandHolding,
    faHandHoldingUsd,
    faMoneyBill,
    faMoneyBillAlt,
    faMoneyCheck,
    faPiggyBank,
    faTimes,
    faUniversity
} from "@fortawesome/free-solid-svg-icons";
import { Sale, SaleLocations } from "../Reports/Sales";
import TableFooter from "../../components/table/TableFooter";
import SalesStats from "../Reports/Sales/SalesStats";
import { es } from "date-fns/locale";
import ReactDatePicker from "react-datepicker";

interface SaleStats {
    total: number,
    online: number,
    cashier: number,
    cards: number,
    cash: number,
    cash_income: number,
    cash_expenses: number,
    transfers: number,
    other: number,
    range_cashier: number,
    range_online: number,
    range_pending: number,
}
const Blank1Sales = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)
    const [saleReports, setSaleReports] = useState<Sale[]>([])
    const [stats, setStats] = useState<SaleStats>()
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0)
    const [rangeStart, setRangeStart] = useState(startOfDay(new Date()));
    const [rangeEnd, setRangeEnd] = useState(endOfDay(new Date()));



    useEffect(() => {
        const date = new Date()
        setLoading(true)
        const start = formatISO(startOfDay(date))
        const end = formatISO(endOfDay(date))
        baseApi.get(`/sales/?branch=${branch.id}&start=${start}&end=${end}&location=CS`).then((resp) => {
            setSaleReports(resp.data.results)
            setCount(resp.data.count)
            setLoading(false)
        }).catch((err) => {
            alert("ERROR")
        })
    }, [branch]);

    useEffect(() => {
        if (rangeStart && rangeEnd) {
            const start = formatISO(startOfDay(rangeStart))
            const end = formatISO(endOfDay(rangeEnd))
            const monthStart = formatISO(startOfMonth(new Date()))
            const monthEnd = formatISO(endOfMonth(new Date()))
            baseApi.get<SaleStats>(`/sales/stats/?branch=${branch.id}&start=${start}&end=${end}&month_start=${monthStart}&month_end=${monthEnd}`).then(resp => {
                setStats(resp.data)
            }).catch()
        }
    }, [branch, rangeStart, rangeEnd]);


    const handlePageChange = (page: number) => {
        setLoading(true)
        const date = new Date()
        const start = formatISO(startOfDay(date))
        const end = formatISO(endOfDay(date))
        baseApi.get(`/sales/?branch=${branch.id}&page=${page}&start=${start}&end=${end}`).then((resp) => {
            setSaleReports(resp.data.results)
            setCount(resp.data.count)
            setLoading(false)
        }).catch((err) => {
            alert("ERROR")
        })
    }

    return (
        <div>
            <div className="d-flex align-items-center">
                <div className=''>
                    <FontAwesomeIcon className='text-dark-25 icon-xl mr-2' icon={faCashRegister} />
                </div>
                <div className='display-4 text-cente py-4'>Hoy</div>
            </div>
{/* ROW 1 */}
            <Row className='mt-8 mb-13'>
                <Col className='min-w-50 min-w-xl-auto mb-1'>
                    <Card className="card-custom px-2 px-xxl-10 pt-7 pb-6  rounded-xl">
                        <div className='btn btn-icon btn-sm bg-success mb-2'>
                            <FontAwesomeIcon className='text-white icon-md'
                                icon={faMoneyBillAlt} />
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className='text-muted font-size-lg'>Efectivo</div>
                        </div>
                        <div className='display-4 font-weight-normal'>{formatCurrency(stats?.cash ?? 0, currency)}</div>
                    </Card>
                </Col>
                <Col className='min-w-50 min-w-xl-auto mb-1'>
                    <Card className="card-custom px-2 px-xxl-10 pt-7 pb-6  rounded-xl">
                        <div className='btn btn-icon btn-sm bg-primary mb-2'>
                            <FontAwesomeIcon className='text-white icon-md'
                                icon={faCreditCard} />
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className='text-muted font-size-lg'>Terminal</div>
                        </div>
                        <div className='display-4 font-weight-normal'>{formatCurrency(stats?.cards ?? 0, currency)}</div>
                    </Card>
                </Col>

                <Col className='min-w-50 min-w-xl-auto mb-1'>
                    <Card className="card-custom px-2 px-xxl-10 pt-7 pb-6  rounded-xl">
                        <div className='btn btn-icon btn-sm bg-secondary mb-2'>
                            <FontAwesomeIcon className='text-white icon-md'
                                icon={faUniversity} />
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className='text-muted font-size-lg'>Transferencia</div>
                        </div>
                        <div className='display-4 font-weight-normal'>{formatCurrency(stats?.transfers ?? 0, currency)}</div>
                    </Card>
                </Col>

                <Col className='min-w-50 min-w-xl-auto mb-1'>
                    <Card className="card-custom px-2 px-xxl-10 pt-7 pb-6  rounded-xl">
                        <div className='btn btn-icon btn-sm bg-dark mb-2'>
                            <FontAwesomeIcon className='text-white icon-md'
                                icon={faPiggyBank} />
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className='text-muted font-size-lg'>Otro</div>
                        </div>
                        <div className='display-4 font-weight-normal'>{formatCurrency(stats?.other ?? 0, currency)}</div>
                    </Card>
                </Col>

                <Col className='min-w-50 min-w-xl-auto mb-1'>
                    <Card className="card-custom px-2 px-xxl-10 pt-7 pb-6  rounded-xl">
                        <div className='btn btn-icon btn-sm bg-danger mb-2'>
                            <FontAwesomeIcon className='text-white icon-md'
                                icon={faHandHoldingUsd} />
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className='text-muted font-size-lg'>Por cobrar</div>
                        </div>
                        <div className='display-4 font-weight-normal'>{formatCurrency(stats?.range_pending ?? 0, currency)}</div>
                    </Card>
                </Col>


            </Row>

            <hr />
{/* ROW 2 */}
            <Row className='mt-10 mb-30 '>
                <Col className='col-xxl-4'>
                    <div className='d-flex align-items-center justify-content-between px-5'>
                        <div className="d-flex align-items-center">
                            <div className=''>
                                <FontAwesomeIcon className='text-dark-25 icon-xl mr-2' icon={faCashRegister} />
                            </div>
                            <div className='display-4 text-cente py-4'>Caja</div>
                        </div>
                        <div className='display-4 font-weight-b text-center'>{formatCurrency((stats?.cash_expenses ?? 0) + (stats?.cash_income ?? 0) + (stats?.cash ?? 0), currency)}</div>
                    </div>
                    <Card className="card-custom px-8 px-xxl-10 pt-7 pb-6  rounded-xl ">


                        <div className="d-flex align-items-center justify-content-between">
                            <div className='text-muted font-size-lg'>Ingresos en Efectivo</div>
                            <div className='font-size-h4 text-success'>+{formatCurrency((stats?.cash_income ?? 0) + (stats?.cash ?? 0), currency)}</div>
                        </div>

                        <hr />

                        <div className="d-flex align-items-center justify-content-between">
                            <div className='text-muted font-size-lg'>Egresos en Efectivo</div>
                            <div className='font-size-h4 text-danger'>-{formatCurrency(stats?.cash_expenses ?? 0, currency)}</div>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between">
                            <div className='text-muted font-size-lg'>Total en Caja</div>
                            <div className='font-size-h4 '>{formatCurrency((stats?.cash_expenses ?? 0) + (stats?.cash_income ?? 0) + (stats?.cash ?? 0), currency)}</div>
                        </div>
                    </Card>

                </Col>

                <Col className='col-xxl-8'>
                    <div className='d-flex align-items-center justify-content-between px-5'>
                        <div className="d-flex align-items-center">
                            <div className=''>
                                <FontAwesomeIcon className='text-dark-25 icon-xl mr-2' icon={faDollarSign} />
                            </div>
                            <div className='display-4 text-cente py-4'>Total Mostrador</div>
                        </div>
                        <div className='display-4 font-weight-b text-center'>{formatCurrency(stats?.range_cashier ?? 0, currency)}</div>
                    </div>
                    <Card className="card-custom px-2 px-xxl-10 pt-7 pb-6  rounded-xl">



                        <Table hover responsive>
                            <TableHeader
                                headers={["FECHA", "MONTO", "MÉTODO", "CLIENTE", "PRODUCTOS"]} />
                            <tbody>
                                {loading ? <LoadingTr /> : saleReports.length === 0 ? <EmptyTrSales /> : saleReports.map(s => {
                                    return <tr className="text-center" key={s.id}>
                                        {/*<td className="text-uppercase font-size-sm">{s.id.slice(-8)}</td>*/}
                                        <td className=''>{formatDateTime(s.created_on)}
                                            <div className='text-muted font-size-xs'>Por: {s.transaction_by}</div>
                                        </td>
                                        <td className=' font-size-h6 text-left'>{formatCurrency(parseFloat(s.total), currency)}

                                            <div>
                                                {s.plan_items.map(i => <span key={i.id}
                                                    className='label label-inline label-light-dark font-weight-bold rounded-xl'>
                                                    {i.plan}
                                                    <span hidden={!i.cancelled}
                                                        className="label label-inline label-light-danger ml-1 font-weight-bolder rounded-xl">
                                                        <FontAwesomeIcon className='mr-1' icon={faTimes} />
                                                        CANCELADO
                                                    </span>,
                                                </span>)}
                                            </div>
                                            <div>
                                                {s.items.map(i => <span key={i.id}
                                                    className='label label-inline label-light-dark font-weight-bold rounded-xl'>
                                                    {i.name}
                                                </span>)}
                                            </div>
                                        </td>

                                        {s.payment_method === null
                                            ? <td className="text-warning text-left"><span
                                                className='label label-inline label-light-warning font-weight-bold mr-1'>Deuda</span>Pendiente
                                                de pago</td>
                                            : <td className=" font-size-lg text-left">
                                                <div>{s.payment_method}</div>
                                                {s.location === SaleLocations.POS ?

                                                    // PAGO PRESENCIAL
                                                    <span className='label label-inline label-light-success'>POS
                                                        {/* <FontAwesomeIcon className="text-muted" icon={faCashRegister}/> */}
                                                    </span>
                                                    // FIN PAGO PRESENCIAL
                                                    :
                                                    // PAGO EN LINEA
                                                    <span
                                                        className='label label-inline label-light-primary label-rounded'>Pago en línea</span>}
                                                {/* FIN PAGO EN LINEA */}

                                                {/* DEUDA */}
                                                <div hidden={s.paid_on?.substring(0, 15) === s.created_on.substring(0, 15)}
                                                    className=" font-size-sm font-weight-normal"><span
                                                        className="text-success">*</span>Deuda pagada
                                                    el: {s.paid_on ? formatDateTime(s.paid_on) : ""}
                                                </div>
                                                {/* FIN DEUDA */}


                                            </td>
                                        }

                                        <td className="text-left">
                                            <div className="font-size-sm">{s.member.full_name}</div>
                                            <div className="text-muted font-size-xs">{s.member.email}</div>
                                        </td>
                                        <td className="text-center">{s.products.map(i => i.product).join(", ")}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        <TableFooter count={count} pageCount={saleReports.length} pageSize={10}
                            onPageChange={handlePageChange} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Blank1Sales;
