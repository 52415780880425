import React, {useEffect, useRef, useState} from 'react';
import {Member} from "../index";
import baseApi from "../../../apis/baseApi";
import {Paginated} from "../../../types";
import {Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import TableFooter from "../../../components/table/TableFooter";
import {formatDate, formatDateTime} from "../../../utils/dateFormatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {formatCurrency} from "../../../utils/currencyFormatters";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {isAfter, parseISO} from "date-fns";


export interface MemberRegistrationFee {
    activate_on: string;
    cancelled: boolean;
    created_on: string;
    expire_on: string;
    fee: string;
    fee_name: string;
    id: string;
    member: string;
    sale_item: string;
    sale_item_total: string;

    [property: string]: any;
}

interface Props {
    member: Member,
}

const MemberRegistrationFees = ({member}: Props) => {
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)
    const [memberFees, setMemberFees] = useState<MemberRegistrationFee[]>([]);
    const count = useRef(0)

    const handlePageChange = (page: number) => {
        baseApi.get<Paginated>(`registration-fees/member/?member=${member.id}&page=${page}&page_size=10`).then(resp => {
            setMemberFees(resp.data.results)
        }).catch()
    }

    useEffect(() => {
        baseApi.get<Paginated>(`registration-fees/member/?member=${member.id}&page=1&page_size=10`).then(resp => {
            count.current = resp.data.count
            setMemberFees(resp.data.results)
        }).catch()
    }, [member]);

    return (
        <div>
            <Table hover>
                <TableHeader headers={["FECHA", "INSCRIPCIÓN", "IMPORTE", "VIGENCIA"]}/>
                <tbody>
                {memberFees.length === 0
                    ? <EmptyTr/>
                    : memberFees.map((fee) =>
                        <tr key={fee.id}
                            className="font-size-sm align-middle text-center">
                            <td>{formatDateTime(fee.created_on)}</td>
                            <td>{fee.fee_name}</td>
                            <td>
                                {formatCurrency(parseFloat(fee.sale_item_total!), currency)}
                                <span hidden={!fee.cancelled}
                                      className="label label-inline label-light-danger ml-1 font-weight-bolder rounded-xl">
                                        <FontAwesomeIcon className='mr-1' icon={faTimes}/>
                                        CANCELADO
                                    </span>
                            </td>
                            <td className={`${isAfter(parseISO(fee.expire_on), new Date()) ? 'text-success' : ''}`}>
                                {formatDate(fee.activate_on)} - {formatDate(fee.expire_on)}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={memberFees.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default MemberRegistrationFees;
