import React, {useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";
import {SketchPicker} from 'react-color'
import {useDispatch, useSelector} from "react-redux";

import FormField from "../../../components/form/FormField";
import baseApi from "../../../apis/baseApi";
import {
    addClass,
    StudioClass,
    StudioClassCategory,
    StudioClassGympassBranchConfig, StudioClassTotalpassConfig,
    updateClass
} from "../../../actions/studioClasses";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import Checkbox from "../../../components/form/Checkbox";
import {StoreState} from "../../../reducers";
import {FormikSelect} from "../../../components/form/FormikSelect";
import yup from "../../../utils/yup";
import {Branch} from "../../../actions";
import FieldError from "../../../components/form/FieldError";
import FormSelectAlt, {SelectOption} from "../../../components/form/FormSelectAlt";
import {TotalpassPlan} from "./StudioClassesListView";

type CreateEditClassModalProps = {
    show: boolean
    onHide: Function
    totalpassPlans: TotalpassPlan[]
    studioClass?: StudioClass
}

interface GymPassProduct {
    name: string
    product_id: number
}

const CreateEditClassModal = ({show, onHide, studioClass, totalpassPlans}: CreateEditClassModalProps) => {
    const dispatch = useDispatch()
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const categories = useSelector<StoreState, StudioClassCategory[]>(state => state.studio.studio_class_categories)

    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("")
    const [gymPassProducts, setGymPassProducts] = useState<GymPassProduct[]>([]);
    const [gymPassId, setGymPassId] = useState<number>();
    const [studioClassGympassConfig, setStudioClassGympassConfig] = useState<StudioClassGympassBranchConfig>();
    const [studioClassTotalpassConfig, setStudioClassTotalpassConfig] = useState<StudioClassTotalpassConfig>();

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };

    useEffect(() => {
        if (branch.gympass_active && branch.gympass_gym_id) {
            baseApi.get(`/gympass/products/?branch=${branch.id}`).then(resp => {
                setGymPassProducts(resp.data.products || [])
            }).catch(e => {
                setGymPassProducts([])
            })
        }
    }, [branch, studioClass]);

    useEffect(() => {
        if (branch.gympass_active && branch.gympass_gym_id) {
            const studioClassGpConfig = studioClass?.gympass_config?.branch_configs.find(c => branch.id === c.branch)
            setGymPassId(studioClassGpConfig?.gympass_id)
            setStudioClassGympassConfig(studioClassGpConfig)
        }
        if (branch.totalpass_config?.is_active) {
            const studioClassTpConfig = studioClass?.totalpass_configs?.find(c => c.totalpass_branch_config === branch.totalpass_config?.id)
            setStudioClassTotalpassConfig(studioClassTpConfig)
        }
    }, [branch, studioClass]);


    const validationSchema = yup.object().shape({
        name: yup.string().required().min(3),
        gympass_config: yup.object().nullable().shape({
            branch_config_data: yup.object().shape({
                product_id: yup.number(),
            }),
            description: yup.string().when('branch_config_data.product_id', {
                is: (val: any) => val !== undefined,
                then: (schema) => schema.min(3).required()
            })
        })
    })

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    return (
        <React.Fragment>
            <Modal show={show} onHide={handleClose} size="lg">
                <Formik enableReinitialize={true} initialValues={{
                    name: studioClass?.name,
                    color: studioClass?.color || "#000000",
                    allow_manual_credits: studioClass?.allow_manual_credits ?? true,
                    categories: studioClass ? categories.filter(c => studioClass?.categories.includes(c.id)).map(c => ({
                        label: c.name,
                        value: c.id
                    })) : [],
                    totalpass_plan_id: studioClassTotalpassConfig?.totalpass_plan_id,
                    gympass_config: branch.gympass_active ? {
                        visible: studioClass?.gympass_config?.visible,
                        bookable: studioClass?.gympass_config?.bookable,
                        description: studioClass?.gympass_config?.description,
                        branch_config_data: {
                            branch: branch.id,
                            product_id: studioClassGympassConfig?.product_id,
                            gympass_id: studioClassGympassConfig?.gympass_id,
                        }
                    } : null
                }}
                        validationSchema={validationSchema}
                        onSubmit={(values: any, {setSubmitting}) => {
                            setSuccess(null)
                            if (!values.gympass_config?.branch_config_data.product_id) {
                                values.gympass_config = null
                            }
                            values.categories = values.categories.map((c: any) => c.value)
                            if (studioClass) {
                                baseApi.patch<StudioClass>(`/studios/classes/${studioClass.id}/`, values).then((resp) => {
                                    dispatch(updateClass(resp.data))
                                    setAlertMsg("Clase actualizada correctamente.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                    if (values.totalpass_plan_id !== studioClassTotalpassConfig?.totalpass_plan_id) {
                                        let tpConfigPayload = {
                                            totalpass_branch_config: branch.totalpass_config?.id,
                                            totalpass_plan_id: values.totalpass_plan_id,
                                            studio_class: resp.data.id,
                                        }
                                        if (studioClassTotalpassConfig) {
                                            baseApi.patch<StudioClassTotalpassConfig>(`/totalpass/classes/${studioClassTotalpassConfig?.id}/`, tpConfigPayload).then(tpResp => {
                                                dispatch(updateClass({
                                                    ...resp.data,
                                                    totalpass_configs: resp.data.totalpass_configs!.map(tpc => tpc.id === studioClassTotalpassConfig?.id ? tpResp.data : tpc)
                                                }))
                                            }).catch(err => {
                                                setAlertMsg("Clase creada, error en configuración de Totalpass")
                                                setSuccess(false)
                                            })
                                        } else {
                                            baseApi.post<StudioClassTotalpassConfig>("/totalpass/classes/", tpConfigPayload).then(tpResp => {
                                                dispatch(updateClass({...resp.data, totalpass_configs: [tpResp.data]}))
                                            }).catch(err => {
                                                setAlertMsg("Clase creada, error en configuración de Totalpass")
                                                setSuccess(false)
                                            })
                                        }
                                    }
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            } else {
                                baseApi.post<StudioClass>("/studios/classes/", values).then((resp) => {
                                    dispatch(addClass(resp.data))
                                    setAlertMsg("Clase creada correctamente.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                    if (values.totalpass_plan_id) {
                                        const studioClassTpConfig = {
                                            totalpass_branch_config: branch.totalpass_config?.id,
                                            totalpass_plan_id: values.totalpass_plan_id,
                                            studio_class: resp.data.id,
                                        }
                                        baseApi.post<StudioClassTotalpassConfig>("/totalpass/classes/", studioClassTpConfig).then(tpResp => {
                                            dispatch(updateClass({...resp.data, totalpass_configs: [tpResp.data]}))
                                        }).catch(err => {
                                            setAlertMsg("Clase creada, error en configuración de Totalpass")
                                            setSuccess(false)
                                        })
                                    }
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }
                        }}>
                    {({isSubmitting, setFieldValue, values}) => (
                        <FormikForm>
                            <Modal.Header>
                                {studioClass ? "Editar" : "Crear"} Clase
                                <div style={{color: 'salmon'}} hidden={!branch.gympass_active}
                                     className="">GymPass Class ID: {gymPassId}</div>
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FormField label="Nombre" name="name"/>
                                <div className="offset-lg-1">
                                    <p>Color</p>
                                    <SketchPicker color={values.color} onChange={(c) => setFieldValue("color", c.hex)}
                                                  disableAlpha={true}/>
                                </div>
                                <div className="offset-lg-1 pt-3">
                                    <Field component={Checkbox} type="checkbox" name="allow_manual_credits"
                                           label="Permitir créditos manuales"/>
                                </div>
                                <div className="pt-2" hidden={categories.length === 0}>
                                    <FormSelectAlt
                                        offset="1"
                                        label="Categorías" placeholder="Seleccionar categorías"
                                        value={values.categories}
                                        options={categories.map(c => ({value: c.id, label: c.name}))}
                                        onChange={(options: SelectOption[]) => {
                                            setFieldValue("categories", options);
                                        }}
                                        isMulti={true}/>
                                </div>
                                <div hidden={totalpassPlans.length === 0}>
                                    <hr/>
                                    <div className="offset-lg-1">
                                        <div className="row align-items-center">
                                            <h4 className="text-success p-0 m-0">Totalpass</h4>
                                            <div className="col-lg-5">
                                                <Field name="totalpass_plan_id"
                                                       placeholder="Totalpass Plan ID"
                                                       component={FormikSelect}
                                                       className="form-control form-control-sm"
                                                       options={totalpassPlans.map(p => ({
                                                               value: p.id,
                                                               label: `${p.id} - ${p.name}`
                                                           }
                                                       ))}

                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div hidden={!branch.gympass_active}>
                                    <div>
                                        <hr/>
                                        <div className="offset-lg-1">
                                            <h4>GymPass</h4>
                                            <Field component={Checkbox} type="checkbox" name="gympass_config.visible"
                                                   label="Visible en GymPass"/>
                                            <Field component={Checkbox} type="checkbox" name="gympass_config.bookable"
                                                   label="Reservable en GymPass"/>

                                        </div>
                                        <div className="offset-lg-1 pt-1">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <Field name="gympass_config.branch_config_data.product_id"
                                                           placeholder="Product ID"
                                                           component={FormikSelect}
                                                           className="form-control form-control-sm"
                                                           options={gymPassProducts.map(p => ({
                                                                   value: p.product_id,
                                                                   label: `${p.product_id} - ${p.name}`
                                                               })
                                                           )}
                                                    />
                                                    <div className="pt-2">
                                                        <Field name="gympass_config.description" component="textarea"
                                                               placeholder="Descripción"
                                                               className="form-control form-control-sm"/>
                                                        <FieldError name="gympass_config.description"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : studioClass ? "Confirmar" : "Crear"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEditClassModal;
