import React from 'react';
import {Reservation} from "./index";
import {isBefore} from "date-fns"
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {TimeSlotV2} from "../Schedules";
import {updateReservation} from "../../actions/timeslotReservations";
import {Button} from "react-bootstrap";
import {formatDateTime} from "../../utils/dateFormatters";

type CheckInPillProps = {
    rsvp: Reservation
    editDisabled: boolean
}

const CheckInPill = ({rsvp, editDisabled = false}: CheckInPillProps) => {
    const dispatch = useDispatch()
    const selectedTimeSlot = useSelector<StoreState, TimeSlotV2>(state => state.timeSlotReservations.timeslot)

    if (rsvp.cancelled) {
        return <div className="btn btn-sm btn-clean font-weight-bold font-size-xs mr-2">
            <div>Cancelada</div>
            <div>{formatDateTime(rsvp.cancelled_on)}</div>
        </div>
    }
    if (rsvp.assistance) {
        return <Button
            disabled={editDisabled}
            onClick={() => {
                dispatch(updateReservation({...rsvp, assistance: false}))
            }}
            className="btn btn-sm btn-pill btn-light-success btn-hover-danger font-weight-bold font-size-xs mr-2 min-w-85px">Checked-In</Button>
    }
    if (isBefore(selectedTimeSlot.start, new Date())) {
        return <div
            onClick={() => {
                if (editDisabled) return
                dispatch(updateReservation({...rsvp, assistance: true}))
            }}
            className="btn btn-sm btn-pill btn-light-danger btn-hover-success font-weight-bold font-size-xs mr-2 min-w-85px">
                Falta
        </div>
    }

    return (
        <button
            disabled={editDisabled}
            onClick={() => {
                if (editDisabled) return
                dispatch(updateReservation({...rsvp, assistance: true}))
            }}
            className="btn btn-sm btn-pill btn-outline-primary btn-hover-success font-weight-bold font-size-xs mr-2 min-w-85px">
            Check-In
        </button>
    );
};

export default CheckInPill;
