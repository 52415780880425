import React from 'react';
import ReactDatePicker from 'react-datepicker';

const EmptyTrSales = () => {
    return (
        <tr className="text-center w-100 text-muted font-size-h2-xl font-weight-bold">
            <td colSpan={7}>
                <div className='font-size-sm'>HISTORIAL DE VENTAS</div>
                <div className='font-size-h3'>0 resultados en la fecha seleccionada</div>
                <div className='font-size-h6'>Para ver tu historial de otros días<span className='text-primary ml-2'> edita el rango de fechas ↖</span></div>

                

            </td>
        </tr>
    );
};

export default EmptyTrSales;
