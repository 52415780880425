import React, {ChangeEvent, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import Resizer from "react-image-file-resizer";

type ImageFieldProps = {
    src?: string | null
    onChange: (file: File) => void
    width?: number
    quality?: number
    maxUploadWidth?: number
    maxUploadHeight?: number
}

const ImageField = ({src, onChange, width, quality=75, maxUploadWidth=400, maxUploadHeight=400}: ImageFieldProps) => {
    const [imgSrc, setImgSrc] = useState<string | null | undefined>(src)

    const imgBtnRef = useRef(null);

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const extension = file.name.split(".").pop()
            const renamedFile = new File([file], `${uuidv4()}.${extension}`, {type: file.type})
            const reader = new FileReader()
            reader.readAsDataURL(renamedFile)
            reader.onloadend = (e) => {
                setImgSrc(reader.result as string)
            }
            Resizer.imageFileResizer(renamedFile, maxUploadWidth, maxUploadHeight, extension!, quality, 0, (resizedFile) => {
                onChange(resizedFile as File)
            }, 'file')
        }
    }
    return (
        <div>
            <div className="image-input image-input-outline" id="kt_image_2">
                <div className="image-input-wrapper" style={{width: width}}>

                    {imgSrc ?
                        <img style={{width: width}} className="image-input-wrapper" src={imgSrc} alt="studio logo"/> :
                        <React.Fragment/>}
                </div>
                <label
                    onClick={() => {
                        // @ts-ignore
                        imgBtnRef.current.click()
                    }}
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change" data-toggle="tooltip" title=""
                    data-original-title="Change avatar">
                    <FontAwesomeIcon icon={faPen}/>
                </label>

            </div>
            <input name="image" ref={imgBtnRef} style={{display: "none"}} type="file"
                   accept="image/png, image/jpeg" onChange={handleImageUpload}/>
        </div>
    );
};

export default ImageField;
