export interface BillingPlan {
    id: string,
    name: string,
    priceId: string,
    productId: string,
    price: number,
    currency: string
    discount_percent?: number
    features: BillingPlanFeature[],
    isMain: boolean,
}

interface BillingPlanFeature {
    text: string
    bold?: boolean
    icon?: string
}

export const billingPlans: BillingPlan[] = [
    {
        id: "9de1a56b-b506-4b07-b8bd-8ab3a9eae62f",
        name: "STUDIO",
        price: 2798,
        priceId: "price_1JUFkyHX6IMUdX6S1hlbHYpj",
        productId: "prod_K8Wo5RiokjPvZP",
        currency: "mxn",
        discount_percent: 0.50,
        isMain: false,
        features: [
            {text: "Hasta 70 clientes", bold: true},
            {text: "1 Sucursal"},
            {text: "Software de gestión - Todas las funciones"},
            {text: "Booking App - Para clientes"},
            {text: "Pagina Web - Template"},
            // {text: "30 dias garantía de reembolso 100%"},
            // {text: "Primeros 7 días gratis", bold: true},
            // {text: "Cancela en cualquier momento", bold: true},
            // {text: "Migración desde otro sistema - gratis", bold: true},
        ],
    },
    {
        id: "d046c88d-0d02-4ceb-bdfa-d661e9096b7d",
        name: "THEME RIDE",
        price: 3398,
        priceId: "price_1JUFlSHX6IMUdX6SGI0WteUP",
        productId: "prod_K8WpAGxYG49UII",
        currency: "mxn",
        discount_percent: 0.50,
        isMain: true,
        features: [
            {text: "Clientes Ilimitados", bold: true},
            {text: "1 Sucursal"},
            {text: "Software de gestión - Todas las funciones"},
            {text: "Booking App - Para clientes"},
            {text: "Pagina Web - Template"},
            // {text: "30 dias garantía de reembolso 100%"},
            // {text: "Primeros 7 días gratis", bold: true},
            // {text: "Cancela en cualquier momento", bold: true},
            // {text: "Migración desde otro sistema - gratis", bold: true},
        ],
    },
    {
        id: "0236a5cf-8c0f-44fa-acf2-c9195f1cebe2",
        name: "ROOFTOP",
        price: 5998,
        priceId: "price_1JUFllHX6IMUdX6SGq3bIPgt",
        productId: "prod_K8WpNVFrTZ7l03",
        currency: "mxn",
        discount_percent: 0.50,
        isMain: false,
        features: [
            {text: "Clientes Ilimitados", bold: true},
            {text: "3 Sucursales", bold: true},
            {text: "Software de gestión - Todas las funciones"},
            {text: "App Personalizada GRATIS", bold: true},
            {text: "Pagina Web - Template"},
            // {text: "30 dias garantía de reembolso 100%"},
            // {text: "Primeros 7 días gratis", bold: true},
            // {text: "Cancela en cualquier momento", bold: true},
            // {text: "Migración desde otro sistema - gratis", bold: true},
        ],
    },
];
