import React, {useEffect, useState} from 'react';
import {Button, Col, Dropdown, DropdownButton, Row, Table} from 'react-bootstrap';
import baseApi from "../../../apis/baseApi";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import {formatDate} from "../../../utils/dateFormatters";
import TableFooter from "../../../components/table/TableFooter";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {format} from "date-fns";
import {CSVDownload} from "react-csv";
import {useHistory} from "react-router-dom";

interface Membership {
    id: string;
    plan: string;
    expire_on: string;
    created_on: string;
}

interface Member {
    id: string;
    full_name: string;
    email: string;
    external_id: string;
    membership: Membership[];
    phone: string
}

interface RecoveryMember {
    id: string;
    expire_on: string;
    plan_name: string;
    member__full_name: string;
    member__external_id: string;
}

const ActiveMembers = () => {
    const history = useHistory()
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)

    const [activeMembers, setActiveMembers] = useState<Member[]>([]);
    const [activeMembersLoading, setActiveMembersLoading] = useState(true);
    const [activeMembersPage, setActiveMembersPage] = useState(1);
    const [activeMembersCount, setActiveMembersCount] = useState(0);

    const [recoverMembers, setRecoverMembers] = useState<RecoveryMember[]>([]);
    const [recoverMembersLoading, setRecoverMembersLoading] = useState(true);
    const [recoverMembersPage, setRecoverMembersPage] = useState(1);
    const [recoverMembersCount, setRecoverMembersCount] = useState(0);

    const [fetchingActiveMembersDownloadData, setFetchingActiveMembersDownloadData] = useState(false);
    const [activeMembersDownloader, setActiveMembersDownloader] = useState(<div/>);
    const [fetchingRecoverMembersDownloadData, setFetchingRecoverMembersDownloadData] = useState(false);
    const [recoverMembersDownloader, setRecoverMembersDownloader] = useState(<div/>);


    useEffect(() => {
        const fetchPage = (page: number) => {
            setActiveMembersLoading(true)
            baseApi.get(`/reports/members-active/?page=${page}&branch=${branch.id}`).then(resp => {
                if (page === 1) setActiveMembersCount(resp.data.count)
                setActiveMembers(resp.data.results)
                setActiveMembersLoading(false)
            }).catch(err => {
                setActiveMembersLoading(false)
            },)
        }
        fetchPage(activeMembersPage)
    }, [activeMembersPage, branch]);

    useEffect(() => {
        const fetchPage = (page: number) => {
            setRecoverMembersLoading(true)
            baseApi.get(`/reports/members-recover/?page=${page}&branch=${branch.id}`).then(resp => {
                if (page === 1) setRecoverMembersCount(resp.data.count)
                setRecoverMembers(resp.data.results)
                setRecoverMembersLoading(false)
            }).catch(err => {
                setRecoverMembersLoading(false)
            },)
        }
        fetchPage(recoverMembersPage)
    }, [recoverMembersPage, branch]);

    const onSelectDownloadActiveMembers = async (eventKey: any, event: Object) => {
        setActiveMembersDownloader(<div/>)
        setFetchingActiveMembersDownloadData(true)
        const resp = await baseApi.get(`/reports/members-active/download/?branch=${branch.id}&months=${eventKey}`)
        const headers = ["ID", "CLIENTE", "CORREO", "TELÉFONO", "PLAN", "ACTIVACIÓN", "VENCIMIENTO", "CRÉDITOS", "#"]
        const data = [headers, ...resp.data]
        let filename = `Clientes activos ${branch.name} ${format(new Date(), "dd/MM/yyyy")}`

        if (eventKey === '0') filename += `-HOY`
        else filename += `-${eventKey}M`
        setActiveMembersDownloader(<CSVDownload filename={filename} data={data}/>)
        setFetchingActiveMembersDownloadData(false)
    }

    const onSelectDownloaRecoverMembers = async () => {
        setRecoverMembersDownloader(<div/>)
        setFetchingRecoverMembersDownloadData(true)
        const resp = await baseApi.get(`/reports/members-recover/download/?branch=${branch.id}}`)
        const headers = ["ID", "CLIENTE", "CORREO", "TELÉFONO", "PLAN", "ACTIVACIÓN", "VENCIMIENTO"]
        const data = [headers, ...resp.data]
        let filename = `Clientes por recuperar ${branch.name} ${format(new Date(), "dd/MM/yyyy")}`
        setRecoverMembersDownloader(<CSVDownload filename={filename} data={data}/>)
        setFetchingRecoverMembersDownloadData(false)
    }


    return (
        <div className="pt-10 pb-5">
            {activeMembersDownloader}
            {recoverMembersDownloader}
            <Row>
                <Col>
                    <div className="d-inline-flex align-items-center">
                        <h2>Clientes activos<span
                            className='label label-light-success label-lg font-size-h2 p-7 font-weight-bolder ml-2'>{activeMembersCount}</span>
                        </h2>
                        <div className="ml-3">
                            <Row>
                                <Col className="text-right mr-6">
                                    <DropdownButton
                                        disabled={fetchingActiveMembersDownloadData} className=''
                                        size="sm" variant="outline-success"
                                        title={
                                            <span
                                                className='font-weight-bold font-size-lg '><FontAwesomeIcon
                                                icon={faFileExcel}/> {fetchingActiveMembersDownloadData ? 'Descargando...' : 'Descargar Excel'}
                                            </span>
                                        }>
                                        <Dropdown.Header>Períodos</Dropdown.Header>
                                        <Dropdown.Item eventKey={0}
                                                       onSelect={onSelectDownloadActiveMembers}>Hoy</Dropdown.Item>
                                        <Dropdown.Item eventKey={1} onSelect={onSelectDownloadActiveMembers}>Último
                                            mes</Dropdown.Item>
                                        <Dropdown.Item eventKey={3} onSelect={onSelectDownloadActiveMembers}>Últimos 3
                                            meses</Dropdown.Item>
                                        <Dropdown.Item eventKey={6} onSelect={onSelectDownloadActiveMembers}>Últimos 6
                                            meses</Dropdown.Item>
                                    </DropdownButton>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="font-size-sm text-muted">Clientes con membresía o créditos vigentes.</div>
                    <div className="rounded shadow-sm px-2 pt-2" style={{background: "white"}}>
                        <Table hover>
                            <TableHeader headers={["ID", "Cliente", "Plan", "Expira"]}/>
                            <tbody>
                            {activeMembersLoading ? <LoadingTr/> : activeMembers.map(m => {
                                return <tr onClick={() => history.push(`/users/${m.id}`)}
                                           className="font-size-lg clickable" key={m.id}>
                                    <td>{m.external_id}</td>
                                    <td>{m.full_name}</td>
                                    <td>{m.membership.map(i => i.plan)}</td>
                                    <td>{m.membership.map(i => formatDate(i.expire_on))}</td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                        <TableFooter count={activeMembersCount} pageCount={activeMembers.length} pageSize={10}
                                     onPageChange={(page) => setActiveMembersPage(page)}/>

                    </div>
                </Col>
                <Col>
                    <div className="d-inline-flex align-items-center">
                        <h2>Clientes por recuperar<span
                            className='label label-light-warning label-lg font-size-h2 p-7 font-weight-bolder ml-2'>{recoverMembersCount}</span>
                        </h2>
                        <Button onClick={onSelectDownloaRecoverMembers} size="sm" variant="outline-success"> <span
                            className='font-weight-bold font-size-lg '>
                            <FontAwesomeIcon
                                icon={faFileExcel}/> {fetchingRecoverMembersDownloadData ? 'Descargando...' : 'Descargar Excel'}</span>
                        </Button>
                    </div>
                    <div className="font-size-sm text-muted">Membresías que vencieron en los últimos 15 días</div>
                    <div className="rounded shadow-sm px-2 pt-2" style={{background: "white"}}>
                        <Table hover>
                            <TableHeader headers={["ID", "Cliente", "Plan", "Expiró"]}/>
                            <tbody>
                            {recoverMembersLoading ? <LoadingTr/> : recoverMembers.map(m => {
                                return <tr className="font-size-lg"
                                           key={m.id}>
                                    <td>{m.member__external_id}</td>
                                    <td>
                                        <div className="text-capitalized">{m.member__full_name}</div>
                                    </td>
                                    <td>{m.plan_name}</td>
                                    <td>{formatDate(m.expire_on)}</td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                        <TableFooter count={recoverMembersCount} pageCount={recoverMembers.length} pageSize={10}
                                     onPageChange={(page) => setRecoverMembersPage(page)}/>
                    </div>
                </Col>
            </Row>

        </div>
    );
};

export default ActiveMembers;
