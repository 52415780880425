import React, {useEffect, useState} from 'react';
import {format, parseISO} from "date-fns";
import {Bar} from "react-chartjs-2"

import baseApi from "../../apis/baseApi";
import {TimeSlotV2} from "../Schedules";
import TimeSlotListCard from "../Reservations/TimeSlotListCard";
import {es} from "date-fns/locale";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {StaffUserPermissions} from "../../actions/staffUser";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBirthdayCake} from "@fortawesome/free-solid-svg-icons";
import {Branch} from "../../actions";

interface DashboardStats {
    sales_count: number;
    rsvp_count: number;
    active_clients: number;
    new_clients: number;
    reservations: any[];
    timeslots: TimeSlotV2[];
    weekday_stats: { [key: number]: number };
    birthdays: { birthday: string, full_name: string , last_name: string | null}[]
}

const Dashboard = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const dashboardPermissions = useSelector<StoreState, StaffUserPermissions | undefined>(state => state.staffUser.permissions.find(p => p.feature === 'dashboard'))
    const [stats, setStats] = useState<DashboardStats>()
    const today = new Date();

    useEffect(() => {
        baseApi.get(`/sa/stats/?branch=${branch.id}`).then(
            (resp) => {
                let timeslots = resp.data.timeslots.map((ts: any) => ({...ts, start: parseISO(ts.start)}))
                setStats({...resp.data, timeslots: timeslots})
            }
        )
    }, [branch]);

    if (dashboardPermissions?.view === false) {
        return <h1>Dashboard</h1>
    }
    return (
        <div className="h-lg-100" style={{minHeight: "100%"}}>
            {/* <h3>Resumen Rolle</h3> */}
            <div className="pl-">
                <h2 className="text-dark mt-10 mb-8">¡ Hola ! - Resumen de hoy</h2>
                <div className="w-130px">
                    <a href="#"
                       className="btn btn-fixed-height btn-link p-0 btn-text-dark-50 btn-hover-text-primary btn-icon-primary font-weight-bolder font-size-sm my-1 mr-3"
                       id="kt_dashboard_daterangepicker" data-toggle="tooltip" title="" data-placement="top"
                       data-original-title="Select dashboard daterange">
                        <span className="opacity-60 font-weight-bolder mr-2"
                              id="kt_dashboard_daterangepicker_title">Today:</span>
                        <span className="font-weight-bolder"
                              id="kt_dashboard_daterangepicker_date">{format(new Date(), "MMM dd")}</span>
                    </a>
                </div>
            </div>

            <div className="row mb-10" style={{minHeight: "80%"}}>
                {/* Col 8 izquierda */}
                <div className="col-12 col-xxl-8">
                    {/* Primera Row de 3 cards */}
                    <div className="row mb-7" style={{minHeight: "25%"}}>
                        <div className="col-6 mb-5 mb-md-0 col-md-4">
                            <div className="card card-custom py-5 px-5" style={{minHeight: "100%"}}>
                                <div className="d-flex">
                                    <div className="text-muted font-size-xs font-weight-bolder">TOTAL VENTAS</div>
                                </div>
                                <div className="text-center h-100">
                                    <p className="display2"><strong>{stats?.sales_count}</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mb-5 mb-md-0 col-md-4">
                            <div className="card card-custom py-5 px-5" style={{minHeight: "100%"}}>
                                <div className="d-flex">
                                    <div className="text-muted font-size-xs font-weight-bolder">RESERVACIONES</div>
                                </div>
                                <div className="text-center">
                                    <p className="display2"><strong>{stats?.rsvp_count}</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mb-5 mb-md-0 col-md-4">
                            <div className="card card-custom py-5 px-5" style={{minHeight: "100%"}}>
                                <div className="d-flex">
                                    <div className="text-muted font-size-xs font-weight-bolder">CLIENTES ACTIVOS</div>
                                </div>
                                <div className="text-center">
                                    <p className="display2"><strong>{stats?.active_clients}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Segunda Row de 3 cards */}
                    <div className="row mb-7" style={{minHeight: "25%"}}>
                        <div className="col-12 mb-5 mb-md-0 col-md-4">
                            <div className="card card-custom py-5 px-5" style={{minHeight: "100%"}}>
                                <div className="d-flex">
                                    <div className="text-muted font-size-xs font-weight-bolder">NUEVOS CLIENTES</div>
                                </div>
                                <div className="text-center">
                                    <p className="display-2"><strong>{stats?.new_clients}</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-5 mb-md-0 col-md-8">
                            <div className="card card-custom py-5 px-5" style={{minHeight:"300px", maxHeight: "300px", overflowY: "scroll"}} >
                                <div className="d-flex">
                                    <div className="text-muted font-size-xs font-weight-bolder">CUMPLEAÑOS</div>
                                </div>

                                {stats?.birthdays.map(b => {
                                    const birthday = parseISO(b.birthday)
                                    return <div className="border-bottom">
                                        <p className="m-0 font-size-sm font-weight-bolder">{b.full_name} {b.last_name ?? ''}</p>
                                        <p className="mb-1 mt-0">{format(birthday, "MMMM dd", {locale: es})}
                                            <span
                                                hidden={(birthday.getMonth() !== today.getMonth()) || (birthday.getDate() !== today.getDate())}
                                                className="text-success pl-1"><FontAwesomeIcon
                                                icon={faBirthdayCake}/></span>
                                        </p>
                                    </div>
                                })
                                }
                            </div>
                        </div>
                    </div>
                    {/* Tercera Row 1 Card CHART*/}
                    <div className="row mb-10 pb-10" style={{minHeight: "40%"}}>
                        <div className="col-12 mb-30 mb-md-0  mb-5">
                            <div className="card card-custom mb-5" style={{minHeight: "100%"}}>
                                <Bar data={{
                                    labels: ["L", "M", "M", "J", "V", "S", "D"],
                                    datasets: [{
                                        label: "Reservaciones en esta semana",
                                        data: Object.values(stats?.weekday_stats ?? {})
                                    }]
                                }}
                                     options={{
                                         scales: {
                                             xAxes: {grid: {display: false}},
                                             yAxes: {grid: {display: false, drawTicks: false, drawBorder: false}}
                                         }
                                     }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Col 4 Derecha */}
                <div className="col-12 col-md-4 col-xxl-4 pr">
                    <div className="card card-custom px-5 py-5" style={{minHeight: '96.5%'}}>
                        <div className="text-muted font-size-xs font-weight-bolder">HORARIOS</div>
                        <p className="font-weight-bolder font-size-h5">Hoy, {format(new Date(), "d 'de' MMMM", {locale: es})}
                            <span><i className="far fa-calendar text-primary" data-toggle="tooltip"
                                     data-placement="right" data-original-title="Seleccionar otra fecha"/></span>
                        </p>
                        <div className="row text-center pt-10">
                            <div className="col-4">
                                <div className="text-muted font-size-xs font-weight-bold">TOTAL</div>
                                <div
                                    className="font-size-h4 font-weight-bold">{stats?.timeslots.reduce((acc, ts) => acc + ts.people_limit, 0)}</div>
                            </div>
                            <div className="col-4 px-0">
                                <div className="text-muted font-size-xs font-weight-bold">RESERVACIONES</div>
                                <div
                                    className="font-size-h4 font-weight-bold">{stats?.timeslots.reduce((acc, ts) => acc + (ts.people_limit - ts.available_spaces), 0)}</div>
                            </div>
                            <div className="col-4">
                                <div className="text-muted font-size-xs font-weight-bold">OCUPACION</div>
                                <div
                                    className="font-size-h4 font-weight-bold">{stats?.timeslots.reduce((acc, ts) => acc + (ts.people_limit - ts.available_spaces), 0)}</div>
                            </div>
                        </div>
                        <div>
                            {stats?.timeslots.map(ts => {
                                return <TimeSlotListCard key={ts.id} timeSlot={ts} setSelectedTimeSlot={() => {
                                }}/>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
