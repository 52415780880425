import React, {useEffect, useRef, useState} from 'react';
import {Button, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import baseApi from "../../../apis/baseApi";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import {formatDate} from "../../../utils/dateFormatters";
import TableFooter from "../../../components/table/TableFooter";

interface Props {
    planId: string
    planName: string
    onGoBack: () => void
}

interface Membership {
    id: string
    plan_name: string
    member: Member
    cancelled: boolean
    expire_on: string
    subscription_id: any
    activate_on: string
    created_on: string
    created_by: string
    plan: string
    sale: string
}

interface Member {
    id: string
    full_name: string
    email: string
    external_id: string
    phone: string
}


const PlanMembersList = ({planId, planName, onGoBack}: Props) => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [memberships, setMemberships] = useState<Membership[]>([]);
    const [loading, setLoading] = useState(true);
    const count = useRef(0)

    useEffect(() => {
        baseApi.get(`/memberships/plan/?plan=${planId}&branch=${branch.id}&active=1`).then(resp => {
            count.current = resp.data.count
            setMemberships(resp.data.results)
            setLoading(false)
        }).catch()
    }, [branch, planId]);

    const handlePageChange = (page: number) => {
        baseApi.get(`/memberships/plan/?plan=${planId}&branch=${branch.id}&active=1&page=${page}`).then(resp => {
            setMemberships(resp.data.results)
            setLoading(false)
        }).catch()
    }

    return (
        <div>
            <div className="d-inline-flex align-items-center pl-2 pt-2">
                <div className="text-left">
                    <Button variant="link" onClick={onGoBack} className="p-0 m-0"><FontAwesomeIcon icon={faArrowLeft}/></Button>
                </div>
                <h4 className="m-0 p-0 ml-4">Clientes activos: {planName}</h4>
            </div>
            <Table hover>
                <TableHeader headers={["ID CLIENTE", "CLIENTE", "ACTIVACIÓN", "VENCIMIENTO"]}/>
                <tbody>
                {loading ? <LoadingTr/> : memberships.map(m => {
                    return <tr className="" key={m.id}>
                        <td className="text-center">{m.member.external_id}</td>
                        <td className="text-center">{m.member.full_name}
                            <div className="text-muted font-size-xs">{m.member.email} </div>
                        </td>
                        <td className="text-center ">{formatDate(m.activate_on)}</td>
                        <td className="text-center ">{formatDate(m.expire_on)}</td>
                    </tr>
                })}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={memberships.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default PlanMembersList;
