import React, {useEffect, useRef, useState} from 'react';
import {Member} from "../index";
import baseApi from "../../../apis/baseApi";
import {Paginated} from "../../../types";
import {Button, Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import TableFooter from "../../../components/table/TableFooter";
import {Sale} from "../../Reports/Sales";
import {formatDateTime} from "../../../utils/dateFormatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import MemberSaleReceipt from "./MemberSaleReceipt";
import {formatCurrency} from "../../../utils/currencyFormatters";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";

interface Props {
    member: Member,
}

const MemberSalesHistory = ({member}: Props) => {
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)
    const [sales, setSales] = useState<Sale[]>([]);
    const [selectedSale, setSelectedSale] = useState<Sale>();
    const count = useRef(0)

    const handlePageChange = (page: number) => {
        baseApi.get<Paginated>(`sales/?member=${member.id}&page=${page}&page_size=10`).then(resp => {
            setSales(resp.data.results)
        }).catch()
    }

    useEffect(() => {
        baseApi.get<Paginated>(`sales/?member=${member.id}&page=1&page_size=10`).then(resp => {
            count.current = resp.data.count
            setSales(resp.data.results)
        }).catch()
    }, [member]);

    if (selectedSale) {
        return <MemberSaleReceipt
            sale={selectedSale}
            onClose={() => {
                setSelectedSale(undefined)
            }}
            onUpdateProductSaleItem={(item) => {
                let totalCancelled = item.quantity * parseFloat(item.price)
                let total = (parseFloat(selectedSale?.total) - totalCancelled).toFixed(2)
                let isSaleCancelled = selectedSale?.products.length === 1 && selectedSale?.items.length === 0 && selectedSale.plan_items.length === 0
                let sale = {
                    ...selectedSale!,
                    total: isSaleCancelled ? selectedSale?.total : total,
                    status: isSaleCancelled ? 2 : selectedSale.status,
                    products: selectedSale!.products.map(e => e.id === item.id ? item : e)
                }
                setSales(sales.map(e => e.id === sale.id ? sale : e))
                setSelectedSale(sale)
            }}
        />
    }
    return (
        <div>
            <Table hover>
                <TableHeader headers={["#", "FECHA", "IMPORTE", "MÉTODO", "ARTÍCULOS", ""]}/>
                <tbody>
                {sales.length === 0 ? <EmptyTr/> : sales.map((sale) => <tr key={sale.id}
                                                                           className="font-size-sm align-middle text-center">

                    <td>{sale.id.slice(-6)}</td>
                    <td>{formatDateTime(sale.paid_on ?? sale.created_on)}</td>
                    <td>
                        {formatCurrency(parseFloat(sale.total), currency)}
                        <span hidden={sale.status !== 2}
                              className="label label-inline label-light-danger ml-1 font-weight-bolder rounded-xl">
                                        <FontAwesomeIcon className='mr-1' icon={faTimes}/>
                                        CANCELADO
                                    </span>
                    </td>
                    <td>{!sale.payment_method ? 'Pendiente de pago (deuda)' : sale.payment_method}</td>
                    <td>{sale.plan_items.length + sale.products.length + sale.items.length}</td>
                    <td>
                        <Button variant="link" size="sm" onClick={() => {
                            setSelectedSale(sale)
                        }}>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </Button>
                    </td>
                </tr>)}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={sales.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default MemberSalesHistory;
