import React, {useEffect, useState} from 'react';
import CreateProductModal, {ProductInventory} from "../CreateProductModal";
import baseApi from "../../../apis/baseApi";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {Button, Row} from 'react-bootstrap';
import {PlanSaleItem, ProductSaleItem} from "../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../../components/modals/DeleteModal";
import {formatCurrency} from "../../../utils/currencyFormatters";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";

export interface Product {
    id: string;
    name: string;
    short_description: string;
    description: string;
    sku: string;
    image?: string;
    price: string;
    created_on: Date;
    studio: string;
    category?: any;
    uses_inventory: boolean;
    inventories: ProductInventory[];
}

interface Props {
    cartProducts: ProductSaleItem[]
    setCartProducts: (items: ProductSaleItem[]) => void
    planItems: PlanSaleItem[]
    onAddWithSubscriptionError: () => void
}

const Products = ({cartProducts, setCartProducts, planItems, onAddWithSubscriptionError}: Props) => {
    const [showCreateProductModal, setShowCreateProductModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    // const [showEditModal, setShowEditModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [products, setProducts] = useState<Product[]>([]);
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)

    useEffect(() => {
        baseApi.get<Product[]>("/products/").then((resp) => {
            setProducts(resp.data.sort((a, b) => a.name.localeCompare(b.name)))
        }).catch((err) => {
            alert(getApiErrorMessage(err))
        })
    }, []);


    return (
        <div>
            <CreateProductModal show={showCreateProductModal} onHide={() => setShowCreateProductModal(false)}
                                products={products} setProducts={setProducts} product={selectedProduct}/>
            <DeleteModal url={`/products/${selectedProduct?.id}/`} onSuccess={() => {
                setProducts(products.filter(p => p.id !== selectedProduct?.id))
            }} show={showDeleteModal} onHide={() => {
                setShowDeleteModal(false)
                setSelectedProduct(undefined)
            }}>
                <p>¿Estas seguro de querer eliminar el producto <strong>{selectedProduct?.name}</strong>?</p>
            </DeleteModal>
            <div className="d-flex align-items-center my-5">
                <h4 className="text-dark">Productos</h4>
                <button onClick={() => {
                    setSelectedProduct(undefined)
                    setShowCreateProductModal(true)
                }
                } className="btn btn-white btn-icon btn-circle btn-sm shadow ml-3">
                    <i className="fi-rr-plus-small text-dark-50"/>
                </button>
            </div>
            <Row>
                {products.map(p =>
                    <div
                        key={p.id} className="col-md-6 col-lg-4 col-xl-3  px-2 mb-5 pl-4 "

                    >
                        <div className="card-hover card card-custom text-center pt-1 mt-5  rounded-lg karpseudo"
                        >
                            <div className="d-flex justify-content-end mx-2">
                                <Button
                                    onClick={() => {
                                        setSelectedProduct(p)
                                        setShowCreateProductModal(true)
                                    }}
                                    className="btn btn-icon btn-light-secondary btn-hover-info bg-white btn-xs">
                                    <FontAwesomeIcon icon={faEdit}/>
                                </Button>
                                <Button
                                    onClick={() => {
                                        setSelectedProduct(p)
                                        setShowDeleteModal(true)
                                    }}
                                    className="btn btn-icon btn-light-secondary btn-hover-danger bg-white btn-xs">
                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                </Button>
                            </div>
                            <div className="pb-5 clickable" onClick={() => {
                                if (planItems.find(i => i.plan.is_subscription)) {
                                    onAddWithSubscriptionError()
                                    return
                                }
                                if (!cartProducts.map(i => i.product).includes(p)) {
                                    setCartProducts([...cartProducts, {
                                        product: p,
                                        price: parseFloat(p.price),
                                        quantity: 1
                                    },
                                    ])
                                }
                            }}>
                                <p className="h1">{p.name}</p>
                                <p className="text-primary font-weight-bolder h4">{formatCurrency(parseFloat(p.price), currency)}</p>
                                <p><small>STOCK: {p.inventories.reduce((prev, current) => prev + current.qty, 0)}</small></p>
                            </div>
                        </div>
                    </div>)}
            </Row>
        </div>
    );
};

export default Products;
