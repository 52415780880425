import React from 'react';

const TableLoadingTr = () => {
    return (
        <tr className="text-center w-100">
            <td colSpan={7}>Cargando...</td>
        </tr>
    );
};

export default TableLoadingTr;
