import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import {Sale} from "../../Reports/Sales";
import yup from "../../../utils/yup";
import baseApi from "../../../apis/baseApi";
import Checkbox from "../../../components/form/Checkbox";

type Props = {
    show: boolean,
    onHide: Function,
    sale: Sale,
    onSuccess: Function,
}

const CancelPendingPaymentModal = ({show, onHide, sale, onSuccess}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };



    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    initialValues={{status: 2, cancel_membership: true}}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true)
                        setSuccess(null)
                        console.log("OK")
                        baseApi.patch(`/sales/pending/${sale.id}/`, values).then(resp => {
                            setAlertMessage("Venta actualizada")
                            setSuccess(true)
                            onSuccess()
                        }).catch(err => {
                            setAlertMessage("Ocurrió un error al intentar actualizar la venta.")
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }}>
                    {({isSubmitting, setFieldValue}) => (
                        <FormikForm>

                            <Modal.Header className="text-danger">
                                Cancelar venta pendiente
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div>
                                    Cliente: {sale.member.external_id} - {sale.member.full_name}
                                </div>
                                <div>
                                    Plan: {sale.plan_items.map(e => e.plan).join(", ")}
                                </div>
                                <div>
                                    Productos: {sale.products.map(e => e.product).join(", ")}
                                </div>
                                <hr/>
                                <div className="font-weight-bolder font-size-h4">
                                    Total: ${sale.total}
                                </div>
                                <hr/>
                                <div>
                                    <Field label="Cancelar membresía" name="cancel_membership" component={Checkbox}/>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button variant="danger" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Cancelar venta"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CancelPendingPaymentModal;
