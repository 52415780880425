import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import TableHeader from "../../../components/table/TableHeader";
import baseApi from "../../../apis/baseApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCreditCard, faReceipt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {format} from "date-fns";
import {faCcAmex, faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import {billingPlans} from "../../../data/plans";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Studio} from "../../../actions/studio";

interface Address {
    city?: any;
    country?: any;
    line1?: any;
    line2?: any;
    postal_code?: any;
    state?: any;
}

interface StripeBillingDetails {
    address: Address;
    email?: any;
    name?: any;
    phone?: any;
}


interface Checks {
    address_line1_check?: any;
    address_postal_code_check?: any;
    cvc_check: string;
}

interface Card {
    brand: string;
    checks: Checks;
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    installments?: any;
    last4: string;
    network: string;
    three_d_secure?: any;
    wallet?: any;
}

export interface PaymentMethodDetails {
    card: Card;
    type: string;
}

interface Refunds {
    object: string;
    data: any[];
    has_more: boolean;
    url: string;
}

export interface Charge {
    id: string;
    object: string;
    amount: number;
    amount_captured: number;
    amount_refunded: number;
    application?: any;
    application_fee?: any;
    application_fee_amount?: any;
    balance_transaction: string;
    billing_details: StripeBillingDetails;
    calculated_statement_descriptor?: any;
    captured: boolean;
    created: number;
    currency: string;
    customer?: any;
    description: string;
    disputed: boolean;
    failure_code?: any;
    failure_message?: any;
    invoice?: any;
    livemode: boolean;
    on_behalf_of?: any;
    order?: any;
    outcome?: any;
    paid: boolean;
    payment_intent?: any;
    payment_method: string;
    payment_method_details: PaymentMethodDetails;
    receipt_email?: any;
    receipt_number?: any;
    receipt_url: string;
    refunded: boolean;
    refunds: Refunds;
    review?: any;
    shipping?: any;
    source_transfer?: any;
    statement_descriptor?: any;
    statement_descriptor_suffix?: any;
    status: string;
    transfer_data?: any;
    transfer_group?: any;
}

const BillingDetails = () => {
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const [charges, setCharges] = useState<Charge[]>([]);

    useEffect(() => {
        baseApi.get("/cbb/history/").then(resp => {
            setCharges(resp.data.data)
        })
    }, []);


    return (
        <div>
            <div className="mt-5 mb-8">
                <div className="d-flex align-items-center">
                    <i className="fi-rr-stats mr-3"/>
                    <h2 className="text-dark">Tu suscripción</h2>
                </div>
            </div>
            <Row>
                <Col lg={4}>
                    <div className="card card-custom p-5">
                        <p className="text-muted text-center font-size-h3 mb-0">Plan</p>
                        <hr/>
                        {/*<p className="text-center"><strong*/}
                        {/*    className="text-success font-size-h3">{billingPlans.find(plan => plan.productId === studio.product_id)?.name}</strong>*/}
                        {/*</p>*/}
                        {/*<p>{billingPlans.find(plan => plan.productId === studio.product_id)?.features.map(f => {*/}
                        {/*    return <p className="text-muted mb-0">{f.text}</p>*/}
                        {/*})}</p>*/}
                    </div>
                </Col>
                <Col lg={8} className="card card-custom p-5">
                    <p className="text-muted text-center font-size-h3 mb-0">Pagos</p>
                    <hr/>
                    <table>
                        <TableHeader headers={["FECHA", "MONTO", "MÉTODO", "STATUS", "VER RECIBO"]}/>
                        <tbody className="text-center">
                        {charges.map(c => {
                            let cardIcon = c.payment_method_details.card.brand === "visa" ? faCcVisa : c.payment_method_details.card.brand === "mastercard" ? faCcMastercard : c.payment_method_details.card.brand === "amex" ? faCcAmex : faCreditCard
                            return <tr key={c.id}>
                                <td>{format(new Date(c.created * 1000), "dd-MMM-yyyy")}</td>
                                {/*<td>{currencyFormat.format(c.amount / 100)} {c.currency.toUpperCase()}</td>*/}
                                <td><FontAwesomeIcon className="text-muted"
                                                     icon={cardIcon}/> &bull;&bull;&bull;{c.payment_method_details.card.last4}
                                </td>
                                <td className={c.status === "succeeded" ? "text-success" : "text-danger"}>{c.status === "succeeded" ?
                                    <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/>}</td>
                                {/*<td>*/}
                                {/*    <a href={c.receipt_url} target="_blank" rel="noreferrer">*/}
                                {/*        <FontAwesomeIcon icon={faReceipt}/>*/}
                                {/*    </a>*/}
                                {/*</td>*/}
                            </tr>
                        })}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </div>
    );
};

export default BillingDetails;
