import React from 'react';
import {Button, Dropdown, DropdownButton, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Branch, BranchesState, logout, selectBranch} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../reducers";

interface Props {
    showSidebar: boolean,
    setShowSidebar: (show: boolean) => void
}

const TopBar = ({showSidebar, setShowSidebar}: Props) => {
    const dispatch = useDispatch()
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)

    // TOOLTIP - cambiar nombre y id
    const logoutTooltip = (props: any) => (<Tooltip id='logout-tt' {...props}>Cerrar Sesion</Tooltip>)

    return (
        <div style={{height: "45px"}} className="border-bottom mt-3">

            <div className="d-flex justify-content-between">

                <div className="d-none d-md-inline">
                    <DropdownButton id="dropdown-basic-button" title={"Sucursal " + currentBranch.name}
                                    size="sm"
                                    bsPrefix={"btn btn-pill text-muted pl-6 border border-muted"}
                                    >
                        {branchesState.branches.map((b) => {
                            return <Dropdown.Item key={b.id}
                                                  onClick={() => dispatch(selectBranch(b))}>{b.name}
                            </Dropdown.Item>
                        })}
                    </DropdownButton>
                </div>

                {/* Solo aparece en cuentas demo */}
                {/*<span*/}
                {/*    className="label label-warning label-pill label-inline label-lg mr-2 align-self-center font-weight-bolder shadow">Mostrando informacion DEMO</span>*/}


                <div>
                    {/*<Button className="btn btn-white btn-icon btn-circle shadow ml-2 d-none d-md-inline">*/}
                    {/*    < i className="fi-rr-search text-dark-50"/>*/}
                    {/*</Button>*/}
                    {/*<Button className="btn btn-white btn-icon btn-circle shadow ml-2 d-none d-md-inline">*/}
                    {/*    < i className="fi-rr-dollar text-dark-50"/>*/}
                    {/*</Button>*/}
                    {/*<Button className="btn btn-white btn-icon btn-circle shadow ml-2 d-none d-md-inline">*/}
                    {/*    <i className="fi-rr-bell text-dark-50"/>*/}
                    {/*</Button>*/}
                    {/*overlay = nombre del tooltip*/}
                    <OverlayTrigger
                        placement="bottom"
                        overlay={logoutTooltip}
                        delay={{show: 100, hide: 400}}
                    >
                        {/*CONTENT START*/}
                        <Button onClick={() => {
                            dispatch(logout())
                        }} className="btn btn-white btn-icon btn-circle shadow ml-2 d-none d-md-inline">
                            <i className="fi-rr-sign-out text-dark-50"/>
                        </Button>
                        {/*CONTENT END*/}
                    </OverlayTrigger>

                    <Button onClick={() => setShowSidebar(!showSidebar)} className="btn btn-white btn-icon btn-circle shadow ml-2 d-lg-none">
                        <i className="fi-rr-menu-burger text-dark-50"/>
                    </Button>
                </div>

            </div>

        </div>
    );
};

export default TopBar;
