import {StudioActionTypes} from "../actions";
import {Studio, StudioActions} from "../actions/studio";

const initialState = {
    id: "",
    name: "",
    facebook: "",
    instagram: "",
    image: "",
    preferences: {}
} as Studio

export const studioReducer = (state = initialState, action: StudioActions) => {
    switch (action.type) {
        case StudioActionTypes.RETRIEVED_STUDIO:
            return action.payload
        case StudioActionTypes.ADDED_CLASS_CATEGORY:
            let classCategories = state.studio_class_categories
            classCategories.push(action.payload)
            return {...state, studio_class_categories: classCategories}
        case StudioActionTypes.UPDATED_CLASS_CATEGORY:
            let cc = state.studio_class_categories
            cc = cc.map(e => e.id === action.payload.id ? action.payload : e)
            return {...state, studio_class_categories: cc}
        default:
            return state
    }
}