import React from 'react';

const EmptyTr = () => {
    return (
        <tr className="text-center w-100 text-muted font-size-h2-xl font-weight-bold">
            <td colSpan={7}>0 resultados</td>
        </tr>
    );
};

export default EmptyTr;
