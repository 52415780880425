import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import FormField from "../../../../components/form/FormField";
import {Member} from "../../index";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {format, parseISO} from "date-fns";
import baseApi from "../../../../apis/baseApi";
import {getApiErrorMessage} from "../../../../utils/apiErrors";
import ModalSelect from "../../../../components/form/ModalSelect";
import Select from "react-select";
import {MX_SIZES} from "../../../../data/shoeSizes";
import {useSelector} from "react-redux";
import {StoreState} from "../../../../reducers";
import {Studio} from "../../../../actions/studio";

type Props = {
    show: boolean,
    onHide: Function
    member: Member
    setMember: (member: Member) => void
}

const EditMemberModal = ({show, onHide, member, setMember}: Props) => {
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik initialValues={{
                    email: member.user.email,
                    full_name: member.full_name,
                    last_name: member.last_name,
                    phone: member.phone,
                    birthday: member.birthday ? parseISO(member.birthday!) : null,
                    shoe_size: member.shoe_size ? {value: member.shoe_size, label: member.shoe_size} : null,
                    instagram_username: member.instagram_username,
                    facebook_url: member.facebook_url,
                    injuries: member.injuries,
                }} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    let birthday = values.birthday ? format(values.birthday, "yyyy-MM-dd") : undefined
                    let shoe_size = values.shoe_size ? values.shoe_size.value : ""
                    baseApi.patch(`/members/update/${member.id}/`, {
                        ...values,
                        phone: values.phone ? values.phone : "0",
                        shoe_size: shoe_size,
                        birthday: birthday,
                    }).then(resp => {
                        setMember({
                            ...member, ...values,
                            shoe_size: shoe_size,
                            birthday: birthday,
                            user: {...member.user, email: values.email}
                        })
                        setSuccess(true)
                        setAlertMessage("Cliente actualizado correctamente.")
                        setSubmitting(false)
                    }).catch(err => {
                            alert(getApiErrorMessage(err))
                            setSubmitting(false)
                        }
                    )

                }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>
                            <Modal.Header>
                                Editar Cliente
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div className="offset-lg-1">
                                    <p className="text-muted m-0 o-0 font-size-xs ">*El usuario debe iniciar sesión con
                                        este e-mail.</p>
                                </div>
                                <FormField label="E-Mail" name="email"/>
                                <FormField label="Nombre" name="full_name"/>
                                <FormField label="Apellido" name="last_name"/>
                                <FormField label="Teléfono" name="phone"/>
                                <div style={{width: "550px"}} className="d-flex flex-row pb-3 offset-lg-1">
                                    <div style={{width: "120px"}}>
                                        <span className="">Cumpleaños</span>
                                    </div>
                                    <div className="customDatePickerWidth" style={{width: "220px"}}>
                                        <ReactDatePicker
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd 'de' MMMM 'de' yyyy"
                                            maxDate={new Date()}
                                            locale={es}
                                            selected={values.birthday}
                                            onChange={date => {
                                                if (date) {
                                                    setFieldValue("birthday", date)
                                                }
                                            }}
                                            customInput={<input className="form-control"/>}
                                        />
                                    </div>
                                </div>
                                <div hidden={!studio.preferences.uses_shoe_field}>
                                    <div className="row">
                                        <label className="col-lg-2 offset-lg-1 col-form-label">Talla zapatos</label>
                                        <div className="col-lg-9">
                                            <ModalSelect label={"Talla de zapatos"}>
                                                <Select placeholder={""}
                                                        value={values.shoe_size}
                                                        isClearable={true}
                                                        onChange={(option: { value: string, label: string } | null) => {
                                                            setFieldValue("shoe_size", option)
                                                        }}
                                                        options={MX_SIZES.map(s => ({
                                                            value: s.toString(),
                                                            label: s.toString()
                                                        }))}/>
                                            </ModalSelect>
                                        </div>
                                    </div>
                                </div>
                                <FormField label="Facebook (URL)" name="facebook_url"/>
                                <FormField label="Instagram usuario" name="instagram_username"/>
                                <FormField label="Teléfono Emergencia" name="emergency_contact_phone"/>
                                <FormField label="Lesiones" name="injuries"/>


                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default EditMemberModal;
