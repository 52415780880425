import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import FormInputGroup from "../../../../components/form/FormInputGroup";
import baseApi from "../../../../apis/baseApi";
import {addDays, endOfDay} from "date-fns";
import {getApiErrorMessage} from "../../../../utils/apiErrors";
import {CreditsTransaction} from "../MemberCredits";
import {Member} from "../../index";
import yup from '../../../../utils/yup';
import FieldError from "../../../../components/form/FieldError";

type Props = {
    show: boolean,
    onHide: Function,
    member: Member
    setMember: (member: Member) => void
    transactions: CreditsTransaction[]
    setTransactions: (transactions: CreditsTransaction[]) => void
}

const AddCreditsModal = ({show, onHide, member, setMember, transactions, setTransactions}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        original_amount: yup.number().positive().required(),
        days: yup.number().positive().required()
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{original_amount: 0, days: 30}} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    baseApi.post("/credits/add/", {
                        original_amount: values.original_amount,
                        current_amount: values.original_amount,
                        member: member.id,
                        expire_on: endOfDay(addDays(new Date(), values.days))
                    }).then((resp) => {
                        setTransactions([{
                            id: resp.data.id,
                            description: "Créditos agregados manualmente",
                            created_on: resp.data.created_on,
                            amount: resp.data.original_amount,
                            transaction_by: "",
                            credits: resp.data.id,
                            expire_on: resp.data.expire_on
                        }, ...transactions])
                        setMember({...member, current_credits: member.current_credits + resp.data.original_amount})
                        setSuccess(true)
                        setAlertMessage("Se han agregado los créditos al usuario correctamente.")
                    }).catch(err => {
                        setAlertMessage(getApiErrorMessage(err))
                        setSuccess(false)
                        setSubmitting(false)
                    })
                }}>
                    {({isSubmitting}) => (
                        <FormikForm>

                            <Modal.Header>
                                Agregar Créditos
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FieldError name="original_amount"/>
                                <FormInputGroup name="original_amount" label="Cantidad" groupText="créditos"
                                                placeholder="0"/>
                                <FieldError name="days"/>
                                <FormInputGroup name="days" label="Expiración a partir de hoy" groupText="días"
                                                placeholder="30"/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Agregar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default AddCreditsModal;
