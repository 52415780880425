import axios from "axios";
import {isTokenExpired, refreshAccess} from "../utils/jwt_utils";



const client = axios.create({
    baseURL: "https://boostapp.top"
})

client.interceptors.request.use(async request => {
    const access = localStorage.getItem("access")
    if (isTokenExpired(access ?? "")) {
        await refreshAccess()
    }
    request.headers["Authorization"] = "Bearer " + localStorage.getItem("access")
    return request
})

export default client
