import React from "react";
import {FieldProps} from "formik";
import Select, {GroupTypeBase, OptionsType, Styles, ValueType} from "react-select";
import {InputActionMeta} from "react-select/src/types";

interface Option {
    label: string;
    value: string;
}

interface FormikSelectProps extends FieldProps {
    options: OptionsType<Option>;
    isMulti?: boolean;
    onChange?: (option: ValueType<Option | Option[], boolean>) => void,
    isClearable?: boolean,
    isDisabled?: boolean
    placeholder: string
    styles:  Partial<Styles<any, boolean, GroupTypeBase<any>>> | undefined
    onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
}

export const FormikSelect =
    ({
         field,
         form,
         options,
         onChange = () => {
         },
         isMulti = false,
        isDisabled = false,
         isClearable,
         placeholder = "Seleccionar",
        onInputChange,
        styles,

     }: FormikSelectProps) => {
        const handleChange = (option: ValueType<Option | Option[], boolean>) => {
            console.log(option)
            if (option) {
                form.setFieldValue(
                    field.name,
                    isMulti
                        ? (option as Option[]).map((item: Option) => item.value)
                        : (option as Option).value
                );
            } else {
                 form.setFieldValue(field.name, null)
            }
            onChange(option)
        };

        const getValue = () => {
            if (options && field.value) {
                return isMulti
                    ? options.filter(option => field.value.indexOf(option.value) >= 0)
                    : options.find(option => option.value === field.value);
            } else {
                return isMulti ? [] : ("" as any);
            }
        };

        return (
            <Select
                isDisabled={isDisabled}
                name={field.name}
                value={getValue()}
                onChange={handleChange}
                options={options}
                isClearable={isClearable}
                isMulti={isMulti}
                placeholder={placeholder}
                styles={styles}
                onInputChange={onInputChange}
            />
        );
    };