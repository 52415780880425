import React, {useState, useEffect, JSX} from 'react';
import Select from "react-select";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCreditCard, faTag} from "@fortawesome/free-solid-svg-icons";
import {Formik, Form, Field} from "formik";
import {Alert, Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import {formatISO, isAfter, isBefore, isToday, parseISO} from "date-fns";
import {AxiosResponse} from "axios";
import {faCcAmex, faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import {StoreState} from "../../../reducers";
import {Studio} from "../../../actions/studio";
import {Branch} from "../../../actions";
import baseApi from "../../../apis/baseApi";
import {PlanSaleItem, ProductSaleItem} from "../index";
import {SaleItem} from "../../Reports/Sales";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {formatCurrency} from "../../../utils/currencyFormatters";
import {StudioPlan} from "../../../actions/studioPlans";
import yup from "../../../utils/yup";
import FieldError from "../../../components/form/FieldError";
import {paymentMethods} from "../../../data/paymentMethods";
import CartPlanListTile from "./ui/CartPlanListTile";
import CartProductListTile from "./ui/CartProductListTile";
import CartItemListTile from "./ui/CartItemListTile";

export type CartMember = {
    id: string
    email: string
    full_name: string
    external_id: string
    last_name?: string
}

type PosCartProps = {
    cartPlans: PlanSaleItem[]
    setCartPlans: (plans: PlanSaleItem[]) => void,
    cartProducts: ProductSaleItem[],
    setCartProducts: (prods: ProductSaleItem[]) => void,
    cartItems: SaleItem[], // SALES V2
    setCartItems: (items: SaleItem[]) => void // SALES V2
}

const Cart = ({cartPlans, setCartPlans, cartProducts, setCartProducts, cartItems, setCartItems}: PosCartProps) => {
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)
    const [searchTerm, setSearchTerm] = useState("")
    const [fetchingMembers, setFetchingMembers] = useState(false)
    const [members, setMembers] = useState<CartMember[]>([])
    const [member, setMember] = useState<CartMember>();
    const [success, setSuccess] = useState<boolean | null>(null);
    const [couponCode, setCouponCode] = useState();
    const [couponError, setCouponError] = useState("");
    const [coupon, setCoupon] = useState<ValidCoupon | null>(null);
    const [discountAmount, setDiscountAmount] = useState<number>();
    const [errorMsg, setErrorMsg] = useState("Error");

    const [stripePaymentMethods, setStripePaymentMethods] = useState<StripePaymentMethod[]>([]);

    const stripePaymentAllowedStudios: string[] = ["a9d64f87-5d5a-4f59-98a2-56d70e63d27e", "ef861606-71bd-4929-b4b7-fc340d982a51", "9a3c10bd-cf10-48f9-a71d-3ee284fdc67c"]

    useEffect(() => {
        baseApi.get(
            `/members/search/?branch=${branch.id}`,
        ).then((response) => {
            setMembers(response.data.results);
            setFetchingMembers(false);
        }).catch();
    }, [])

    useEffect(() => {
        const searchFn = setTimeout(() => {
            if (searchTerm.length < 2) return
            setFetchingMembers(true)
            baseApi.get(
                `/members/search/?search=${searchTerm}&branch=${branch.id}`,
            ).then((response) => {
                setMembers(response.data.results);
                setFetchingMembers(false);
            }).catch();
        }, 800);
        return () => clearTimeout(searchFn);
    }, [searchTerm]);

    useEffect(() => {
        if (stripePaymentAllowedStudios.includes(studio.id) && member) {
            setStripePaymentMethods([])
            baseApi.get(`/stripe/v1.1/payment-methods/?member=${member?.id}`).then(resp => {
                setStripePaymentMethods(resp.data.data)
            })
        }
    }, [member])

    const initSubscription = async (member: string, card: string, item: PlanSaleItem) => {
        await baseApi.post("/stripe/pos/subscriptions/", {
            member: member,
            plan: item.plan.id,
            card: card,
            anchor_date: isToday(item.activate_on) ? null : formatISO(item.activate_on)
        }).then(resp => {
            setSuccess(true)
            setDiscountAmount(0)
            setCartPlans([])
            setCartProducts([])
            setCartItems([])
        }).catch(err => {
            setSuccess(false)
            setErrorMsg(getApiErrorMessage(err))
        })
    }

    const renderPlans = () => {
        if (cartPlans.length === 0) {
            return <div>
                <p className="text-muted font-size-md font-weight-bolder">Aun no has seleccionado un plan</p>
                <div className="separator separator-solid"/>
            </div>
        }
        return cartPlans.map((i, index) => {
            return <CartPlanListTile key={index} item={i} currency={currency} onActivateDateChange={(date) => {
                setCartPlans(cartPlans.map(planItem => planItem !== i ? planItem : {
                    ...planItem,
                    activate_on: date
                }))
            }} onRemove={() => {
                setCartPlans(cartPlans.filter(planItem => planItem !== i))
            }}/>
        })
    }

    // CART PRODUCTS
    const renderProducts = () => {
        return cartProducts.map((i, index) => {
            return <CartProductListTile key={index} item={i} currency={currency} onQtyChange={(value) => {
                if (value === -1 && i.quantity === 1) return
                const items = cartProducts.map(item => item === i ? {
                    ...item,
                    quantity: item.quantity + value
                } : item)
                setCartProducts(items)
            }} onRemove={() => {
                setCartProducts(cartProducts.filter(cartProd => cartProd !== i))
            }}/>
        })
    }

    const renderItems = () => {
        return cartItems.map((i, index) => {
            return <div key={index} className="mt-2">
                <CartItemListTile item={i} currency={currency} onRemove={() => {
                    setCartItems(cartItems.filter(item => item !== i))
                }}/>
            </div>
        })
    }


    const renderAlert = () => {
        if (success === null) return;
        return <Alert
            variant={success ? "success" : "danger"}>{success ? "Venta realizada correctamente" : errorMsg}</Alert>;
    };

    const getPlanPrice = (plan: StudioPlan): number => {
        let planPrice = parseFloat(plan.price)
        const promotionActive = plan.promotions?.find(p => isAfter(new Date(), parseISO(p.activate_on)) && isBefore(new Date(), parseISO(p.expire_on)))
        if (promotionActive) {
            const promotion = plan.promotions![0]
            if (promotion.is_percent) {
                planPrice = planPrice - (planPrice * (promotion.discount_amount / 100))
            } else {
                planPrice = planPrice - promotion.discount_amount
            }
        }
        return Number(planPrice.toFixed(2))
    }

    const getTotal = (ignoreDiscount = false): number => {
        const plansTotal = cartPlans.reduce((a, b) => a + getPlanPrice(b.plan), 0)
        const productsTotal = cartProducts.reduce((a, b) => a + (b.price * b.quantity), 0)
        const itemsTotal = cartItems.reduce((a, b) => a + (b.price * b.quantity), 0)
        let total = plansTotal + productsTotal + itemsTotal
        // if (coupon !== null) {
        //     if (coupon.is_percent) {
        //         total -= coupon.discount_amount
        //     }
        // }
        if (!ignoreDiscount) {
            if (discountAmount !== undefined) {
                total -= discountAmount
            }
        }
        return Number(total.toFixed(2))
    }

// PRUEBAS K para subtotal

    const getSubtotal = (ignoreDiscount = false): number => {
        const plansTotal = cartPlans.reduce((a, b) => a + getPlanPrice(b.plan), 0)
        const productsTotal = cartProducts.reduce((a, b) => a + (b.price * b.quantity), 0)
        const itemsTotal = cartItems.reduce((a, b) => a + (b.price * b.quantity), 0)
        let total2 = plansTotal + productsTotal + itemsTotal
        return total2
    }
// FIN PRUEBAS K

    const validateCoupon = async (): Promise<AxiosResponse<any>> => {
        const total = cartPlans.reduce((a, b) => a + getPlanPrice(b.plan), 0) +
            cartProducts.reduce((a, b) => a + (b.price * b.quantity), 0)
        return await baseApi.post("/coupons/staff-validate/", {
            "coupon_code": couponCode,
            "total": total,
            "member": member?.id
        },)
    }

    const ValidationSchema = yup.object().shape({
        member: yup.string().required("Selecciona el cliente al que le asignaras este plan"),
        payment_method: yup.string().defined("Selecciona un método de pago").max(50).nullable(),
    })

    const cardIcons: { [key: string]: JSX.Element } = {
        "visa": <FontAwesomeIcon icon={faCcVisa}/>,
        "mastercard": <FontAwesomeIcon icon={faCcMastercard}/>,
        "amex": <FontAwesomeIcon icon={faCcAmex}/>,
    }

    return (
        <div className="card card-custom h-100">
            <Formik
                validationSchema={ValidationSchema}
                initialValues={{
                    plan_items: [],
                    products: [],
                    member: "",
                    payment_method: undefined as string | undefined,
                    card: null as { label: any, value: string } | null,
                    location: "CS"
                }}
                onSubmit={(values, {setSubmitting, setFieldValue}) => {
                    setSuccess(null)
                    const planItems = cartPlans.filter(p => !p.plan.is_subscription).map(p => ({
                        ...p,
                        plan: p.plan.id,
                        activate_on: formatISO(p.activate_on),
                        price: getPlanPrice(p.plan),
                        promotion: p.plan.promotions?.find(i => isAfter(new Date(), parseISO(i.activate_on)) && isBefore(new Date(), parseISO(i.expire_on)))?.id
                    }))
                    const subscription = cartPlans.find(p => p.plan.is_subscription)
                    if (subscription) {
                        initSubscription(values.member, values.card!.value, subscription!).then(() => {
                            setSubmitting(false)
                        })
                        return
                    }
                    baseApi.post("/sales/", {
                        ...values,
                        branch: branch.id,
                        items: cartItems,
                        card: values.card?.value,
                        plan_items: planItems,
                        products: cartProducts.map(p => ({...p, product: p.product.id})),
                        pos_discount: discountAmount,
                        total: getTotal()
                    }).then((resp) => {
                        setDiscountAmount(0)
                        setCartPlans([])
                        setCartProducts([])
                        setCartItems([])
                        setSuccess(true)
                        setSubmitting(false)
                    }).catch((err) => {
                        setErrorMsg(getApiErrorMessage(err))
                        setSuccess(false)
                        setSubmitting(false)
                    })

                }}>
                {({isSubmitting, setFieldValue, values, setFieldError}) => (
                    <Form>
                        <div className="card-body px-5" style={{minHeight: "100%"}}>
                            <h3 className="text-dark font-weight-bold mr-5 align-middle mt-n3 mb-5">🛒 Carrito</h3>
                            {renderAlert()}

                            <div className='bg-secondary pt-6 pb-8 px-3 rounded-lg'>
                                <div className='text-dark font-weight-bold font-size-lg'>Cliente</div>
                                <Select placeholder="Cliente"
                                        options={members.map((m) => ({
                                            value: m.id,
                                            label: `${m.external_id} - ${m.full_name} ${m.last_name ?? ''}`
                                        }))}
                                        onInputChange={(text) => setSearchTerm(text)}
                                        noOptionsMessage={() => "Sin resultados"}
                                        isLoading={fetchingMembers} loadingMessage={() => "Cargando clientes..."}
                                        onChange={(option) => {
                                            setFieldValue("member", option?.value);
                                            setMember(members.find(m => m.id === option?.value))
                                            setFieldValue("card", null)
                                        }}/>
                                <FieldError name="member"/>
                            </div>
                            {/* <div className="separator separator-solid mt-8 mb-5"/> */}

                            <p className="text-dark font-size-lg font-weight-bold bg-secondary py-4 px-3 rounded-lg mt-5">Resumen
                                de venta</p>

                            {renderPlans()}
                            <FieldError name="plan_items"/>
                            {renderProducts()}
                            {renderItems()}
                            {/*/!* CODIGO DESCUENTO *!/*/}
                            {/*<div className="separator separator-solid mb-8"/>*/}
                            {/*<div className="d-flex justify-content-between mt-5">*/}
                            {/*    <Field name="coupon_code" label="Código descuento"*/}
                            {/*           className="form-control form-control-sm mr-2" placeholder="Código de descuento"*/}
                            {/*           onChange={(event: React.ChangeEvent<any>) => {*/}
                            {/*               setCouponCode(event.target.value)*/}
                            {/*           }}/>*/}
                            {/*    <Button onClick={() => {*/}
                            {/*        validateCoupon().then(resp => {*/}
                            {/*            setFieldValue("coupon_code", couponCode)*/}
                            {/*            setCoupon(resp.data)*/}
                            {/*        }).catch(err => {*/}
                            {/*            console.log(err)*/}
                            {/*            setFieldError("coupon_code", getApiErrorMessage(err))*/}
                            {/*        })*/}
                            {/*    }} variant={"outline-success"} size="sm">Aplicar</Button>*/}
                            {/*</div>*/}
                            {/*<div className="text-success">*/}
                            {/*    {coupon === null ?*/}
                            {/*        <React.Fragment/> : `${coupon.title} -${coupon.discount_amount}${coupon.is_percent ? '%' : ''}`}*/}
                            {/*</div>*/}
                            {/*<div className="text-danger">*/}
                            {/*    {couponError}*/}
                            {/*</div>*/}
                            {/*<FieldError name="coupon_code"/>*/}
                            {/* Descuento */}
                            <div
                                className='text-dark font-size-lg font-weight-bold bg-secondary py-4 px-3 rounded-lg mt-5'>
                                <div>
                                    <div className="d-flex justify-content-between">
                                        <div>Subtotal</div>
                                        <div className='font-size-h6'>$ {getSubtotal()}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                        <div>Descuento
                                            {/* <FontAwesomeIcon className="ml-1 text-muted font-size-sm" icon={faTag} /> */}
                                        </div>

                                        <div>
                                            <div
                                                className='text-right font-weight-normal font-size-sm mb-n3 text-muted'>
                                                <FontAwesomeIcon className="mr-2 text-muted font-size-xs" icon={faTag}/>
                                                Aplicar Descuento
                                            </div>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <Field name="pos_discount" label="Descuento"
                                                       disabled={cartPlans.find(p => p.plan.is_subscription)}
                                                       value={discountAmount}
                                                       className="form-control form-control-sm mr-2 text-right text-danger font-size-h5 col-6 col-sm-12 col-lg-5 col-xl-4 col-xxl-3 border-0 bg-transparent border-bottom border-danger p-0"
                                                       placeholder="- $0"
                                                       onChange={(event: React.ChangeEvent<any>) => {
                                                           if (!isNaN(event.target.value)) {
                                                               const discount = parseFloat(event.target.value)
                                                               if (discount <= getTotal(true)) {
                                                                   setDiscountAmount(discount)
                                                               } else {
                                                                   setDiscountAmount(0)
                                                               }
                                                           } else {
                                                               setDiscountAmount(0)
                                                           }
                                                       }}/>
                                            </div>
                                            <hr className='p-0 m-0'/>
                                        </div>
                                    </div>
                                    {/*TOTAL*/}
                                    <div className="d-flex justify-content-between mt-4">
                                        <div>TOTAL</div>
                                        <div
                                            className='font-size-h1 font-weight-bolder text-success'>{formatCurrency(getTotal(cartPlans.find(p => p.plan.is_subscription) !== undefined), currency)}</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="separator separator-solid mb-8"/> */}
                            {/* METODO DE PAGO */}
                            <div className='bg-secondary pt-6 pb-8 px-3 rounded-lg mt-5'>
                                <div className='text-dark font-weight-bold font-size-lg'>Método de pago</div>
                                <Select placeholder="Efectivo, Transferencia, etc."
                                        options={paymentMethods.filter(pm => {
                                            if (cartPlans.find(i => i.plan.is_subscription)) {
                                                if (pm.value !== "Stripe") return false
                                            }
                                            if (pm.value === "Stripe") {
                                                if (!stripePaymentAllowedStudios.includes(studio.id)) return false
                                                if (studio.preferences.payment_processor !== "ST") return false
                                            }
                                            return true
                                        })}
                                        onChange={(option) => setFieldValue("payment_method", option?.value)}/>
                                <FieldError name="payment_method"/>
                                <div hidden={values.payment_method !== "Stripe"}>
                                    <div className="font-size-xs text-black-50">*Asegurate de que el cliente este de
                                        acuerdo con este cargo, de lo contrario Stripe puede suspender tu cuenta
                                        indefinidamente.
                                    </div>
                                    <div className='text-dark font-weight-bold font-size-lg mt-2'>Tarjeta</div>
                                    <Select
                                        value={values.card}
                                        placeholder="Tarjeta"
                                        options={stripePaymentMethods.map(pm => ({
                                            value: pm.id,
                                            label: <div className="">
                                                <div className="text-black">
                                                    {cardIcons[pm.card.brand.toLowerCase()] ??
                                                      <FontAwesomeIcon icon={faCreditCard}/>} ***{pm.card.last4}
                                                </div>
                                                <div className="font-size-sm">
                                                    {pm.billing_details.name}
                                                </div>
                                            </div>
                                        }))}
                                        onChange={(option) => setFieldValue("card", option)}/>
                                    <FieldError name="card"/>
                                </div>
                            </div>
                            <div className="text-center">
                                {/*<Button onClick={() => initSubscription(values)}>Iniciar suscripción</Button>*/}
                                <Button type="submit"
                                        disabled={isSubmitting || (cartProducts.length === 0 && cartPlans.length === 0 && cartItems.length === 0) || (values.payment_method === "Stripe" && !values.card)}
                                        className="btn btn-success btn-lg btn-pill mt-4 justify-content-center font-weight-bolder"
                                        style={{boxShadow: '0px 8px 25px #1bc5bd62'}}>Finalizar y guardar venta
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )


};

export default Cart;

interface ValidCoupon {
    title: string;
    discount_amount: number;
    is_percent: boolean;
    redeem_code: string;
}

export interface StripePaymentMethod {
    id: string
    object: string
    billing_details: BillingDetails
    card: Card
    created: number
    customer: any
    livemode: boolean
    metadata: Metadata
    type: string
}

export interface BillingDetails {
    address: Address
    email: any
    name: any
    phone: any
}

export interface Address {
    city: any
    country: any
    line1: any
    line2: any
    postal_code: any
    state: any
}

export interface Card {
    brand: string
    checks: Checks
    country: string
    exp_month: number
    exp_year: number
    fingerprint: string
    funding: string
    generated_from: any
    last4: string
    networks: Networks
    three_d_secure_usage: ThreeDSecureUsage
    wallet: any
}

export interface Checks {
    address_line1_check: any
    address_postal_code_check: any
    cvc_check: string
}

export interface Networks {
    available: string[]
    preferred: any
}

export interface ThreeDSecureUsage {
    supported: boolean
}

export interface Metadata {
}
