import React, {useState} from 'react';
import {Button, Col, Row, Toast} from "react-bootstrap";

import {StudioPlan} from "../../actions/studioPlans";
import Memberships from "./Cart/Memberships";
import Products, {Product} from "./Cart/Products";
import CreateCashExpenseModal from "./CreateCashExpenseModal";
import PendingPaymentSales from "./PendingPaymentSales";
import MemberFees from "./MemberFees";
import {SaleItem} from "../Reports/Sales";
import TabNavigator2 from '../../components/TabNavigator2';
import Cart from "./Cart";

export interface ProductSaleItem {
    product: Product
    quantity: number
    price: number
}

export interface PlanSaleItem {
    plan: StudioPlan
    quantity: number
    price: number
    activate_on: Date
}



const Pos = () => {
    const [cartPlans, setCartPlans] = useState<PlanSaleItem[]>([])
    const [cartProducts, setCartProducts] = useState<ProductSaleItem[]>([]);
    const [cartItems, setCartItems] = useState<SaleItem[]>([]); // SALES V2
    const [showCashMovementModal, setShowCashMovementModal] = useState(false);
    const [positiveMove, setPositiveMove] = useState(true);
    const [showToast, setShowToast] = useState(false);

    const  onAddWithSubscriptionError = () => {
        setShowToast(true)
    }

    return (
        <div style={{overflowY: "auto", overflowX: "hidden"}} className="h-100 on-pos">
            <div style={{position: 'absolute', top: 10, right: 10, zIndex: 999}}>
                <Toast show={showToast} onClose={() => setShowToast(false)} autohide delay={5000} >
                    <Toast.Header>
                        <strong className="mr-auto ">Notificación</strong>
                    </Toast.Header>
                    <Toast.Body className="font-size-sm">No es posible agregar otros artículos en una venta que contiene una suscripción.</Toast.Body>
                </Toast>
            </div>

            <CreateCashExpenseModal show={showCashMovementModal} onHide={() => setShowCashMovementModal(false)}
                                    positive={positiveMove}/>
            <Row>
                <Col className='col-12 col-md-5 col-lg-6 col-xxl-8'>
                    <div className="mt-5 mb-8 d-md-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <i className="fi-rr-shopping-cart mr-3"/>
                            <h2 className="text-dark">Punto de Venta</h2>
                        </div>
                        <div className="text-md-right">
                            <Button className="mr-3" onClick={() => {
                                setPositiveMove(true)
                                setShowCashMovementModal(true);
                            }} variant="outline-success" size="sm">
                                Agregar ingreso
                            </Button>
                            <Button onClick={() => {
                                setPositiveMove(false)
                                setShowCashMovementModal(true);
                            }} variant="outline-danger" size="sm">
                                Agregar egreso
                            </Button>
                        </div>
                    </div>
                    <TabNavigator2 tabLabels={["💳 Planes", "📦 Productos", "💵 Por cobrar", "⛔ Multas"]} panels={[
                        <Memberships cart={cartPlans} setCart={setCartPlans} cartItems={cartItems}
                                     setCartItems={setCartItems} onAddWithSubscriptionError={onAddWithSubscriptionError}/>,
                        <Products cartProducts={cartProducts} setCartProducts={setCartProducts} planItems={cartPlans} onAddWithSubscriptionError={onAddWithSubscriptionError}/>,
                        <PendingPaymentSales/>,
                        <MemberFees/>,
                    ]}/>
                </Col>
                <Col className='col-12 col-md-7 col-lg-6 col-xxl-4'>
                    <Cart cartPlans={cartPlans} setCartPlans={setCartPlans} cartProducts={cartProducts}
                             setCartProducts={setCartProducts} cartItems={cartItems} setCartItems={setCartItems}/>
                </Col>
            </Row>

        </div>
    );
};

export default Pos;
