import React from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {ProductSaleItem} from "../../index";
import {formatCurrency} from "../../../../utils/currencyFormatters";

interface Props {
    item: ProductSaleItem
    currency: string
    onQtyChange: (qty: number) => void
    onRemove: () => void
}

const CartProductListTile = ({item, currency, onQtyChange, onRemove} :Props) => {
    return (
        <div className="mt-2  mx-1  card border-0 shadow rounded-lg">
            <div className="row justify-content-around align-items-start gutter-0 mt-5 pl-3 ">
                <div className="col-4 p-0" style={{lineHeight: '4px'}}>
                    {/* PRODUCT SKU */}
                    {/* <p className="text-muted font-size-xs">SKU: {i.product.sku}</p> */}
                    {/* FIN SKU */}
                    <p className="font-weight-bolder">📦 {item.product.name}</p>
                    <p>{item.product.description.slice(0, 20)}</p>
                </div>
                <div className="col-2 text-center p-0" style={{lineHeight: '4px'}}>
                    <p className="text-muted font-size-xs">CANTIDAD</p>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div onClick={() => onQtyChange(-1)} style={{padding: "0", width: "20px", height: "20px"}}
                             className="border font-weight-boldest rounded-circle clickable pt-2 text-primary">-
                        </div>
                        <span className="mx-2">{item.quantity}</span>
                        <div onClick={() => onQtyChange(1)} style={{width: "20px", height: "20px"}}
                             className="border font-weight-boldest rounded-circle text-center clickable">
                            <span className="align-middle pt-2 text-primary">+</span>
                        </div>
                    </div>
                </div>
                <div className="col-2 text-center p-0" style={{lineHeight: '4px'}}>
                    <p className="text-muted font-size-xs">PRECIO</p>
                    <p>{formatCurrency(parseFloat(item.product.price), currency)}</p>
                </div>
                <div className="col-1 p-0" style={{lineHeight: '4px'}}>
                    <Button onClick={onRemove} variant="link">
                        <FontAwesomeIcon icon={faTimes} className='text-danger'/>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CartProductListTile;
