import React, {useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import FormField from "../../components/form/FormField";
import {TimeSlotV2} from "../Schedules";
import {format} from "date-fns";
import yup from "../../utils/yup";
import baseApi from "../../apis/baseApi";
import {getApiErrorMessage} from "../../utils/apiErrors";
import FieldError from "../../components/form/FieldError";
import {useDispatch, useSelector} from "react-redux";
import {addReservation, updateReservation} from "../../actions/timeslotReservations";
import  {Reservation} from "./index";
import {Room, Seat} from "../Toolbox/Rooms";
import {CartMember} from "../Pos/Cart";
import Select from "react-select";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";

type CreateScheduleModalProps = {
    show: boolean,
    onHide: Function,
    timeslot: TimeSlotV2
    reservation?: Reservation
    room?: Room
    onUpdateTimeSlot: (ts: TimeSlotV2) => void
}

const CreateReservationModal = ({show, onHide, timeslot, reservation, room, onUpdateTimeSlot}: CreateScheduleModalProps) => {
    const dispatch = useDispatch()
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)

    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const [selectedSeat, setSelectedSeat] = useState<Seat>()
    const [searchTerm, setSearchTerm] = useState("")
    const [fetchingMembers, setFetchingMembers] = useState(false)
    const [members, setMembers] = useState<CartMember[]>([])
    const [rsvpSeatId, setRsvpSeatId] = useState<string>();

    useEffect(() => {
        baseApi.get(
            `/members/search/?branch=${branch.id}`,
        ).then((response) => {
            setMembers(response.data.results);
            setFetchingMembers(false);
        }).catch();
    }, [branch])

    useEffect(() => {
        const searchFn = setTimeout(() => {
            if (searchTerm.length < 2) return
            setFetchingMembers(true)
            baseApi.get(
                `/members/search/?search=${searchTerm}&branch=${branch.id}`,
            ).then((response) => {
                setMembers(response.data.results);
                setFetchingMembers(false);
            }).catch();
        }, 800);
        return () => clearTimeout(searchFn);
    }, [searchTerm, branch]);

    useEffect(() => {
        if (reservation && reservation.seat) {
            console.log(reservation.seat)
            let seatId = room?.seats?.find(s => s.label === reservation.seat)?.id
            setRsvpSeatId(seatId)
        }
    }, [reservation, timeslot]);


    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        member: yup.string().nullable().defined(),
        full_name: yup.string().when("member", {is: null, then: yup.string().required(), otherwise: yup.string()}),
    })

    const renderRoom = () => {
        if (timeslot.room === null || !room?.uses_map) return <React.Fragment/>
        let slots = Array.from(Array(room.cols * room.rows).keys())
        let spotWidth = Math.floor(683 / room!.cols)
        return <div className=" border p-3 text-center">
            {slots.map(index => {
                const row = Math.floor(index / room!.cols)
                const col = index % room!.cols
                const seat = room!.seats.find(seat => seat.col === col && seat.row === row)
                if (!seat) {
                    return <div className={`d-inline-flex`} key={index} style={{width: spotWidth}}/>
                }
                if (!rsvpSeatId && reservation && reservation.seat === seat.label) {
                    setRsvpSeatId(seat.id)
                }
                const occupied = timeslot.occupied_seats.includes(seat.id)
                return <div className={`d-inline-flex justify-content-center `} key={index}
                            style={{width: spotWidth}}>
                    <div onClick={occupied ? () => {
                    } : () => setSelectedSeat(seat)}
                         style={{
                             width: spotWidth / 2,
                             height: spotWidth / 2,
                             backgroundColor: occupied ? "#dee2e6" : selectedSeat?.id === seat.id ? "#ade8f4" : "white"
                         }}
                         className="border rounded-circle seat-select d-flex align-items-center justify-content-center ">
                        <div className="">
                            {seat.label}
                        </div>

                    </div>
                </div>
            })}
        </div>
    }

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={reservation ? null : ValidationSchema}
                    initialValues={{full_name: "", email: "", phone: "", member: undefined}}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        if (timeslot.room !== null && room?.uses_map && !selectedSeat) {
                            setSuccess(false)
                            setAlertMessage("Es necesario escoger un lugar")
                            setSubmitting(false)
                            return
                        }

                        if (reservation) {
                            baseApi.patch<Reservation>(`/rsvp/update/${reservation!.id}/`, {seat: selectedSeat?.id}).then(resp => {
                                setAlertMessage("Reservación actualizada")
                                dispatch(updateReservation({...reservation, seat: selectedSeat!.label}))
                                setSuccess(true)
                                let occupiedSeats = [...timeslot.occupied_seats, selectedSeat!.id];
                                occupiedSeats = occupiedSeats.filter(seat_id => seat_id !== rsvpSeatId)
                                console.log(occupiedSeats)
                                onUpdateTimeSlot({...timeslot, occupied_seats: occupiedSeats})

                            }).catch(err => {
                                setAlertMessage(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        } else {
                            const url = values.member === null ? "/rsvp/dashboard/" : "/rsvp/"
                            let data: any = {
                                member: values.member,
                                timeslot: timeslot.id,
                                seat: selectedSeat?.id
                            }
                            if (values.member === null) {
                                data.dashboard_reservation = {full_name: values.full_name}
                            }
                            baseApi.post<Reservation>(url, data).then(resp => {
                                const memberData = members.find(m => m.id === values.member)
                                let member = memberData ? {
                                    id: memberData.id,
                                    external_id: memberData.external_id,
                                    full_name: memberData.full_name,
                                    phone: "",
                                    shoe_size: "",
                                } : {
                                    id: "",
                                    external_id: "",
                                    full_name: values.full_name,
                                    phone: values.phone,
                                    shoe_size: ""
                                }
                                dispatch(addReservation({
                                    ...resp.data,
                                    seat: selectedSeat?.label || null,
                                    member: member,
                                    email: memberData?.email ?? ""
                                }))
                                setAlertMessage("Reservación creada")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch(err => {
                                setAlertMessage(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        }

                    }}>
                    {({isSubmitting, setFieldValue, values}) => (
                        <FormikForm>
                            <Modal.Header>{reservation ? 'Editar' : 'Crear'} Reservación</Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div className="offset-lg-1 font-weight-bolder mb-5">
                                    {timeslot.studio_class_name} - {format(timeslot.start, "dd/MMM/yy H:mm")}
                                </div>
                                <FieldError name="member"/>
                                <div className="text-primary" hidden={!reservation}>
                                    <h6 hidden={!reservation?.member}>
                                        {reservation?.member?.external_id} - {reservation?.member?.full_name} {reservation?.member?.last_name}
                                        <div>{reservation?.email}</div>
                                    </h6>
                                    <h6 hidden={!reservation?.dashboard_reservation}>
                                        {reservation?.dashboard_reservation?.full_name}
                                    </h6>
                                </div>
                                <div hidden={reservation !== undefined} className="offset-lg-1 w-50 mb-2">
                                    <Select placeholder="Cliente"
                                            options={
                                                [{
                                                    value: null,
                                                    label: "N/A - Cliente sin cuenta"
                                                }, ...members.map((m) => ({
                                                    value: m.id,
                                                    label: `${m.external_id} - ${m.full_name} ${m.last_name ?? ''}`
                                                }))]
                                            }
                                            onInputChange={(text) => setSearchTerm(text)}
                                            noOptionsMessage={() => "Sin resultados"}
                                            isLoading={fetchingMembers} loadingMessage={() => "Cargando..."}
                                            onChange={(option) => setFieldValue("member", option?.value)}/>
                                </div>
                                <div hidden={values.member !== null}>
                                    <FormField label="Nombre" name="full_name"/>
                                </div>
                                <FieldError name="full_name"/>
                                {renderRoom()}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : `${reservation ? 'Guardar' : 'Crear'}`}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateReservationModal;
