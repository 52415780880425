import {RegistrationFeesActionTypes} from "../actions";
import {RegistrationFeeAction, RegistrationFeesState} from "../actions/registrationFees";

const initialState = {
    fetched: false,
    loading: true,
    fees: []
} as RegistrationFeesState

export const registrationFeesReducer = (state = initialState, action: RegistrationFeeAction) => {
    switch (action.type) {
        case RegistrationFeesActionTypes.FETCHING:
            return {...initialState, fetched: true}
        case RegistrationFeesActionTypes.FETCHED:
            return {loading: false, fees: action.payload, fetched: true}
        case RegistrationFeesActionTypes.ADD_REGISTRATION_FEE:
            return {...state, fees: [...state.fees, action.payload]}
        case RegistrationFeesActionTypes.REMOVE_REGISTRATION_FEE:
            return {...state, fees: state.fees.filter(f => f.id !== action.payload)}
        case RegistrationFeesActionTypes.UPDATE_REGISTRATION_FEE:
            return {...state, fees: state.fees.map(f => f.id === action.payload.id ? action.payload : f)}
        default:
            return state
    }
}