import React from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {WaitList} from "../../../pages/Reservations/WaitListTable";
import {useHistory} from "react-router-dom";

type WaitListTrProps = {
    waitListItem: WaitList
    onDeleteClicked: () => void
}

const WaitListTr = ({waitListItem, onDeleteClicked}: WaitListTrProps) => {
    const history = useHistory()

    return (
        <tr
            className={`font-size-sm align-middle text-center ${waitListItem.activated ? "bg-success" : waitListItem.cancelled ? "bg-secondary" : ""}`}
            key={waitListItem.id}>
            <td className=" text-uppercase"> {waitListItem.member.external_id}</td>

            <td className="align-middle text-left">
                <div className="font-weight-bold font-size-sm">{waitListItem.member.full_name} {waitListItem.member.last_name}</div>
            </td>

            <td>{waitListItem.member.shoe_size}</td>
            <td>
                <Button
                    disabled={waitListItem.cancelled}
                    className="btn btn-icon btn-light btn-xs btn-hover-danger mr-1"
                    onClick={() => {
                        onDeleteClicked()
                    }}>
                    <FontAwesomeIcon icon={faTrashAlt}/>
                </Button>
                <Button
                    onClick={() => history.push(`/users/${waitListItem.member!.id}`)}
                    className="btn btn-icon btn-light btn-xs btn-hover-primary">
                    <FontAwesomeIcon icon={faUser}/>
                </Button>
            </td>
        </tr>
    );
};

export default WaitListTr;
