import React, {useState} from 'react';
import {RegistrationFee, removeRegistrationFee} from "../../../actions/registrationFees";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfinity, faPen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import CreateEditRegistrationFee from "./CreateEditRegistrationFee";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import DeleteModal from "../../../components/modals/DeleteModal";

const RegistrationFees = () => {
    const dispatch = useDispatch()
    const registrationFees = useSelector<StoreState, RegistrationFee[]>(state => state.registrationFees.fees);
    const [selectedFee, setSelectedFee] = useState<RegistrationFee>();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <div>
            <CreateEditRegistrationFee show={showCreateModal} fee={selectedFee!} onHide={() => {
                setSelectedFee(undefined)
                setShowCreateModal(false)
            }}/>
            <DeleteModal url={`/registration-fees/${selectedFee?.id}/`} onSuccess={() => {
                dispatch(removeRegistrationFee(selectedFee?.id ?? ''))
            }} show={showDeleteModal} onHide={() => {
                setShowDeleteModal(false)
                setSelectedFee(undefined)
            }}>
                <p>¿Seguro que quieres eliminar la inscripción <strong> {selectedFee?.name} </strong>?</p>
            </DeleteModal>

            <div className="d-flex align-items-center mt-5 mb-5">
                <h5 className="pt-2">Inscripciones</h5>
                <button onClick={() => {
                    setShowCreateModal(true)
                }
                } className="btn btn-light-primary btn-pill ml-3">+ Nueva Inscripción
                </button>
            </div>
            <div className="row pb-5">

                <div hidden={registrationFees.length > 0}
                     className="text-center text-muted font-size-h2 w-100 mt-5">
                    0 inscripciones registradas
                </div>

                {registrationFees.map((fee) => {
                    return (
                        <div key={fee.id} className="col-6 col-md-3">
                            <div className="card card-custom shadow text-center pb-5 pt-2 mt-2 px-2">

                                <div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div>

                                            <Button
                                                onClick={() => {
                                                    setSelectedFee(fee)
                                                    setShowDeleteModal(true)
                                                }}
                                                className="btn btn-icon btn-light-secondary btn-hover-danger bg-white btn-xs">
                                                <FontAwesomeIcon icon={faTrashAlt}/>
                                            </Button>

                                        </div>
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    setSelectedFee(fee)
                                                    setShowCreateModal(true)
                                                }}
                                                className="btn btn-icon btn-light-secondary btn-xs bg-white">
                                                <FontAwesomeIcon icon={faPen}/>
                                            </Button>
                                        </div>
                                    </div>
                                    <p className="h2">{fee.name}</p>
                                    <p className="text-muted font-weight-light">{fee.days_duration > 10000 ?
                                        <FontAwesomeIcon icon={faInfinity}/> : `${fee.days_duration} dias`}</p>
                                    <p className="text-primary h6 p-0 m-0"
                                       style={{letterSpacing: ".1rem"}}>${fee.price}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default RegistrationFees;
