import React, {useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import {TimeSlotV2} from "../Schedules";
import {format} from "date-fns";
import yup from "../../utils/yup";
import baseApi from "../../apis/baseApi";
import {getApiErrorMessage} from "../../utils/apiErrors";
import FieldError from "../../components/form/FieldError";
import {useSelector} from "react-redux";
import {Reservation} from "./index";
import {CartMember} from "../Pos/Cart";
import Select from "react-select";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";

type CreateScheduleModalProps = {
    show: boolean,
    onHide: Function,
    timeslot: TimeSlotV2
}

const CreateWaitListModal = ({show, onHide, timeslot}: CreateScheduleModalProps) => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const [searchTerm, setSearchTerm] = useState("")
    const [fetchingMembers, setFetchingMembers] = useState(false)
    const [members, setMembers] = useState<CartMember[]>([])

    useEffect(() => {
        baseApi.get(
            `/members/search/?branch=${branch.id}`,
        ).then((response) => {
            setMembers(response.data.results);
            setFetchingMembers(false);
        }).catch();
    }, [branch])

    useEffect(() => {
        const searchFn = setTimeout(() => {
            if (searchTerm.length < 2) return
            setFetchingMembers(true)
            baseApi.get(
                `/members/search/?search=${searchTerm}&branch=${branch.id}`,
            ).then((response) => {
                setMembers(response.data.results);
                setFetchingMembers(false);
            }).catch();
        }, 800);
        return () => clearTimeout(searchFn);
    }, [searchTerm, branch]);


    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        member: yup.string().required(),
    })


    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{member: undefined}}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        const url = "/rsvp/waitlist/"
                        let data: any = {
                            member: values.member,
                            timeslot: timeslot.id,
                        }
                        baseApi.post<Reservation>(url, data).then(resp => {
                            setAlertMessage("Usuario agregado al WaitList")
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch(err => {
                            setAlertMessage(getApiErrorMessage(err))
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }}>
                    {({isSubmitting, setFieldValue, values}) => (
                        <FormikForm>
                            <Modal.Header>Agregar a WaitList</Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div className="offset-lg-1 font-weight-bolder mb-5">
                                    {timeslot.studio_class_name} - {format(timeslot.start, "dd/MMM/yy H:mm")}
                                </div>
                                <FieldError name="member"/>
                                <div className="offset-lg-1 w-50 mb-2">
                                    <Select placeholder="Cliente"
                                            options={
                                                members.map((m) => ({
                                                    value: m.id,
                                                    label: `${m.external_id} - ${m.full_name} ${m.last_name ?? ''}`
                                                }))
                                            }
                                            onInputChange={(text) => setSearchTerm(text)}
                                            noOptionsMessage={() => "Sin resultados"}
                                            isLoading={fetchingMembers} loadingMessage={() => "Cargando..."}
                                            onChange={(option) => setFieldValue("member", option?.value)}/>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : 'Crear'}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateWaitListModal;
