import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";
import FormField from "../../../components/form/FormField";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {StudioClassesState} from "../../../actions/studioClasses";
import baseApi from "../../../apis/baseApi";
import {BranchesState} from "../../../actions";
import FormInputGroup from "../../../components/form/FormInputGroup";
import {addPlan, PlanDateRestrictions, PlanPenalizations, StudioPlan} from "../../../actions/studioPlans";
import FormSelectAlt, {SelectOption} from "../../../components/form/FormSelectAlt";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import Checkbox from "../../../components/form/Checkbox";
import yup from '../../../utils/yup';
import FieldError from "../../../components/form/FieldError";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStripe} from "@fortawesome/free-brands-svg-icons";
import {addRegistrationFee, RegistrationFee, updateRegistrationFee} from "../../../actions/registrationFees";

type CreatePlanModalProps = {
    show: boolean,
    onHide: Function,
    fee?: RegistrationFee
}

const CreateEditRegistrationFee = ({show, onHide, fee}: CreatePlanModalProps) => {
    const dispatch = useDispatch()

    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("");

    // @ts-ignore

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required().min(3),
        days_duration: yup.number().required().min(1).max(32000),
        price: yup.number().required().min(0),
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        name: fee?.name,
                        price: fee?.price,
                        days_duration: fee?.days_duration,
                        public_visible: !fee ? true : fee.public_visible,
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        if (fee) {
                            baseApi.put<RegistrationFee>(`/registration-fees/${fee.id}/`, values).then((resp) => {
                                dispatch(updateRegistrationFee(resp.data))
                                setAlertMsg("Inscripción actualizado correctamente")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMsg(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        } else {
                            baseApi.post<RegistrationFee>("/registration-fees/", values).then((resp) => {
                                dispatch(addRegistrationFee(resp.data))
                                setAlertMsg("Inscripción creada correctamente")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMsg(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        }
                    }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>

                            <Modal.Header>
                                {fee ? "Editar " : "Crear "} Inscripción
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FieldError name="name"/>
                                <FormField label="Nombre" name="name"/>
                                <FieldError name="days_duration"/>
                                <FormInputGroup name="days_duration" label="Vigencia" groupText="días"
                                                placeholder="365"/>
                                <FieldError name="price"/>
                                <FormInputGroup name="price" label="Precio" groupText="$" prepend={true}/>
                                <div>
                                    <div className="offset-lg-1 d-inline-flex pb-4 align-items-center">
                                        <Field component={Checkbox} name="public_visible"
                                               label="Vender en app y web"/>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Confirmar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEditRegistrationFee;
