import React from 'react';

type ToolboxHeaderProps = {
    title: string
    action?: () => void
    action2?: () => void
}

const ToolboxHeader = ({title, action, action2}: ToolboxHeaderProps) => {
    return (
        <div className="card-header  border-bottom  px-1  border-0 d-flex flex-row justify-content-between">
            <div className="">
                <h3 className="card-label h3">{title}
                    <span className="text-muted pt-2 font-size-sm d-block"></span>
                </h3>
            </div>
            <div className="card-toolbar">
                {action ?
                    <button onClick={action} className="btn btn-light-primary mr-3 btn-pill">+ Crear </button>
                    : <React.Fragment/>
                }
                {action2 ?
                    <button onClick={action2} className="btn btn-light-primary mr-3 btn-pill">+ Crear </button>
                    : <React.Fragment/>
                }
            </div>
        </div>
    );
};

export default ToolboxHeader;
