import React, {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";

import FormField from "../../../components/form/FormField";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import baseApi from "../../../apis/baseApi";
import {FormikSelect} from "../../../components/form/FormikSelect";
import ModalSelect from "../../../components/form/ModalSelect";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {BranchesState} from "../../../actions";
import yup from "../../../utils/yup";
import FieldError from "../../../components/form/FieldError";
import {PushNotification} from "../index";
import {TimeSlotV2} from "../../Schedules";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {StudioClass} from "../../../actions/studioClasses";

type CreateCoachModalProps = {
    show: boolean
    onHide: Function
    notifications: PushNotification[]
    setNotifications: (notifications: PushNotification[]) => void
    timeslot?: TimeSlotV2 | null
}

const CreateNotificationModal = ({show, onHide, notifications, setNotifications, timeslot}: CreateCoachModalProps) => {
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const studioClasses = useSelector<StoreState, StudioClass[]>(state => state.studioClasses.classes)
    const currentBranchId = useSelector<StoreState, string>(state => state.currentBranch.id)

    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        title: yup.string().min(3).max(150).required(),
        message: yup.string().max(500).required(),
        branch: yup.string().required()
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{title: "", message: "", branch: currentBranchId}}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        let url = !timeslot ? "/notifications/" : "/notifications/timeslot/"
                        if (timeslot) {
                            // @ts-ignore
                            values.timeslot = timeslot.id
                        }
                        baseApi.post(url, values).then(resp => {
                            setNotifications([resp.data, ...notifications])
                            setAlertMessage("Notificación enviada")
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch(e => {
                            setAlertMessage("La notificación no pudo ser enviada." + getApiErrorMessage(e))
                            setSuccess(false)
                            setSubmitting(false)
                        })

                    }}>
                    {({isSubmitting}) => (
                        <FormikForm>
                            <Modal.Header>
                                Crear Notificación
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div hidden={branchesState.selectOptions.length === 1 || !(!timeslot)}>
                                    <ModalSelect label="Sucursal">
                                        <Field name="branch" options={branchesState.selectOptions}
                                               component={FormikSelect}/>
                                        <FieldError name="branch"/>
                                    </ModalSelect>
                                </div>
                                {!timeslot ? <React.Fragment/> :
                                    <div style={{color: studioClasses.find(c => c.id === timeslot?.studio_class)?.color ?? 'black'}} className="offset-lg-1 mb-5"
                                         hidden={!timeslot}>
                                        <h6>{timeslot?.studio_class_name}</h6>
                                        <h6>{format(timeslot!.start, "EEEE, dd 'de' MMMM", {locale: es})}</h6>
                                    </div>}
                                <FieldError name="title"/>
                                <FormField label="- Titulo" name="title"/>
                                <FieldError name="message"/>
                                <FormField component="textarea" label="- Mensaje" name="message"/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Confirmar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateNotificationModal;
