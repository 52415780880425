import React, {useEffect, useState} from 'react';
import {Button, Card, Table} from 'react-bootstrap';
import {Branch} from "../../actions";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";

import baseApi from "../../apis/baseApi";
import TableHeader from "../../components/table/TableHeader";
import TableFooter from "../../components/table/TableFooter";
import CreateMemberModal from './CreateMemberModal';
import MemberTr from '../../components/table/rows/MemberTr';
import EmptyTr from "../../components/table/rows/EmptyTr";
import MembersDownloadModal from "./MembersDownloadModal";
import {useHistory, useLocation} from "react-router-dom";

interface User {
    id: string,
    email: string,
    birthday?: string
    gender: string
    // last_name?: string
}

interface MemberPreferredBranch {
    id: string
    name: string
}

interface MemberCurrentPlan {
    id: string;
    name: string;
    expire_on: string;
}

export interface Member {
    id: string,
    external_id: string,
    full_name: string,
    last_name?: string;
    phone: string,
    preferred_branch: MemberPreferredBranch,
    current_credits: number,
    current_membership?: MemberCurrentPlan,
    credits_expiration?: string,
    user: User,
    created_on: string,
    emergency_contact_phone: string
    email?: string,
    img: string | null
    shoe_size: string
    birthday?: string
    id_number: string
    facebook_url: string
    instagram_username: string
    injuries: string
}

const Members = () => {
    const location = useLocation()
    const history = useHistory()

    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)

    const [page, setPage] = useState(1);
    const [membersList, setMembersList] = useState<Member[]>([])
    const [showAdd, setShowAdd] = useState(false)
    const [count, setCount] = useState(0)
    const [searchTerm, setSearchTerm] = useState<string>();
    const [showDownloadModal, setShowDownloadModal] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('page')) {
            setPage(parseInt(searchParams.get('page')!))
        }
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        let url = `/studios/members/?branch=${currentBranch.id}`
        if (searchParams.has('page')) {
            url += `&page=${searchParams.get('page')}`
        }
        baseApi.get(url).then((resp) => {
            setCount(resp.data.count)
            setMembersList(resp.data.results)
        }).catch(() => alert("Error obteniendo los usuarios"))
    }, [currentBranch])

    const handlePageChange = (page: number) => {
        setPage(page)
        history.replace({pathname: location.pathname, search: `?page=${page}`})
        let url = `/studios/members/?branch=${currentBranch.id}&page=${page}`
        if (searchTerm) {
            url += `&search=${searchTerm}`
        }
        baseApi.get(url).then((resp) => {
            setMembersList(resp.data.results)
            setCount(resp.data.count)
        }).catch(() => alert("Error obteniendo los usuarios"))
    }

    useEffect(() => {
        if (searchTerm === undefined) return
        if (!searchTerm) {
            handlePageChange(1)
            return
        }
        setPage(1)
        let timeout = setTimeout(() => {
            baseApi.get(`/studios/members/?search=${searchTerm}&branch=${currentBranch.id}&page=1`).then((resp) => {
                setMembersList(resp.data.results)
                setCount(resp.data.count)
            })
        }, 1200)
        return () => {
            clearTimeout(timeout)
        };
    }, [searchTerm, currentBranch]);


    return (
        <div className="on-clients">
            <CreateMemberModal show={showAdd} onHide={() => setShowAdd(false)} membersList={membersList}
                               setMembersList={setMembersList}/>
            <MembersDownloadModal show={showDownloadModal} onHide={() => {
                setShowDownloadModal(false)
            }}/>
            <div className="d-flex align-items-center justify-content-between mt-5 mb-8">
                <div className="">
                    <div className="d-flex align-items-center">
                        <i className="fi-rr-user mr-3"/>
                        <h2 className="text-dark">Clientes</h2>
                    </div>
                </div>
                <div className="d-inline-flex">
                    <div className="text-right mr-4">
                        <Button className="btn btn-pill btn-outline-secondary text-primary"
                                onClick={() => setShowDownloadModal(true)}>
                            <i className="fi-rr-download mr-1 text-primary"/>
                            Descargar
                        </Button>
                    </div>
                    <div className="text-right">
                        <Button className="btn btn-light-primary btn-pill" onClick={() => setShowAdd(true)}>
                            <i className="fi-rr-user-add mr-1"/>
                            Agregar Cliente
                        </Button>
                    </div>
                </div>
            </div>

            <div className="ml-auto w-md-400px  pb-2">
                <input className=" form-control form-control-sm" value={searchTerm}
                       onChange={(e) => setSearchTerm(e.target.value)} placeholder="Buscar..."/>
            </div>
            <Card className="card-custom px-5">

                <Table hover>
                    <TableHeader
                        headers={["ID", "NOMBRE", "TELÉFONO", "ESTADO", "CREDITOS", "VENCIMIENTO", "SUCURSAL", "ACCION"]}/>
                    <tbody>
                    {membersList.length === 0 ? <EmptyTr/> : membersList.map((m) => (
                        <MemberTr key={m.id} member={m}/>))}
                    </tbody>

                </Table>
                <TableFooter count={count} pageCount={membersList.length} pageSize={10}
                             onPageChange={handlePageChange} initialPage={page}/>
            </Card>
        </div>
    );
};

export default Members;
