import {RoomsActions, RoomsState} from "../actions/rooms";
import {RoomsActionTypes} from "../actions";

const initialState : RoomsState = {
    fetched: false,
    loading: true,
    error: "",
    rooms: []
}

export const roomsReducer = (state= initialState, action : RoomsActions) : RoomsState => {
    switch (action.type) {
        case RoomsActionTypes.FETCHING:
            return {...state, fetched: true, loading: true}
        case RoomsActionTypes.FETCHED:
            return {loading: false, fetched: true, error: "", rooms: action.payload}
        case RoomsActionTypes.ADD_ROOM:
            return {...state, rooms: [...state.rooms, action.payload]}
        case RoomsActionTypes.REMOVE_ROOM:
            return {...state, rooms: state.rooms.filter(c => c.id !== action.payload)}
        case RoomsActionTypes.UPDATE_ROOM:
            return {...state, rooms: state.rooms.map((c) => c.id === action.payload.id ? action.payload : c)}
        default:
            return state
    }
}