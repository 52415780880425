import React from 'react';
import TabNavigator from "../../../components/TabNavigator";
import StudioClassesListView from "./StudioClassesListView";
import StudioClassCategoriesListView from "./StudioClassCategoriesListView";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Studio} from "../../../actions/studio";

const StudioClasses = () => {
    const studio = useSelector<StoreState, Studio>( state => state.studio)
    const classCategoriesBetaStudios = [
        "a9d64f87-5d5a-4f59-98a2-56d70e63d27e",
        "31ab5015-8064-4a03-b67c-5a84ab3b12a0",
        "9a3c10bd-cf10-48f9-a71d-3ee284fdc67c",
    ]

    return <div className="rounded px-2 pt-2" style={{background: "white",}}>
        <TabNavigator tabLabels={["Clases", "Categorías"]} panels={
            [
                <StudioClassesListView />,
                classCategoriesBetaStudios.includes(studio.id) ?  <StudioClassCategoriesListView /> : <div/>,
            ]
        }/>
    </div>
};

export default StudioClasses;
