import React from 'react';
import PlansGridView from "./PlansGridView";
import TabNavigator from "../../../components/TabNavigator";
import PlansArchivedGridView from "./PlansArchivedGridView";

const Plans = () => {
    return <div className="rounded shadow-sm px-2 pt-2" style={{background: "white",}}>
        <TabNavigator
            tabLabels={[
                "Paquetes y Membresías",
                // "Categorías",
                "Archivado"
            ]}
            panels={
                [
                    <PlansGridView/>,
                    // <PlanCategories/>,
                    <PlansArchivedGridView/>,
                ]
            }/>
    </div>
};

export default Plans;
