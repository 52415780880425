import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import yup from "../../../utils/yup";
import FieldError from "../../../components/form/FieldError";
import FormField from "../../../components/form/FormField";
import Checkbox from "../../../components/form/Checkbox";
import FormInputGroup from "../../../components/form/FormInputGroup";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import baseApi from "../../../apis/baseApi";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {Coupon} from "./index";
import {FormikSelect} from "../../../components/form/FormikSelect";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {StudioPlan} from "../../../actions/studioPlans";
import KeenToggle from "../../../components/misc/KeenToggle";

type Props = {
    show: boolean,
    onHide: Function,
    onCreate: (coupon: Coupon) => void
}


const CreateEditCouponModal = ({show, onHide, onCreate}: Props) => {
    const plans = useSelector<StoreState, StudioPlan[]>(state => state.studioPlans.plans)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };


    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const validationSchema = yup.object().shape({
        title: yup.string().min(3).max(30).required(),
        redeem_code: yup.string().min(6).max(12).required(),
        discount_amount: yup.number().positive().required(),
        is_percent: yup.boolean(),
        activate_on: yup.date().required(),
        expire_on: yup.date().required(),
        restrictions: yup.object().nullable().shape({
            max_redeems_total: yup.number().positive().integer().nullable().transform((_, value) => {
                return value === "" ? null : Number(value);
            }),
            max_redeems_per_user: yup.number().positive().integer().nullable().transform((_, value) => {
                return value === "" ? null : Number(value);
            }),
            min_purchase_total: yup.number().positive().integer().nullable().transform((_, value) => {
                return value === "" ? null : Number(value);
            }),
            valid_plans: yup.array(),
            allow_all_plans: yup.boolean(),
        })
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        title: "",
                        redeem_code: "",
                        discount_amount: 10,
                        is_percent: true,
                        activate_on: null,
                        expire_on: null,
                        restrictions: null,
                    }} onSubmit={(values: any, {setSubmitting}) => {
                    let data = validationSchema.cast(values)
                    if (data.restrictions?.valid_plans && data.restrictions.valid_plans.length > 0) {
                        data.restrictions.allow_all_plans = false;
                    }
                    setSuccess(null)
                    baseApi.post<Coupon>("/coupons/", data).then(resp => {
                            setAlertMessage("Cupón creado correctamente")
                            setSuccess(true)
                            onCreate(resp.data)
                        }
                    ).catch(err => {
                        setAlertMessage(getApiErrorMessage(err))
                        setSuccess(false)
                        setSubmitting(false)
                    })
                    console.log(values)
                }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>

                            <Modal.Header>
                                Crear cupón
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FieldError name="title"/>
                                <FormField label="Titulo" name="title"/>
                                <FieldError name="redeem_code"/>
                                <FormField label="Código de descuento" name="redeem_code"/>
                                <div className="offset-lg-1">
                                    <Field component={Checkbox} type="checkbox" name="is_percent"
                                           label="Usar porcentaje"/>
                                </div>
                                <FieldError name="discount_amount"/>
                                <FormInputGroup name="discount_amount" label="Monto descuento"
                                                groupText={values.is_percent ? "%" : "$"} prepend={!values.is_percent}/>
                                <FieldError name="activate_on"/>
                                <div className="d-flex flex-row offset-lg-1">
                                    <p className="mr-10">Vigente desde</p>
                                    <div className="customDatePickerWidth " style={{width: "220px"}}>
                                        <ReactDatePicker
                                            showTimeSelect
                                            timeCaption="Hora"
                                            dateFormat="dd/MMM/yyyy HH:mm"
                                            locale={es}
                                            selected={values.activate_on}
                                            minDate={new Date()}
                                            onChange={date => {
                                                setFieldValue("activate_on", date)
                                            }}
                                            customInput={<input className="form-control"/>}
                                        />
                                    </div>
                                </div>
                                <FieldError name="expire_on"/>
                                <div className="d-flex flex-row offset-lg-1 my-4">
                                    <p className="mr-10">Vigente hasta</p>
                                    <div className="customDatePickerWidth " style={{width: "220px"}}>
                                        <ReactDatePicker
                                            showTimeSelect
                                            timeCaption="Hora"
                                            dateFormat="dd/MMM/yyyy HH:mm"
                                            locale={es}
                                            selected={values.expire_on}
                                            minDate={values.activate_on}
                                            onChange={date => {
                                                setFieldValue("expire_on", date)
                                            }}
                                            customInput={<input className="form-control"/>}
                                        />
                                    </div>
                                </div>

                                {/*<div className="offset-lg-1">*/}
                                {/*    <Field component={Checkbox} type="checkbox" name="is_percent"*/}
                                {/*           label="Descuento global"/>*/}
                                {/*    <small className="text-muted">Todos pueden utilizar este cupón.</small>*/}
                                {/*</div>*/}
                                <div className="offset-lg-1">
                                    <h4>Condiciones <small>(Opcional)</small></h4>
                                    <div className="form-group row">
                                        <label className="col-lg-4  col-form-label ">
                                            - Limite de usos total
                                        </label>
                                        <div className="col-lg-3 ml-n20">
                                            <FieldError name="restrictions.max_redeems_total"/>
                                            <div className="input-group ">
                                                <Field name="restrictions.max_redeems_total" className="form-control"
                                                       placeholder=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-4  col-form-label ">
                                            - Limite de usos
                                        </label>
                                        <div className="col-lg-3 ml-n20">
                                            <FieldError name="restrictions.max_redeems_per_user"/>
                                            <div className="input-group ">
                                                <Field name="restrictions.max_redeems_per_user"
                                                       className="form-control"
                                                       placeholder=""/>
                                                <div className="input-group-append font-size-xs"><span
                                                    className="input-group-text text-muted font-size-sm">/cliente</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-4 col-form-label">
                                            - Monto mínimo de <br/> compra
                                        </label>
                                        <div className="col-lg-3 ml-n20">
                                            <FieldError name="restrictions.min_purchase_total"/>
                                            <div className="input-group ">
                                                <Field name="restrictions.min_purchase_total" className="form-control"
                                                       placeholder=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-5"/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-4 col-form-label">
                                            - Aplica solo en <br/>estos planes
                                        </label>
                                        <div className="col-lg-7 ml-n20">
                                            <Field
                                                name="restrictions.valid_plans"
                                                placeholder="Opcional"
                                                isDisabled={false}
                                                isMulti={true}
                                                component={FormikSelect}
                                                options={plans.map((p) => ({value: p.id, label: p.name}))
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group align-items-center d-inline-flex">
                                            <div className="" >- El cliente debe contar con una membresía activa</div>
                                            <div className="">
                                                <KeenToggle checked={values.restrictions?.member_has_active_membership}
                                                            onChange={(e) => setFieldValue("restrictions.member_has_active_membership", e.target.checked)}/>
                                            </div>
                                    </div>
                                    <div className="form-group row mb-0 pb-0">
                                        <label className="col-lg-4 col-form-label">
                                            - Membresías activas
                                        </label>
                                        <div className="col-lg-7 ml-n20">
                                            <Field
                                                name="restrictions.active_membership_plans"
                                                placeholder="Opcional"
                                                isDisabled={false}
                                                isMulti={true}
                                                component={FormikSelect}
                                                options={plans.map((p) => ({value: p.id, label: p.name}))
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="text-muted">* El cliente debe contar con una membresía activa con alguno de estos planes.</div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEditCouponModal;
