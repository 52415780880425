import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import React, {useEffect, useState} from "react";
import DeleteModal from "../../components/modals/DeleteModal";
import CreateEditCoachModal from "./CreateEditCoachModal";
import {Button, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashAlt, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {User} from "../../actions/user";
import {CoachesState, fetchCoaches, removeCoach} from "../../actions/coaches";

export interface Coach {
    id: string,
    user: User | null
    full_name: string
    img: string | null
    payment_preferences: CoachPaymentPreferences | null
    payment_preferences_branch: CoachPaymentPreferences[]
}

export interface CoachPaymentPreferences {
    id: string;
    branch: string | null;
    payment_type: number;
    count_cancelled_rsvp_outside_limit: boolean;
    ranges: CoachPaymentRange[]
}

export interface CoachPaymentRange {
    id: string;
    range_start: number;
    range_end: number
    amount: number
}

const Coaches = () => {
    const dispatch = useDispatch()
    const coachesState = useSelector<StoreState, CoachesState>(state => state.coaches)
    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [selectedCoach, setSelectedCoach] = useState<Coach>()

    useEffect(() => {
        if (coachesState.loading) {
            dispatch(fetchCoaches())
        }
    }, [coachesState, dispatch]);

    if (coachesState.loading) {
        return <div>Cargando...</div>
    }
    return (
        <div className="p-4">
            <DeleteModal url={`/coaches/update/${selectedCoach?.id}/`} onSuccess={() => {
                dispatch(removeCoach(selectedCoach!.id))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedCoach(undefined)
            }}>
                <p>¿Estas seguro de querer eliminar <strong>{selectedCoach?.full_name}</strong>?</p>
            </DeleteModal>
            <CreateEditCoachModal
                coach={selectedCoach}
                show={showCreate}
                onSave={setSelectedCoach}
                onHide={() => {
                    setShowCreate(false)
                    setSelectedCoach(undefined)
                }}/>

            <div className="d-flex align-items-center mb-15">
                <i className="fi-rr-calendar mr-3"></i>
                <h2 className="text-dark">Coaches</h2>
                <button onClick={() => setShowCreate(true)}
                        className="d-inline ml-5 btn btn-light-primary  btn-pill btn-lg bg-primary-o-50 text-primary border-0 btn-hover-primary font-weight-bold">+
                    Nuevo Coach
                </button>
            </div>
            <Row>
                <div hidden={coachesState.coaches.length > 0} className="text-center text-muted font-size-h2 w-100 mt-15">
                    0 coaches registrados
                </div>
                {coachesState.coaches.map((c) => {
                    return <div key={c.id} className="col-6 col-md-3 col-xxl-2 mb-2">
                        <div className="card card-custom overlay text-center py-5 shadow pt-8">
                            <div className="symbol symbol-60 symbol-lg-85 symbol-circle mx-auto pb-5">
                                {c.img ?
                                    <img alt="Pic"
                                         src={c.img}/>
                                    : <div className="text-muted">
                                        <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "85px"}}/>
                                    </div>
                                }
                            </div>
                            <div className="overlay-layer align-items-start justify-content-between pt-1 pr-1">
                                <Button
                                    onClick={() => {
                                        setSelectedCoach(c)
                                        setShowDelete(true)
                                    }}
                                    bsPrefix="btn btn-clean btn-icon btn-hover-danger btn-sm ml-1" size="sm">
                                    <FontAwesomeIcon icon={faTrashAlt} size="lg"/>
                                </Button>
                                <Button onClick={() => {
                                    setSelectedCoach(c)
                                    setShowCreate(true)
                                }} bsPrefix="btn btn-clean btn-icon btn-sm mr-1" size="sm">
                                    <FontAwesomeIcon icon={faEdit} size="lg"/>
                                </Button>
                            </div>
                            <div className="font-weight-bold font-size-h6">{c.full_name}</div>
                        </div>
                    </div>
                })}
            </Row>
        </div>
    );
};

export default Coaches;

