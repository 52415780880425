import * as yup from "yup"

yup.setLocale({
    mixed: {
        default: 'Invalido',
        required: 'Campo requerido',
    },
    number: {
        min: 'El número debe ser mayor/igual a ${min}',
        max: 'El número debe ser menor/igual a ${max}',
        positive: 'El numero debe ser positivo',
        integer: 'El numero debe ser entero'
    },
    string: {
        min: 'El texto debe incluir al menos ${min} caracteres.',
        max: 'El texto no puede ser mayor a ${max} caracteres.',
    }
});

export default yup