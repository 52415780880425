import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Button} from 'react-bootstrap';
import {TooltipRenderProps,} from "react-joyride";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";


const OnboardingTooltip = ({
                               continuous,
                               index,
                               step,
                               backProps,
                               closeProps,
                               primaryProps,
                               tooltipProps,
                           }: TooltipRenderProps) => {
    return (
        <div style={{width: "500px", borderRadius: "20px"}} className="bg-light py-15 px-5" {...tooltipProps}>
            <h6>{step.title}</h6>
            <div>{step.content}</div>
            <div className="text-center pt-3">
                {continuous && (
                    <Button className="btn btn-pill btn-lg align-self-center" {...primaryProps}>
                        {index === 7 ? "Explorar" : "Siguiente"}
                        {index === 7 ? <React.Fragment/> :
                            <FontAwesomeIcon className="ml-2" icon={faChevronRight} style={{fontSize: "10px"}}/>}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default OnboardingTooltip;
