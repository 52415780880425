import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import FormField from "../../components/form/FormField";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Branch, BranchesState} from "../../actions";
import baseApi from "../../apis/baseApi";
import {getApiErrorMessage} from "../../utils/apiErrors";

type Props = {
    show: boolean,
    onHide: Function,
    positive: boolean
}

const CreateCashExpenseModal = ({show, onHide, positive}: Props) => {
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik initialValues={{amount: 0, concept: ""}} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    if (!positive && values.amount > 0) {
                        values.amount  = values.amount * -1
                    }
                    baseApi.post("cash-register/movement/", {...values, branch: currentBranch.id}).then(resp => {
                        setAlertMessage("Movimiento registrado.")
                        setSuccess(true)
                        setSubmitting(false)
                    }).catch(err => {
                        setAlertMessage(getApiErrorMessage(err))
                        setSuccess(false)
                        setSubmitting(false)
                    })
                }}>
                    {({isSubmitting}) => (
                        <FormikForm>
                            <Modal.Header className={positive ? "text-success" : "text-danger"}>
                                Crear {positive ? "ingreso" : "egreso"} de efectivo
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FormField  label="Cantidad" name="amount" />
                                <FormField label="Concepto" name="concept" />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Crear"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateCashExpenseModal;
