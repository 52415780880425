import React from 'react';

const LoadingTr = () => {
    return (
        <tr className="text-center w-100 text-muted font-size-h2-xl font-weight-bold">
            <td colSpan={7}>Cargando...</td>
        </tr>
    );
};

export default LoadingTr;
