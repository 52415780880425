import React from 'react';

interface Props {
    height?: number
}

const Separator = ({height=8}: Props) => {
    return (
        <div className={`separator separator-solid my-${height}`} />
    );
};

export default Separator;
