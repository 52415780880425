import React from 'react';
import Select, {ActionMeta, ValueType} from "react-select";

export interface SelectOption {
    label: string;
    value: any
}

interface FormSelectAltProps {
    label: string;
    placeholder: string;
    value: any
    options: SelectOption[];
    onChange: (value: ValueType<any, any>, actionMeta: ActionMeta<any>) => void;
    comment?: string
    isMulti?: boolean
    offset?: string
    classNames?: string
    isDisabled?: boolean
}

const FormSelectAlt = ({value, label, placeholder, options, onChange, comment, isMulti=false, offset="3", classNames="", isDisabled=false}: FormSelectAltProps) => {
    return (
        <div className={`form-group row ${classNames}`}>
            <label className={`col-lg-4 offset-lg-${offset} col-form-label`}>
                - {label}
            </label>
            <div className="col-lg-4">
                <Select isDisabled={isDisabled} value={value} placeholder={placeholder} options={options}
                        onChange={onChange} isMulti={isMulti}/>
                <span className="form-text text-muted">
                    {comment}
                </span>
            </div>
            <div className="col-lg-4" />
        </div>
    );
};

export default FormSelectAlt;
