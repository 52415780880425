import React, {useEffect, useState} from "react";
import {Formik, Form as FormikForm, Field} from "formik";
import {Button, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {AuthState, superLogin} from "../actions";
import {StoreState} from "../reducers";
import loginApi from "../apis/loginApi";
import {Studio} from "../actions/studio";
import baseApi from "../apis/baseApi";
import Select from "react-select";
import {SelectOption} from "../components/form/FormSelectAlt";

// ONLY USED FOR STAFF LOGIN
const XLogin = () => {
    const dispatch = useDispatch()
    const authState = useSelector<StoreState, AuthState>(state => state.auth)
    const [accessToken, setAccessToken] = useState<string>();
    const [studios, setStudios] = useState<Studio[]>();
    const [selectedStudio, setSelectedStudio] = useState<SelectOption>();

    useEffect(() => {
        if (accessToken) {
            baseApi.get("/studios/list/").then(resp => {
                setStudios(resp.data)
            })
        }
    }, [accessToken]);


    if (studios) {
        return <div>
            <div className="row align-items-center">
                <div className="col-12 ">
                    <div className="row justify-content-center">
                        <div
                            className="col-11 col-md-6 col-lg-5 col-xl-4 col-xxl-3 py-30 rounded-lg px-lg-0 kaglass w-100 ml-8 ml-sm-0">
                            <div className="text-center p-5">
                                <div className="mb-5">Seleccionar Estudio</div>
                                <Select value={selectedStudio}
                                        options={studios?.map(s => ({value: s.id, label: s.name}))} placeholder="Studio"
                                        onChange={o => setSelectedStudio(o!)}/>
                                <Button disabled={authState.loading || !setSelectedStudio}
                                        className="btn btn-dark btn-pill btn-lg mt-5"
                                        onClick={() => {
                                            dispatch(superLogin(selectedStudio!.value))
                                        }}>
                                    Acceder
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return <div className="h-100 w-100">
        <div className="row align-items-center">
            <div className="col-12 ">
                <div className="row justify-content-center">
                    <div
                        className="col-11 col-md-6 col-lg-5 col-xl-4 col-xxl-3 py-30 rounded-lg px-lg-0 kaglass w-100 ml-8 ml-sm-0">
                        <h3 className=" ml-12 mb-15">Iniciar Sesión</h3>
                        <p className="text-danger text-center">{authState.error}</p>
                        <Formik
                            initialValues={{username: "", password: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                loginApi.post("/auth/jwt/create/", values).then((response) => {
                                    setAccessToken(response.data.access)
                                    localStorage.setItem("access", response.data.access)
                                }).catch((error) => {
                                    setSubmitting(false)
                                })
                            }}>
                            {({isSubmitting}) => (

                                <FormikForm className="text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Form.Group className="w-75 w-md-75 w-lg-50 ">
                                            <label className="">Correo Electrónico</label>
                                            <Field name="username" className="form-control py-6 py-sm-0"/>
                                        </Form.Group>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Form.Group className="w-75 w-md-75 w-lg-50 ">
                                            <label className="">Contraseña</label>
                                            <Field type="password" name="password"
                                                   className="form-control py-6 py-sm-0"/>
                                        </Form.Group>
                                    </div>

                                    <div className="text-center">
                                        <Button disabled={authState.loading || isSubmitting}
                                                className="btn btn-dark btn-pill btn-lg"
                                                type="submit">
                                            Iniciar Sesión
                                        </Button>
                                    </div>
                                    <br/>
                                </FormikForm>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default XLogin;
