import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import CreateEmailModal from "./CreateEmailModal";
import baseApi from "../../../apis/baseApi";
import {Table} from "react-bootstrap";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {formatDate} from "../../../utils/dateFormatters";

const EmailsView = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [showCreate, setShowCreate] = useState(false);
    const [emails, setEmails] = useState<StudioEmail[]>([]);

    useEffect(() => {
        baseApi.get(`/emails/?branch=${branch.id}`).then(resp => {
            setEmails(resp.data.results)
        })
    }, []);

    return (
        <div className="h-100 p-4">
            <CreateEmailModal
                show={showCreate}
                onHide={() => setShowCreate(false)}
                emails={emails}
                setEmails={setEmails}/>
            <div className="d-flex align-items-center mb-15">
                <i className="fi-rr-envelope mr-3"/>
                <h2 className="text-dark">Notificaciones E-Mail</h2>
                <button onClick={() => setShowCreate(true)}
                        className="d-none d-sm-inline ml-5 btn btn-light-primary  btn-pill btn-lg bg-primary-o-50 text-primary border-0 btn-hover-primary font-weight-bold">+
                    Nuevo E-Mail
                </button>

            </div>
            <div className="w-50">
                <div className=' font-size-h6 mb-3 ml-3 text-muted'>Historial de E-Mails</div>
                <Table>
                    <tbody>
                    {emails.length === 0 ? <EmptyTr/> : emails.map(n => {
                        return <tr key={n.created_on} className="">
                            <div className='pl-3 mb-5 shadow px-4 rounded-xl py-4 bg-white'>

                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div className='d-flex text-left'>
                                            <i className="fi-rr-envelope mr-3"/>
                                            <div>
                                                E-Mails enviados: {n.recipients_count}
                                            </div>

                                        </div>
                                    </div>

                                    <div className='text-muted font-size-xs'>{formatDate(n.created_on)}</div>
                                </div>
                                <div className=''>
                                    <div className='font-size-h6 font-weight-bolder'>{n.title}</div>
                                    <div style={{whiteSpace: "pre-line"}} className='font-size-sm'>{n.text}</div>
                                </div>
                                <div className="">
                                    <div className="symbol symbol-60 symbol-lg-85 mx-auto mt-2">
                                        {n.bottom_image ? <img alt="Pic" src={n.bottom_image}/> : <React.Fragment/>}
                                    </div>
                                </div>

                            </div>
                        </tr>
                    })}

                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default EmailsView;

export interface StudioEmail {
    id: number
    recipient_rules: StudioEmailRecipientRules | null
    title: string
    text: string
    email_type: number
    bottom_image: string | null
    recipients_count: number
    created_on: string
    studio: string
    branch: string
    created_by: string
}

export interface StudioEmailRecipientRules {
    id: number
    is_active: boolean
    member_since: string | null
    inactive_since: string | null
    has_active_plans: string[]
}
