import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import {format, startOfMonth} from "date-fns";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {Button} from "react-bootstrap";
import ReservationsRanking from "./ReservationsRanking";
import PurchasesRanking from "./PurchasesRanking";

const MemberRankingsReport = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [rangeStart, setRangeStart] = useState<Date>(startOfMonth(new Date()));
    const [rangeEnd, setRangeEnd] = useState<Date | null>(new Date());

    const [activeTab, setActiveTab] = useState(0);

    return (
        <div>
            <h1 className="pt-10">🏆 Ranking clientes</h1>
            <div className='mb-12 text-dark-50'>Conoce tus clientes con más asistencias, ordenado de mayor a menor.
            </div>

            <div className="d-sm-flex align-items-center">
                <div className='col-11 col-lg-9 col-xl-7 col-xxl-5 ml-n3'>
                    <div className='font-size-h5'>Selecciona un rango de fechas:</div>
                    <ReactDatePicker
                        selectsRange
                        selected={rangeStart}
                        locale={es}
                        value={`${format(rangeStart, "dd MMMM yyyy", {locale: es})}  al  ${rangeEnd === null ? '' : format(rangeEnd, "dd MMMM yyyy", {locale: es})}`}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        shouldCloseOnSelect={rangeEnd === null}
                        maxDate={new Date()}
                        onChange={(dates) => {
                            if (dates) {
                                const [start, end] = dates as Date[]
                                setRangeStart(start)
                                setRangeEnd(end)

                            }
                        }}
                        customInput={<input className="form-control font-size-h4 text-primary"
                                            style={{cursor: "pointer"}}/>}
                    />
                </div>
            </div>
            <div className="d-flex d-flex-row align-items-start my-5">
                <Button variant={activeTab === 0 ? "primary" : "outline-primary"} size="sm" className="mr-1"
                        onClick={() => {
                            setActiveTab(0)
                        }}>Ranking reservas</Button>
                <Button variant={activeTab === 1 ? "primary" : "outline-secondary"} size="sm" onClick={() => {
                    setActiveTab(1)
                }}>Ranking membresías</Button>
            </div>
            <div hidden={activeTab !== 0}>
                <ReservationsRanking active={activeTab === 0} rangeStart={rangeStart} rangeEnd={rangeEnd} branch={branch} />
            </div>
            <div hidden={activeTab !== 1}>
                <PurchasesRanking active={activeTab === 1} rangeStart={rangeStart} rangeEnd={rangeEnd} branch={branch} />
            </div>

            <div className="py-20"/>
        </div>
    );
};

export default MemberRankingsReport;