import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";
import {useDispatch, useSelector} from "react-redux";

import FormField from "../../../components/form/FormField";
import baseApi from "../../../apis/baseApi";
import {StudioClassCategory,} from "../../../actions/studioClasses";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {StoreState} from "../../../reducers";
import yup from "../../../utils/yup";
import {Branch} from "../../../actions";
import FieldError from "../../../components/form/FieldError";
import {addClassCategory, updatedClassCategory} from "../../../actions/studio";

type CreateEditClassModalProps = {
    show: boolean
    onHide: Function
    studioClassCategory?: StudioClassCategory
}

const CreateEditClassModal = ({show, onHide, studioClassCategory}: CreateEditClassModalProps) => {
    const dispatch = useDispatch()
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    // const categories = useSelector<StoreState, StudioClassCategory[]>(state => state.studio.studio_class_categories)

    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("")

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };


    const validationSchema = yup.object().shape({
        name: yup.string().required().min(3),
    })

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    return (
        <React.Fragment>
            <Modal show={show} onHide={handleClose} size="lg">
                <Formik enableReinitialize={true} initialValues={{
                    name: studioClassCategory?.name,
                }}
                        validationSchema={validationSchema}
                        onSubmit={(values: any, {setSubmitting}) => {
                            setSuccess(null)
                            console.log(values)
                            if (studioClassCategory) {
                                baseApi.patch<StudioClassCategory>(`/studios/classes/categories/${studioClassCategory.id}/`, values).then((resp) => {
                                    dispatch(updatedClassCategory(resp.data))
                                    setAlertMsg("Categoría actualizada correctamente.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            } else {
                                baseApi.post<StudioClassCategory>("/studios/classes/categories/", values).then((resp) => {
                                    dispatch(addClassCategory(resp.data))
                                    setAlertMsg("Categoría creada correctamente.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }
                        }}>
                    {({isSubmitting, setFieldValue, values}) => (
                        <FormikForm>
                            <Modal.Header>
                                {studioClassCategory ? "Editar" : "Crear"} Categoría
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FormField label="Nombre" name="name"/>
                                <FieldError name="name"/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : studioClassCategory ? "Confirmar" : "Crear"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEditClassModal;
