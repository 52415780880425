import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { isBefore, parseISO, isSameMonth, isSameDay } from "date-fns"

import { Member } from "../../../pages/Members";
import TablePill from "../LabelPill";
import { formatDate } from "../../../utils/dateFormatters";
import defaultUser from "../../../assets/img/default-user.jpg"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";

type MemberTrProps = {
    member: Member
}

const MemberTr = ({ member }: MemberTrProps) => {
    const currentBranchId = useSelector<StoreState>(state => state.currentBranch.id)
    const today = new Date()
    const isBirthdayToday = member.birthday ? parseISO(member.birthday).getDate() === today.getDate() && parseISO(member.birthday).getMonth() === today.getMonth() : false
    const birthdayTooltip = (props: any) => (
        <Tooltip id="birthdayTT" {...props}>
            Hoy es el cumpleaños de {member.full_name}.
        </Tooltip>
    );
    return (
        <tr className="font-size-sm">

            <td className="align-middle text-center px-1">
                <Link className='text-dark' to={{
                    pathname: `/users/${member.id}`,
                    state: { member: member }
                }}>
                    {member.external_id}
                </Link>
            </td>
            <td className="align-middle">
                <Link className='text-dark' to={{
                    pathname: `/users/${member.id}`,
                    state: { member: member }
                }}>
                    <div className="d-flex flex-row">
                        <div className="symbol symbol-20 symbol-lg-30 symbol-xxl-35 symbol-circle symbol-inline">
                            <img alt="user" src={member.img || defaultUser} />
                        </div>
                        <div className="ml-2">
                            <div className="font-weight-bold font-size-lg">{member.full_name} {member.last_name ?? ''}
                                <OverlayTrigger placement="bottom" overlay={birthdayTooltip} delay={{ show: 250, hide: 250 }}>
                                    <i hidden={!isBirthdayToday} className="text-success ml-2">
                                        <FontAwesomeIcon icon={faBirthdayCake} />
                                    </i>
                                </OverlayTrigger>
                            </div>
                            <div className="font-size-sm text-muted">{member.user.email}</div>
                        </div>
                    </div>
                </Link>
            </td>
            <td className="align-middle">
                <Link className='text-dark' to={{
                    pathname: `/users/${member.id}`,
                    state: { member: member }
                }}>
                    {member.phone}
                </Link>
            </td>
            <td className="align-middle text-center">
                <Link className='text-dark' to={{
                    pathname: `/users/${member.id}`,
                    state: { member: member }
                }}>
                    {member.current_credits > 0 ? <TablePill text="Activo" /> : member.current_membership ?
                        isBefore(new Date(), parseISO(member.current_membership.expire_on)) ?
                            <TablePill text="Activo" /> : <TablePill text="Vencido" variant="danger" />
                        : <TablePill text="Inactivo" variant="danger" />
                    }
                </Link>
            </td>
            <td className="align-middle text-center">
                <Link className='text-dark' to={{
                    pathname: `/users/${member.id}`,
                    state: { member: member }
                }}>
                    {member.current_credits}
                </Link>
            </td>
            <td className="align-middle text-center">
                <Link className='text-dark' to={{
                    pathname: `/users/${member.id}`,
                    state: { member: member }
                }}>
                    {formatDate(member.current_membership?.expire_on || member.credits_expiration)}
                </Link>
            </td>
            <td className="align-middle text-center">
                <Link className='text-dark' to={{
                    pathname: `/users/${member.id}`,
                    state: { member: member }
                }}>
                    <span className={member.preferred_branch.id === currentBranchId ? "" : "label label-inline label-warning"}>
                        {member.preferred_branch.name}
                    </span>
                </Link>
            </td>
            <td className="align-middle text-center px-1">
                <Link to={{
                    pathname: `/users/${member.id}`,
                    state: { member: member }
                }}>

                    <button className="btn btn-icon btn-light btn-xs btn-hover-primary mr-1" data-toggle="tooltip"
                        title=""
                        data-original-title="Ver detalles de cliente">
                        <FontAwesomeIcon icon={faEllipsisH} size="xs" />
                    </button>
                </Link>
                {/*<div className="dropdown dropdown-inline">*/}

                {/*    <button type="button" className="btn btn-icon btn-light btn-xs btn-hover-primary"*/}
                {/*            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                {/*        <FontAwesomeIcon icon={faDollarSign} size="xs"/>*/}
                {/*    </button>*/}
                {/*    <div className="dropdown-menu">*/}
                {/*        ...*/}
                {/*    </div>*/}
                {/*</div>*/}
            </td>
        </tr>
    );
};

export default MemberTr;
