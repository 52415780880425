import React, {ChangeEvent} from 'react';

type KeenToggleProps = {
    checked: boolean
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const KeenToggle = ({checked, onChange}: KeenToggleProps) => {
    return (
        <span className="switch switch-sm switch-icon m-4">
			<label>
				<input type="checkbox" checked={checked} onChange={onChange}/>
				<span></span>
			</label>
        </span>
    );
};

export default KeenToggle;
