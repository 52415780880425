import React from 'react';
import {Field} from 'formik';

type FormFieldProps = {
    name: string
    type?: string
    disabled?: boolean
}

const MiniFormField = ({name, type = "text", disabled=false}: FormFieldProps) => {
    return (
        <div className="col-auto ml-n4">
            <Field disabled={disabled} name={name} className="form-control w-50px form-control-sm text-center" type={type} />
        </div>
    );
};

export default MiniFormField;
