import React from 'react';
import TableHeader from "../../components/table/TableHeader";
import ReservationTr from "../../components/table/rows/ReservationTr";
import {Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {TimeslotReservationsState} from "../../actions/timeslotReservations";
import TableLoadingTr from "../../components/table/rows/TableLoadingTr";
import EmptyTr from "../../components/table/rows/EmptyTr";
import {Reservation} from "./index";
import {Room} from "../Toolbox/Rooms";

interface Props {
    room?: Room
    onEditSpotClick: (rsvp: Reservation) => void
}

const ReservationsTable = ({room, onEditSpotClick} : Props) => {
    const rsvpState = useSelector<StoreState, TimeslotReservationsState>(state => state.timeSlotReservations)
    const editDisabled = useSelector<StoreState, boolean>(state => !state.staffUser.permissions.find(p => p.feature === "reservations")?.edit ?? true)

    return (
        <Table className="border-bottom">
            <TableHeader headers={["ID", "NOMBRE", "SPOT", "TALLA", "CHECK-IN", "ACCION"]}/>
            <tbody>
            {rsvpState.loading ?
                <TableLoadingTr/>
                : rsvpState.reservations.length === 0 ? <EmptyTr/> : rsvpState.reservations.map((rsvp) =>
                    <ReservationTr key={rsvp.id} rsvp={rsvp} room={room} editDisabled={editDisabled} onEditSpotClick={onEditSpotClick}/>)
            }
            </tbody>
        </Table>
    );
};

export default ReservationsTable;
