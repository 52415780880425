import React from 'react';
import TabNavigator from "../../components/TabNavigator";
import PushNotificationsView from "./PushNotificationsView";
import EmailsView from "./EmailsView";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";

export interface PushNotification {
    title: string
    message: string
    branch: string
    created_by: string
    created_on: string
}

const Notifications = () => {
    const studioId = useSelector<StoreState, string>(state => state.studio.id)

    const hiddenPushNotificationStudios = [
        "a202b831-ee68-4715-8d95-71d305bdb6c5",
        "94f74527-a7e6-4036-bd6e-6aba0554f661",
        "b788fce4-89bd-4d41-b966-7b2460c3ea9e",
        "4e8c8da6-764a-4d3d-b106-4c247901db3a", // HARMONY
        // "e081f019-9f2e-4d62-aba1-7beaecb866e0", // Ripple
    ]

    const emailAllowedStudios = [
        "a9d64f87-5d5a-4f59-98a2-56d70e63d27e",
        "9a3c10bd-cf10-48f9-a71d-3ee284fdc67c",
        "fcad8ad7-3f95-4dc4-936e-39bf880c467c",
        "05d977f4-1fde-452a-a31e-583bbc2a14d1",
        "034d36b9-6f6b-4c51-a465-17c6cac0617f",
        "a202b831-ee68-4715-8d95-71d305bdb6c5",
        "94f74527-a7e6-4036-bd6e-6aba0554f661", // RONNA
        "b788fce4-89bd-4d41-b966-7b2460c3ea9e", // EMOTION
        "4e8c8da6-764a-4d3d-b106-4c247901db3a", // HARMONY
        "ffc043b6-cc14-4a87-b398-3c728dab1e7f", // BEAT STUDIO
        "e081f019-9f2e-4d62-aba1-7beaecb866e0", // Ripple
        "8772ddbc-bdf1-41fe-b882-57d0cfa5e9ef", // Rush ride
        "e00ee88c-8017-42fa-b273-10b4d908e3d2", // Route
        "a1198467-a3f0-4e6e-b3b3-a113b2490d67", // Higher
        "eb319ceb-3e80-4ad4-aee2-257975e2849e", // VALI
        "e527fe5d-3c78-4760-a792-eddf26bc0029", // CONNECT
        "48a0e378-0057-4bc0-87ba-7364c58bee0d", // RASSA
    ]

    if (hiddenPushNotificationStudios.includes(studioId)) {
        return <div>
            <TabNavigator tabLabels={["Emails"]} panels={[
                <EmailsView/>
            ]}/>
        </div>
    }
    return (
        <div>
            {

                emailAllowedStudios.includes(studioId)
                    ? <TabNavigator tabLabels={["Notificaciones Push", "Emails"]} panels={[
                        <PushNotificationsView/>, <EmailsView/>
                    ]}/>
                    : <TabNavigator tabLabels={["Notificaciones Push"]} panels={[
                        <PushNotificationsView/>
                    ]}/>
            }
        </div>
    );
};

export default Notifications;
