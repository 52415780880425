import React, {useState} from 'react';
import {Nav} from 'react-bootstrap';

type TabNavigatorProps = {
    tabLabels: string[]
    panels: JSX.Element[]
}

const TabNavigator = ({tabLabels, panels}: TabNavigatorProps) => {
    const [activeKey, setActiveKey] = useState(0);
    const handleSelect = (eventKey: any) => {
        setActiveKey(eventKey)
    }
    return <div>
        <Nav variant="tabs" className="nav nav-tabs nav-tabs-line " defaultActiveKey={0} onSelect={handleSelect}>
            {tabLabels.map((label, index) => <Nav.Item key={index}>
                <Nav.Link className="font-size-h5 font-weight-bolder px-1" eventKey={index}>{label}</Nav.Link>
            </Nav.Item>)}
        </Nav>
        {panels[activeKey]}
    </div>
};

export default TabNavigator;
