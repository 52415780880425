export const formatCurrency = (value: number, currency: string, precise = false) => {
    if (value === undefined || !currency) return ""
    if (precise) {
        return preciseCurrency.format(value)
    }
    let minFractionDigits = 2
    if (currency.toUpperCase() === "MXN") minFractionDigits = 0
    const userLang = navigator.language || navigator.languages[0];
    return new Intl.NumberFormat(
        userLang,
        {
            style: "currency",
            currency: currency.toUpperCase(),
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: 2,
        },
    ).format(value)
}

const currencyFormat = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const preciseCurrency = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});