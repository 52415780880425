import React from 'react';
import {Col, Image, Row} from "react-bootstrap";
import {format} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";

import {TimeSlotV2} from "../Schedules";

type TimeSlotListCardProps = {
    timeSlot: TimeSlotV2,
    setSelectedTimeSlot: (timeslot: TimeSlotV2 | null) => void
}

const TimeSlotListCard = ({timeSlot, setSelectedTimeSlot}: TimeSlotListCardProps) => {
    return (
        <Row onClick={() => setSelectedTimeSlot(timeSlot)} className={`border-top text-center align-items-center mx-0 py-5 karpseudo ${timeSlot.archived ? 'bg-secondary' : ''}`} style={{minHeight: "66px", cursor:"pointer"}}>
            <Col md={3} className="px-0 col-3">
                <div className="font-size-md font-weight-bold">{format(timeSlot.start, "h:mm aa")}</div>
                <div className="font-size-sm text-muted"> {timeSlot.minutes_duration} min</div>
            </Col>
            <Col md={3} xl={2} className="px-0 col-3 col-xxl-2">
                <div className="symbol symbol-40 symbol-lg-40 symbol-circle text-muted">
                    {timeSlot.coaches.length > 0
                        ? <Image src={timeSlot.coaches[0].img}/>
                        : <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "40px"}}/>
                    }
                </div>
            </Col>
            <Col md={4} className="px-0 col-4">
                <div className="font-size-md font-weight-bolder">{timeSlot.studio_class_name}</div>
            </Col>
            <Col md={2} xl={3} className="px-0 col-1 col-md-2 col-xxl-3">
                 <span
                     hidden={!timeSlot.archived}
                     className="label label-inline label-light-danger label-pill font-weight-bolder font-size-lg">
                            Eliminado
                </span>
                <div
                    className="font-size-sm">{timeSlot.people_limit - timeSlot.available_spaces}/{timeSlot.people_limit}
                </div>
            </Col>
            <Col className="px-0 col-1 d-md-none">
                <i className="fi-rr-angle-small-right icon-sm"></i>
            </Col>
        </Row>
    )
};

export default TimeSlotListCard;
