import React from 'react';
import {Link} from "react-router-dom";
import {Alert, Button} from "react-bootstrap";

const CheckoutSuccess = () => {
    return (
        <div>
            <Alert variant="success">¡Bienvenido, tu suscripción ha iniciado!</Alert>
            <Link to="/">
                <Button>Ir a dashboard</Button>
            </Link>
        </div>
    );
};

export default CheckoutSuccess;
