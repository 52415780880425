import React from "react";
import {Switch, Route} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Schedules from "../pages/Schedules";
import Toolbox from "../pages/Toolbox";
import Members from "../pages/Members";
import Reservations from "../pages/Reservations";
import Pos from "../pages/Pos";
import Registration from "../pages/Registration";
import Billing from "../pages/Billing";
import {ProtectedRoute} from "./misc/ProtectedRoute";
import NotFoundScreen from "./misc/NotFoundScreen";
import MemberDetails from "../pages/Members/MemberDetails";
import Reports from "../pages/Reports";
import Coaches from "../pages/Coaches";
import Notifications from "../pages/Notifications";
import WebsiteApp from "../pages/WebsiteApp";
import Success from "../pages/Billing/Checkout/Success";
import Coaches2 from "../pages/Coaches2";
import GymPassCheckIns from "../pages/GymPassCheckIns";
import Blank from "../pages/Blank";
import StudioLinks from "../pages/StudioLinks";


const Routes = () => {
    return <Switch>
        <Route path="/" strict exact component={Dashboard} />
        <ProtectedRoute path="/schedules" component={Schedules} permission="schedules" />
        <ProtectedRoute path="/users/:memberId" component={MemberDetails} permission="clients" />
        <ProtectedRoute path="/users" component={Members} permission="clients" />
        <ProtectedRoute path="/reservations" component={Reservations} permission="reservations"/>
        <ProtectedRoute path="/gympass-checkins" component={GymPassCheckIns} permission="reservations"/>
        <ProtectedRoute path="/toolbox" component={Toolbox} permission="personalization"/>
        <ProtectedRoute path="/billing" component={Billing} permission="billing"/>
        <ProtectedRoute path="/pos" component={Pos} permission="pos"/>
        <ProtectedRoute path="/reports" component={Reports} permission="reports"/>
        <ProtectedRoute path="/coaches" component={Coaches} permission="coaches" />
        <ProtectedRoute path="/coaches2" component={Coaches2} permission="coaches" />
        <ProtectedRoute path="/cashier" component={Blank} permission="pos"/>
        <ProtectedRoute path="/links" component={StudioLinks} permission="personalization"/>

        <ProtectedRoute path="/notifications" component={Notifications} permission="notifications" />
        <Route path="/checkout-success" component={Success} />
        <Route path="/website-app" component={WebsiteApp} />
        <Route path="/registration" exact component={Registration} />
        <Route component={NotFoundScreen} />


    </Switch>
}



export default Routes