import {StudioPlanAction, StudioPlansState} from "../actions/studioPlans";
import {StudioPlansActionTypes} from "../actions";

const initialState = {
    loading: true,
    plans: []
} as StudioPlansState

export const studioPlansReducer = (state = initialState, action: StudioPlanAction) => {
    switch (action.type) {
        case StudioPlansActionTypes.FETCHING:
            return initialState
        case StudioPlansActionTypes.FETCHED:
            return {loading: false, plans: action.payload}
        case StudioPlansActionTypes.ADD_PLAN:
            return {...state, plans: [...state.plans, action.payload]}
        case StudioPlansActionTypes.REMOVE_PLAN:
            return {...state, plans: state.plans.filter(p => p.id !== action.payload)}
        case StudioPlansActionTypes.UPDATE_PLAN:
            return {...state, plans: state.plans.map(p => p.id === action.payload.id ? action.payload : p)}
        default:
            return state
    }
}