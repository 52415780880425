import {Branch, BranchesActions, BranchesState, OptionType} from "../actions/branches";
import {BranchActionTypes} from "../actions";

const initialState = {
    loading: true,
    error: "",
    branches: [],
    selectOptions: []
} as BranchesState

export const BranchesReducer = (state: BranchesState = initialState, action: BranchesActions) => {
    switch (action.type) {
        case BranchActionTypes.BRANCHES_FETCHED_SUCCESS:
        case BranchActionTypes.UPDATE_BRANCHES:
            return {
                loading: false,
                error: "",
                branches: action.payload,
                selectOptions: action.payload.map((b: Branch) => ({value: b.id, label: b.name} as OptionType))
            } as BranchesState
        case BranchActionTypes.BRANCHES_FETCHED_FAILED:
            return {...initialState, error: "Ocurrió un error", loading: false} as BranchesState
        case BranchActionTypes.REMOVE_BRANCH:
            return {
                ...state,
                branches: state.branches.filter(b => b.id !== action.payload),
                selectOptions: state.selectOptions.filter(o => o.value !== action.payload)
            }
        default:
            return state
    }
}