import React from 'react';
import {Col, Row} from "react-bootstrap";

interface ModalSelectProps {
    label: string
    className?: string
    extra?: JSX.Element
    children: JSX.Element | JSX.Element[]

}

const ModalSelect = ({label, children, extra}: ModalSelectProps) => {
    return <Row className="form-group align-items-center">
        {/* <Col className="offset-lg-1 col-form-label" lg={2}>
            - {label}
        </Col> */}
        <Col lg={4}>
                {children}
        </Col>
        <Col >{extra}</Col>
    </Row>
};

export default ModalSelect;
