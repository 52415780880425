import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";

import {TimeSlotV2} from "../Schedules";
import {Member} from "../Members";
import WaitListTr from "../../components/table/rows/WaitListTr";
import baseApi from "../../apis/baseApi";
import TableHeader from "../../components/table/TableHeader";
import EmptyTr from "../../components/table/rows/EmptyTr";
import DeleteModal from "../../components/modals/DeleteModal";
import {format} from "date-fns";
import {es} from "date-fns/locale";

type WaitListTableProps = {
    timeslot: TimeSlotV2
}

export interface WaitList {
    id: string
    member: Member
    cancelled: boolean
    activated: boolean
}

const WaitListTable = ({timeslot}: WaitListTableProps) => {
    const [waitList, setWaitList] = useState<WaitList[]>([])
    const [selectedItem, setSelectedItem] = useState<WaitList>();
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        baseApi.get(`/rsvp/waitlist/staff/?timeslot=${timeslot.id}`).then((resp) => {
            setWaitList(resp.data)
        })
    }, [timeslot]);

    return (
        <div>
            <DeleteModal
                text={"Eliminar de Lista de Espera"}
                url={`/rsvp/waitlist/update/${selectedItem?.id}/`}
                onSuccess={() => {
                    setWaitList(waitList.map((e) => e.id === selectedItem?.id ? {...e, cancelled: true} : e))
                }}
                successText={"Cliente eliminado de lista de espera."}
                show={showDelete}
                onHide={() => {
                    setShowDelete(false)
                }}
            >
                <div>
                    Cliente:
                    <span
                        className="font-weight-bold">{selectedItem?.member.external_id} - {selectedItem?.member.full_name} {selectedItem?.member.last_name}
                    </span>
                </div>
                <p className="p-0 m-0">Clase: {timeslot.studio_class_name}</p>
                <p className="p-0 m-0">Horario: {format(timeslot.start, "dd/MMM/yyyy h:mm a", {locale: es})}</p>
            </DeleteModal>
            <Table>
                <TableHeader headers={["ID", "NOMBRE", "TALLA", "ACCIÓN"]}/>
                <tbody>
                {waitList.length === 0
                    ? <EmptyTr/>
                    : waitList.map((item) => <WaitListTr
                            key={item.id}
                            waitListItem={item}
                            onDeleteClicked={() => {
                                setSelectedItem(item)
                                setShowDelete(true)
                            }}
                        />
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default WaitListTable;
