import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/free-regular-svg-icons";
import {RotateProp} from "@fortawesome/fontawesome-svg-core";

type IconTextProps = {
    text: string
    icon: IconDefinition
    rotation?: RotateProp
}

const IconText = ({text, icon, rotation} : IconTextProps) => {
    return (
        <div className="pb-3">
            <span className="mr-1 text-muted"><FontAwesomeIcon icon={icon} rotation={rotation}/> </span>
            <span className="font-size-sm text-muted">{text}</span>
        </div>
    );
};

export default IconText;
