import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Membership} from "./Memberships";
import {formatDate, formatDateTime} from "../../../utils/dateFormatters";
import {faSyncAlt, faTag} from "@fortawesome/free-solid-svg-icons";
import MemberRsvp from "./MemberRsvp";
import {Member} from "../index";
import {Sale} from "../../Reports/Sales";
import baseApi from "../../../apis/baseApi";
import MemberCredits from "./MemberCredits";
import MembershipUpdateHistory from "./MembershipUpdateHistory";
import {Button, Table} from "react-bootstrap";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import TableHeader from "../../../components/table/TableHeader";
import {PenalizationFee, PenalizationFeeType} from "../../Pos/MemberFees";
import {format, parseISO} from "date-fns";
import EmptyTr from "../../../components/table/rows/EmptyTr";

interface Props {
    member: Member
    membership: Membership
    onClose: () => void
}

const MembershipDetails = ({member, membership, onClose}: Props) => {
    const [sale, setSale] = useState<Sale>();
    const [fees, setFees] = useState<PenalizationFee[]>([])

    useEffect(() => {
        if (membership.sale) {
            baseApi.get(`/sales/${membership.sale}/`).then(resp => {
                setSale(resp.data)
            })
        }
    }, [membership]);

    useEffect(() => {
        if (membership) {
            baseApi.get(`/fees/membership/?membership=${membership.id}`).then(resp => {
                setFees(resp.data)
            })
        }
    }, [membership]);

    return (
        <div className="p-5 mx-10">
            <Button onClick={onClose} className="px-2 py-1 mb-1" variant="outline-primary" size="sm">
                <FontAwesomeIcon icon={faArrowLeft}/> Atrás
            </Button>
            <h4 className="m-0 p-0">Detalles de Membresía</h4>
            <div className="row">
                <div className="col-12">
                    <div className="font-weight-bold font-size-lg">
                        {membership.plan.name}
                        {membership.subscription_id ?
                            <FontAwesomeIcon className="text-success ml-2" icon={faSyncAlt}/> :
                            <React.Fragment/>}
                    </div>
                    <div>Vigencia: {formatDate(membership.activate_on)} - {formatDate(membership.expire_on)}</div>
                    <div>Creado: {formatDateTime(membership.created_on)}</div>
                </div>
            </div>
            <hr/>
            <div>
                <h5 className="text-primary">Venta</h5>
                {!sale ? <div>Cargando</div> : <React.Fragment/>}
                <div hidden={!sale}>
                    <div>Precio: {sale?.plan_items.find(i => i.plan === membership.plan.name)?.price}
                        <span hidden={!sale?.plan_items.find(i => i.plan === membership.plan.name)?.promotion}
                              className="ml-2 label label-inline label-light-success label-pill font-weight-bolder font-size-lg">
                            <FontAwesomeIcon icon={faTag} className="mr-2"/> Promo
                        </span>
                    </div>
                    <div>Método de pago: {sale?.payment_method}</div>
                    <div>Creado por: {sale?.transaction_by}</div>
                </div>
            </div>
            <hr/>
            <div>
                <h5 className="text-primary">Actualizaciones a la vigencia</h5>
                <MembershipUpdateHistory membership={membership}/>
            </div>
            <hr/>
            <div className="row mt-2">
                <div className="col-6">
                    <h5 className="text-primary">Reservaciones</h5>
                    <MemberRsvp member={member} membershipId={membership.id}/>
                </div>
                <div className="col-6">
                    <h5 className="text-primary">Créditos</h5>
                    <MemberCredits member={member} setMember={(m) => {
                    }} membershipId={membership.id} compactView={true}/>
                </div>
            </div>
            <hr/>
            <div>
                <h5 className="text-primary">Multas</h5>
                <Table hover className='table-responsive'>
                    <TableHeader headers={["FECHA", "MONTO", "TIPO", "CLIENTE", "RESERVACIÓN", "STATUS"]}/>
                    <tbody>
                    {fees.length === 0 ? <EmptyTr /> : fees.map(f => {
                        const feeType = Object.values(PenalizationFeeType)[f.fee_type]
                        return <tr className="text-left" key={f.id}>
                            <td>
                                <div>{format(parseISO(f.created_on), "dd/MMM/yy H:mm")}</div>
                            </td>
                            <td>$ {f.amount}</td>
                            <td>{feeType}{feeType === PenalizationFeeType.ACCUMULATED_NO_SHOW_FEE ? `(x${f.reservations.length})` :
                                <React.Fragment/>}</td>
                            <td className="text-left">
                                <div
                                    className="font-weight-bold font-size-sm">{f.reservation.member.full_name} {f.reservation.member.last_name ?? ''}</div>
                                <div className="text-muted font-size-xs">{f.reservation.member.email}</div>
                            </td>
                            <td className="text-left">
                                <div className="font-weight-bold font-size-sm">{f.reservation.timeslot_class}</div>
                                <div
                                    className="text-muted font-size-xs">{formatDateTime(f.reservation.timeslot_start)}</div>
                            </td>
                            <td>
                                <span
                                    className={`ml-2 label label-inline label-light-${f.status === 0 ? 'warning' : f.status === 1 ? 'success' : ''} label-pill font-weight-bolder font-size-lg`}>
                                    {f.status === 0 ? 'Pendiente' : f.status === 1 ? 'Pagado' : 'Cancelada'}
                                </span>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
};

export default MembershipDetails;
