import React from 'react';
import InventoryReport from "./Inventory";
import ActiveMembers from "./ActiveMembers";

import TabNavigator from "../../components/TabNavigator";

import MemberRankingsReport from "./MemberRankings/index";
import SalesReport from "./Sales/index";
import PlanSales from "./PlanSales/index";
import SchedulesCoachesReport from "./SchedulesCoaches/index";
import CoachComissionsReport from "./CoachCommissions";
import OtherReports from "./OtherReports";



const Reports = () => {

    return (
        <div className="h-100 on-reports on-finish mt-1">
            <div className="">
                <div className="d-flex align-items-center">
                    <i className="fi-rr-stats mr-3"/>
                    <h2 className="text-dark mr-5">Reportes</h2>
                </div>
            </div>

            <div className='mt-5'>
                <TabNavigator
                    tabLabels={["Ventas", "👤 Clientes Activos", "🏆 Ranking Clientes", "🚴 Coaches", "💳 Planes", "📦 Inventario", "Comisiones", "Otros"]}
                    panels={
                        [
                            <SalesReport/>,
                            <ActiveMembers/>,
                            <MemberRankingsReport/>,
                            <SchedulesCoachesReport/>,
                            <PlanSales/>,
                            <InventoryReport/>,
                            <CoachComissionsReport/>,
                            <OtherReports />
                        ]
                    }/>
            </div>
        </div>
    )
};

export default Reports;
