import React, {useEffect, useRef, useState} from 'react';
import {Card, Table} from "react-bootstrap";
import TableHeader from "../../components/table/TableHeader";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import baseApi from "../../apis/baseApi";
import TableFooter from "../../components/table/TableFooter";
import EmptyTr from "../../components/table/rows/EmptyTr";
import {formatDateTime} from "../../utils/dateFormatters";
import {getApiErrorMessage} from "../../utils/apiErrors";

export interface GymPassCheckIn {
    id: number;
    internal_checkin: boolean;
    gympass_checkin: boolean;
    member: GymPassCheckInMember;
    branch: string;
    created_on: string;
    error_msg?: string
}

interface GymPassCheckInMember {
    id: string;
    full_name: string;
    last_name: string;
    email: string;
    external_id: string;
    gympass_id: string;
}

const GymPassCheckIns = () => {
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [checkIns, setCheckIns] = useState<GymPassCheckIn[]>([]);
    const count = useRef(0);

    useEffect(() => {
        if (currentBranch.gympass_gym_id) {
            handlePageChange(1)
        }
    }, [currentBranch]);

    const handlePageChange = (page: number) => {
        baseApi.get(`/gympass/list-checkins/?branch=${currentBranch.id}&page=${page}`).then(resp => {
            count.current = resp.data.count
            setCheckIns(resp.data.results)
        }).catch((err) => alert("Error"))
    }

    const updateCheckIn = (checkIn : GymPassCheckIn) => {
        console.log("OK")
        baseApi.patch(`/gympass/update-checkins/${checkIn.id}/`, {internal_checkin: true}).then(resp => {
            setCheckIns(checkIns.map(ch => ch.id === checkIn.id ? resp.data : ch))
        }).catch(e => {
            const msg = getApiErrorMessage(e)
            if (msg) {
                checkIn.error_msg = msg
                setCheckIns(checkIns.map(ch => ch.id === checkIn.id ? checkIn : ch))
            }
        })
    }


    return (
        <div className="h-100 p-4">
            <div className="">
                <div className="d-flex align-items-center">
                    <i className="fi-rr-user mr-3"/>
                    <h2 className="text-dark">GymPass Check-Ins</h2>
                </div>
            </div>
            <div className="mt-5 w-50 h-100">
                <Card className=" px-5 shadow-0 border-0 h-100">
                    <Table className="text-center">
                        <TableHeader headers={["Fecha", "Cliente",  "CheckIn"]} />
                        <tbody>
                        {checkIns.length === 0 ? <EmptyTr /> : checkIns.map(ch => {
                            const member = ch.member
                            let btnClassName = "btn btn-sm btn-pill btn-outline-primary btn-hover-success font-weight-bold font-size-xs mr-2 min-w-85px"
                            if (ch.gympass_checkin) {
                                btnClassName = "btn btn-sm btn-pill btn-light-success font-weight-bold font-size-xs mr-2 min-w-85px"
                            }
                            return <tr key={ch.id}>
                                <td>{formatDateTime(ch.created_on)}</td>

                                <td className="text-left">{member.external_id} - {member.full_name} {member.last_name ?? ''}
                                    <div className="font-size-sm text-muted">{member.email}</div>
                                    <div className="font-size-sm" style={{color: "salmon"}}>{member.gympass_id}</div>
                                </td>
                                <td className="text-right">
                                    <div className="text-danger font-size-sm ">{ch.error_msg ?? <React.Fragment />}</div>
                                    <button
                                        onClick={() => {
                                            if (!ch.gympass_checkin) {
                                                updateCheckIn(ch)
                                            }

                                        }}
                                        className={btnClassName}>
                                        Check-In
                                    </button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                    <TableFooter count={count.current} pageCount={checkIns.length} pageSize={10}
                                 onPageChange={handlePageChange}/>
                </Card>
            </div>
        </div>
    );
};

export default GymPassCheckIns;
