import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {Button, Table} from "react-bootstrap";
import {endOfDay, format, formatISO, parseISO, startOfDay, startOfMonth,} from "date-fns";
import {es} from "date-fns/locale";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import ReactDatePicker from "react-datepicker";
import baseApi from "../../../apis/baseApi";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import {formatDateTime} from "../../../utils/dateFormatters";
import {CSVLink} from "react-csv";

const SchedulesCoachesReport = () => {
    // TODO: handle alert error message
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [rangeStart, setRangeStart] = useState<Date>(startOfMonth(new Date()));
    const [rangeEnd, setRangeEnd] = useState<Date | null>(new Date());
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<ScheduleCoachesReportItem[]>([]);

    useEffect(() => {
        if (rangeStart && rangeEnd) {
            const fetchPage = () => {
                const start = formatISO(startOfDay(rangeStart))
                const end = formatISO(endOfDay(rangeEnd))
                setLoading(true)
                baseApi.get(`/reports/coaches-classes/?branch=${branch.id}&start=${start}&end=${end}`).then(resp => {
                    // if (page === 1) setCount(resp.data.count)
                    setItems(resp.data)
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                },)
            }
            fetchPage()
        }
    }, [branch, rangeStart, rangeEnd]);

    const renderButton = () => {
        if (!rangeEnd) return <React.Fragment/>
        const headers = [
            {label: "Fecha", key: "start"},
            {label: "Clase", key: "class_name"},
            {label: "Coach", key: "coach"},
            {label: "Reservaciones", key: "rsvp_count"},
            {label: "Asistencias", key: "assistance_count"},
            {label: "Faltas", key: "misses_count"},
            {label: "Cancelaciones", key: "cancelled_count"},
        ]
        let filename = `Reporte horarios-${branch.name}-${format(rangeStart, "dd/MM/yyyy",)}-${format(rangeEnd!, "dd/MM/yyyy",)}`
        let data = items.map(i => {
            let start = format(parseISO(i.start), "dd/MM/yyyy H:mm")
            let misses_count = i.rsvp_count - i.assistance_count
            return {...i, start: start, misses_count: misses_count}
        })
        return <CSVLink filename={filename} data={data} headers={headers} target="_blank">
            <Button variant="outline-success" className="my-3">
                <span><FontAwesomeIcon icon={faFileExcel}/> Descargar</span>
            </Button>
        </CSVLink>
    }


    return (
        <div>

            {/* TITULO */}
            <h1 className="pt-10">🚴 Reporte Coaches</h1>
            <div className='mb-12 text-dark-50'>Descarga un reporte de tus coaches con número de reservas, asistencias, faltas y cancelaciones.</div>

            <div className='font-size-h5'>Selecciona un rango de fechas para descargar:</div>

            {/* RANGO FECHAS */}
            <div className="d-sm-flex align-items-center">
                <div className='col-11 col-lg-9 col-xl-7 col-xxl-5 ml-n3'>
                    <ReactDatePicker
                        selectsRange
                        selected={rangeStart}
                        locale={es}
                        value={`${format(rangeStart, "dd MMMM yyyy", {locale: es})}  al  ${rangeEnd === null ? '' : format(rangeEnd, "dd MMMM yyyy", {locale: es})}`}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        shouldCloseOnSelect={rangeEnd === null}
                        maxDate={new Date()}
                        onChange={(dates) => {
                            if (dates) {
                                const [start, end] = dates as Date[]
                                setRangeStart(start)
                                setRangeEnd(end)
                            }
                        }}
                        customInput={<input className="form-control font-size-h4 text-primary"
                                            style={{cursor: "pointer"}}/>}
                    />
                </div>
                <div>{renderButton()}</div>

            </div>

            {/* BOTON DESCARGAR */}
            {/* {renderButton()} */}

            <div>
                <div className='mt-4'>
                    <div className='label label-l font-size-l p-4 font-weight-bold mr-2 label-inline'># reservaciones</div>
                    <div className='label label-light-success label-l font-size-l p-4 font-weight-bold mr-2 label-inline'># asistencias</div>
                    <div className='label label-light-danger label-l font-size-l p-4 font-weight-bold mr-2 label-inline'># faltas</div>
                    <div className='label label-light-warning label-l font-size-l p-4 font-weight-bold label-inline'>#cancelaciones</div>
                </div>
            </div>

            {/* TABLA */}
            <div className="rounded shadow-sm p-5 mb-5 mt-3 col-12 col-lg-9 col-xl-7 col-xxl-5" style={{background: "white"}}>
                
                <Table hover bordered>
                    <TableHeader
                        headers={["FECHA",  "COACH"]}/>
                    <tbody>
                    {loading ? <LoadingTr/> : items.map(item => {
                        return <tr className="" key={item.start}>
                            <td className=" ">
                                {/* <div className='font-size-h5 font-weight-bold'>{item.coach}</div> */}
                                <div className='font-size-lg'>{formatDateTime(item.start)}</div>
                                {/* <div className='font-weight-bolder'>{item.coach}</div> */}
                                <div className='text-muted font-size-xs'>{item.class_name}</div>
                            </td>
                            {/* <td className="text-center w-150px">{item.class_name}</td> */}
                            {/* <td>
                                <div>R: {item.rsvp_count}</div>
                                <div className='text-success'>A: {item.assistance_count}</div>
                                <div className='text-warning'>F: {item.rsvp_count - item.assistance_count}</div>
                                <div className='text-danger'>C: {item.cancelled_count}</div>



                            </td> */}
                            <td className="text-left  font-size-lg">
                                <div className='text-center mb-1'>{item.coach}</div>
                                <div className='text-center'>
                                    <div className='label label-lg font-size-h6 p-5 font-weight-bold mr-2'>{item.rsvp_count}</div>
                                    <div className='label label-light-success label-lg font-size-h6 p-5 font-weight-bold mr-2'>{item.assistance_count}</div>
                                    <div className='label label-light-danger label-lg font-size-h6 p-5 font-weight-bold mr-2'>{item.rsvp_count - item.assistance_count}</div>
                                    <div className='label label-light-warning label-lg font-size-h6 p-5 font-weight-bold'>{item.cancelled_count}</div>

                                </div>
                            </td>
                            {/* <td className="text-center w-100px font-size-h6">{item.rsvp_count}</td>
                            <td className="text-center w-100px text-success font-size-h6">{item.assistance_count}</td>
                            <td className="text-center w-100px text-warning font-size-h6">{item.rsvp_count - item.assistance_count}</td>
                            <td className="text-center w-100px text-danger font-size-h6">{item.cancelled_count}</td> */}
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
            {/* FIN TABLA */}

            <div className="py-20"/>
        </div>
    );
};

export default SchedulesCoachesReport;

interface ScheduleCoachesReportItem {
    start: string;
    coach: string;
    rsvp_count: number;
    assistance_count: number;
    cancelled_count: number;
    class_name: string
}