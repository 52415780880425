import React, {useEffect, useState} from 'react';
import CreateNotificationModal from "./CreateNotificationModal";
import {Card, Table} from "react-bootstrap";
import EmptyTrNotifications from "../../../components/table/rows/EmptyTrNotifications";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {formatDate} from "../../../utils/dateFormatters";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import baseApi from "../../../apis/baseApi";
import {PushNotification} from "../index";

const PushNotificationsView = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [notifications, setNotifications] = useState<PushNotification[]>([]);
    const [showCreate, setShowCreate] = useState(false);

    useEffect(() => {
        baseApi.get(`/notifications/?branch=${branch.id}`).then(resp => {
            setNotifications(resp.data.results)
        })
    }, [branch]);
    return (
        <div className="h-100 p-4">
            <CreateNotificationModal show={showCreate} onHide={() => setShowCreate(false)} notifications={notifications} setNotifications={setNotifications} />
            <div className="d-flex align-items-center mb-15">
                <i className="fi-rr-megaphone mr-3" />
                <h2 className="text-dark">Notificaciones Push</h2>
                <button onClick={() => setShowCreate(true)}
                        className="d-none d-sm-inline ml-5 btn btn-light-primary  btn-pill btn-lg bg-primary-o-50 text-primary border-0 btn-hover-primary font-weight-bold">+
                    Nueva Notificación
                </button>

            </div>
            <button onClick={() => setShowCreate(true)}
                    className="w-300px d-sm-none ml-5 btn btn-light-primary   btn-lg bg-primary-o-50 text-primary border-0 btn-hover-primary font-weight-bold mt-n12 mb-8">+
                Nueva Notificación
            </button>
            <Card className=" px-5 bg-transparent shadow-0 border-0">

                {/* EMPTY K NOTIFICATIONS */}
                {notifications.length === 0 ?

                    <div className='text-center col-12 w-100 mt-5'>
                        <EmptyTrNotifications/>
                        <button onClick={() => setShowCreate(true)}
                                className="btn btn-primary  btn-lg border-0  font-weight-bold shadow mt-3 mb-18">
                            Envíar mi primera notificación
                        </button>
                    </div>

                    : notifications.map(n => {
                        return <div></div>
                    })}
                {/* FIN EMPTY K NOTIFICATIONS */}

                {/* ORIGINAL NOTIFICATIONS */}
                {/* <Table hover>
                                        <TableHeader
                                            headers={[ "FECHA", "TITULO", "MENSAJE", "CREADOR"]}/>
                                        <tbody>
                                        {notifications.length === 0 ? <EmptyTr /> : notifications.map(n => {
                                            return <tr key={n.created_on} className="text-center">
                                                <td>{formatDate(n.created_on)}</td>
                                                <td>{n.title}</td>
                                                <td>{n.message}</td>
                                                <td>{n.created_by}</td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </Table> */}
                {/* FIN ORIGINAL NOTIFICATIONS */}
                {/*{saleReports.length === 0 ? <EmptyTr /> : saleReports.map(s => {*/}
                {/*    return <tr className="text-center" key={s.id}>*/}
                {/*        <td className="text-uppercase font-size-sm">{s.id.slice(-6)}</td>*/}
                {/*        <td>{formatDate(s.created_on)}</td>*/}
                {/*        <td>$ {s.total}</td>*/}
                {/*        <td className="text-left">*/}
                {/*            <div className="font-weight-bold font-size-sm">{s.member.full_name}</div>*/}
                {/*            <div className="text-muted font-size-xs">{s.member.email}</div>*/}
                {/*        </td>*/}
                {/*        <td>{s.transaction_by}</td>*/}
                {/*        <td className="text-center">{s.plan_items.map(i => i.plan).join(", ")}</td>*/}
                {/*        <td>{formatDate(s.expiration)}</td>*/}
                {/*    </tr>*/}
                {/*})}*/}


                {/* pruebas */}
                <div className='w-300px w-sm-450px'>
                    <Table >
                        <div className=' font-size-h6 mb-3 ml-3 text-muted'>Historial de Notificaciones </div>
                        {/* <TableHeader
                            headers={[ "FECHA"]}/> */}
                        <tbody>
                        {notifications.length === 0 ? <EmptyTr /> : notifications.map(n => {
                            return <tr key={n.created_on} className="">
                                <div className=' mb-5 shadow px-4 rounded-xl py-4 bg-white'>


                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className='d-flex text-left'>
                                                <div className="notif-image w-40px h-40px"></div>
                                                <div className='ml-3'>
                                                    <div className='font-size-h6 font-weight-bolder'>{n.title}</div>
                                                    <div className='font-size-sm'>{n.message}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='text-muted font-size-xs'>{formatDate(n.created_on)}</div>
                                    </div>

                                </div>
                                {/* <td>{n.title}</td>
                                <td>{n.message}</td>
                                <td>{n.created_by}</td> */}
                            </tr>
                        })}

                        </tbody>
                    </Table>
                </div>


                {/* pruebas */}


                {/*<TableFooter count={count} pageCount={saleReports.length} pageSize={10}*/}
                {/*             onPageChange={handlePageChange}/>*/}
            </Card>
        </div>
    );
};

export default PushNotificationsView;
