import React, {ComponentType} from 'react';
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {StaffUserPermissions} from "../../actions/staffUser";
import {Route} from "react-router-dom";
import PermissionDenied from "./PermissionDenied";

type ProtectedRouteProps = {
    path: string
    component: ComponentType<any>
    permission: string
}

export const ProtectedRoute = ({path, component, permission} : ProtectedRouteProps) => {
    const permissions = useSelector<StoreState, StaffUserPermissions[]>(state => state.staffUser.permissions)
    if (permissions.some(p => p.feature === permission && p.view))
        return (
            <Route path={path} exact component={component} />
        );
    return <Route component={PermissionDenied} />
};