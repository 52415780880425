import React, {useState} from 'react';
import EmptyAlert from "../../../components/misc/Empty";
import {Row} from "react-bootstrap";
import MembershipCard from "./ui/MembershipCard";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {StudioPlansState} from "../../../actions/studioPlans";
import CreateEditPlanModal from "../../Toolbox/Plans/CreateEditPlanModal";
import {PlanSaleItem} from "../index";
import {RegistrationFeesState} from "../../../actions/registrationFees";
import {SaleItem, SaleItemType} from "../../Reports/Sales";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfinity} from "@fortawesome/free-solid-svg-icons";
import {formatCurrency} from "../../../utils/currencyFormatters";
import SubscriptionMemberships from "./SubscriptionMemberships";
import {Studio} from "../../../actions/studio";

interface Props {
    cart: PlanSaleItem[],
    setCart: (items: PlanSaleItem[]) => void
    cartItems: SaleItem[], // SALES V2
    setCartItems: (items: SaleItem[]) => void // SALES V2
    onAddWithSubscriptionError: () => void
}

const Memberships = ({cart, setCart, cartItems, setCartItems, onAddWithSubscriptionError}: Props) => {
    const studioPlansState = useSelector<StoreState, StudioPlansState>(state => state.studioPlans)
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)
    const registrationFeesState = useSelector<StoreState, RegistrationFeesState>(state => state.registrationFees)
    const [showCreatePlanModal, setShowCreatePlanModal] = useState(false)
    const [noLimitCredits, setNoLimitCredits] = useState(false)

    const subscriptionAllowedStudios:string[] = ["a9d64f87-5d5a-4f59-98a2-56d70e63d27e", "ef861606-71bd-4929-b4b7-fc340d982a51", "9a3c10bd-cf10-48f9-a71d-3ee284fdc67c"]

    return (
        <div>
            <CreateEditPlanModal show={showCreatePlanModal} onHide={() => setShowCreatePlanModal(false)}
                                 noLimitCredits={noLimitCredits}/>
            {studioPlansState.plans.length === 0 ? <EmptyAlert/> :
                <div>
                    <div className="d-flex align-items-center mb-5 mt-5">

                        <h4 className="text-dark">Paquetes de Clases</h4>
                        <button onClick={() => {
                            setNoLimitCredits(false)
                            setShowCreatePlanModal(true)
                        }
                        } className="btn btn-white btn-icon btn-circle btn-sm shadow ml-3">
                            <i className="fi-rr-plus-small text-dark-50"/>
                        </button>
                    </div>

                    <Row>
                        {studioPlansState.plans.filter((p) => !p.no_limit_credits && !p.is_subscription && !p.is_manual_credits).map(p => {
                            return <MembershipCard key={p.id} plan={p} currency={currency} onClick={() => {
                                if (cart.find(i => i.plan.is_subscription)) {
                                    onAddWithSubscriptionError()
                                    return
                                }
                                if (!cart.map(i => i.plan).includes(p)) {
                                    setCart([...cart, {
                                        plan: p,
                                        quantity: 1,
                                        price: parseFloat(p.price),
                                        activate_on: new Date()
                                    }])
                                }
                            }}/>
                        })}
                    </Row>

                    <div className="d-flex align-items-center mb-5">
                        <h4 className="text-dark">Membresías</h4>
                        <button onClick={() => {
                            setNoLimitCredits(true)
                            setShowCreatePlanModal(true)
                        }} className="btn btn-white btn-icon btn-circle btn-sm shadow ml-3">
                            <i className="fi-rr-plus-small text-dark-50"/>
                        </button>
                    </div>

                    <Row className="h-100">
                        {studioPlansState.plans.filter((p) => p.no_limit_credits && !p.is_subscription).map(p => {
                            return <MembershipCard key={p.id} plan={p} currency={currency} onClick={() => {
                                if (cart.find(i => i.plan.is_subscription)) {
                                    onAddWithSubscriptionError()
                                    return
                                }
                                if (!cart.map(i => i.plan).includes(p)) {
                                    setCart([...cart, {
                                        plan: p,
                                        quantity: 1,
                                        price: parseFloat(p.price),
                                        activate_on: new Date()
                                    }])
                                }
                            }}/>
                        })}
                    </Row>
                </div>
            }
            {/*SUBSCRIPTIONS*/}
            <div hidden={!subscriptionAllowedStudios.includes(studio.id)}>
                <SubscriptionMemberships cart={cart} setCart={setCart} cartItems={cartItems} setCartItems={setCartItems}/>
            </div>

            {/*REGISTRATION FEES*/}
            <div hidden={registrationFeesState.fees.length === 0}>

                <div className="d-flex align-items-center mb-5">
                    <h4 className="text-dark">Inscripciones</h4>
                </div>
                <Row>
                    {registrationFeesState.fees.map(fee => <div>
                        <div
                            key={fee.id}
                            onClick={() => {
                                if (cart.find(i => i.plan.is_subscription)) {
                                    onAddWithSubscriptionError()
                                    return
                                }
                                if (!cartItems.find(item => item.ref === fee.id)) {
                                    setCartItems([...cartItems, {
                                        id: "",
                                        item_type: SaleItemType.registrationFee,
                                        name: fee.name,
                                        quantity: 1,
                                        price: parseFloat(fee.price),
                                        total: parseFloat(fee.price),
                                        ref: fee.id,
                                    }])
                                }
                            }}
                            className="col px-2 mb-5 pl-4"
                            style={{cursor: "pointer"}}
                        >
                            <div className="card card-hover card-custom text-center pt-7 pb-5 px-4 rounded-lg ">
                                <div>
                                    <p className="text-muted text-uppercase">{fee.name}</p>
                                    <p className="text-muted">{fee.days_duration > 10000 ?
                                        <FontAwesomeIcon icon={faInfinity}/> :
                                        <span>{fee.days_duration} días </span>}</p>
                                    <p className={`text-primary font-weight-bolder h4`}>{formatCurrency(parseFloat(fee.price), currency)}</p>
                                </div>
                            </div>
                        </div>
                    </div>)}
                </Row>
            </div>
        </div>
    );
};

export default Memberships;
