import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import {Sale} from "../../Reports/Sales";
import Select from "react-select";
import {paymentMethods} from "../../../data/paymentMethods";
import FieldError from "../../../components/form/FieldError";
import yup from "../../../utils/yup";
import baseApi from "../../../apis/baseApi";
import {PenalizationFee, PenalizationFeeType} from "./index";
import {formatDateTime} from "../../../utils/dateFormatters";

type Props = {
    show: boolean,
    onHide: Function,
    fee: PenalizationFee,
    onSuccess: Function,
}

const FeePaymentModal = ({show, onHide, fee, onSuccess}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        payment_method: yup.string().required()
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{status: 1, payment_method: ""}} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    baseApi.patch(`/fees/${fee.id}/`, values).then(resp => {
                        setAlertMessage("Multa actualizada")
                        setSuccess(true)
                        onSuccess()
                    }).catch(err => {
                        setAlertMessage("Ocurrió un error al intentar actualizar la multa.")
                        setSuccess(false)
                    })
                }}>
                    {({isSubmitting, setFieldValue}) => (
                        <FormikForm>
                            <Modal.Header>
                                Cobrar multa
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div>
                                    Reservación: {formatDateTime(fee.reservation.timeslot_start)} - {fee.reservation.timeslot_class}
                                </div>
                                <div>
                                    Multa: {Object.values(PenalizationFeeType)[fee.fee_type]}
                                </div>
                                <div>
                                    Cliente: {fee.reservation.member.external_id} - {fee.reservation.member.full_name}
                                </div>
                                <div>
                                    Plan: {fee.plan_name}
                                </div>
                                <hr/>
                                <div className="font-weight-bolder font-size-h4">
                                    Total: ${fee.amount}
                                </div>
                                <hr/>
                                <Select placeholder="Método de pago"
                                        options={paymentMethods.filter(e => e.value !== null)}
                                        onChange={(option) => setFieldValue("payment_method", option?.value)}/>
                                <FieldError name="payment_method"/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button variant="success" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Finalizar venta"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default FeePaymentModal;
