import React, {useEffect, useState} from 'react';
import {Button, Table} from "react-bootstrap";
import CreateStaffModal from "./CreateStaffModal";
import TableHeader from "../../../components/table/TableHeader";
import baseApi from "../../../apis/baseApi";
import {StaffUser} from "../../../actions/staffUser";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import ToolboxHeader from "../ToolboxHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import DeleteModal from "../../../components/modals/DeleteModal";

export interface UserWithStaff {
    full_name: string
    id: string
    username: string
    staff: StaffUser
}

const Staff = () => {
    const branches = useSelector<StoreState, Branch[]>(state => state.branches.branches)
    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [staff, setStaff] = useState<UserWithStaff[]>([]);
    const [selectedStaff, setSelectedStaff] = useState<UserWithStaff>()

    useEffect(() => {
        baseApi.get("/staff/").then((resp) => {
            setStaff(resp.data)
        })
    }, []);

    const renderBranchNames = (branchIds: string[]) => {
        const names = branchIds.map((id, index) => branches.find((branch) => branch.id === id)?.name)
        return names.join(", ")
    }

    return (
        <div className="p-4">
            <CreateStaffModal
                show={showCreate}
                onHide={() => {
                    setShowCreate(false)
                    setSelectedStaff(undefined)
                }}
                user={selectedStaff}
                staffUsers={staff}
                setStaffUsers={setStaff}
            />
            <DeleteModal url={`/staff/update/${selectedStaff?.id}/`} onSuccess={() => {
                setStaff(staff.filter(s => s.id !== selectedStaff?.id))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedStaff(undefined)
            }}>
                <p className="font-weight-bolder">¿Estas seguro que deseas <span className="text-danger">eliminar</span> este usuario staff?</p>
                <p className="m-0 p-0">{selectedStaff?.full_name}</p>
                <p>{selectedStaff?.username}</p>
            </DeleteModal>
            <ToolboxHeader title="STAFF" action={() => setShowCreate(true)}/>
            <Table>
                <TableHeader headers={["Nombre", "Correo", "Sucursales", "Acciones"]}/>
                <tbody>
                {staff.length === 0 ? <EmptyTr/> : staff.map((staffUser) => {
                    return <tr key={staffUser.id} className="text-left font-size-lg">
                        <td>{staffUser.full_name}</td>
                        <td>{staffUser.username}</td>
                        <td>{renderBranchNames(staffUser.staff.is_staff_of)}</td>
                        <td className="text-center px-1">
                            <Button onClick={() => {
                                setSelectedStaff(staffUser)
                                setShowCreate(true)
                            }
                            } className="btn btn-icon btn-light btn-xs btn-hover-primary mr-1">
                                <FontAwesomeIcon icon={faEdit} size="sm"/>
                            </Button>
                            <Button onClick={() => {
                                setSelectedStaff(staffUser)
                                setShowDelete(true)
                            }
                            } className="btn btn-icon btn-light btn-xs btn-hover-danger">
                                <FontAwesomeIcon icon={faTrashAlt} size="sm"/>
                            </Button>

                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
        </div>
    );
};

export default Staff;
