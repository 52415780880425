import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import React, {useEffect, useState} from "react";
import baseApi from "../../apis/baseApi";
import DeleteModal from "../../components/modals/DeleteModal";
// import CreateEditCoachModal from "./CreateEditCoachModal";
import ToolboxHeader from "../Toolbox/ToolboxHeader";
import {Button, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashAlt, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {User} from "../../actions/user";
import CreateEditCoachModal2 from "./CreateEditCoachModal2";

export interface Coach {
    id: string,
    user: User | null
    full_name: string
    img: string | null
}

const Coaches2 = () => {
    const [coaches, setCoaches] = useState<Coach[]>([]);
    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [selectedCoach, setSelectedCoach] = useState<Coach>()

    useEffect(() => {
        baseApi.get(`/coaches/`).then((resp) => {
            setCoaches(resp.data)
        })
    }, []);

    return (
        <div className="p-4">
            <DeleteModal url={`/coaches/update/${selectedCoach?.id}/`} onSuccess={() => {
                setCoaches(coaches.filter(c => c.id !== selectedCoach?.id))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedCoach(undefined)
            }}>
                <p>¿Estas seguro de querer eliminar <strong>{selectedCoach?.full_name}</strong>?</p>
            </DeleteModal>
            <CreateEditCoachModal2
                coach={selectedCoach}
                show={showCreate}
                coaches={coaches}
                setCoaches={setCoaches}
                onHide={() => {
                    setShowCreate(false)
                    setSelectedCoach(undefined)
                }}/>

            <div className="d-flex align-items-center mb-15">
                <i className="fi-rr-calendar mr-3"></i>
                <h2 className="text-dark">Coaches</h2>
                <button onClick={() => setShowCreate(true)}
                        className="d-none d-md-inline ml-5 btn btn-light-primary  btn-pill btn-lg bg-primary-o-50 text-primary border-0 btn-hover-primary font-weight-bold">+
                    Nuevo Coach
                </button>
            </div>
            <Row>
                <div hidden={coaches.length > 0} className="text-center text-muted font-size-h2 w-100 mt-15">
                    0 coaches registrados
                </div>
                {coaches.map((c) => {
                    return <div key={c.id} className="col-4 col-md-3 col-xl-5 col-xxl-4 mb-2">
                        <div className="card card-custom overlay py-5 shadow pt-8 px-5">
                            <div className="d-flex justify-content-start">

                                <div>
                                    <div className="symbol symbol-60 symbol-lg-95 symbol-circle mx-auto pb-3">
                                        {c.img ?
                                            <img alt="Pic"
                                                 src={c.img}/>
                                            : <div className="text-muted">
                                                <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "85px"}}/>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="ml-4">
                                    <div className="font-weight-bold font-size-h2">{c.full_name}</div>
                                    <div className="text-muted">Base:</div>
                                    <div className="text-muted">Por clase:</div>
                                    <div className="text-muted">Por reservación:</div>
                                    <div className="text-muted">Por asistente:</div>


                                </div>
                            </div>



                            {/* OVERLAY */}
                            <div className="overlay-layer align-items-start justify-content-between pt-1 pr-1">
                                <Button
                                    onClick={() => {
                                        setSelectedCoach(c)
                                        setShowDelete(true)
                                    }}
                                    bsPrefix="btn btn-clean btn-icon btn-hover-danger btn-sm ml-1" size="sm">
                                    <FontAwesomeIcon icon={faTrashAlt} size="lg"/>
                                </Button>
                                <Button onClick={() => {
                                    setSelectedCoach(c)
                                    setShowCreate(true)
                                }} bsPrefix="btn btn-clean btn-icon btn-sm mr-1" size="sm">
                                    <FontAwesomeIcon icon={faEdit} size="lg"/>
                                </Button>
                            </div>

                            {/* FIN OVERLAY */}



                            
                            {/*<div className="font-size-xs text-muted">anapaulaojeda@gmail.com</div>*/}
                            {/*<div className="font-size-xs text-muted">Indoor Cycling - Barre</div>*/}
                            {/*<div className="font-size-xs text-muted">Clases esta semana</div>*/}
                            {/*<div className="font-size-xs text-muted">Label</div>*/}
                        </div>
                    </div>
                })}
            </Row>
        </div>
    );
};

export default Coaches2;

