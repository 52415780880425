import {TimeSlotV2} from "../pages/Schedules";
import {TimeslotReservationsActions, TimeslotReservationsState} from "../actions/timeslotReservations";
import {TimeSlotReservationsTypes} from "../actions";
import {Reservation} from "../pages/Reservations";

const initialState = {
    loading: true,
    reservations: [],
    timeslot: {} as TimeSlotV2
} as TimeslotReservationsState

export const timeSlotReservationsReducer = (state = initialState, action: TimeslotReservationsActions) => {
    switch (action.type) {
        case TimeSlotReservationsTypes.FETCHING:
            return {...state, loading: true}
        case TimeSlotReservationsTypes.FETCHED:
            const sortedReservations = action.payload.reservations.sort((a: Reservation, b: Reservation) => {
                if (a.cancelled) return 1
                return parseInt(a.seat ?? "0") - parseInt(b.seat ?? "0")
            })
            return {loading: false, reservations: sortedReservations, timeslot: action.payload.timeslot}
        case TimeSlotReservationsTypes.UPDATE_RESERVATION:
            return {
                ...state,
                reservations: state.reservations.map((r) => r.id === action.payload.id ? action.payload : r)
            }
        case TimeSlotReservationsTypes.ADD_RESERVATION:
            const reservations = [action.payload, ...state.reservations]
            return {...state, reservations: reservations}
        default:
            return state
    }
}