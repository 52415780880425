import React from 'react';
import {StudioPlan} from "../../../../actions/studioPlans";
import {StudioClass} from "../../../../actions/studioClasses";
import {isAfter, isBefore, parseISO} from "date-fns";
import {formatCurrency} from "../../../../utils/currencyFormatters";


type MembershipCardProps = {
    plan: StudioPlan
    currency: string
    onClick: () => void
}

const MembershipCard = ({plan, currency, onClick}: MembershipCardProps) => {
    const promotionActive = plan.promotions?.find(p => isAfter(new Date(), parseISO(p.activate_on)) && isBefore(new Date(), parseISO(p.expire_on)))

    const getDaysDuration = () => {
        let text = `${plan.interval_count} `
        if (plan.interval === "day") {
            text += plan.interval_count === 1 ? "día" : "días"
        } else if (plan.interval === "week") {
            text += plan.interval_count === 1 ? "semana" : "semanas"
        } else if (plan.interval === "month") {
            text += plan.interval_count === 1 ? "mes" : "meses"
        } else if (plan.interval === "year") {
            text += plan.interval_count === 1 ? "año" : "años"
        }
        return <span>{text}</span>
    }

    const getIntervalTypeText = () => {
        let text = ""
        if (plan.interval === "day") {
            text += plan.interval_count === 1 ? "día" : "días"
        } else if (plan.interval === "week") {
            text += plan.interval_count === 1 ? "semana" : "semanas"
        } else if (plan.interval === "month") {
            text += plan.interval_count === 1 ? "mes" : "meses"
        } else if (plan.interval === "year") {
            text += plan.interval_count === 1 ? "año" : "años"
        }
        return text.toUpperCase()
    }

    const getStudioClassNames = (studioClasses: StudioClass[]) => {
        return studioClasses.map(s => s.name).join(", ")
        // console.log(ids)
        // // @ts-ignore
        // const names = ids.map(id => studioClasses.find(sc => sc.id === id).name)
        // return names.join(", ")
    }

    const getPlanPrice = () => {
        const planPrice = parseFloat(plan.price)
        if (promotionActive) {
            const promotion = plan.promotions![0]
            if (promotion.is_percent) {
                const price = planPrice - (planPrice * (promotion.discount_amount / 100))
                return formatCurrency(price, currency)
            } else {
                const price = planPrice - promotion.discount_amount
                return formatCurrency(price, currency)
            }
        }
        if (planPrice > 0) {
            return formatCurrency(planPrice, currency)
        }
        return "GRATIS"
    }

    return (
        <div onClick={onClick} style={{cursor: "pointer"}} className="col-6 col-lg-4 col-xl-3  px-2 mb-5 pl-4">
            <div className="card-hover card card-custom text-center pt-7 pb-5 rounded-lg karpseudo">
                {/* DESKTOP */}
                <div>
                    <p className="text-muted text-uppercase">{plan.name}</p>
                    <p className="h1">{plan.no_limit_credits ? plan.interval_count : plan.credits}</p>
                    <p className="font-weight-bolder mt-n2">{plan.no_limit_credits ? getIntervalTypeText() : plan.credits === 1 ? "CREDITO" : "CREDITOS"}</p>
                    <p className="text-muted">{getDaysDuration()} </p>
                    {/*TODO: map through allowed classes*/}
                    {/* ALLOWED CLASSES ORIGINAL */}
                    {/* <p className="text-muted font-size-sm mb-2">{plan.allow_all_classes ? "Todas las clases" : getStudioClassNames(plan.allowed_classes)}</p> */}
                    {/* FIN ALLOWED CLASSES OG */}
                    <p className="text-muted font-size-sm mb-2">{plan.allow_all_classes ? "Todas las clases" : "*Solo algunas clases*"}</p>

                    <p style={{textDecoration: "line-through"}}
                       className="p-0 m-0 font-size-sm">{promotionActive ? `$${parseInt(plan.price)}` : ''}</p>
                    <p className={`text-primary font-weight-bolder h4 ${promotionActive ? 'text-danger' : 'pt-5'}`}>{getPlanPrice()}</p>
                </div>
                {/* FIN DESKTOP */}

                {/* MOBILE */}

                {/* FIN MOBILE */}
            </div>
        </div>
    );

};

export default MembershipCard;
