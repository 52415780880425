import React from "react";
import {Formik, Form as FormikForm, Field} from "formik";
import {Button, Col, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {AuthState, login} from "../actions";
import {StoreState} from "../reducers";


const Login = () => {
    const dispatch = useDispatch()
    const authState = useSelector<StoreState, AuthState>(state => state.auth)

    return <div className="h-100 w-100 kalogin">
        <div className="row align-items-center kalogin">
            <div className="col-12 ">
                <div className="row justify-content-center">
                    <div className="col-11 col-md-6 col-lg-5 col-xl-4 col-xxl-3 py-30 rounded-lg px-lg-0 kaglass w-100 ml-8 ml-sm-0">
                        <h1 className=" ml-12 mb-8">Hola</h1>
                        <h3 className=" ml-12 mb-15">Iniciar Sesión</h3>
                        <p className="text-danger text-center">{authState.error}</p>
                        <Formik
                            initialValues={{username: "", password: ""}}
                            onSubmit={(values) => {
                                dispatch(login(values.username, values.password))
                            }}>
                            {() => (

                                <FormikForm className="text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Form.Group className="w-75 w-md-75 w-lg-50 ">
                                            <label className="">Correo Electrónico</label>
                                            <Field name="username" className="form-control py-6 py-sm-0"/>
                                        </Form.Group>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Form.Group className="w-75 w-md-75 w-lg-50 ">
                                            <label className="">Contraseña</label>
                                            <Field type="password" name="password" className="form-control py-6 py-sm-0"/>
                                        </Form.Group>
                                    </div>

                                    <div className="text-center">
                                        <Button disabled={authState.loading} className="btn btn-dark btn-pill btn-lg"
                                                type="submit">
                                            Iniciar Sesión
                                        </Button>
                                    </div>
                                    <br/>
                                </FormikForm>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>

        </div>
    </div>;
};

export default Login;
