import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import {AxiosResponse} from 'axios';
import baseApi from "../../../../apis/baseApi";
import {getApiErrorMessage} from "../../../../utils/apiErrors";
import {SaleProductItem} from "../../../Reports/Sales";
import {formatCurrency} from "../../../../utils/currencyFormatters";
import {useSelector} from "react-redux";
import {StoreState} from "../../../../reducers";

type Props = {
    item?: SaleProductItem
    onSuccess: (resp: AxiosResponse<any>) => void
    show: boolean,
    onHide: Function
    data?: object
    method?: string
}

const CancelProductSaleItemModal = ({
                                        item,
                                        show,
                                        onHide,
                                        onSuccess
                                    }: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("")
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)

    const renderAlert = () => {
        if (success === null) return <React.Fragment/>;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    if (!item) return <React.Fragment/>
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik initialValues={{}} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    setAlertMsg("")

                    baseApi.patch(`/sales/products/${item?.id}/`, {cancelled: true}).then((resp) => {
                        setSuccess(true)
                        setAlertMsg("Artículo cancelado correctamente")
                        onSuccess(resp)
                    }).catch((err) => {
                        setAlertMsg(getApiErrorMessage(err))
                        setSuccess(false)
                        setSubmitting(false)
                    })


                }}>
                    {({isSubmitting}) => (
                        <FormikForm>
                            <Modal.Header>
                                Cancelar artículo
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div>Producto</div>
                                <div className="font-size-lg font-weight-bold">
                                    {item?.quantity}x {item?.product}
                                </div>
                                <div className="mt-5">


                                </div>
                                <div className="font-size-lg  mt-5  ">
                                    Total:
                                    <span className="font-size-h5 font-weight-bold ml-2">
                                        {formatCurrency(item!.quantity * parseFloat(item!.price), currency)}
                                    </span>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button variant="danger" type="submit" disabled={isSubmitting}>
                                    {'Cancelar artículo'}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CancelProductSaleItemModal;
