import React, {useEffect, useState} from 'react';
import {Card, Col, Dropdown, DropdownButton, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faExchangeAlt,
    faFileExcel,
    faMoneyBill,
    faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import {endOfDay, endOfMonth, format, formatISO, startOfDay, startOfMonth, subMonths} from "date-fns";
import {es} from "date-fns/locale";
import ReactDatePicker from "react-datepicker";
import CsvDownloadModal from "./CsvDownloadModal";
import baseApi from "../../../apis/baseApi";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import {formatCurrency} from "../../../utils/currencyFormatters";

interface SaleStats {
    total: number,
    online: number,
    cashier: number,
    cards: number,
    cash: number,
    cash_income: number,
    cash_expenses: number,
    transfers: number,
    other: number,
    range_cashier: number,
    range_online: number,
    range_pending: number,
}

interface Props {
    rangeStart: Date;
    rangeEnd: Date;
    setRangeStart: (date: Date) => void;
    setRangeEnd: (date: Date) => void;
}

const SalesStats = ({rangeStart, rangeEnd, setRangeEnd, setRangeStart}: Props) => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)
    const [stats, setStats] = useState<SaleStats>()


    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [downloadRangeStart, setDownloadRangeStart] = useState<Date>();
    const [downloadRangeEnd, setDownloadRangeEnd] = useState<Date>();


    useEffect(() => {
        if (rangeStart && rangeEnd) {
            const start = formatISO(startOfDay(rangeStart))
            const end = formatISO(endOfDay(rangeEnd))
            const monthStart = formatISO(startOfMonth(new Date()))
            const monthEnd = formatISO(endOfMonth(new Date()))
            baseApi.get<SaleStats>(`/sales/stats/?branch=${branch.id}&start=${start}&end=${end}&month_start=${monthStart}&month_end=${monthEnd}`).then(resp => {
                setStats(resp.data)
            }).catch()
        }
    }, [branch, rangeStart, rangeEnd]);

    return (
        <div>
            <CsvDownloadModal show={showDownloadModal} onHide={() => setShowDownloadModal(false)}
                              start={downloadRangeStart} end={downloadRangeEnd} branch={branch}/>
            <Row className="text-right my-5">

                {/*MES*/}
                <Col className="align-self-end" lg={4}>
                    {/*<div className="text-left text-uppercase font-size-lg">{format(new Date(), "MMMM yyyy", {locale: es})}</div>*/}
                </Col>
                {/*FIN MES*/}


                <Col lg={8} className=" font-size-sm align-self-end">
                    <Row>

                        {/*<Col className="text-left ml-5 align-self-end bg-danger">*/}
                        {/*    <div className="">*/}
                        {/*        {*/}
                        {/*            `${format(rangeStart, "dd/MMM/yy", {locale: es})} -*/}
                        {/*            ${rangeEnd === null ? '' : format(rangeEnd, "dd/MMM/yy", {locale: es})}`*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</Col>*/}

                        {/* DATE SELECT ORIGINAL */}
                        {/* <Col lg={8} className="text-right align-self-end">
                                                        <ReactDatePicker
                                                            selectsRange
                                                            selected={rangeStart}
                                                            locale={es}
                                                            value={`${format(rangeStart, "dd MMMM yyyy", {locale: es})}  al  ${rangeEnd === null ? '' : format(rangeEnd, "dd MMMM yyyy", {locale: es})}`}
                                                            startDate={rangeStart}
                                                            endDate={rangeEnd}
                                                            shouldCloseOnSelect={rangeEnd === null}
                                                            maxDate={new Date()}
                                                            onChange={(dates) => {
                                                                if (dates) {
                                                                    const [start, end] = dates as Date[]
                                                                    setRangeStart(start)
                                                                    setRangeEnd(end)

                                                                }
                                                            }}
                                                            customInput={<input className="form-control font-size-h4 text-primary ml-2"  style={{cursor: "pointer"}}/>}
                                                        />
                                                    </Col> */}
                        {/* FIN DATE SELECT ORIGINAL */}
                        <Col className="text-right mr-6">
                            <DropdownButton className='' size="sm" variant="light-success"
                                            title={<span className='font-weight-bold font-size-lg '><FontAwesomeIcon
                                                icon={faFileExcel}/> Descargar Excel</span>}>
                                <Dropdown.Header>Rango Seleccionado</Dropdown.Header>
                                {!rangeStart || !rangeEnd ? <React.Fragment/> :
                                    <Dropdown.Item onClick={() => {
                                        setDownloadRangeStart(rangeStart)
                                        setDownloadRangeEnd(rangeEnd)
                                        setShowDownloadModal(true)
                                    }}>{format(rangeStart, "dd/MM/yy")} - {format(rangeEnd, "dd/MM/yy")}</Dropdown.Item>
                                }
                                <Dropdown.Divider/>
                                <Dropdown.Header>Períodos</Dropdown.Header>
                                {Array.from(Array(12).keys()).map(i => {
                                    const month = subMonths(new Date(), i)
                                    return <Dropdown.Item key={i} onClick={() => {
                                        setDownloadRangeStart(startOfMonth(month))
                                        setDownloadRangeEnd(endOfMonth(month))
                                        setShowDownloadModal(true)
                                    }}>
                                        {format(month, "MMMM - yyyy", {locale: es})}
                                    </Dropdown.Item>
                                })}
                            </DropdownButton>
                        </Col>
                    </Row>


                </Col>
                {/*<Col lg={4} className="text-right align-self-end">*/}
                {/*    <ReactDatePicker*/}
                {/*        selectsRange*/}
                {/*        selected={rangeStart}*/}
                {/*        locale={es}*/}
                {/*        value={`${format(rangeStart, "dd MMMM yyyy", {locale: es})}  al  ${rangeEnd === null ? '' : format(rangeEnd, "dd MMMM yyyy", {locale: es})}`}*/}
                {/*        startDate={rangeStart}*/}
                {/*        endDate={rangeEnd}*/}
                {/*        shouldCloseOnSelect={rangeEnd === null}*/}
                {/*        maxDate={new Date()}*/}
                {/*        onChange={(dates) => {*/}
                {/*            if (dates) {*/}
                {/*                const [start, end] = dates as Date[]*/}
                {/*                setRangeStart(start)*/}
                {/*                setRangeEnd(end)*/}

                {/*            }*/}
                {/*        }}*/}
                {/*        customInput={<input className="form-control font-size-h4"/>}*/}
                {/*    />*/}
                {/*</Col>*/}
            </Row>


            <Row className="mb-10 mt-n3">
                <Col className=' d-none d-md-inline' lg={4}>
                    <Card className="card-custom px-3 pl-xxl-11 pr-xxl-2 py-27  mr-xxl-1 d-none d-xxl-block">
                        {/*<div className="pb-2 text-warning">Estamos mejorando el diseño, regresa más tarde para ver tus estadísticas mensuales.</div>*/}
                        <Row>
                            <Col className='col-xl-6'>
                                <div className="text-muted font-weight-bolder font-size-h6">TOTAL ESTE MES</div>
                                <div className="font-weight-bolder" style={{fontSize: "30px"}}>
                                    {formatCurrency(stats?.total || 0, currency)}
                                </div>
                                {/*{renderPercentChange()}*/}
                            </Col>
                            <Col className='col-xl-6'>
                                <div className="text-primary font-weight-bolder font-size-lg">Pagos en línea</div>
                                <div
                                    className="font-weight-bolder font-size-h5">
                                    {formatCurrency(stats?.online || 0, currency)}
                                </div>
                                <div className="py-3"></div>
                                <div className="text-warning font-weight-bolder font-size-lg">Pagos Presenciales</div>
                                <div
                                    className="font-weight-bolder font-size-h5">
                                    {formatCurrency(stats?.cashier || 0, currency)}
                                </div>
                            </Col>
                        </Row>
                    </Card>


                    {/* MD-XL SIZES ESTE MES CARD */}
                    <Card className="card-custom d-xxl-none px-3 py-25 mb-md-4 mb-lg-0">
                        {/*<div className="pb-2 text-warning">Estamos mejorando el diseño, regresa más tarde para ver tus estadísticas mensuales.</div>*/}
                        <Row>
                            <Col className='col-12 text-center'>
                                <div className="text-muted font-weight-bolder font-size-h6">TOTAL ESTE MES</div>
                                <div className="font-weight-bolder display4">
                                    {formatCurrency(stats?.total || 0, currency)}
                                </div>
                                {/*{renderPercentChange()}*/}
                            </Col>
                            <Col className='col-12 text-center mt-4'>
                                <div className="d-lg-flex justify-content-between">
                                    <div>
                                        <div className="text-primary font-weight-bold ">Pagos en línea</div>
                                        <div
                                            className="font-weight-bolder font-size-h6">
                                            {formatCurrency(stats?.online || 0, currency)}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-warning font-weight-bold ">Pagos Presenciales</div>
                                        <div
                                            className="font-weight-bolder font-size-h6">
                                            {formatCurrency(stats?.cashier || 0, currency)}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                {/* FIN MEDIUM SIZES CARD */}

                {/* MOBILE XS - SM */}
                {/* MOBILE TOTAL ESTE MES */}
                <Col className='col-12 mb-2  d-md-none'>
                    <Card className="card-custom px-2 px-xxl-15 py-5  mr-xxl-6 mr-lg-">
                        {/*<div className="pb-2 text-warning">Estamos mejorando el diseño, regresa más tarde para ver tus estadísticas mensuales.</div>*/}
                        <Row className='align-items-center'>
                            <Col className='text-center'>
                                <div className="text-muted font-weight-bolder font-size-h6">TOTAL ESTE MES</div>
                                <div
                                    className="font-weight-bolder font-size-h1">
                                    {formatCurrency(stats?.total || 0, currency)}
                                </div>
                                {/*{renderPercentChange()}*/}
                            </Col>
                            <Col>
                                <div className="text-primary font-weight-bolder font-size-lg">Pagos en línea</div>
                                <div
                                    className="font-weight-bolder font-size-h6">
                                    {formatCurrency(stats?.online || 0, currency)}
                                </div>
                                <div className="py-3"></div>
                                <div className="text-warning font-weight-bolder font-size-lg">Pagos Presenciales</div>
                                <div
                                    className="font-weight-bolder font-size-h6">
                                    {formatCurrency(stats?.cashier || 0, currency)}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                {/* FIN MOBILE CARD ESTE MES */}


                {/* PRUEBAS */}
                {/* PRUEBAS */}
                {/* PRUEBAS */}
                {/* PRUEBAS */}
                {/* PRUEBAS */}
                {/* PRUEBAS */}

                <Col className='' lg={8}>
                    <Card className="card-custom px-2 px-xxl-15 pt-7 pb-6  mr-xxl-6 mr-lg-">
                        {/*<div className="pb-2 text-warning">Estamos mejorando el diseño, regresa más tarde para ver tus estadísticas mensuales.</div>*/}
                        <Row>


                            {/* DESKTOP DATEPICKER */}
                            <Col lg={12} className="justify-content-center d-none d-sm-inline">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div>
                                        <ReactDatePicker
                                            selectsRange
                                            selected={rangeStart}
                                            locale={es}
                                            value={`${format(rangeStart, "d MMMM yyyy", {locale: es})}  →  ${rangeEnd === null ? '' : format(rangeEnd, "d MMMM yyyy", {locale: es})}`}
                                            startDate={rangeStart}
                                            endDate={rangeEnd}
                                            shouldCloseOnSelect={rangeEnd === null}
                                            maxDate={new Date()}
                                            onChange={(dates) => {
                                                if (dates) {
                                                    const [start, end] = dates as Date[]
                                                    setRangeStart(start)
                                                    setRangeEnd(end)
                                                }
                                            }}
                                            customInput={<input
                                                className="form-control label label-inline label-light- text-dark-50 rounded-xl pt-5 pb-5 font-size-h5  font-weight-bold text-center min-w-350px border-0"
                                                style={{cursor: "pointer"}}/>}

                                        />
                                    </div>

                                    <div>
                                        <ReactDatePicker
                                            selectsRange
                                            selected={rangeStart}
                                            locale={es}
                                            value={`${format(rangeStart, "d MMMM yyyy", {locale: es})}  -  ${rangeEnd === null ? '' : format(rangeEnd, "d MMMM yyyy", {locale: es})}`}
                                            startDate={rangeStart}
                                            endDate={rangeEnd}
                                            shouldCloseOnSelect={rangeEnd === null}
                                            maxDate={new Date()}
                                            onChange={(dates) => {
                                                if (dates) {
                                                    const [start, end] = dates as Date[]
                                                    setRangeStart(start)
                                                    setRangeEnd(end)
                                                }
                                            }}
                                            customInput={<span className='ml-n6'><FontAwesomeIcon icon={faEdit}
                                                                                                  style={{cursor: "pointer"}}
                                                                                                  className="text-primary"/></span>}
                                        />
                                    </div>
                                </div>
                            </Col>
                            {/* FIN DESKTOP DATEPICKER */}

                            {/* MOBILE DATEPICKER */}
                            <Col lg={12} className="justify-content-center d-sm-none">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div>
                                        <ReactDatePicker
                                            selectsRange
                                            selected={rangeStart}
                                            locale={es}
                                            value={`${format(rangeStart, "d MMMM yyyy", {locale: es})}  -  ${rangeEnd === null ? '' : format(rangeEnd, "d MMMM yyyy", {locale: es})}`}
                                            startDate={rangeStart}
                                            endDate={rangeEnd}
                                            shouldCloseOnSelect={rangeEnd === null}
                                            maxDate={new Date()}
                                            onChange={(dates) => {
                                                if (dates) {
                                                    const [start, end] = dates as Date[]
                                                    setRangeStart(start)
                                                    setRangeEnd(end)
                                                }
                                            }}
                                            customInput={<input
                                                className="form-control label label-inline label-light- text-dark-50 rounded-xl pt-5 pb-5 font-size-h6  font-weight-bold text-center min-w-300px border-0"
                                                style={{cursor: "pointer"}}/>}

                                        />
                                    </div>

                                    <div>
                                        <ReactDatePicker
                                            selectsRange
                                            selected={rangeStart}
                                            locale={es}
                                            value={`${format(rangeStart, "d MMMM yyyy", {locale: es})}  -  ${rangeEnd === null ? '' : format(rangeEnd, "d MMMM yyyy", {locale: es})}`}
                                            startDate={rangeStart}
                                            endDate={rangeEnd}
                                            shouldCloseOnSelect={rangeEnd === null}
                                            maxDate={new Date()}
                                            onChange={(dates) => {
                                                if (dates) {
                                                    const [start, end] = dates as Date[]
                                                    setRangeStart(start)
                                                    setRangeEnd(end)
                                                }
                                            }}
                                            customInput={<span className='ml-n6'><FontAwesomeIcon icon={faEdit}
                                                                                                  style={{cursor: "pointer"}}
                                                                                                  className="text-primary"/></span>}
                                        />
                                    </div>
                                </div>
                            </Col>
                            {/* FIN MOBILE DATEPICKER */}


                            <Col>
                                <div className='text-center mb-1 mt-2'>
                                    <div className="font-weight-bold text-muted font-size-xs py-1">TOTAL FECHAS
                                        SELECCIONADAS↑
                                    </div>
                                    <div
                                        className="font-weight-bolder font-size-h1 mt-n2">
                                        {formatCurrency((stats?.range_online ?? 0) + (stats?.range_cashier ?? 0), currency)}
                                    </div>
                                </div>

                                <div className="d-flex text-center justify-content-center mb-4">

                                    <div className='mr-4'>
                                        <div className="text-primary font-weight-bold font-size-lg">Pagos en línea</div>
                                        <div
                                            className="font-weight-bolder font-size-h5 label label-inline label-light-primary">
                                            {formatCurrency(stats?.range_online ?? 0, currency)}
                                        </div>
                                    </div>
                                    <div className='ml-4'>
                                        <div className="text-warning font-weight-bold font-size-lg">Pagos Presenciales
                                        </div>
                                        <div
                                            className="font-weight-bolder font-size-h5 label label-inline label-light-warning">
                                            {formatCurrency(stats?.range_cashier ?? 0, currency)}
                                        </div>
                                    </div>
                                    {/* <div className='ml-4'>
                                        <div className="text-danger font-weight-bold font-size-lg">Por cobrar</div>
                                        <div
                                            className="font-weight-bolder font-size-h5 label label-inline label-light-danger">
                                            ${stats?.range_pending}
                                        </div>
                                    </div> */}
                                </div>
                            </Col>

                        </Row>

                        <hr style={{borderColor: "#B5B5C320"}}/>


                        <div className='d-flex'>
                            <div className="w-100 h-100  ml-2 align-items-center text-center py-3 border-right">
                                <div className="mt-auto mb-auto">
                                    <div className="font-weight-bold font-size-lg text-muted">
                                        <FontAwesomeIcon className='text-muted mr-1 d-none d-md-inline'
                                                         icon={faMoneyBill}/>Efectivo
                                    </div>
                                    <div
                                        className="font-weight-bolder font-size-h4 ">{formatCurrency(stats?.cash ?? 0, currency)}</div>
                                </div>
                            </div>

                            <div className="w-100 h-100  ml-2 align-items-center text-center py-3  border-right">
                                <div className="mt-auto mb-auto">
                                    <div className="font-weight-bold font-size-lg text-muted">
                                        <FontAwesomeIcon className='text-muted mr-1 d-none d-md-inline'
                                                         icon={faMoneyCheck}/>Terminal
                                    </div>
                                    <div
                                        className="font-weight-bolder font-size-h4">{formatCurrency(stats?.cards ?? 0, currency)}</div>
                                </div>
                            </div>
                            <div className="w-100 h-100  ml-2 align-items-center text-center py-3  border-right">
                                <div className="mt-auto mb-auto">
                                    <div className="font-weight-bold font-size-lg text-muted">
                                        <FontAwesomeIcon className='text-muted mr-1 d-none d-md-inline'
                                                         icon={faExchangeAlt}/>Transferencia
                                    </div>
                                    <div
                                        className="font-weight-bolder font-size-h4">{formatCurrency(stats?.transfers ?? 0, currency)}</div>
                                </div>
                            </div>
                            <div className="w-100 h-100  ml-2 align-items-center text-center py-3">
                                <div className="mt-auto mb-auto">
                                    <div className="font-weight-bold font-size-lg text-muted">Otro N/A</div>
                                    <div
                                        className="font-weight-bolder font-size-h4">{formatCurrency(stats?.other ?? 0, currency)}</div>
                                </div>
                            </div>
                        </div>

                    </Card>
                </Col>


                {/* PRUEBAS */}
                {/* PRUEBAS */}
                {/* PRUEBAS */}
                {/* PRUEBAS */}
                {/* PRUEBAS */}
                {/* PRUEBAS */}
                {/* PRUEBAS */}
                {/* PRUEBAS */}

                {/* ORIGINAL CARD STATS */}
                {/* <Col lg={8} className="text-right  d-none d-md-inline">

                                            <Row className="h-100">
                                                <Col className="h-100  mb-15">
                                                    <div className="d-flex justify-content-between h-100">
                                                        <Card className="w-100 h-100 card-custom ml-2 align-items-center text-center">
                                                            <div className="mt-auto mb-auto">
                                                                <div className="font-weight-bolder font-size-h6">Efectivo</div>
                                                                <div className="font-weight-bolder font-size-h4 mt-2 text-muted">${stats?.cash}</div>
                                                            </div>
                                                        </Card>
                                                        <Card className="w-100 h-100 card-custom ml-2 align-items-center text-center">
                                                            <div className="mt-auto mb-auto">
                                                                <div className="font-weight-bolder font-size-h6">Tarjeta POS</div>
                                                                <div className="font-weight-bolder font-size-h4 mt-2 text-muted">${stats?.cards}</div>
                                                            </div>
                                                        </Card>
                                                        <Card className="w-100 h-100 card-custom ml-2 align-items-center text-center">
                                                            <div className="mt-auto mb-auto">
                                                                <div className="font-weight-bolder font-size-h6">Transferencia</div>
                                                                <div className="font-weight-bolder font-size-h4 mt-2 text-muted">${stats?.transfers}</div>
                                                            </div>
                                                        </Card>
                                                        <Card className="w-100 h-100 card-custom ml-2 align-items-center text-center">
                                                            <div className="mt-auto mb-auto">
                                                                <div className="font-weight-bolder font-size-h6">Otro N/A</div>
                                                                <div className="font-weight-bolder font-size-h4 mt-2 text-muted">${stats?.other}</div>
                                                            </div>
                                                        </Card>
                                                        <Card className="w-100 h-100 card-custom ml-2 align-items-center text-center">
                                                            <div className="font-weight-bolder font-size-h5 my-2">TOTALES</div>
                                                            <div className="">
                                                                <div className="font-weight-bolder font-size- text-muted">Presencial</div>
                                                                <div className="font-weight-bolder font-size-h4 mt-n2 mb-3 text-warning">${stats?.range_cashier}</div>
                                                                <div className="font-weight-bolder font-size- text-muted">En línea</div>
                                                                <div className="font-weight-bolder font-size-h4 mt-n2 mb-3 text-primary">${stats?.range_online}</div>
                                                                <div className="font-weight-bolder font-size- text-muted">Por cobrar</div>
                                                                <div className="font-weight-bolder font-size-h3 mt-n2 text-danger mb-n8">${stats?.range_pending}</div>
                                                            </div>
                                                        </Card>
                                                        <Card
                                                            className="w-100 h-100 card-custom ml-2 align-items-center text-center border bg-transparent">
                                                            <div className="">
                                                                <div className="font-weight-bolder font-size-h5 my-2">CAJA EFECTIVO</div>

                                                                <div className="font-weight-bolder text-muted">Ingresos Efectivo</div>
                                                                <div
                                                                    className="font-weight-bold font-size-h5 mt-n2 text-success mb-3">+${(stats?.cash_income ?? 0) +(stats?.cash ?? 0)}</div>
                                                                <div className="font-weight-bolder text-muted">Egresos</div>
                                                                <div
                                                                    className="font-weight-bold font-size-h5 mt-n2 text-danger mb-3">-${stats?.cash_expenses}</div>
                                                                <div className="font-weight-bolder text-muted">Total en caja</div>
                                                                <div
                                                                    className="font-weight-bold font-size-h5 mt-n2  mb-n8">${(stats?.cash_expenses ?? 0) + (stats?.cash_income ?? 0) + (stats?.cash ?? 0)}</div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col> */}
                {/* FIN ORIGINAL CARD STATS */}

            </Row>

            {/* PRUEBAS CAJA */}
            <Row className=' mt-n9'>
                <Col lg={4}></Col>
                <Col lg={8}>
                    <div className="d-flex justify-content-between">
                        <div className='d-flex'>
                            <div className='text-dark-50 font-weight-bold mr-5'>
                                Caja Efectivo:
                                <span
                                    className='text-success ml-2 font-size-lg'>+{formatCurrency((stats?.cash_income ?? 0) + (stats?.cash ?? 0), currency)}</span>
                            </div>
                            <div className='text-dark-50 font-weight-bold mr-5'>
                                Caja Egresos:
                                <span className='text-danger ml-2 font-size-lg'>-{formatCurrency(stats?.cash_expenses ?? 0, currency)}</span>
                            </div>
                            <div className='text-dark-50 font-weight-bold'>
                                Caja Total Efectivo:
                                <span
                                    className='text-dark ml-2 font-size-lg'>{formatCurrency((stats?.cash_expenses ?? 0) + (stats?.cash_income ?? 0) + (stats?.cash ?? 0), currency)}</span>
                            </div>
                        </div>
                        <div className='d-flex mr-5'>
                            <div className="text-danger font-weight-bold font-size-lg mr-1">Por cobrar:</div>
                            <div
                                className="font-weight-bolder font-size-h5 label label-inline label-light-danger">{formatCurrency(stats?.range_pending ?? 0, currency)}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* PRUEBAS CAJA */}


            {/* MOBILE */}
            {/* MOBILE */}
            {/* MOBILE */}
            {/* MOBILE */}
            {/* MOBILE ORIGINAL*/}

            {/* <Row className="mb-10 mt-n3 d-md-none">

                                        <Col className='col-12 mb-2  d-md-none'>
                                            <Card className="card-custom px-2 px-xxl-15 py-5  mr-xxl-6 mr-lg-">
                                                
                                                <Row className='align-items-center'>
                                                    <Col className='text-center'>
                                                        <div className="text-muted font-weight-bolder font-size-h6">TOTAL ESTE MES</div>
                                                        <div
                                                            className="font-weight-bolder font-size-h1">
                                                            {currencyFormat.format(stats?.total || 0)}
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="text-primary font-weight-bolder font-size-lg">Pagos en línea</div>
                                                        <div
                                                            className="font-weight-bolder font-size-h6">
                                                            {currencyFormat.format(stats?.online || 0)}
                                                        </div>
                                                        <div className="py-3"></div>
                                                        <div className="text-warning font-weight-bolder font-size-lg">Pagos Presenciales</div>
                                                        <div
                                                            className="font-weight-bolder font-size-h6">
                                                            {currencyFormat.format(stats?.cashier || 0)}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        
                                                                        <Col className="text-right">

                                                                            <Row className="h-100">
                                                                                <Col className="  mb-15 ">
                                                                                    
                                                                                    <div className='text-center font-weight-bold font-size-h3'>Rango Seleccionado</div>
                                                                                    <div className="justify-content-between">
                                                                                    <Card className="w-100 h-100 card-custom ml-2 align-items-center text-center mb-2">
                                                                                            <div className="font-weight-bolder font-size-h5 my-2">TOTALES</div>
                                                                                            <div className="">
                                                                                                <div className="font-weight-bolder font-size- text-muted">Presencial</div>
                                                                                                <div className="font-weight-bolder font-size-h4 mt-n2 mb-3 text-warning">${stats?.range_cashier}</div>
                                                                                                <div className="font-weight-bolder font-size- text-muted">En línea</div>
                                                                                                <div className="font-weight-bolder font-size-h4 mt-n2 mb-3 text-primary">${stats?.range_online}</div>
                                                                                                <div className="font-weight-bolder font-size- text-muted">Por cobrar</div>
                                                                                                <div className="font-weight-bolder font-size-h3 mt-n2 text-danger">${stats?.range_pending}</div>
                                                                                            </div>
                                                                                        </Card>
                                                                                        <Card className="w-100 h-100 card-custom ml-2 align-items-center text-center">
                                                                                            <div className="mt-auto mb-auto">
                                                                                                <div className="font-weight-bolder font-size-h6">Efectivo</div>
                                                                                                <div className="font-weight-bolder font-size-h4 mt-2 text-muted">${stats?.cash}</div>
                                                                                            </div>
                                                                                        </Card>
                                                                                        <Card className="w-100 h-100 card-custom ml-2 align-items-center text-center">
                                                                                            <div className="mt-auto mb-auto">
                                                                                                <div className="font-weight-bolder font-size-h6">Tarjeta POS</div>
                                                                                                <div className="font-weight-bolder font-size-h4 mt-2 text-muted">${stats?.cards}</div>
                                                                                            </div>
                                                                                        </Card>
                                                                                        <Card className="w-100 h-100 card-custom ml-2 align-items-center text-center">
                                                                                            <div className="mt-auto mb-auto">
                                                                                                <div className="font-weight-bolder font-size-h6">Transferencia</div>
                                                                                                <div className="font-weight-bolder font-size-h4 mt-2 text-muted">${stats?.transfers}</div>
                                                                                            </div>
                                                                                        </Card>
                                                                                        <Card className="w-100 h-100 card-custom ml-2 align-items-center text-center">
                                                                                            <div className="mt-auto mb-auto">
                                                                                                <div className="font-weight-bolder font-size-h6">Otro N/A</div>
                                                                                                <div className="font-weight-bolder font-size-h4 mt-2 text-muted">${stats?.other}</div>
                                                                                            </div>
                                                                                        </Card>
                                                                                        
                                                                                        <Card
                                                                                            className="w-100 h-100 card-custom ml-2 align-items-center text-center border bg-transparent">
                                                                                            <div className="">
                                                                                                <div className="font-weight-bolder font-size-h5 my-2">CAJA EFECTIVO</div>

                                                                                                <div className="font-weight-bolder text-muted">Ingresos Efectivo</div>
                                                                                                <div
                                                                                                    className="font-weight-bold font-size-h5 mt-n2 text-success mb-3">+${(stats?.cash_income ?? 0) +(stats?.cash ?? 0)}</div>
                                                                                                <div className="font-weight-bolder text-muted">Egresos</div>
                                                                                                <div
                                                                                                    className="font-weight-bold font-size-h5 mt-n2 text-danger mb-3">-${stats?.cash_expenses}</div>
                                                                                                <div className="font-weight-bolder text-muted">Total en caja</div>
                                                                                                <div
                                                                                                    className="font-weight-bold font-size-h5 mt-n2  mb-n8">${(stats?.cash_expenses ?? 0) + (stats?.cash_income ?? 0) + (stats?.cash ?? 0)}</div>
                                                                                            </div>
                                                                                        </Card>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>

                                    </Row> */}
            {/* END MOBILE */}
            {/* END MOBILE */}
            {/* END MOBILE */}
            {/* END MOBILE */}
            {/* END MOBILE */}


            <Row>
                <Col lg={8} className="text-right align-self-end">
                    <ReactDatePicker
                        selectsRange
                        selected={rangeStart}
                        locale={es}
                        value={`${format(rangeStart, "dd MMMM yyyy", {locale: es})}  al  ${rangeEnd === null ? '' : format(rangeEnd, "dd MMMM yyyy", {locale: es})}`}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        shouldCloseOnSelect={rangeEnd === null}
                        maxDate={new Date()}
                        onChange={(dates) => {
                            if (dates) {
                                const [start, end] = dates as Date[]
                                setRangeStart(start)
                                setRangeEnd(end)

                            }
                        }}
                        customInput={<input className="form-control font-size-h4 text-primary bg-transparent border-0"
                                            style={{cursor: "pointer"}}/>}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default SalesStats;