import {StudioActionTypes} from "./types";
import {StudioClassCategory} from "./studioClasses";

export interface Studio {
    id: string
    active: boolean,
    name: string
    image: string
    cover_image: string | null
    instagram: string
    facebook: string
    whatsapp: string
    preferences: StudioPreferences
    studio_class_categories: StudioClassCategory[]
}

export interface StudioPreferences {
    automatic_waitlist: boolean;
    currency: string;
    hours_to_cancellation: number;
    payment_processor: string | null;
    paypal: boolean;
    public: string | null;
    terms_and_conditions: string | null;
    shoe_size_country: string;
    uses_shoe_field: boolean;
    uses_last_name: boolean;
}


export type StudioActions = {
    type: StudioActionTypes,
    payload: Studio
}

export const setStudio = (studio: Studio) => {
    return {
        type: StudioActionTypes.RETRIEVED_STUDIO,
        payload: studio
    }
}

export const addClassCategory = (cat: StudioClassCategory) => {
    return {
        type: StudioActionTypes.ADDED_CLASS_CATEGORY,
        payload: cat
    }
}

export const updatedClassCategory = (cat: StudioClassCategory) => {
    return {
        type: StudioActionTypes.UPDATED_CLASS_CATEGORY,
        payload: cat
    }
}