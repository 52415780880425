import {StudioClass, StudioClassesActions, StudioClassesState} from "../actions/studioClasses";
import {StudioClassesTypes} from "../actions";

const initialState: StudioClassesState = {
    loading: true,
    error: "",
    classes: []
}

export const studioClassesReducer = (state = initialState, action: StudioClassesActions): StudioClassesState => {
    switch (action.type) {
        case StudioClassesTypes.FETCHING:
            return {...state, loading: true}
        case StudioClassesTypes.FETCHED:
            let classes: StudioClass[] = action.payload
            classes.sort((a, b) => a.name.localeCompare(b.name))
            return {loading: false, error: "", classes: action.payload}
        case StudioClassesTypes.FETCHING_FAILED:
            return {loading: false, error: "ERROR", classes: []}
        case StudioClassesTypes.ADD_CLASS:
            let classes2: StudioClass[] = [...state.classes, action.payload]
            classes2.sort((a, b) => a.name.localeCompare(b.name))
            return {...state, classes: classes2}
        case StudioClassesTypes.REMOVE_CLASS:
            return {...state, classes: state.classes.filter(c => c.id !== action.payload)}
        case StudioClassesTypes.UPDATE_CLASS:
            return {...state, classes: state.classes.map((c) => c.id === action.payload.id ? action.payload : c)}
        default:
            return state
    }
}