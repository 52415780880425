import {CoachesActions, CoachesState} from "../actions/coaches";
import {CoachesActionTypes} from "../actions";

const initialState : CoachesState = {
    loading: true,
    error: "",
    coaches: []
}

export const coachesReducer = (state= initialState, action : CoachesActions) : CoachesState => {
    switch (action.type) {
        case CoachesActionTypes.FETCHING:
            return {...state, loading: true}
        case CoachesActionTypes.FETCHED:
            return {loading: false, error: "", coaches: action.payload}
        case CoachesActionTypes.ADD_COACH:
            return {...state, coaches: [...state.coaches, action.payload]}
        case CoachesActionTypes.REMOVE_COACH:
            return {...state, coaches: state.coaches.filter(c => c.id !== action.payload)}
        case CoachesActionTypes.UPDATE_COACH:
            return {...state, coaches: state.coaches.map((c) => c.id === action.payload.id ? action.payload : c)}
        default:
            return state
    }
}