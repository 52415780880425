import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Table} from "react-bootstrap";

import AddMemberPlanModal from './Modals/AddMemberPlanModal';
import MemberPlanTr from "../../../components/table/rows/MemberPlanTr";
import {StudioPlan} from "../../../actions/studioPlans";
import baseApi from "../../../apis/baseApi";
import TableFooter from "../../../components/table/TableFooter";
import TableHeader from "../../../components/table/TableHeader";
import DeleteModal from "../../../components/modals/DeleteModal";
import {formatDate} from "../../../utils/dateFormatters";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import EditPlanExpirationModal from "./Modals/EditPlanExpirationModal";
import {Member} from "../index";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import Select from "react-select";
import PauseMembershipModal from "./Modals/PauseMembershipModal";
import MembershipDetails from "./MembershipDetails";


export interface Membership {
    id: string
    expire_on: string
    created_on: string
    cancelled: boolean
    plan: StudioPlan
    credits_available: number | null
    activate_on: string
    subscription_id: string | null
    pause_schedules: MembershipPauseSchedule[]
    sale: string | null
    plan_sale_item_total?: string | null
}

export interface MembershipPauseSchedule {
    id: number
    status: string
    pause_start: string
    pause_end: string
    created_on: string
    membership: string
    created_by: string
}

type MemberPlanProps = {
    member: Member
    setMember: (member: Member) => void
}

const Memberships = ({member, setMember}: MemberPlanProps) => {
    const studioPlans = useSelector<StoreState, StudioPlan[]>(state => state.studioPlans.plans)
    const [memberPlans, setMemberPlans] = useState<Membership[]>([])
    const [showAdd, setShowAdd] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showEditExpirationModal, setShowEditExpirationModal] = useState(false);
    const [showPauseModal, setShowPauseModal] = useState(false);
    const [selectedMembership, setSelectedMembership] = useState<Membership>()
    const [viewMembershipDetails, setViewMembershipDetails] = useState<Membership>()
    const count = useRef(0)


    const [submittingEventInvite, setSubmittingEventInvite] = useState(false);
    const [inviteMsg, setInviteMsg] = useState<string>();
    const [inviteSuccess, setInviteSuccess] = useState<boolean>();

    const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState({value: false, label: "Cancelar inmediatamente"});

    useEffect(() => {
        baseApi.get(`/memberships/history/?member=${member.id}`).then((resp) => {
            count.current = resp.data.count
            setMemberPlans(resp.data.results)
        }).catch((err) => alert("ERROR"))
    }, [member])

    const handlePageChange = (page: number) => {
        baseApi.get(`/memberships/history/?member=${member.id}&page=${page}`).then((resp) => {
            setMemberPlans(resp.data.results)
        }).catch((err) => alert("ERROR"))
    }

    const showAlert = () => {
        if (inviteMsg === undefined) return <React.Fragment/>
        return <Alert variant={inviteSuccess ? "success" : "danger"}>{inviteMsg}</Alert>
    }

    const renderInviteButton = () => {
        if (studioPlans.some(e => e.id === "7645bea5-09cb-46f2-9b34-ab17b31ec22b")) {
            return <div className="text-right">
                <Button disabled={submittingEventInvite} onClick={() => {
                    setSubmittingEventInvite(true)
                    baseApi.patch("/plans/invite/7645bea5-09cb-46f2-9b34-ab17b31ec22b/", {
                        "member_id": member.id,
                    }).then(resp => {
                            setInviteSuccess(true)
                            setInviteMsg("Usuario invitado a evento especial")
                        }
                    ).catch((err) => {
                        setInviteSuccess(false)
                        setInviteMsg(getApiErrorMessage(err))
                    })
                }} size="sm" variant="link">Invitar a evento especial</Button>
            </div>
        }
        return <React.Fragment/>
    }

    if (viewMembershipDetails) {
        return <MembershipDetails member={member} membership={viewMembershipDetails} onClose={() => setViewMembershipDetails(undefined)} />
    }
    return (
        <div className="p-4">
            <AddMemberPlanModal show={showAdd} onHide={() => setShowAdd(false)} memberId={member.id}/>
            <PauseMembershipModal show={showPauseModal} onHide={() => {
                setShowPauseModal(false);
                setSelectedMembership(undefined)
            }}
                  membership={selectedMembership!}
                  memberPlans={memberPlans}
                  setMemberPlans={setMemberPlans}
            />
            <EditPlanExpirationModal
                membership={selectedMembership}
                show={showEditExpirationModal}
                memberPlans={memberPlans}
                setMemberPlans={setMemberPlans}
                onHide={() => {
                    setShowEditExpirationModal(false)
                    setSelectedMembership(undefined)
                }}
                member={member}
                setMember={setMember}/>
            {/*<div className="float-right">*/}
            {/*    <Button onClick={() => setShowAdd(true)} size="sm">+ Agregar Plan</Button>*/}
            {/*</div>*/}
            <DeleteModal
                url={`/memberships/update/${selectedMembership?.id}/?cancel_at_period_end=${cancelAtPeriodEnd.value}`}
                onSuccess={() => {
                    if (selectedMembership?.credits_available && selectedMembership!.credits_available > 0) {
                        setMember({
                            ...member,
                            current_credits: member.current_credits - selectedMembership!.credits_available
                        })
                    }
                    setMemberPlans(memberPlans.map(m => m.id === selectedMembership?.id ? {
                        ...m,
                        cancelled: true
                    } : m))
                }}
                text={"Cancelar Membresía"}
                successText={"Membresía cancelada correctamente."}
                show={showCancelModal}
                onHide={() => {
                    setShowCancelModal(false)
                    setSelectedMembership(undefined)
                    setCancelAtPeriodEnd({value: false, label: "Cancelar inmediatamente"})
                }}
            >
                <p>¿Seguro que deseas cancelar esta membresía del usuario?</p>
                <p>{selectedMembership?.plan.name}</p>
                <p>Vigencia: {formatDate(selectedMembership?.expire_on)}</p>
                <p hidden={cancelAtPeriodEnd.value} className="text-danger"><small>*Todas
                    las <strong>reservaciones</strong> realizadas con esta
                    membresía serán <strong>canceladas</strong>.</small></p>
                {/*STRIPE*/}
                <div hidden={!selectedMembership?.subscription_id}>
                    <div className=" align-content-center">
                        <div className="font-weight-bold">
                            <div className="align-self-center mr-3">Cancelación</div>
                        </div>
                        <div className="" style={{width: "300px"}}>
                            <Select
                                value={cancelAtPeriodEnd}
                                options={[
                                    {value: true, label: "Cancelar al final del periodo"}, {
                                        value: false,
                                        label: "Cancelar inmediatamente"
                                    }
                                ]}
                                onChange={(o) => setCancelAtPeriodEnd(o!)}
                            />
                        </div>
                    </div>
                </div>
            </DeleteModal>
            {showAlert()}

            {renderInviteButton()}
            <Table hover>
                <TableHeader headers={[
                    "#",
                    "FECHA COMPRA",
                    "NOMBRE",
                    "IMPORTE",
                    "CRÉDITOS DISPONIBLES",
                    "VENCIMIENTO",
                    "ESTADO",
                    "ACCIÓN"
                ]}/>

                <tbody>
                {memberPlans.length === 0 ? <EmptyTr/> : memberPlans.map((memberPlan) => {
                        return <MemberPlanTr
                            key={memberPlan.id}
                            memberPlan={memberPlan}
                            onDetailsClicked={() => {
                                setViewMembershipDetails(memberPlan)
                            }}
                            onCancel={() => {
                                if (memberPlan.subscription_id) {
                                    setCancelAtPeriodEnd({value: true, label: "Cancelar al final del periodo"})
                                }
                                setSelectedMembership(memberPlan)
                                setShowCancelModal(true)
                            }}
                            onEditExpiration={() => {
                                if (memberPlan.cancelled) return
                                setSelectedMembership(memberPlan)
                                setShowEditExpirationModal(true)
                            }}
                            onPauseClicked={() => {
                                setSelectedMembership(memberPlan)
                                setShowPauseModal(true)
                            }}
                        />
                    }
                )}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={memberPlans.length} pageSize={10}
                         onPageChange={handlePageChange}/>

        </div>
    );
};

export default Memberships;
