import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {removeClass, StudioClass} from "../../../actions/studioClasses";
import DeleteModal from "../../../components/modals/DeleteModal";
import CreateEditClassModal from "./CreateEditClassModal";
import {Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import TableActionsCell from "../../../components/table/TableActionsCell";
import {Branch} from "../../../actions";
import baseApi from "../../../apis/baseApi";

export interface TotalpassPlan {
    code: string;
    createdAt: string;
    deletedAt: null;
    id: number;
    name: string;
    placeId: number;
    updatedAt: string;
}

const StudioClassesListView = () => {
    const dispatch = useDispatch()
    const classes = useSelector<StoreState, StudioClass[]>(state => state.studioClasses.classes)
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)

    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [selectedClass, setSelectedClass] = useState<StudioClass>();
    const [totalpassPlans, setTotalpassPlans] = useState<TotalpassPlan[]>([]);

    useEffect(() => {
        if (branch.totalpass_config?.is_active) {
            baseApi.get(`/totalpass/plans/?branch=${branch.id}`).then(resp => {
                setTotalpassPlans(resp.data)
                // setGymPassProducts(resp.data.products || [])
            }).catch(e => {
                // setGymPassProducts([])
            })
        }
    }, [branch]);


    return (
        <div className="p-4">
            <DeleteModal url={`/studios/classes/${selectedClass?.id}/`} onSuccess={(resp) => {
                console.log("selected class: " + selectedClass?.id)
                dispatch(removeClass(selectedClass?.id || ""))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedClass(undefined)
            }}>
                <p>¿Seguro que quieres eliminar la clase
                    <strong> {selectedClass?.name}</strong> ?
                </p>
            </DeleteModal>
            <CreateEditClassModal
                show={showCreate}
                onHide={() => {
                    setShowCreate(false)
                    setSelectedClass(undefined)
                }}
                totalpassPlans={totalpassPlans}
                studioClass={selectedClass}
            />
            <div className="text-right">
                <button onClick={() => setShowCreate(true)} className="btn btn-light-primary mr-3 btn-pill">+ Crear
                </button>
            </div>
            <div className="">
                <Table className="text-left mt-5">
                    <TableHeader headers={["Nombre", "Color", "Valor", "Acciones"]}/>
                    <tbody className="border-top">

                    {classes.length === 0 ? <EmptyTr/> : classes.map((c) => {
                        return <tr className="my-4" key={c.id}>
                            <td className="text-left font-size-lg">{c.name}</td>
                            <td className="text-center" style={{color: c.color}}>
                                <FontAwesomeIcon icon={faCircle}/>
                            </td>
                            <td className="text-center">1</td>
                            <td className="text-center px-1">
                                <TableActionsCell onClickEdit={() => {
                                    setSelectedClass(c)
                                    setShowCreate(true)
                                }} onClickDelete={() => {
                                    setSelectedClass(c)
                                    setShowDelete(true)
                                }}/>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </Table>

            </div>
        </div>
    );
};

export default StudioClassesListView;
