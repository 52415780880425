import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import TableFooter from "../../../components/table/TableFooter";
import {endOfDay, formatISO, startOfDay} from "date-fns";
import baseApi from "../../../apis/baseApi";
import {Branch} from "../../../actions";

interface MemberRankItem {
    full_name: string;
    last_name: string | null;
    external_id: string;
    purchases_count: number;
    purchases_total: number;
}

interface Props {
    rangeStart: Date
    rangeEnd: Date | null
    branch: Branch
    active: boolean
}

const PurchasesRanking = ({rangeStart, rangeEnd, branch, active}: Props) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<MemberRankItem[]>([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (active && rangeStart && rangeEnd) {
            const fetchPage = (page: number) => {
                const start = formatISO(startOfDay(rangeStart))
                const end = formatISO(endOfDay(rangeEnd))
                setLoading(true)
                baseApi.get(`/reports/member-rankings/sales/?branch=${branch.id}&start=${start}&end=${end}&page=${page}`).then(resp => {
                    if (page === 1) setCount(resp.data.count)
                    setItems(resp.data.results)
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                },)
            }
            fetchPage(page)
        }
    }, [branch, page, rangeStart, rangeEnd, active]);

    return (
        <div className="rounded shadow-sm p-5 my-5 col-lg-8 col-xxl-6" style={{background: "white"}}>
            <div>
                <div className='mt-2 text-right mb-5'>
                    <div
                        className='label label-light-success label-l font-size-l p-4 font-weight-bold mr-2 label-inline'>
                        #compras
                    </div>
                </div>
            </div>
            <Table hover bordered>
                <TableHeader
                    headers={["CANTIDAD DE MEMBRESÍAS", "TOTAL",]}/>

                <tbody>
                {loading ? <LoadingTr/> : items.map(item => {
                    return <tr className="" key={item.external_id}>
                        <td className="text-center w-300px font-size-lg">
                            <div className='text-muted font-size-xs mb-n2'>{item.external_id}</div>
                            <div className='text-capitalize mb-1'>{item.full_name} {item.last_name ?? ''}</div>
                            <div
                                className='label label-light-success label-lg font-size-h6 p-5 font-weight-bolder'>{item.purchases_count}</div>
                        </td>
                        <td className="text-center w-150px align-items-middle">
                            <div
                                className=' text-muted  font-size-h6 p-5 font-weight-bolder'>${item.purchases_total}</div>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
            <TableFooter count={count} pageCount={items.length} pageSize={10} onPageChange={(page) => {setPage(page)}} />
        </div>
    );
};

export default PurchasesRanking;
