import React, {useEffect, useRef, useState} from 'react';
import {Badge, Button, Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import baseApi from "../../../apis/baseApi";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {formatDateTime} from "../../../utils/dateFormatters";
import {Sale, SaleLocations} from "../../Reports/Sales";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCashRegister, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import TableFooter from "../../../components/table/TableFooter";
import PendingPaymentModal from "./PendingPaymentModal";
import CancelPendingPaymentModal from "./CancelPendingPaymentModal";

const PendingPaymentSales = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [sales, setSales] = useState<Sale[]>([]);
    const [loading, setLoading] = useState(true);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedSale, setSelectedSale] = useState<Sale>();
    const [statusFilter, setStatusFilter] = useState(0);
    const count = useRef(0);

    useEffect(() => {
        handlePageChange(1)
    }, []);

    const handlePageChange = (page: number) => {
        setLoading(true)
        baseApi.get(`/sales/pending/?branch=${branch.id}&page=${page}&status=${statusFilter}`).then(resp => {
            setSales(resp.data.results)
            count.current = resp.data.count
            setLoading(false)
        }).catch(err => alert("Error"))
    }

    useEffect(() => {
        handlePageChange(1)
    }, [statusFilter]);


    return (
        <div>
            <PendingPaymentModal onSuccess={() => {
                setSales(sales.filter(s => s.id !== selectedSale!.id))
            }} sale={selectedSale!} show={showPaymentModal} onHide={() => {
                setShowPaymentModal(false);
            }}/>
            <CancelPendingPaymentModal show={showCancelModal} onHide={() => {
                setShowCancelModal(false)
            }} sale={selectedSale!} onSuccess={() => {
                setSales(sales.filter(s => s.id !== selectedSale!.id))
            }}/>
            <div className="d-flex d-flex-row align-items-start mt-3">
                <h4 className="text-dark">Ventas pendientes de pago
                </h4>
                <div className="font-size-xs ml-2">
                    <Badge className="badge-size-sm" variant="success">Nuevo</Badge>
                </div>
            </div>
            <div className="d-flex d-flex-row align-items-start mb-5">
                <Button variant={statusFilter === 0 ? "secondary" : "outline-secondary"} size="sm" className="mr-3"
                        onClick={() => {
                            setStatusFilter(0)
                        }}>Pendientes</Button>
                <Button variant={statusFilter === 2 ? "secondary" : "outline-secondary"} size="sm" onClick={() => {
                    setStatusFilter(2)
                }}>Canceladas</Button>
            </div>
            <div>

                <Table hover   className='d-none d-lg-block table-responsive'>
                    <TableHeader headers={["FECHA", "DEUDA", "CLIENTE", "PLANES", "ACCIÓN"]}/>

                    <tbody>
                    {loading ? <LoadingTr/> : sales.length === 0 ? <EmptyTr/> : sales.map(s => {
                        return <tr className="text-left" key={s.id}>
                            {/* <td className="text-uppercase font-size-sm">{s.id.slice(-8)}</td> */}
                            <td><div>{formatDateTime(s.created_on)}</div>
                                <div className='font-size-xs'>{s.transaction_by}</div>
                            </td>
                            <td>$ {s.total}</td>
                            <td className="text-left">
                                <div className="font-weight-bold font-size-sm">{s.member.full_name}</div>
                                <div className="text-muted font-size-xs">{s.member.email}</div>
                            </td>
                            <td className="text-center">{s.plan_items.map(i => <span>{i.plan}
                                <span hidden={!i.cancelled} className="text-warning">:CANCELADO</span>,
                        </span>)}
                            </td>
                            <td className="text-center">
                                <div hidden={statusFilter === 2}>
                                    <Button onClick={() => {
                                        setSelectedSale(s)
                                        setShowPaymentModal(true)
                                    }} className="px-2 py-1 m-0" variant="outline-success" size="sm">Cobrar</Button>
                                    <Button onClick={() => {
                                        setSelectedSale(s)
                                        setShowCancelModal(true)
                                    }} className="px-2 py-1 ml-2" variant="outline-danger" size="sm">
                                        <FontAwesomeIcon icon={faTrashAlt} size="sm"/>
                                    </Button>
                                </div>
                            </td>

                            
                        </tr>

                        
                    })}
                    </tbody>
                </Table>
                
                {/* TABLA MOBILE */}
                <Table hover  className='d-md-block d-lg-none '>
                    <TableHeader headers={["FECHA", "ACCIÓN"]}/>

                    <tbody>
                    {loading ? <LoadingTr/> : sales.length === 0 ? <EmptyTr/> : sales.map(s => {
                        return <tr className="text-left" key={s.id}>
                            {/* <td className="text-uppercase font-size-sm">{s.id.slice(-8)}</td> */}
                            <td><div>{formatDateTime(s.created_on)} </div>
                                <span className='font-size-xs'>{s.transaction_by}</span>
                                <div className="font-weight-bold font-size-h6">Cliente: {s.member.full_name}</div>
                                <div className="text-muted font-size-xs">{s.member.email}</div>
                                <div className='font-size-h6 font-weight-bold text-primary'>$ {s.total}</div>
                                <div>
                                    {s.plan_items.map(i => <span>{i.plan}
                                    <span hidden={!i.cancelled} className="text-warning">:CANCELADO</span>,
                                                            </span>)}
                                </div>
                                
                               
                            </td>
                            <td className="text-right">
                                <div hidden={statusFilter === 2}>
                                    <Button onClick={() => {
                                        setSelectedSale(s)
                                        setShowPaymentModal(true)
                                    }} className="px-2 py-1 m-0" variant="outline-success" size="sm">Cobrar</Button>
                                    <Button onClick={() => {
                                        setSelectedSale(s)
                                        setShowCancelModal(true)
                                    }} className="px-2 py-1 ml-2" variant="outline-danger" size="sm">
                                        <FontAwesomeIcon icon={faTrashAlt} size="sm"/>
                                    </Button>
                                </div>
                            </td>

                            
                        </tr>

                        
                    })}
                    </tbody>
                </Table>


                <TableFooter count={count.current} pageCount={sales.length} pageSize={10}
                             onPageChange={handlePageChange}/>
            </div>
        </div>
    );
};

export default PendingPaymentSales;
