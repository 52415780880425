import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import LoadingRoller from "../../components/misc/LoadingRoller";
import {CSVLink} from "react-csv";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import baseApi from "../../apis/baseApi";

interface Props {
    show: boolean;
    onHide: VoidFunction,
}

const MembersDownloadModal = ({show, onHide}: Props) => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [success, setSuccess] = useState<boolean>();

    useEffect(() => {
        if (show) {
            baseApi.get(`/members/download/?branch=${branch.id}`).then(resp => {
                setData(resp.data)
                setSuccess(true)
                setLoading(false)
            }).catch(err => {
                setSuccess(false)
            })
        }
    }, [show]);


    const renderButton = () => {
        if (!success) return <React.Fragment />
        return <CSVLink filename={`clientes-${branch.name}`} data={data} target="_blank">
            <Button variant="success">
                DESCARGAR
            </Button>
        </CSVLink>
    }
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={onHide}>
                <Modal.Header>Descargar clientes</Modal.Header>
                <Modal.Body className="text-center">
                    <div hidden={!loading}>
                        Preparando documento...
                        <p>No cierres esta ventana.</p>
                        <LoadingRoller />
                    </div>
                    <h4 hidden={!success}>Documento listo</h4>
                    {renderButton()}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default MembersDownloadModal;
