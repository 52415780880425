import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import baseApi from "../../../apis/baseApi";
import {PenalizationFee, PenalizationFeeType} from "./index";
import {formatDateTime} from "../../../utils/dateFormatters";

type Props = {
    show: boolean,
    onHide: Function,
    fee: PenalizationFee,
    onSuccess: Function,
}

const CancelPenaltyFeeModal = ({show, onHide, fee, onSuccess}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };


    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    initialValues={{status: 2, cancel_membership: true}}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true)
                        setSuccess(null)
                        console.log("OK")
                        baseApi.patch(`/fees/${fee.id}/`, values).then(resp => {
                            setAlertMessage("Venta actualizada")
                            setSuccess(true)
                            onSuccess()
                        }).catch(err => {
                            setAlertMessage("Ocurrió un error al intentar actualizar la venta.")
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }}>
                    {({isSubmitting, setFieldValue}) => (
                        <FormikForm>

                            <Modal.Header className="text-danger">
                                Cancelar Multa
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div>
                                    Reservación: {formatDateTime(fee.reservation.timeslot_start)} - {fee.reservation.timeslot_class}
                                </div>
                                <div>
                                    Multa: {Object.values(PenalizationFeeType)[fee.fee_type]}
                                </div>
                                <div>
                                    Cliente: {fee.reservation.member.external_id} - {fee.reservation.member.full_name}
                                </div>
                                <div>
                                    Plan: {fee.plan_name}
                                </div>
                                <hr/>
                                <div className="font-weight-bolder font-size-h4">
                                    Total: ${fee.amount}
                                </div>
                                <hr/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button variant="danger" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Cancelar multa"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CancelPenaltyFeeModal;
