import React from "react";
import {useState} from 'react';
import {Formik, Form as FormikForm, Field} from "formik";
import FormField from "../../components/form/FormField";
import {Alert, Button} from "react-bootstrap";
import {getApiErrorMessage} from "../../utils/apiErrors";
import baseApi from "../../apis/baseApi";
import {FormikSelect} from "../../components/form/FormikSelect";
import {tzOptions} from "../../data/timezones";
import FieldError from "../../components/form/FieldError";
import yup from "../../utils/yup";

const Registration = () => {
    const [success, setSuccess] = useState<boolean>();
    const [message, setMessage] = useState("");
    const [studioId, setStudioId] = useState("");
    const [branchId, setBranchId] = useState("");

    const validationSchema = yup.object().shape({
        email: yup.string().required().email(),
        subscription: yup.object().shape({
            amount: yup.number().required().min(0),
            payment_method: yup.string().required(),
            interval_count: yup.number().required().min(1)
        })
    })

    const renderAlert = () => {
        if (success === undefined) return;
        return <Alert
            variant={success ? "success" : "danger"}>{success ? "Studio creado" : `Error: ${message}`}</Alert>;
    };

    const customStyles = {
        // @ts-ignore
        control: (provided, state) => ({
            ...provided,
            background: '#F3F6F9',
            borderColor: '#E4E6EF',
            minHeight: '33px',
            height: '33px',
            boxShadow: state.isFocused ? null : null,
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
        }),
// @ts-ignore
        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),
// @ts-ignore
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        // @ts-ignore
        indicatorSeparator: state => ({
            display: 'none',
        }),
        // @ts-ignore
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            width: '24px',
            paddingTop: 0,
            paddingBottom: 0,
            // viewBox: '0 0 5 5',
        }),
        // @ts-ignore
        dropdownIndicator: (provided, state) => ({
            // width: '5',
            // height: '5',
            color: 'grey',
            // viewBox: '0 0 5 5',
            paddingTop: 0,
            paddingBottom: 0,
        })
    };



    return (
        <div>
            {renderAlert()}
            <div className="card-body card" hidden={!studioId}>
                <h5>Iframes</h5>
                <div>My Studio</div>
                <a href={`https://mystudio.boostapp.com.mx/#/login/${studioId}}`}>https://mystudio.boostapp.com.mx/#/login/{studioId}</a>
                <div>Horarios</div>
                <a href={`https://boostapp.com.mx/schedules?studio=${studioId}&branch=${branchId}`}>https://boostapp.com.mx/schedules?studio={studioId}&branch={branchId}</a>
                <div>Planes</div>
                <a href={`https://boostapp.com.mx/?studio=${studioId}&branch=${branchId}`}>https://boostapp.com.mx/?studio={studioId}&branch={branchId}</a>
                <div>Coaches</div>
                <a href={`https://coaches.boostapp.com.mx/?studio=${studioId}`}>https://coaches.boostapp.com.mx/?studio={studioId}</a>
                <div>Links Website</div>
                <a href={`https://cyclingboost.link/${studioId.substring(30)}`}>https://cyclingboost.link/{studioId.substring(30)}</a>
            </div>
            <h4 className="offset-lg-1 mt-4">Registrar Estudio</h4>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    full_name: "",
                    phone: "",
                    is_manager: 1,
                    is_staff: 1,
                    studio_name: "",
                    timezone: "America/Mexico_City",
                    currency: "mxn",
                    subscription: {
                        interval: "month",
                        interval_count: "1",
                        currency: "mxn"
                    }
                }}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    baseApi
                        .post("/sa/", {
                            ...values,
                            username: values.email,
                            studio: {name: values.studio_name}
                        })
                        .then(resp => {
                            setSuccess(true)
                            setStudioId(resp.data.studio_id)
                            setBranchId(resp.data.branch_id)
                            setSubmitting(false)
                        }).catch(err => {
                            setMessage(getApiErrorMessage(err))
                            setSuccess(false)
                            setSubmitting(false)
                        }
                    );
                }}
            >
                {({isSubmitting, setFieldValue}) => (
                    <FormikForm>
                        <FieldError name="email"/>
                        <FormField label="E-Mail" name="email"/>

                        <FormField label="Password" name="password"/>
                        <FormField label="Nombre completo" name="full_name"/>
                        <FormField label="Teléfono" name="phone"/>
                        <FormField label="Nombre del Estudio" name="studio_name"/>
                        <div className="offset-lg-1 row-cols-lg-4 my-2">
                            <label>Divisa</label>
                            <Field name="currency" component={FormikSelect}
                                   onChange={(v: any) => {
                                       setFieldValue("subscription.currency", v?.value)
                                   }}
                                   options={[
                                       {value: "ars", label: "Argentina - ARS"},
                                       {value: "cad", label: "Canada - CAD"},
                                       {value: "cop", label: "Colombia - COP"},
                                       {value: "eur", label: "Euro - €"},
                                       {value: "hnl", label: "Honduras - HNL"},
                                       {value: "mxn", label: "México - MXN"},
                                       {value: "pen", label: "Perú - SOL"},
                                       {value: "usd", label: "United States - USD"},
                                   ]}
                            />
                        </div>
                        <div className="offset-lg-1 row-cols-lg-4 my-2">
                            <label>Timezone</label>
                            <Field name="timezone" component={FormikSelect} options={tzOptions}/>
                        </div>
                        <hr/>
                        <div className="offset-lg-1">
                            <h6>Suscripción del studio</h6>
                            <FieldError name="subscription.amount"/>
                            <div className=" form-group row">
                                <label className="col-lg-2 col-form-label">Precio</label>
                                <div className="col-lg-2">
                                    <Field name="subscription.amount" type="number" className="form-control "/>
                                </div>
                            </div>

                            <FieldError name="subscription.payment_method"/>
                            <div className=" form-group row">
                                <label className="col-lg-2 col-form-label">Método de pago</label>
                                <div className="col-lg-2">
                                    <Field name="subscription.payment_method" component={FormikSelect}
                                           options={[
                                               {value: "bank_transfer", label: "Transferencia"},
                                               {value: "stripe", label: "Stripe"},
                                               {value: "mercadopago", label: "MercadoPago"},
                                           ]}/>
                                </div>
                            </div>
                            <FieldError name="subscription.interval_count"/>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label">Periodo pago</label>
                                <div className="col-lg-2">
                                    <div className="input-group input-group-sm">
                                        <Field name="subscription.interval_count" type="number" className="form-control"
                                               placeholder="1"/>
                                        <div className="input-group-append ">
                                            <div className="" style={{width: "100px"}}>
                                                <Field
                                                    styles={customStyles}
                                                    name="subscription.interval"
                                                    component={FormikSelect}
                                                    options={[
                                                        {label: "día", value: "day"},
                                                        {label: "semana", value: "week"},
                                                        {label: "mes", value: "month"},
                                                        {label: "año", value: "year"}
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button className="offset-lg-1 mt-5" type="submit" disabled={isSubmitting}>
                            Guardar
                        </Button>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default Registration;
