export const tzOptions = [
  { value: "America/Puerto_Rico", label: "Puerto Rico (Atlantic)" },
  { value: "America/New_York", label: "New York (Eastern)" },
  { value: "America/Chicago", label: "Chicago (Central)" },
  { value: "America/Denver", label: "Denver (Mountain)" },
  { value: "America/Phoenix", label: "Phoenix (MST)" },
  { value: "America/Los_Angeles", label: "Los Angeles (Pacific)" },
  { value: "America/Anchorage", label: "Anchorage (Alaska)" },
  { value: "Pacific/Honolulu", label: "Honolulu (Hawaii)" },
  { value: "America/Adak", label: "Adak" },
  { value: "America/Anchorage", label: "Anchorage" },
  { value: "America/Anguilla", label: "Anguilla" },
  { value: "America/Antigua", label: "Antigua" },
  { value: "America/Araguaina", label: "Araguaina" },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "Argentina - Buenos Aires"
  },
  { value: "America/Argentina/Catamarca", label: "Argentina - Catamarca" },
  {
    value: "America/Argentina/ComodRivadavia",
    label: "Argentina - ComodRivadavia"
  },
  { value: "America/Argentina/Cordoba", label: "Argentina - Cordoba" },
  { value: "America/Argentina/Jujuy", label: "Argentina - Jujuy" },
  { value: "America/Argentina/La_Rioja", label: "Argentina - La Rioja" },
  { value: "America/Argentina/Mendoza", label: "Argentina - Mendoza" },
  {
    value: "America/Argentina/Rio_Gallegos",
    label: "Argentina - Rio Gallegos"
  },
  { value: "America/Argentina/Salta", label: "Argentina - Salta" },
  { value: "America/Argentina/San_Juan", label: "Argentina - San Juan" },
  { value: "America/Argentina/San_Luis", label: "Argentina - San Luis" },
  { value: "America/Argentina/Tucuman", label: "Argentina - Tucuman" },
  { value: "America/Argentina/Ushuaia", label: "Argentina - Ushuaia" },
  { value: "America/Aruba", label: "Aruba" },
  { value: "America/Asuncion", label: "Asuncion" },
  { value: "America/Atikokan", label: "Atikokan" },
  { value: "America/Atka", label: "Atka" },
  { value: "America/Bahia", label: "Bahia" },
  { value: "America/Barbados", label: "Barbados" },
  { value: "America/Belem", label: "Belem" },
  { value: "America/Belize", label: "Belize" },
  { value: "America/Blanc-Sablon", label: "Blanc-Sablon" },
  { value: "America/Boa_Vista", label: "Boa Vista" },
  { value: "America/Bogota", label: "Bogota" },
  { value: "America/Boise", label: "Boise" },
  { value: "America/Buenos_Aires", label: "Buenos Aires" },
  { value: "America/Cambridge_Bay", label: "Cambridge Bay" },
  { value: "America/Campo_Grande", label: "Campo Grande" },
  { value: "America/Cancun", label: "Cancun" },
  { value: "America/Caracas", label: "Caracas" },
  { value: "America/Catamarca", label: "Catamarca" },
  { value: "America/Cayenne", label: "Cayenne" },
  { value: "America/Cayman", label: "Cayman" },
  { value: "America/Chicago", label: "Chicago" },
  { value: "America/Chihuahua", label: "Chihuahua" },
  { value: "America/Coral_Harbour", label: "Coral Harbour" },
  { value: "America/Cordoba", label: "Cordoba" },
  { value: "America/Costa_Rica", label: "Costa Rica" },
  { value: "America/Cuiaba", label: "Cuiaba" },
  { value: "America/Curacao", label: "Curacao" },
  { value: "America/Danmarkshavn", label: "Danmarkshavn" },
  { value: "America/Dawson", label: "Dawson" },
  { value: "America/Dawson_Creek", label: "Dawson Creek" },
  { value: "America/Denver", label: "Denver" },
  { value: "America/Detroit", label: "Detroit" },
  { value: "America/Dominica", label: "Dominica" },
  { value: "America/Edmonton", label: "Edmonton" },
  { value: "America/Eirunepe", label: "Eirunepe" },
  { value: "America/El_Salvador", label: "El Salvador" },
  { value: "America/Ensenada", label: "Ensenada" },
  { value: "America/Fortaleza", label: "Fortaleza" },
  { value: "America/Fort_Wayne", label: "Fort Wayne" },
  { value: "America/Glace_Bay", label: "Glace Bay" },
  { value: "America/Godthab", label: "Godthab" },
  { value: "America/Goose_Bay", label: "Goose Bay" },
  { value: "America/Grand_Turk", label: "Grand Turk" },
  { value: "America/Grenada", label: "Grenada" },
  { value: "America/Guadeloupe", label: "Guadeloupe" },
  { value: "America/Guatemala", label: "Guatemala" },
  { value: "America/Guayaquil", label: "Guayaquil" },
  { value: "America/Guyana", label: "Guyana" },
  { value: "America/Halifax", label: "Halifax" },
  { value: "America/Havana", label: "Havana" },
  { value: "America/Hermosillo", label: "Hermosillo" },
  { value: "America/Indiana/Indianapolis", label: "Indiana - Indianapolis" },
  { value: "America/Indiana/Knox", label: "Indiana - Knox" },
  { value: "America/Indiana/Marengo", label: "Indiana - Marengo" },
  { value: "America/Indiana/Petersburg", label: "Indiana - Petersburg" },
  { value: "America/Indiana/Tell_City", label: "Indiana - Tell City" },
  { value: "America/Indiana/Vevay", label: "Indiana - Vevay" },
  { value: "America/Indiana/Vincennes", label: "Indiana - Vincennes" },
  { value: "America/Indiana/Winamac", label: "Indiana - Winamac" },
  { value: "America/Indianapolis", label: "Indianapolis" },
  { value: "America/Inuvik", label: "Inuvik" },
  { value: "America/Iqaluit", label: "Iqaluit" },
  { value: "America/Jamaica", label: "Jamaica" },
  { value: "America/Jujuy", label: "Jujuy" },
  { value: "America/Juneau", label: "Juneau" },
  { value: "America/Kentucky/Louisville", label: "Kentucky - Louisville" },
  { value: "America/Kentucky/Monticello", label: "Kentucky - Monticello" },
  { value: "America/Knox_IN", label: "Knox IN" },
  { value: "America/La_Paz", label: "La Paz" },
  { value: "America/Lima", label: "Lima" },
  { value: "America/Los_Angeles", label: "Los Angeles" },
  { value: "America/Louisville", label: "Louisville" },
  { value: "America/Maceio", label: "Maceio" },
  { value: "America/Managua", label: "Managua" },
  { value: "America/Manaus", label: "Manaus" },
  { value: "America/Marigot", label: "Marigot" },
  { value: "America/Martinique", label: "Martinique" },
  { value: "America/Matamoros", label: "Matamoros" },
  { value: "America/Mazatlan", label: "Mazatlan" },
  { value: "America/Mendoza", label: "Mendoza" },
  { value: "America/Menominee", label: "Menominee" },
  { value: "America/Merida", label: "Merida" },
  { value: "America/Mexico_City", label: "Mexico City" },
  { value: "America/Miquelon", label: "Miquelon" },
  { value: "America/Moncton", label: "Moncton" },
  { value: "America/Monterrey", label: "Monterrey" },
  { value: "America/Montevideo", label: "Montevideo" },
  { value: "America/Montreal", label: "Montreal" },
  { value: "America/Montserrat", label: "Montserrat" },
  { value: "America/Nassau", label: "Nassau" },
  { value: "America/New_York", label: "New York" },
  { value: "America/Nipigon", label: "Nipigon" },
  { value: "America/Nome", label: "Nome" },
  { value: "America/Noronha", label: "Noronha" },
  { value: "America/North_Dakota/Center", label: "North Dakota - Center" },
  {
    value: "America/North_Dakota/New_Salem",
    label: "North Dakota - New Salem"
  },
  { value: "America/Ojinaga", label: "Ojinaga" },
  { value: "America/Panama", label: "Panama" },
  { value: "America/Pangnirtung", label: "Pangnirtung" },
  { value: "America/Paramaribo", label: "Paramaribo" },
  { value: "America/Phoenix", label: "Phoenix" },
  { value: "America/Port-au-Prince", label: "Port-au-Prince" },
  { value: "America/Porto_Acre", label: "Porto Acre" },
  { value: "America/Port_of_Spain", label: "Port of Spain" },
  { value: "America/Porto_Velho", label: "Porto Velho" },
  { value: "America/Puerto_Rico", label: "Puerto Rico" },
  { value: "America/Rainy_River", label: "Rainy River" },
  { value: "America/Rankin_Inlet", label: "Rankin Inlet" },
  { value: "America/Recife", label: "Recife" },
  { value: "America/Regina", label: "Regina" },
  { value: "America/Resolute", label: "Resolute" },
  { value: "America/Rio_Branco", label: "Rio Branco" },
  { value: "America/Rosario", label: "Rosario" },
  { value: "America/Santa_Isabel", label: "Santa Isabel" },
  { value: "America/Santarem", label: "Santarem" },
  { value: "America/Santiago", label: "Santiago" },
  { value: "America/Santo_Domingo", label: "Santo Domingo" },
  { value: "America/Sao_Paulo", label: "Sao Paulo" },
  { value: "America/Scoresbysund", label: "Scoresbysund" },
  { value: "America/Shiprock", label: "Shiprock" },
  { value: "America/St_Barthelemy", label: "St Barthelemy" },
  { value: "America/St_Johns", label: "St Johns" },
  { value: "America/St_Kitts", label: "St Kitts" },
  { value: "America/St_Lucia", label: "St Lucia" },
  { value: "America/St_Thomas", label: "St Thomas" },
  { value: "America/St_Vincent", label: "St Vincent" },
  { value: "America/Swift_Current", label: "Swift Current" },
  { value: "America/Tegucigalpa", label: "Tegucigalpa" },
  { value: "America/Thule", label: "Thule" },
  { value: "America/Thunder_Bay", label: "Thunder Bay" },
  { value: "America/Tijuana", label: "Tijuana" },
  { value: "America/Toronto", label: "Toronto" },
  { value: "America/Tortola", label: "Tortola" },
  { value: "America/Vancouver", label: "Vancouver" },
  { value: "America/Virgin", label: "Virgin" },
  { value: "America/Whitehorse", label: "Whitehorse" },
  { value: "America/Winnipeg", label: "Winnipeg" },
  { value: "America/Yakutat", label: "Yakutat" },
  { value: "America/Yellowknife", label: "Yellowknife" },
  { value: "Europe/Amsterdam", label: "Amsterdam" },
  { value: "Europe/Andorra", label: "Andorra" },
  { value: "Europe/Athens", label: "Athens" },
  { value: "Europe/Belfast", label: "Belfast" },
  { value: "Europe/Belgrade", label: "Belgrade" },
  { value: "Europe/Berlin", label: "Berlin" },
  { value: "Europe/Bratislava", label: "Bratislava" },
  { value: "Europe/Brussels", label: "Brussels" },
  { value: "Europe/Bucharest", label: "Bucharest" },
  { value: "Europe/Budapest", label: "Budapest" },
  { value: "Europe/Chisinau", label: "Chisinau" },
  { value: "Europe/Copenhagen", label: "Copenhagen" },
  { value: "Europe/Dublin", label: "Dublin" },
  { value: "Europe/Gibraltar", label: "Gibraltar" },
  { value: "Europe/Guernsey", label: "Guernsey" },
  { value: "Europe/Helsinki", label: "Helsinki" },
  { value: "Europe/Isle_of_Man", label: "Isle of Man" },
  { value: "Europe/Istanbul", label: "Istanbul" },
  { value: "Europe/Jersey", label: "Jersey" },
  { value: "Europe/Kaliningrad", label: "Kaliningrad" },
  { value: "Europe/Kiev", label: "Kiev" },
  { value: "Europe/Lisbon", label: "Lisbon" },
  { value: "Europe/Ljubljana", label: "Ljubljana" },
  { value: "Europe/London", label: "London" },
  { value: "Europe/Luxembourg", label: "Luxembourg" },
  { value: "Europe/Madrid", label: "Madrid" },
  { value: "Europe/Malta", label: "Malta" },
  { value: "Europe/Mariehamn", label: "Mariehamn" },
  { value: "Europe/Minsk", label: "Minsk" },
  { value: "Europe/Monaco", label: "Monaco" },
  { value: "Europe/Moscow", label: "Moscow" },
  { value: "Europe/Nicosia", label: "Nicosia" },
  { value: "Europe/Oslo", label: "Oslo" },
  { value: "Europe/Paris", label: "Paris" },
  { value: "Europe/Podgorica", label: "Podgorica" },
  { value: "Europe/Prague", label: "Prague" },
  { value: "Europe/Riga", label: "Riga" },
  { value: "Europe/Rome", label: "Rome" },
  { value: "Europe/Samara", label: "Samara" },
  { value: "Europe/San_Marino", label: "San Marino" },
  { value: "Europe/Sarajevo", label: "Sarajevo" },
  { value: "Europe/Simferopol", label: "Simferopol" },
  { value: "Europe/Skopje", label: "Skopje" },
  { value: "Europe/Sofia", label: "Sofia" },
  { value: "Europe/Stockholm", label: "Stockholm" },
  { value: "Europe/Tallinn", label: "Tallinn" },
  { value: "Europe/Tirane", label: "Tirane" },
  { value: "Europe/Tiraspol", label: "Tiraspol" },
  { value: "Europe/Uzhgorod", label: "Uzhgorod" },
  { value: "Europe/Vaduz", label: "Vaduz" },
  { value: "Europe/Vatican", label: "Vatican" },
  { value: "Europe/Vienna", label: "Vienna" },
  { value: "Europe/Vilnius", label: "Vilnius" },
  { value: "Europe/Volgograd", label: "Volgograd" },
  { value: "Europe/Warsaw", label: "Warsaw" },
  { value: "Europe/Zagreb", label: "Zagreb" },
  { value: "Europe/Zaporozhye", label: "Zaporozhye" },
  { value: "Europe/Zurich", label: "Zurich" },
  { value: "Atlantic/Azores", label: "Azores" },
  { value: "Atlantic/Bermuda", label: "Bermuda" },
  { value: "Atlantic/Canary", label: "Canary" },
  { value: "Atlantic/Cape_Verde", label: "Cape Verde" },
  { value: "Atlantic/Faeroe", label: "Faeroe" },
  { value: "Atlantic/Faroe", label: "Faroe" },
  { value: "Atlantic/Jan_Mayen", label: "Jan Mayen" },
  { value: "Atlantic/Madeira", label: "Madeira" },
  { value: "Atlantic/Reykjavik", label: "Reykjavik" },
  { value: "Atlantic/South_Georgia", label: "South Georgia" },
  { value: "Atlantic/Stanley", label: "Stanley" },
  { value: "Atlantic/St_Helena", label: "St Helena" },
  { value: "Pacific/Apia", label: "Apia" },
  { value: "Pacific/Auckland", label: "Auckland" },
  { value: "Pacific/Chatham", label: "Chatham" },
  { value: "Pacific/Easter", label: "Easter" },
  { value: "Pacific/Efate", label: "Efate" },
  { value: "Pacific/Enderbury", label: "Enderbury" },
  { value: "Pacific/Fakaofo", label: "Fakaofo" },
  { value: "Pacific/Fiji", label: "Fiji" },
  { value: "Pacific/Funafuti", label: "Funafuti" },
  { value: "Pacific/Galapagos", label: "Galapagos" },
  { value: "Pacific/Gambier", label: "Gambier" },
  { value: "Pacific/Guadalcanal", label: "Guadalcanal" },
  { value: "Pacific/Guam", label: "Guam" },
  { value: "Pacific/Honolulu", label: "Honolulu" },
  { value: "Pacific/Johnston", label: "Johnston" },
  { value: "Pacific/Kiritimati", label: "Kiritimati" },
  { value: "Pacific/Kosrae", label: "Kosrae" },
  { value: "Pacific/Kwajalein", label: "Kwajalein" },
  { value: "Pacific/Majuro", label: "Majuro" },
  { value: "Pacific/Marquesas", label: "Marquesas" },
  { value: "Pacific/Midway", label: "Midway" },
  { value: "Pacific/Nauru", label: "Nauru" },
  { value: "Pacific/Niue", label: "Niue" },
  { value: "Pacific/Norfolk", label: "Norfolk" },
  { value: "Pacific/Noumea", label: "Noumea" },
  { value: "Pacific/Pago_Pago", label: "Pago Pago" },
  { value: "Pacific/Palau", label: "Palau" },
  { value: "Pacific/Pitcairn", label: "Pitcairn" },
  { value: "Pacific/Ponape", label: "Ponape" },
  { value: "Pacific/Port_Moresby", label: "Port Moresby" },
  { value: "Pacific/Rarotonga", label: "Rarotonga" },
  { value: "Pacific/Saipan", label: "Saipan" },
  { value: "Pacific/Samoa", label: "Samoa" },
  { value: "Pacific/Tahiti", label: "Tahiti" },
  { value: "Pacific/Tarawa", label: "Tarawa" },
  { value: "Pacific/Tongatapu", label: "Tongatapu" },
  { value: "Pacific/Truk", label: "Truk" },
  { value: "Pacific/Wake", label: "Wake" },
  { value: "Pacific/Wallis", label: "Wallis" },
  { value: "Pacific/Yap", label: "Yap" },
  { value: "UTC", label: "UTC" }
];
