import {Dispatch} from "redux";
import baseApi from "../apis/baseApi";
import {CoachesActionTypes} from "./types";
import {Coach} from "../pages/Coaches";

export interface CoachesState {
    loading: boolean
    error: string
    coaches: Coach[]
}
export interface CoachesActions {
    type: CoachesActionTypes
    payload: any
}
export const fetchCoaches = () => {
    return async (dispatch: Dispatch) => {
        baseApi.get<Coach[]>(`/coaches/`).then((response) => {
            dispatch<CoachesActions>({
                type: CoachesActionTypes.FETCHED,
                payload: response.data
            })
        })
    }
}

export const addCoach = (coach: Coach) => {
    return (dispatch: Dispatch) => {
        dispatch({type: CoachesActionTypes.ADD_COACH, payload: coach})
    }
}

export const updateCoach = (coach: Coach) => {
    return (dispatch: Dispatch) => {
        dispatch({type: CoachesActionTypes.UPDATE_COACH, payload: coach})
    }
}

export const removeCoach = (coachId: string) => {
    return (dispatch: Dispatch) => {
        dispatch({type: CoachesActionTypes.REMOVE_COACH, payload: coachId})
    }
}