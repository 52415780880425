import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Row} from "react-bootstrap";
import {startOfWeek, endOfWeek, formatISO, addDays, format, parseISO, subWeeks, addWeeks} from "date-fns";
import {es} from "date-fns/locale";

import {StudioClass} from "../../actions/studioClasses";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import {Room} from "../Toolbox/Rooms";
import ScheduleCard from "./ScheduleCard";
import EditCreateScheduleModal from "./EditCreateScheduleModal";
import baseApi from "../../apis/baseApi";
import DeleteModal from "../../components/modals/DeleteModal";
import EditMultipleSlotsModal from "./EditMultipleSlotsModal";
import LoadingRoller from "../../components/misc/LoadingRoller";
import Select from "react-select";
import {filterSelectStyle} from "../../styles/reactSelectStyles";
import {Coach} from "../Coaches";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faEdit, faEye, faFilter} from "@fortawesome/free-solid-svg-icons";
import {CoachesState, fetchCoaches} from "../../actions/coaches";

export interface TimeSlotV2 {
    activate_days_before: number | null;
    archived: boolean;
    available_spaces: number;
    close_hours_before: number | null;
    coaches: MinCoach[];
    gympass_class_id: number;
    gympass_id: number;
    id: string;
    minutes_duration: number;
    occupied_seats: string[];
    people_limit: number;
    publish_date: null | string;
    room: null | string;
    rsvp_count: number;
    schedule: string;
    start: Date;
    start_time: string;
    studio_class: string;
    studio_class_name: string;
    totalpass_occurrence_id: string | null;
}

export interface TimeSlot {
    id: string,
    start: Date,
    start_time: string,
    schedule: string,
    available_spaces: number
    studio_class: StudioClass
    minutes_duration: number
    people_limit: number
    coaches: MinCoach[],
    room: Room | null
    occupied_seats: string[],
    activate_days_before: number | null
    close_hours_before: number | null
    publish_date?: string
    gympass_id: number | null
    rsvp_count?: number
    archived: boolean
}

export interface MinCoach {
    id: string
    full_name: string
    img: string
}

const Schedules = () => {
    const dispatch = useDispatch()
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const coachesState = useSelector<StoreState, CoachesState>(state => state.coaches)
    const studioClasses = useSelector<StoreState, StudioClass[]>(state => state.studioClasses.classes)

    const [weekOf, setWeekOf] = useState(new Date());
    const [timeSlots, setTimeSlots] = useState<TimeSlotV2[]>([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlotV2>();
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showMultipleEditModal, setShowMultipleEditModal] = useState(false)
    const [disableForm, setDisableForm] = useState(false)
    const [fetching, setFetching] = useState(false);
    const [coachFilter, setCoachFilter] = useState<Coach>();
    const [studioClassFilter, setStudioClassFilter] = useState<StudioClass>();

    useEffect(() => {
        setFetching(true)
        const start = formatISO(addDays(startOfWeek(weekOf), 1)).replace("+", "%2B");
        const end = formatISO(addDays(endOfWeek(weekOf), 1)).replace("+", "%2B");
        baseApi.get(`/schedules/v1.1/slots/staff/?start=${start}&end=${end}&branch=${currentBranch.id}`)
            .then((resp) => {
                // @ts-ignore
                const data: TimeSlotV2[] = resp.data.map((t) => ({
                    ...t,
                    start: parseISO(t.start),
                } as TimeSlotV2))
                const sortedData = data.sort((a, b) => (a.start.getTime() - b.start.getTime()))
                setTimeSlots(sortedData)
                setFetching(false)
            })

            .catch(() => alert("Error obteniendo horarios"));
    }, [currentBranch, weekOf]);

    useEffect(() => {
        if (coachesState.loading) {
            dispatch(fetchCoaches())
        }
    }, [coachesState, dispatch]);

    const renderDayTimeSlots = (day: Date) => {
        let daySlots: TimeSlotV2[] = []
        timeSlots.forEach((ts) => {
            if (ts.start.getDay() === day.getDay()) {
                if (coachFilter && !ts.coaches.map(c => c.id).find(coachId => coachFilter.id === coachId)) {
                    return;
                }
                if (studioClassFilter && ts.studio_class !== studioClassFilter.id) return
                daySlots.push(ts)
            }
        })
        return daySlots.map((ts) => <ScheduleCard
                key={ts.id}
                onDetails={() => {
                    setDisableForm(true)
                    setSelectedTimeSlot(ts)
                    setShowCreateModal(true)
                }
                }
                onEdit={() => {
                    setSelectedTimeSlot(ts)
                    setShowCreateModal(true)
                }} timeSlot={ts}
                onDelete={() => {
                    setSelectedTimeSlot(ts)
                    setShowDeleteModal(true)
                }}
            />
        )
    }

    const renderHeader = () => {
        const weekStart = addDays(startOfWeek(weekOf), 1);
        let days: Date[] = []
        let i = 0
        for (i = 0; i < 7; i++) {
            const day = addDays(weekStart, i)
            days.push(day)
        }
        return <Row className="d-flex flex-row text-center">
            {days.map((d) =>
                (
                    <Col key={d.toString()} className="px-2">
                        <div>
                            <div
                                className="card card-custom rounded-circle mx-auto shadow-sm h-35px h-sm-50px w-35px w-sm-50px">
                                <h6 className="font-weight-boldest text-center my-auto">
                                    {format(d, " d", {locale: es})}
                                </h6>
                            </div>
                            <p className="text-uppercase text-muted font-weight-bolder text-center pt-2">{format(d, "EEEE", {locale: es})}</p>
                        </div>
                        <div hidden={fetching}>
                            {renderDayTimeSlots(d)}
                        </div>
                    </Col>
                )
            )}
        </Row>
    }

    const onArrowBack = () => setWeekOf(subWeeks(weekOf, 1))
    const onArrowForward = () => setWeekOf(addWeeks(weekOf, 1))

    return (
        <div className="on-schedules">
            <DeleteModal url={`/schedules/slots/${selectedTimeSlot?.id}/`} onSuccess={() => {
                setTimeSlots(timeSlots.filter(ts => ts.id !== selectedTimeSlot?.id))
            }} show={showDeleteModal} onHide={() => {
                setShowDeleteModal(false)
                setSelectedTimeSlot(undefined)
            }}>
                <p>¿Seguro que quieres eliminar este bloque?</p>
                <p><strong>{selectedTimeSlot?.studio_class_name}</strong></p>
                <p>{format(selectedTimeSlot?.start || 0, "dd-MMM-yyyy HH:mm")}</p>
            </DeleteModal>
            <EditCreateScheduleModal
                show={showCreateModal}
                onHide={() => {
                    setDisableForm(false)
                    setShowCreateModal(false)
                    setSelectedTimeSlot(undefined)
                }}
                timeslots={timeSlots}
                setTimeSlots={setTimeSlots}
                weekOf={weekOf}
                disabled={disableForm}
                selectedTimeSlot={selectedTimeSlot}
            />
            <EditMultipleSlotsModal show={showMultipleEditModal} onHide={() => setShowMultipleEditModal(false)}
                                    timeSlots={timeSlots} setTimeSlots={setTimeSlots}/>

            <div className="mt-5 mb-8">
                <div className="align-items-center">
                    {/*  */}
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <i className="fi-rr-calendar mr-3"></i>
                            <h2 className="text-dark">Calendario</h2>
                            <button onClick={() => setShowCreateModal(true)} className="d-none d-md-inline ml-5 btn btn-light-primary  btn-pill btn-lg bg-primary-o-50 text-primary border-0 btn-hover-primary font-weight-bold">
                                + Nuevo Horario
                            </button>
                            <button onClick={() => setShowMultipleEditModal(true)} className="d-none d-md-inline ml-5 btn btn-secondary btn-hover-success btn-pill btn-lg border-0">
                                <div className="d-inline-flex align-items-center">
                                    <FontAwesomeIcon className="mr-1" size="xs" icon={faEdit} /> Editar multiples horarios
                                </div>
                            </button>
                        </div>
                        <div>
                            {/* <Button className="btn-light-primary mr-3 d-none d-md-block">
                            <FontAwesomeIcon icon={faEye} />
                            {" "}Listado
                        </Button> */}
                            <Button className="btn-light btn-icon btn-circle d-md-none text-muted">
                                <FontAwesomeIcon icon={faEye} />
                                {" "}
                            </Button>
                            <button onClick={() => setShowCreateModal(true)} className="d-md-none btn btn-white text-primary shadow btn-circle btn-icon ml-2">
                                <i className="fi-rr-plus text-primary icon-md"></i>
                            </button>
                        </div>
                    </div>

                    {/* FILTERS */}
                    <div className="d-inline-flex justify-content-center align-items-center mt-5">
                        <div className="mr-2 text-muted">
                            <FontAwesomeIcon icon={faFilter} />
                        </div>
                        <Select
                            className="mr-2"
                            placeholder={"Coach"}
                            isClearable={true}
                            styles={filterSelectStyle}
                            value={coachFilter ? {label: coachFilter?.full_name, value: coachFilter?.id} : undefined}
                            options={coachesState.coaches.map(c => ({label: c.full_name, value: c.id}))}
                            onChange={option => {
                                const coach = coachesState.coaches.find(c => c.id === option?.value)
                                setCoachFilter(coach)
                            }}
                        />
                        <Select
                            placeholder={"Clase"}
                            isClearable={true}
                            styles={filterSelectStyle}
                            value={studioClassFilter ? {label: studioClassFilter?.name, value: studioClassFilter?.id} : undefined}
                            options={studioClasses.map(c => ({label: c.name, value: c.id}))}
                            onChange={option => {
                                const studioClass = studioClasses.find(c => c.id === option?.value)
                                setStudioClassFilter(studioClass)
                            }}
                        />
                    </div>
                    {/* END FILTERS */}

                    {/*-------------------- AREA TITULO PARTE DERECHA BOTONES-----------------*/}
                    {/*begin::Toolbar*/}
                    <div className="d-flex align-items-center flex-wrap justify-content-between mt-10 mb-n5">

                        <Button onClick={onArrowBack} className="btn btn-sm btn-light btn-icon btn-circle bg-white text-dark shadow-sm mr-2"
                                data-toggle="tooltip" data-original-title="Semana anterior">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                        <h6 className="text-uppercase text-dark font-weight-bold mt-2 mr-5 align-middle ml-5 text-center">{format(weekOf, "MMMM yyyy", {locale: es})}</h6>

                        <Button onClick={onArrowForward} className="btn btn-sm btn-light btn-icon btn-circle bg-white text-dark shadow-sm"
                                data-toggle="tooltip" data-original-title="Siguiente semana">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </div>

                    {/*end::Toolbar*/}
                </div>
                {/* END MOBILE HEADER */}
            </div>
            {/* END HEADER */}

            <div className="pt-5">
                {renderHeader()}
                <div hidden={!fetching}>
                    <LoadingRoller/>
                </div>
                <div hidden={timeSlots.length > 0 || fetching}
                     className="text-center text-muted font-weight-bolder font-size-h2 mt-15">0 horarios registrados en
                    esta fecha
                </div>
            </div>
        </div>
    );
};

export default Schedules;
