import React, {useEffect, useState} from 'react';
import baseApi from "../../apis/baseApi";
import FormField from "../../components/form/FormField";
import {Alert, Button} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Studio} from "../../actions/studio";
import ImageField from "../../components/form/ImageField";
import yup from "../../utils/yup";
import FieldError from "../../components/form/FieldError";

export interface StudioLinks {
    app_store: null | string;
    banner: null | string;
    facebook: null | string;
    google_maps: null | string;
    id: string;
    instagram: null | string;
    logo: null | string;
    play_store: null | string;
    studio: string;
    studio_name: string;
    tiktok: null | string;
    website: null | string;
    whatsapp_phone: string;
}

const StudioLinks = () => {
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const [loading, setLoading] = useState(true);
    const [links, setLinks] = useState<StudioLinks>()
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("")
    const [logoImg, setLogoImg] = useState<null | File>(null);
    const [bannerImg, setBannerImg] = useState<null | File>(null);

    const validationSchema = yup.object().shape({
        website: yup.string().url().nullable(),
        play_store: yup.string().url().nullable().matches(new RegExp("^https://play.google.com"), "Link invalido"),
        app_store: yup.string().url().nullable().matches(new RegExp("^https://apps.apple.com"), "Link invalido"),
        google_maps: yup.string().url().nullable(),
        instagram: yup.string().url().nullable().matches(new RegExp("^https://instagram.com"), "Link invalido"),
        tiktok: yup.string().url().nullable().matches(new RegExp("^https://tiktok.com"), "Link invalido"),
        facebook: yup.string().url().nullable().matches(new RegExp("^https://facebook.com"), "Link invalido"),
        whatsapp_phone: yup.string().min(9).nullable(),
    })

    const renderAlert = () => {
        if (success === null) return <React.Fragment/>
        return <Alert className="mt-2"
                      variant={success ? "success" : "danger"}>{success ? "Links actualizados correctamente." : "Error"}</Alert>
    }

    useEffect(() => {
        if (studio.id) {
            baseApi.get<StudioLinks>(`/links/${studio.id}/`).then(resp => {
                setLinks(resp.data)
                setLoading(false)
            })
        }
    }, [studio.id]);


    if (loading) return <div>Cargando...</div>
    return (
        <div>
            {renderAlert()}
            <Formik
                validationSchema={validationSchema}
                initialValues={{...links}}
                onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    if (logoImg || bannerImg) {
                        const formData = new FormData();
                        if (logoImg) formData.append("logo", logoImg, logoImg.name);
                        if (bannerImg) formData.append("banner", bannerImg, bannerImg.name);
                        baseApi.patch(`/links/${studio.id}/`, formData).then(resp => {
                            // dispatch(setStudio({...studio, ...resp.data}))
                        })
                    }
                    delete values.banner
                    delete values.logo
                    baseApi.patch(`/links/${studio.id}/`, values).then((resp) => {
                        setAlertMsg("Cambios guardados")
                        setSuccess(true)
                        setSubmitting(false)
                    }).catch((err) => {
                        setAlertMsg("Error")
                        setSuccess(false)
                        setSubmitting(false)
                    })

                }
                }>
                {({isSubmitting, values, setFieldValue}) => (
                    <FormikForm className="w-50">
                        <h4 className="offset-lg-1">Links Website</h4>
                        <div className="offset-lg-1 mb-5 d-inline-flex">
                            <div>
                                <h3 className="text-muted pt-2 font-size-sm">LOGO</h3>
                                <ImageField src={links?.logo} onChange={(file) => setLogoImg(file)}/>
                            </div>
                            <div className="pl-10">
                                <h3 className="text-muted pt-2 font-size-sm">BANNER</h3>
                                <ImageField src={links?.banner} onChange={(file) => setBannerImg(file)} width={240} maxUploadWidth={900} maxUploadHeight={600} quality={80}/>
                            </div>
                        </div>
                        <FormField label="WhatsApp" name="whatsapp_phone" fieldClassName="col-lg-8"/>
                        <FieldError name="whatsapp_phone" />
                        <FormField label="Pagina web" name="website" fieldClassName="col-lg-8"/>
                        <FieldError name="website" />
                        <FormField label="Google Play" name="play_store" fieldClassName="col-lg-8"/>
                        <FieldError name="play_store" />
                        <FormField label="App Store" name="app_store" fieldClassName="col-lg-8"/>
                        <FieldError name="app_store" />
                        <FormField label="Google Maps" name="google_maps" fieldClassName="col-lg-8"/>
                        <FieldError name="google_maps" />
                        <FormField label="Instagram" name="instagram" fieldClassName="col-lg-8"/>
                        <FieldError name="instagram" />
                        <FormField label="TikTok" name="tiktok" fieldClassName="col-lg-8"/>
                        <FieldError name="tiktok" />
                        <FormField label="Facebook" name="facebook" fieldClassName="col-lg-8"/>
                        <FieldError name="facebook" />
                        <div className="text-right mr-20">
                            <Button disabled={isSubmitting}
                                    type="submit">{isSubmitting ? "..." : "Guardar"} </Button>
                        </div>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default StudioLinks;
