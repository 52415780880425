import React from "react";
import {useState} from "react";
import {Alert, Badge, Button, Modal,} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {Formik, Form as FormikForm, Field} from "formik";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faHourglass,
    faMapMarked,
    faMapPin,
    faRunning,
    faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {addDays, addMinutes, formatISO, formatISO9075, isBefore, parseISO, parse, isSameISOWeek} from "date-fns";
import {es} from "date-fns/locale";
import yup from "../../utils/yup";

import {StoreState} from "../../reducers";
import {Branch, BranchesState} from "../../actions";
import {StudioClass} from "../../actions/studioClasses";
import {TimeSlotV2} from "./index";
import {Coach} from "../Coaches";
import {getApiErrorMessage} from "../../utils/apiErrors";
import {FormikSelect} from "../../components/form/FormikSelect";
import ModalSelect from "../../components/form/ModalSelect";
import MiniFormField from "../../components/form/MiniFormField";
import Checkbox from "../../components/form/Checkbox";
import FieldError from "../../components/form/FieldError";
import baseApi from "../../apis/baseApi";

import "react-datepicker/dist/react-datepicker.css";
import "../../assets/styles/datepicker.css"
import {Room} from "../Toolbox/Rooms";

type CreateScheduleModalProps = {
    show: boolean
    onHide: Function
    timeslots: TimeSlotV2[]
    setTimeSlots: (slots: TimeSlotV2[]) => void
    weekOf: Date
    selectedTimeSlot?: TimeSlotV2
    disabled?: boolean
}

const ScheduleSchema = yup.object().shape({
    people_limit: yup.number().positive().required(),
    minutes_duration: yup.number().positive().required(),
    studio_class: yup.string().required(),
    activate_days_before: yup.number().min(1).max(99).nullable(),
    close_hours_before: yup.number().min(1).max(1000).nullable()
})

const EditCreateScheduleModal = ({
                                     show,
                                     onHide,
                                     selectedTimeSlot,
                                     timeslots,
                                     setTimeSlots,
                                     weekOf,
                                     disabled = false
                                 }: CreateScheduleModalProps) => {

    const rooms = useSelector<StoreState, Room[]>(state => state.rooms.rooms)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const classes = useSelector<StoreState, StudioClass[]>(state => state.studioClasses.classes)
    const coaches = useSelector<StoreState, Coach[]>(state => state.coaches.coaches)
    const [selectedClass, setSelectedClass] = useState<StudioClass>();

    const [success, setSuccess] = useState<boolean | null>(null);

    const [alertMsg, setAlertMsg] = useState("")

    const minDate = new Date()

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };


    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const getInitialStartDate = (): Date => {
        if (selectedTimeSlot!.start_time !== null) {
            return parse(selectedTimeSlot!.start_time, "HH:mm:ss", selectedTimeSlot!.start)
        }
        return selectedTimeSlot!.start
    }

    const renderIntegrationBadges = () => {
        let tpBadge;
        let gpBadge;
        if (currentBranch.totalpass_config?.is_active && selectedClass?.totalpass_configs?.find(tpc => tpc.totalpass_branch_config === currentBranch.totalpass_config?.id)) {
            tpBadge = <span
                className="bg-success text-white font-size-xs rounded px-1">Totalpass</span>
        }
        if (currentBranch.gympass_active && selectedClass?.gympass_config?.branch_configs.find(bc => bc.branch === currentBranch.id)) {
            gpBadge = <span style={{background: 'salmon', color: "white", opacity: "0.8"}}
                            className="font-size-xs rounded px-1">Gympass</span>
        }
        if (tpBadge || gpBadge) {
            return <span>{tpBadge}{gpBadge}</span>
        }
        return <React.Fragment/>
    }

    return (
        <React.Fragment>
            <Modal id="schedule-modal" show={show} onHide={handleClose} size="lg">
                <div className="rounded-modal">
                    <Formik
                        validationSchema={ScheduleSchema}
                        initialValues={{
                            monday: false,
                            tuesday: false,
                            wednesday: false,
                            thursday: false,
                            friday: false,
                            saturday: false,
                            sunday: false,
                            is_recurrent: false,
                            branch: currentBranch.id,
                            publish_date: selectedTimeSlot ?
                                typeof selectedTimeSlot.publish_date === 'string'
                                    ? parseISO(selectedTimeSlot!.publish_date!)
                                    : undefined
                                : undefined,
                            activate_days_before: selectedTimeSlot ? selectedTimeSlot.activate_days_before : null,
                            close_hours_before: selectedTimeSlot ? selectedTimeSlot.close_hours_before : null,
                            studio_class: selectedTimeSlot ? selectedTimeSlot.studio_class : undefined,
                            coaches: selectedTimeSlot ? selectedTimeSlot.coaches.map(c => c.id) : [],
                            coach_ids: selectedTimeSlot ? selectedTimeSlot.coaches.map(c => c.id) : [],
                            people_limit: selectedTimeSlot ? selectedTimeSlot.people_limit : undefined,
                            room: selectedTimeSlot ? selectedTimeSlot.room : undefined,
                            minutes_duration: selectedTimeSlot ? selectedTimeSlot.minutes_duration : 0,
                            start: selectedTimeSlot ? getInitialStartDate() : addMinutes(new Date(), 30),
                            end: selectedTimeSlot ? selectedTimeSlot.start : addMinutes(new Date(), 30),
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            console.log(values)
                            values.close_hours_before = values.close_hours_before || null
                            values.activate_days_before = values.activate_days_before || null
                            if (selectedTimeSlot) {
                                baseApi.patch(`/schedules/slots/${selectedTimeSlot.id}/`, {
                                    ...values,
                                    start: formatISO(values.start),
                                    start_time: formatISO9075(values.start, {representation: "time"}),
                                    coach_ids: values.coaches,
                                }).then((resp) => {

                                    setAlertMsg("Se ha actualizado el horario correctamente.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            } else {
                                baseApi.post("/schedules/", {
                                    ...values,
                                    start: formatISO(values.start),
                                    end: formatISO(values.end),
                                    start_time: formatISO9075(values.start, {representation: "time"}),
                                    publish_date: values.publish_date ? formatISO(values.publish_date) : null
                                }).then(resp => {
                                    let newSlots = resp.data.slots.map((slot: any) => ({
                                        ...slot,
                                        start: parseISO(slot.start)
                                    }))
                                    newSlots = newSlots.filter((slot: TimeSlotV2) => isSameISOWeek(slot.start, weekOf))
                                    setTimeSlots([...timeslots, ...newSlots])
                                    setAlertMsg("Se ha creado el horario correctamente.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }
                        }}
                    >
                        {({isSubmitting, values, setFieldValue}) => (
                            <FormikForm>
                                <Modal.Header
                                    className="font-size-h5 border-0 py-0 mt-3 mb-n8 text-center justify-content-center">
                                    {selectedTimeSlot ? disabled ? "Detalles del horario" : "Editar horario" : "Nuevo Horario"}
                                    <div
                                        className="text-muted font-size-normal">{selectedTimeSlot ? `# ${selectedTimeSlot.id.slice(28).toUpperCase()}` : ""}</div>
                                </Modal.Header>

                                <Modal.Body>
                                    {renderAlert()}

                                    {/* SUCURSAL */}
                                    <div hidden={branchesState.branches.length === 1}>
                                        <div className="">
                                            <div
                                                className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                                <FontAwesomeIcon className="mr-1" icon={faMapPin}/>
                                                Sucursal
                                            </div>
                                        </div>
                                        <ModalSelect label="Sucursal">
                                            <Field name="branch" options={branchesState.selectOptions}
                                                   onChange={(x: any) => {
                                                       if (x) {
                                                           setFieldValue("room", null)
                                                       }
                                                   }}
                                                   isDisabled={selectedTimeSlot !== undefined || disabled}
                                                   component={FormikSelect}/>

                                        </ModalSelect>
                                    </div>

                                    {/* CLASE */}
                                    <div className="mt-n3">
                                        <div
                                            className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                            <FontAwesomeIcon className="mr-1" icon={faRunning}/>
                                            Clase
                                        </div>
                                    </div>
                                    <ModalSelect label="Clase">
                                        <Field name="studio_class"
                                               isDisabled={disabled}
                                               options={classes.map((c) => {
                                                   return ({label: c.name, value: c.id});
                                               })}
                                               component={FormikSelect}
                                               onChange={(option: any) => {
                                                   if (option) {
                                                       setSelectedClass(classes.find(c => c.id === option.value))
                                                   }
                                               }}
                                        />
                                        <FieldError name="studio_class"/>
                                        <div>
                                            {renderIntegrationBadges()}
                                        </div>
                                    </ModalSelect>
                                    {/* COACH */}
                                    <div className="mt-n3">
                                        <div
                                            className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                            <FontAwesomeIcon className="mr-1" icon={faUserCircle}/>

                                            Coach
                                        </div>
                                    </div>
                                    <ModalSelect label="Coach">
                                        <Field name="coaches"
                                               isDisabled={disabled}
                                               options={coaches.map((c) => ({label: c.full_name, value: c.id}))}
                                               component={FormikSelect}
                                               isMulti={true}
                                        />
                                    </ModalSelect>

                                    {/* SALÓN */}
                                    <div className="mt-n3">
                                        <div
                                            className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                            <FontAwesomeIcon className="mr-1" icon={faMapMarked}/>

                                            Salón
                                        </div>
                                    </div>
                                    <ModalSelect label="Salón"
                                                 extra={<div className="d-flex flex-row align-items-center">
                                                     <div className="ml-sm-4 mr-2">
                                                         <span>Capacidad</span>
                                                     </div>
                                                     <MiniFormField disabled={disabled} name="people_limit"/>
                                                     <FieldError name="people_limit"/>
                                                 </div>}>
                                        <Field isClearable={true} name="room"
                                               isDisabled={disabled}
                                               options={rooms.filter(r => r.branch === values.branch).map((r) => ({
                                                   value: r.id,
                                                   label: r.name
                                               }))}
                                               component={FormikSelect}
                                               onChange={(room: any) => {
                                                   if (room) {
                                                       const x = rooms.find(r => r.id === room.value)
                                                       if (x !== undefined) {
                                                           setFieldValue("people_limit", x.people_limit)
                                                       }
                                                   }
                                               }}
                                        />
                                    </ModalSelect>

                                    {/* FECHA */}
                                    <div className="mt-n3">
                                        <div
                                            className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                            <FontAwesomeIcon className="mr-1" icon={faCalendarAlt}/>
                                            Fecha
                                        </div>
                                    </div>
                                    <div style={{width: "550px"}} className="d-md-flex flex-row pb-3">
                                        {/* <div style={{width: "30px"}}>
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </div>
                                        <div style={{width: "90px"}}>
                                            <span className="">Fecha</span>
                                        </div> */}
                                        <div className="customDatePickerWidth" style={{width: "220px"}}>
                                            <ReactDatePicker
                                                disabled={disabled}
                                                dateFormat="dd 'de' MMMM 'del' yyyy"
                                                locale={es}
                                                selected={values.start}
                                                minDate={minDate}
                                                onChange={date => {
                                                    if (date) {
                                                        setFieldValue("start", date)
                                                        if (isBefore(values.end, date as Date)) {
                                                            setFieldValue("end", date)
                                                        }
                                                    }
                                                }}
                                                customInput={<input className="form-control"/>}
                                            />
                                        </div>
                                    </div>


                                    {/* HORA */}
                                    <div className="d-sm-flex">
                                        {/* INICIO HORA */}
                                        <div>
                                            <div className="">
                                                <div
                                                    className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                                    <FontAwesomeIcon className="mr-1" icon={faClock}/>
                                                    Hora
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row align-items-center ">
                                                <div style={{width: "220px"}} className="d-flex flex-row pb-3">
                                                    {/* <div style={{width: "30px"}}>
                                                        <FontAwesomeIcon icon={faClock}/>
                                                    </div>
                                                    <div style={{width: "90px"}}>
                                                        <span className="">Hora</span>
                                                    </div> */}
                                                    <div className="customDatePickerWidth" style={{width: "220px"}}>
                                                        <ReactDatePicker
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            disabled={disabled}
                                                            locale={es}
                                                            selected={values.start}
                                                            minDate={minDate}
                                                            onChange={date => {
                                                                if (date) {
                                                                    setFieldValue("start", date)
                                                                    if (isBefore(values.end, date as Date)) {
                                                                        setFieldValue("end", date)
                                                                    }
                                                                }
                                                            }}
                                                            dateFormat="h:mm aa"
                                                            timeIntervals={15}
                                                            timeCaption={"Hora"}
                                                            customInput={<input className="form-control"/>}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* FIN HORA */}
                                        {/* DURACION */}
                                        <div>
                                            <div className="ml-sm-3 mb-2 mb-sm-0">
                                                {/* <div style={{paddingRight: "25px"}} className="">
                                                    <span>Duración</span>
                                                </div> */}
                                                <div className="">
                                                    <div
                                                        className="label label-inline label-light-primary rounded-lg  font-weight-bold label-lg mt-n1">
                                                        <FontAwesomeIcon className="mr-1" icon={faHourglass}/>
                                                        Duración
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center mt-1">
                                                    <MiniFormField disabled={disabled} name="minutes_duration"/>
                                                    <div className="d-flex pb-2 pl-1 text-secondary">
                                                        <small>minutos</small>
                                                    </div>
                                                </div>
                                                <FieldError name="minutes_duration"/>
                                            </div>
                                        </div>
                                        {/* FIN DURACION */}
                                    </div>

                                    {/* REPETIR */}
                                    <div hidden={selectedTimeSlot !== undefined}
                                         className=" pt-1  bg-secondary pt-6 pb-1 px-3 rounded-lg">

                                        <div className="font-weight-bolder font-size-h6 mb-1 text-center">
                                            Crear copias <span
                                            className="text-muted font-weight-normal font-size-lg"></span>
                                            {/*<small className="text-dark-50 ml-3">Recomendado: dejar en blanco</small>*/}
                                        </div>
                                        <div className="offset-lg-4 w-100" hidden={selectedTimeSlot !== undefined}>
                                            <ModalSelect className="w-300px" label="Crear horarios como este">
                                                <Field name="is_recurrent"
                                                       options={[{value: false, label: "Horario único"}, {
                                                           value: true,
                                                           label: "Crear más horarios como este"
                                                       }]}
                                                       component={FormikSelect}/>
                                            </ModalSelect>
                                        </div>

                                        <div className="offset-lg-1" hidden={!values.is_recurrent}>
                                            <div style={{width: "500px"}} className=" mb-3">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="d-flex align-items-center flex-column">
                                                        <Field name="monday" component={Checkbox}/>
                                                        <label>Lu</label>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-center align-items-center flex-column">
                                                        <Field name="tuesday" component={Checkbox}/>
                                                        <label>Ma</label>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-center align-items-center flex-column">
                                                        <Field name="wednesday" component={Checkbox}/>
                                                        <label>Mi</label>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-center align-items-center flex-column">
                                                        <Field name="thursday" component={Checkbox}/>
                                                        <label>Ju</label>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-center align-items-center flex-column">
                                                        <Field name="friday" component={Checkbox}/>
                                                        <label>Vi</label>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-center align-items-center flex-column">
                                                        <Field name="saturday" component={Checkbox}/>
                                                        <label>Sa</label>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-center align-items-center flex-column">
                                                        <Field name="sunday" component={Checkbox}/>
                                                        <label>Do</label>
                                                    </div>
                                                    <p className="m-0 mx-5">Hasta </p>
                                                    <div className="customDatePickerWidth" style={{width: "220px"}}>
                                                        <ReactDatePicker
                                                            dateFormat="dd 'de' MMMM 'del' yyyy"
                                                            locale={es}
                                                            selected={values.end}
                                                            minDate={addDays(values.start, 1)}
                                                            onChange={date => {
                                                                setFieldValue("end", date)
                                                            }}
                                                            customInput={<input className="form-control"/>}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* ABRIR HORARIO */}
                                    <div>
                                        <div className="bg-secondary pt-6  px-3 rounded-lg mt-2">
                                            <FieldError name="activate_days_before"/>
                                            <div className="font-weight-bolder font-size-h6 mb-1 text-center">
                                                Reservaciones <span
                                                className="text-muted font-weight-normal font-size-lg">(opcional)</span>
                                            </div>
                                            <div className="form-group pb-2">
                                                <div className="row justify-content-center">
                                                    <div
                                                        className="col col-auto col-form-label d-inline-flex align-items-end">
                                                        <label className="font-size-lg mr-2">
                                                            Solo permitir reservar
                                                        </label>
                                                        <MiniFormField disabled={disabled} name="activate_days_before"
                                                                       type="number"/>
                                                        <label className=" font-size-lg ml-n3">
                                                            día(s) antes del horario
                                                        </label>

                                                    </div>
                                                </div>

                                                <div className="row justify-content-center">
                                                    <div className=" col col-auto d-inline-flex align-items-end">
                                                        <div className="font-size-xs mr-1 mb-2">
                                                            <Badge className="badge-size-sm"
                                                                   variant="success">Nuevo</Badge>
                                                        </div>
                                                        <label className=" font-size-lg mr-2">
                                                            Cerrar reservaciones
                                                        </label>
                                                        <MiniFormField disabled={disabled} name="close_hours_before"
                                                                       type="number"/>
                                                        <label className="font-size-lg ml-n3">
                                                            hora(s) antes del horario
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className=" pt-2 bg-secondary pt-6 pb-8 px-3 rounded-lg mt-n5">
                                        <div className="font-weight-bolder font-size-h6 mb-1 text-center">
                                            Programar publicación de horario <span
                                            className="text-muted font-weight-normal font-size-lg">(opcional)</span>
                                        </div>
                                        <div className="text-center">
                                            <div style={{width: ""}}
                                                 className="d-flex pb-1 align-items-center justify-content-center">
                                                {/*<div style={{width: "100px"}}>*/}
                                                {/*    /!*<span className="">- Programar publicación de horario:</span>*!/*/}
                                                {/*</div>*/}
                                                <span className="mr-2">📅 Publicar el:</span>
                                                <div className="customDatePickerWidth" style={{width: "250px"}}>
                                                    <ReactDatePicker
                                                        disabled={disabled}
                                                        dateFormat="dd 'de' MMMM 'del' yyyy, HH:mm"
                                                        locale={es}
                                                        showTimeSelect={true}
                                                        timeCaption={"Hora"}
                                                        selected={values.publish_date}
                                                        minDate={new Date()}
                                                        onChange={date => {
                                                            setFieldValue("publish_date", date)
                                                        }}
                                                        customInput={<input className="form-control"/>}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <small className="text-dark-50">El horario se mantendrá oculto para los
                                                clientes hasta la fecha y hora de publicación programada</small>
                                        </div>
                                        {/*<div>*/}
                                        {/*    <small>* Deja en blanco para publicar en este momento</small>*/}
                                        {/*</div>*/}

                                    </div>
                                </Modal.Body>


                                <Modal.Footer>


                                    {/* BOTONES DESKTOP */}
                                    <div className="d-none d-md-block">
                                        {renderAlert()}
                                        <Button variant="outline-secondary btn-pill font-size-lg" onClick={handleClose}>
                                            Cerrar
                                        </Button>
                                        <Button className="ml-1 font-weight-bold btn-pill font-size-lg" type="submit"
                                                disabled={isSubmitting || disabled}>
                                            {isSubmitting ? "..." : "Guardar"}
                                        </Button>
                                    </div>

                                    {/* BOTONES MOBILE */}
                                    <div className="d-block d-md-none">
                                        {renderAlert()}
                                        <Button variant="outline-secondary btn-block min-w-300px btn-pill"
                                                onClick={handleClose}>
                                            Cerrar
                                        </Button>
                                        <Button
                                            className="font-weight-bold btn-pill font-size-lg min-w-300px btn-block py-5"
                                            type="submit" disabled={isSubmitting || disabled}>
                                            {isSubmitting ? "..." : "Guardar"}
                                        </Button>
                                    </div>


                                </Modal.Footer>
                            </FormikForm>
                        )}
                    </Formik>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default EditCreateScheduleModal;
